const initialState = {
  darck: true,
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "DARCK_MODE":
      return {
        ...state,
        darck: action.payload,
      };
    default:
      return state;
  }
}
