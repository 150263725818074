const intialState = 0;
const SetAffectationMntRetenucliReducer = (state = intialState, action) => {
  switch (action.type) {
    case "AFF_MNT_RETENU_CLI":
      return action.payload;

    default:
      return state;
  }
};
export default SetAffectationMntRetenucliReducer;
