import React, { Component } from "react";
import { Modal, Row, Col, Card } from "react-bootstrap";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
// import { Table } from "reactstrap";
// import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Label } from "reactstrap";
// import OpenIconSpeedDial from "./SpeedDial";
// import ProgressBar from "./ProgressBar";
//import { SelectDevisLig } from "../../redux/actions/GetDevisLig";
import { connect } from "react-redux";
import { Divider, Fab } from "@material-ui/core";
// import { SelectSumQDV } from "../../redux/actions/GetSumQLigDV";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ReceiptIcon from "@material-ui/icons/Receipt";
import EditIcon from "@material-ui/icons/Edit";
// import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ModifierLigDevis from "./ModifierLigDevis";
import { SelectUser } from "../../redux/actions/DevisClient";
import Alert from "@material-ui/lab/Alert";
import ReactToPrint from "react-to-print";
import { SelectClient } from "../../redux/actions/GetClients";
import HomeIcon from "@material-ui/icons/Home";
// import LocationCityIcon from "@material-ui/icons/LocationCity";
import PhoneIcon from "@material-ui/icons/Phone";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import "./ss.scss";
import translate from "../../i18n/translate";
// import "./Responsivedv.css";

const DATE_OPTIONS = {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
};

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class EditDevisClientModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbaropen: false,
      snacckbarmsg: "",
      totalqte: this.props.totalqteee,
      totalq: 0,
      newtable: [],
      open: false,
      hidden: false,
      openModifierModal: false,
      artligs: [],
      show: false,
      snackbaropen: false,
      snackbaropensup: false,
      snackbaropennodel: false,
      snackbarmsg: ",",
      sumsttc: [],
      ttt: 0,
      clientimp: [],
      Assiett:[],
    };
  }

  componentDidMount() {
  {/*  this.props.SelectDevisLig(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
  ); */}



    this.props.SelectUser(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  // submitHandler = (shown, artligs) => {
  //   this.setState({
  //     shown: shown,
  //     artligs: artligs,
  //   });
  // };
  activer = () => {
    // update annuler=0
    this.props.annuler !== "0"
      ? fetch(
          `https://www.24-crm.tn/webservice/api/BCDVCLIs?idD=${this.props.devisid}&typfacC=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.props.onHide();
            this.props.SelectUser(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );

            this.setState({ snackbaropen: true, snackbarmsg: result });
          })
          .then(() => window.location.reload())
      : window.alert("Devis déja activé");
  };
  annuler = () => {
    //update annuler=1
    this.props.annuler === "0"
      ? fetch(
          `https://www.24-crm.tn/webservice/api/BCDVCLIs?idd=${this.props.devisid}&typfaccs=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.setState({ snackbaropen: true, snackbarmsg: result });
            this.props.onHide();
            this.props.SelectUser(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
            window.location.reload();
          })
      : window.alert("Bon de livraison déja annulée");
  };
  imprimer = () => {
    window.alert("imprimer");
  };

  supprimer = () => {
    if (window.confirm("êtes-vous sûr de vouloir supprimer le Devis?")) {
      fetch(
        `https://www.24-crm.tn/webservice/api/LigBCDV/${this.props.devisid}?typfacc=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
        });

      fetch(
        `https://www.24-crm.tn/webservice/api/BCDVCLIs/${this.props.devisid}?typfacc=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.props.onHide();
          this.props.SelectUser(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          this.setState({ snackbaropen: true, snackbarmsg: result });
        })
        .then(() => window.location.reload());
    }
  };

  nonsupprimer = () => {
    window.alert(
      "Vous devez annuler ce devis pour que vous puissiez le supprimer"
    );
  };

  openModifier = () => {
    this.setState({ openModifierModal: true });
  };

  handleOpen = () => {
    this.setState({ open: true });
      //avoir le client de code = codeclient
    fetch(
      `https://www.24-crm.tn/webservice/api/Clients?codeclient=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ clientimp: data }));
 //avoir Assiette/TVA/Montant
    fetch(
      `https://www.24-crm.tn/webservice/Api/TVA?numfacture=${this.props.devisid}&type=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ Assiett: data }));
  };



  
  handleClose = () => {
    this.setState({ open: false });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarClosesup = (event) => {
    this.setState({ snackbaropensup: false });
  };

  snackbarClosenodel = (event) => {
    this.setState({ snackbaropennodel: false });
  };

  render() {
    console.log("devis",this.props.tabtab)
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    const actions =
      this.props.Auth.fct == "100"
        ? [
            { icon: <EditIcon />, name: "Modifier" },
            {
              icon:
                this.props.annuler === "0" ? (
                  <CancelPresentationIcon />
                ) : (
              //     <SlideshowIcon />
              //   ),
              // name: this.props.annuler === "0" ? "Annuler" : "Activer",
              <DeleteOutlineIcon />
            ),
          name: this.props.annuler === "0" ? "Annuler" : "Supprimer",
            },
           // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
          ]
        : [
            { icon: <EditIcon />, name: "Modifier" },
            {
              icon:
                this.props.annuler === "0" ? (
                  <CancelPresentationIcon />
                ) : (
              //     <SlideshowIcon />
              //   ),
              // name: this.props.annuler === "0" ? "Annuler" : "Activer",
              <DeleteOutlineIcon />
            ),
          name: this.props.annuler === "0" ? "Annuler" : "Supprimer",  
            },
          ];

    const { darck, classes } = this.props;
    const altText = this.props.ConfigDossier.coddos+".jpg";
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropensup}
          autoHideDuration={2000}
          onClose={this.snackbarClosesup}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClosesup}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropennodel}
          autoHideDuration={4000}
          onClose={this.snackbarClosenodel}
        >
          <Alert onClose={this.snackbarClosenodel} severity="error">
            Vous devez l'annuler d'abord pour le supprimer
          </Alert>
        </Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>
                Devis client N° {this.props.devisid} du{" "} 
                {new Date(this.props.datedevis).toLocaleDateString(
                  DATE_OPTIONS
                )}
           </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Card>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                {this.props.MG ? (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h5" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Client
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h5" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Raison sociale
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "#020f64" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h5" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Magasin
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.MG}
                      </Typography>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={6}>
                      <Typography variant="h5" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Client
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={6}>
                      <Typography variant="h5" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Raison sociale
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                {/* <div className="ligbc-table">tabd28 tabbl2*/}
                <div className="tabdv2">
                  <table stripped>
                    <thead>
                      <tr style={{ textAlign: "center", fontSize: "12px" }}>
                        <th>Code</th>
                        <th style={{ width: "40%" }}>Désignation</th>
                        <th>Quantité</th>
                        {/* <th>Unité</th> */}
                        <th>PUHT</th>
                        <th>Remise</th>
                        <th>TVA</th>
                        <th>PUTTCNet</th>
                        <th>TotalHT</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        overflowY: "auto",
                        display: "block",
                        maxHeight: "10em",
                      }}
                    >
                      {this.props.tabtab.map((t, i) => (
                        <tr key={i} style={{ textAlign: "center" }}>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{t.codart}</span>
                          </td>
                          <td
                            style={{
                              color: `${darck ? "#020f64" : "#fff"}`,
                              fontSize: "12px",
                             
                            }}
                          >
                            {t.desart}
                          </td>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}`,  }}
                          >
                        <span>{t.quantite}</span>   
                          </td>
                          {/* <td>
                            <span>{t.unite}</span>
                          </td> */}
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                          <span>{Number(t.priuni).toFixed(3)}</span>  
                          </td>

                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                          <span>{Number(t.remise).toFixed(2)}</span>  
                          </td>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                           <span>{Number(t.tautva).toFixed(2)}</span> 
                          </td>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                          <span>{Number(t.PUTTCNET).toFixed(3)}</span> 
                          </td>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                          <span>{Number(t.montht).toFixed(3)}</span>  
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }} id="dv1">
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                <Row style={{ marginTop: "10px" }}>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total HT Brut
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Remise Article
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Remise Globale
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total HT Net
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total TVA
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>
                </Row>

                {/* ///////////////////2eme/////////////////////////////////// */}

                <Row style={{ margin: "5px , 0" }}>
                  <Col id="d">
                    <Divider
                      style={{ marginTop: "-8px", backgroundColor: "grey" }}
                    ></Divider>
                  </Col>
                </Row>

                <Row style={{ marginBottom: "-25px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total TTC
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(
                        parseFloat(this.props.totalttc) -
                          parseFloat(this.props.valtimbree) -
                          parseFloat(this.props.avanceimpot)
                      ).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {/* Total Quantité */}
                      Valeur de Timbre
                    </p>
                    {/* {this.state.sums.map((sum) => (
                      <p style={{ color: "black" }}>{sum.Column1}
                      </p> */}
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* {this.props.sumqt} */}
                      {Number(this.props.valtimbree).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Avance Impôt
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* {Number(this.state.totaltva).toFixed(3)} */}
                      {Number(this.props.avanceimpot).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                    <p
                      style={{
                        color: `${darck ? "black" : "#fff"}`,
                        fontWeight: "bold",
                      }}
                    >
                      {/* {Number(this.props.totalttc).toFixed(3)} */}
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ////////Responsive////////// */}
            <Card style={{ marginTop: "10px" }} id="dv2">
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                <Row className="rowdv">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total HTBrut
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Remise Article
                    </p>
                    <p style={{ color: "black" }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Remise Globale
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>
                  </Col>
                </Row>{" "}
                <Row style={{ margin: "5px , 0" }}>
                  <Col id="d">
                    <Divider
                      style={{ marginTop: "-8px", backgroundColor: "grey" }}
                    ></Divider>
                  </Col>
                </Row>
                <Row className="rowdv">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total HT Net
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total TVA
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Total TTC
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(
                        parseFloat(this.props.totalttc) -
                          parseFloat(this.props.valtimbree) -
                          parseFloat(this.props.avanceimpot)
                      ).toFixed(3)}
                    </p>
                  </Col>
                </Row>
                {/* ///////////////////2eme/////////////////////////////////// */}
                <Row style={{ margin: "5px , 0" }}>
                  <Col id="d">
                    <Divider
                      style={{
                        marginTop: "-8px",
                        backgroundColor: `${darck ? "#fff" : "grey"}`,
                      }}
                    ></Divider>
                  </Col>
                </Row>
                <Row className="rowdv">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {/* Total Quantité */}
                      Valeur Timbre
                    </p>
                    {/* {this.state.sums.map((sum) => (
                      <p style={{ color: "black" }}>{sum.Column1}
                      </p> */}
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* {this.props.sumqt} */}
                      {Number(this.props.valtimbree).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Avance Impôt
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* {Number(this.state.totaltva).toFixed(3)} */}
                      {Number(this.props.avanceimpot).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      Net à Payer
                    </p>
                    <p
                      style={{
                        color: `${darck ? "black" : "#fff"}`,
                        fontWeight: "bold",
                      }}
                    >
                      {/* {Number(this.props.totalttc).toFixed(3)} */}
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ///////////////////SpeedDial//////////////////////////////// */}
            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "10px",
                  }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        //  action.name == "Imprimer" && this.imprimer();
                        action.name == "Modifier" && this.openModifier();
                        action.name == "Supprimer" &&
                          this.props.annuler === "1" &&
                          this.supprimer();
                        action.name == "Supprimer" &&
                          this.props.annuler === "0" &&
                          this.nonsupprimer();
                        action.name == "Annuler" && this.annuler();
                        action.name == "Activer" && this.activer();
                      }}
                    />
                  ))}
                  {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "#fff",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "#fff",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}

                  {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "#fff",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <ReceiptIcon />
                        </Fab>
                      )}
                      content={() => this.ticketRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "#fff",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <ReceiptIcon />
                        </Fab>
                      )}
                      content={() => this.ticketRef}
                    />
                  )}
                </SpeedDial>
              </Col>
            </Row>

            <ModifierLigDevis
              totaltva={this.props.totaltva}
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              numfacc={this.props.devisid}
              datfac={this.props.datedevis}
              onHide01={this.props.onHide}
              taurem={this.props.taurem}
              catfisc={this.props.catfisc}
              client={this.props.client}
              regimecli={this.props.regimecli}
              raisonsociale={this.props.raisonsociale}
              droitdetimbre={this.props.droitdetimbre}
              avanceimpot={this.props.avanceimpot}
              valtimbre={this.props.valtimbre}
            />
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: "none",
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          ></Modal.Footer>
        </Modal>

        {/* /////////////////// print //////////////////////// */}

        <div style={{ display: "none" }}>
          <div
            ref={(el) => (this.componentRef = el)}
            style={{ margin: "40px  35px", height: "94%" }}
          >
            {/* <img
  src={altText}
  style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} /> */}
          
    {/* logoetat */}        
    <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
          
            <h1 style={{ fontSize: "50px", fontWeight: "bolder" }}> {this.props.InfoEntreprise[0].lib}</h1>
            <p style={{ fontSize: "20px", marginTop: "-10px" }}>
              {this.props.InfoEntreprise[0].spe}
              <br />
              Adresse : {this.props.InfoEntreprise[0].adr},{this.props.InfoEntreprise[0].ville},{this.props.InfoEntreprise[0].pays}<br />
              Tél 1 :{this.props.InfoEntreprise[0].tel1} <br/>
              Tél 2 :{this.props.InfoEntreprise[0].tel2} <br/>
              Email:{" "}{this.props.InfoEntreprise[0].email} <br/>
              Maricule Fiscale   :  {this.props.InfoEntreprise[0].codtva} <br/>
              RIB: {this.props.InfoEntreprise[0].rib}{" "}<br/>
              {translate("Etabli par")}: {this.props.vendeur} </p>



            <h5
              style={{
                marginLeft: "130px",
                flexDirection: "column",
                marginTop: "25px",
                marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >
              Devis № {this.props.devisid}{" "}
            </h5>
            <Row>
              <Col>
                <h6 style={{ marginLeft: "170px", marginTop: "40px" }}>
                  Date:{" "}
                  {new Date(this.props.datedevis).toLocaleDateString(
                    DATE_OPTIONS
                  )}
                </h6>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>Code Client : {this.props.client}</h6>
                  {this.state.clientimp.map((t) => (
                    <h6 style={{ marginRight: "20px" }}>
                      <PhoneIcon /> {t.tel1} / {t.tel2}
                    </h6>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: "5px",
                    borderStyle: "solid",
                    height: "150px",
                    width: "95%",
                    borderRadius: "20px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3> {this.props.raisonsociale} </h3>
                    {this.state.clientimp.map((t) =>
                      t.adr === "" ? (
                        <h5>
                          <HomeIcon /> --
                        </h5>
                      ) : (
                        <h5>
                          <HomeIcon /> {t.adr}
                        </h5>
                      )
                    )}

                    {this.state.clientimp.map((t) =>
                      t.ville === "" ? <h5>--</h5> : <h5>{t.ville}</h5>
                    )}
                  </div>
                </div>

                {this.state.clientimp.map((t) =>
                  t.codtva === "" ? (
                    <h6>Code TVA : --</h6>
                  ) : (
                    <h6>Code TVA : {t.codtva}</h6>
                  )
                )}
              </Col>
            </Row>
            {/* <br /> */}
            <div
              style={{
                marginLeft: "5px",
                marginTop: "10px",
                width: "99%",
                minHeight: "540px",
                padding: "20px 25px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  borderStyle: "5px",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >


             {/*    <tr style={{ borderBottom: "1px solid grey" }}>
                    <th>Code</th>
                    <th style={{ width: "37%" }}>Désignation</th>
                    <th>Quantité</th>
                    <th>PUHT</th>
                    <th>Remise</th>
                    <th>TVA</th>
                    <th>TotalHT</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.tabtab.map((t, i) => (
                    <tr key={i}
                        style={{ textAlign: "center",height: "60px",borderBottom: "1px solid grey", }}>
                      <td>{t.codart}</td>
                      <td style={{ width: "37%" }}>{t.desart}</td>
                      <td>{t.quantite}</td>
                      <td>{Number(t.priuni).toFixed(3)}</td>
                      <td>{Number(t.remise).toFixed(2)}</td>
                      <td>{Number(t.tautva).toFixed(2)}</td>
                  <td>{Number(t.montht).toFixed(3)}</td></tr>    */} 




              <tr style={{ borderBottom: "1px solid grey" }}>
              <th>{translate("Code")}</th>
              <th style={{ width: "35%" }}>{translate("Désignation")}</th>
              <th>{translate("Qté")}</th>
              <th>{translate("UN")}</th>
              <th>{translate("P.U.H.T")}</th>
              <th>{translate("TVA%")}</th>
              <th>{translate("Pu Ttc Net")}</th>
              <th>{translate("Rem%")}</th>
              <th>{translate("Mont.HT")}</th> </tr> </thead>
          <tbody>
            {this.props.tabtab.map((t, i) => (
              <tr
                key={i}
                style={{
                  textAlign: "center",
                  height: "60px",
                  borderBottom: "1px solid grey", }}>
                <td  style={{ color: `${darck ? "darkslateblue" : "#fff"}` }} >  {t.codart} </td>
                {t.quantite == "0" ? (
                  <td style={{
                      width: "37%",
                      fontWeight: "bold",
                      color: `${darck ? "darkslateblue" : "#fff"}`,}}>{t.desart}</td>
                ) : (
                <td style={{ width: "37%" }}>{t.desart}</td>)}
                <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}>{t.quantite}</td>
                <td><span>{t.unite}</span></td> 
                <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }} > {Number(t.priuni).toFixed(3)} </td>
                <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}  > {Number(t.tautva).toFixed(2)} </td>
                <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}> {Number(t.ttclig).toFixed(3) / Number(t.quantite)} </td>
                <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}> {Number(t.remise).toFixed(2)} </td>
                <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}>{Number(t.montht).toFixed(3)} </td></tr>




                  ))}
                </tbody>
              </table>
            </div>
            {/* <br /> */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                {/* <table
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    width: "450px",
                    borderWidth: "1px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    <tr><th>T.V.A</th>
                        <th>Assiette</th>
                        <th>Montant</th>  </tr> </thead>
                  {this.state.Assiett.map((t, i) => (
                    <tbody key={i}>
                        <tr style={{ height: "50px" }}>
                          <td>{t.TauxTva}</td>
                          <td>{t.Assiette}</td>
                          <td>{t.Montant}</td> </tr>  </tbody> ))}




                      
                </table> */}
                 <table
                          style={{
                            textAlign: "center",
                            borderStyle: "solid",
                            width: "450px",
                            borderWidth: "1px",
                          }}
                        >
                          <thead
                            style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            <tr>
                              <th>{translate("TVA")}</th>
                              <th>{translate("Assiette")}</th>
                              <th>{translate("Montant")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Initialize the totals */}
                            {(() => {
                              const tvaGroups = {};

                              // Grouping by TVA and summing the values
                              this.props.tabtab.forEach((t) => {
                                const tvaRate = Number(t.tautva).toFixed(2);

                                if (!tvaGroups[tvaRate]) {
                                  tvaGroups[tvaRate] = { assiette: 0, montant: 0 };
                                }

                                tvaGroups[tvaRate].assiette += Number(t.montht);
                                tvaGroups[tvaRate].montant += Number(t.ttclig - t.montht);
                              });

                              // Render the rows for each TVA group
                              return Object.keys(tvaGroups).map((tvaRate) => (
                                <tr key={tvaRate} style={{ height: "50px" }}>
                                  <td>{tvaRate}</td>
                                  <td>{tvaGroups[tvaRate].assiette.toFixed(3)}</td>
                                  <td>{tvaGroups[tvaRate].montant.toFixed(3)}</td>
                                </tr>
                              ));
                            })()}
                          </tbody>
                        </table>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              >
                <h5>
                  <b>Total quantité: </b>
                </h5>
                &nbsp;&nbsp;&nbsp; <h5>{this.props.sumqt}</h5>
              </div>

              <table
                style={{
                  borderStyle: "solid",
                  width: "270px",
                  marginRight: "10px",
                  borderWidth: "1px",
                }}
              >
                {Number(this.props.remiseglobale) != 0 ? (
                  <>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;Total.H.T Brut:
                      </td>
                      <td> {Number(this.props.totalhtbrut).toFixed(3)}</td>
                    </tr>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;Remise Globale:
                      </td>
                      <td> {Number(this.props.remiseglobale).toFixed(3)}</td>
                    </tr>
                  </>
                ) : null}

                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    &nbsp;&nbsp;&nbsp;Total.H.T Net:
                  </td>
                  <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Total TVA:
                  </td>
                  <td>{Number(this.props.totaltva).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Timbre fiscal:
                  </td>
                  <td>{Number(this.props.valtimbre).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Net à Payer:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {Number(
                      parseFloat(this.props.totalttc) +
                        parseFloat(this.props.valtimbre)
                    ).toFixed(3)}{" "}
                  </td>
                </tr>
              </table>
            </div>
            {/* <br />
            <br /> */}
          {/* //////////////////////// footer /////////////////////////////  */} 
            {Number(this.props.remiseglobale) != 0 ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature Client:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature & cachet:
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  marginTop: "25px",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    marginLeft: "5px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Notes:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "350px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature Client:
                </div>

                <div
                  style={{
                    borderStyle: "solid",
                    borderColor: "grey",
                    width: "340px",
                    marginRight: "10px",
                    borderWidth: "1px",
                    height: "120px",
                    textAlign: "left",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;Signature & cachet:
                </div>
              </div>
            )}
          </div>
        </div>

        {/* impression ticket  */}
        <div style={{ display: "none" }}>
          <div ref={(el) => (this.ticketRef = el)}>
            <h1
              style={{
                fontSize: "100px",
                textAlign: "center",
                fontWeight: "bolder",
                marginBottom: "40px",
                marginTop: "300px",
              }}
            >
              Société {this.props.InfoEntreprise[0].coddos}
            </h1>

            <h5
              style={{
                marginLeft: "120px",
                flexDirection: "column",
                marginTop: "120px",
                marginBottom: "40px",
                fontWeight: "bolder",
                fontSize: "100px",
              }}
            >
              Devis № {this.props.devisid}{" "}
            </h5>
            <Row>
              <Col>
                <h6
                  style={{
                    marginLeft: "170px",
                    marginTop: "60px",
                    fontSize: "50px",
                  }}
                >
                  {new Date(this.props.datedevis).toLocaleDateString(
                    DATE_OPTIONS
                  )}
                </h6>
              </Col>
              <Col>
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    alignItems: "center",
                    marginTop: "50px",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                ></div>

                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "80px",
                        marginTop: "70px",
                        marginRuight: "35px",
                      }}
                    >
                      {" "}
                      {this.props.raisonsociale}{" "}
                    </h3>
                  </div>
                </div>

                {this.state.clientimp.map((t) =>
                  t.codtva === "" ? (
                    <h6 style={{ fontSize: "65px", marginTop: "50px" }}>
                      Code TVA : --
                    </h6>
                  ) : (
                    <h6 style={{ fontSize: "65px", marginTop: "50px" ,marginLeft:"-200px" }}>
                      Code TVA : {t.codtva}
                    </h6>
                  )
                )}
              </Col>
            </Row>
            <br />
            <div
              style={{
                marginLeft: "5px",
                marginTop: "50px",
                width: "99%",
                minHeight: "540px",
                padding: "20px 25px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  borderStyle: "5px",
                  width: "100%",
                  marginTop: "60px",
                  paddingTop: "15px",
                }}
              >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "50px",
                    paddingTop: "10px",
                  }}
                >
                  <tr
                    style={{
                      borderBottom: "1px solid grey",
                      paddingBottom: "30px",
                    }}
                  >
                    <th style={{ paddingBottom: "30px" }}>Qté</th>
                    <th style={{ width: "37%", paddingBottom: "30px" }}>
                      Article
                    </th>

                    <th style={{ paddingBottom: "30px" }}>PUHT</th>

                    <th style={{ paddingBottom: "30px" }}>TotalHT</th>
                  </tr>
                </thead>

                <tbody style={{ fontSize: "45px" }}>
                  {this.props.tabtab.map((t, i) => (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        height: "60px",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <td style={{ paddingBottom: "45px" }}>{t.quantite}</td>
                      <td style={{ width: "37%", paddingBottom: "45px" }}>
                        {t.desart}
                      </td>

                      <td style={{ paddingBottom: "45px" }}>
                        {Number(t.priuni).toFixed(3)}
                      </td>

                      <td style={{ paddingBottom: "45px" }}>
                        {Number(t.montht).toFixed(3)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <br />
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "160px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  width: "400px",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                }}
              >
                <h5>
                  <b style={{ fontSize: "40px" }}>Nbre d'article: </b>
                </h5>
                &nbsp;&nbsp;&nbsp;{" "}
                <h5 style={{ fontSize: "40px" }}>{this.props.sumqt}</h5>
              </div>

              <table
                style={{
                  borderStyle: "solid",
                  width: "700px",
                  fontSize: "50px",
                  paddingBottom: "35px",
                  marginRight: "50px",
                  borderWidth: "1px",
                }}
              >
                {Number(this.props.remiseglobale) != 0 ? (
                  <>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;Total.H.T Brut:
                      </td>
                      <td> {Number(this.props.totalhtbrut).toFixed(3)}</td>
                    </tr>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;Remise Globale:
                      </td>
                      <td> {Number(this.props.remiseglobale).toFixed(3)}</td>
                    </tr>
                  </>
                ) : null}

                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    &nbsp;&nbsp;&nbsp;Total.H.T Net:
                  </td>
                  <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Total TVA:
                  </td>
                  <td>{Number(this.props.totaltva).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Timbre fiscal:
                  </td>
                  <td>{Number(this.props.valtimbre).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;Net à Payer:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {Number(
                      parseFloat(this.props.totalttc) +
                        parseFloat(this.props.valtimbre)
                    ).toFixed(3)}{" "}
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
   // SelectDevisLig: (a, b, c, d, e) => dispatch(SelectDevisLig(a, b, c, d, e)),
    SelectUser: (a, b, c, d, e) => dispatch(SelectUser(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    ligs: state.ligs,
    clients: state.clients,
    ConfigDossier: state.ConfigDossier,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditDevisClientModal);
