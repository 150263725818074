import React from "react";

class UploadImage extends React.Component {
  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      this.convertToBase64(file);
    }
  };

  convertToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64String = reader.result
        .replace("data:", "").replace(/^.+,/, "");
      this.props.onFileSelect(base64String); // Pass the Base64 value to the parent component
    };
  
  };

  render() {
    return (
      <input
        type="file"
        id="outlined-basic"
        label="Photo"
        variant="outlined"
        margin="dense"
        fullWidth
        name="photo"
        onChange={this.handleFileChange}
        style={{  marginTop: "15px",  color: !this.props.darck ? "#fff" : null,}}
      
      />
    );
  }
}

export default UploadImage;
