const intialState = 0;
const SetAffectationMntRetenuFRSReducer = (state = intialState, action) => {
  switch (action.type) {
    case "AFF_MNT_RETENU_FRS":
      return action.payload;

    default:
      return state;
  }
};
export default SetAffectationMntRetenuFRSReducer;
