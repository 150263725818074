import React, { Component } from "react";
import { Modal, Card, Row, Col, Alert } from "react-bootstrap";
import "../styling/Styles.css";

import LanguageIcon from "@material-ui/icons/Language";
import { Divider, Snackbar, IconButton } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ListAltIcon from '@material-ui/icons/ListAlt';
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import {Fab} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ModifierMVTCAISModal from "./ModifierMVTCAISModal";

import MailModal from "./MailModal";
import moment from 'moment';
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import translate from "../../i18n/translate";

const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  { icon: <EditIcon />, name: "Modifier" },
  { icon: <DeleteOutlineIcon />, name: "Supprimer" },
 // { icon: <ListAltIcon />, name: "Relevé" },
];

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class GetMVTCAIByID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      openReleveClientModal: false,
      openMailModal: false,
      snackbaropen: false,
      snackbaropenn: false,
      snackbarmsg: "",
      defaultdate: date,
    };
  }

  // componentDidMount() {
  //   document.getElementById("tell").innerHTML =
  //     '<a href="tel:' + this.props.tel1 + '">' + this.props.tel1 + "</a>";
  // }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    this.setState({ openMailModal: true });
  };

  openModifier = () => {
    this.setState({ openModifierModal: true });
  };

  openReleveClient = () => {
    this.setState({ openReleveClientModal: true });
  };

  nomail = () => {
    this.setState({ snackbaropen: true });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarClosee = (event) => {
    this.setState({ snackbaropenn: false });
  };


  deleteclient(clientid) {
    if (window.confirm("confirme-vous la suppression?",this.props.numopr)) {
fetch(`https://www.24-crm.tn/webservice/api/Caisse?id=${this.props.numopr}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, {
      method: "Delete",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
      },
     // Convert the object to JSON
    })
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({ snackbaropenn: true, snackbarmsg: result });
            this.props.onHide();

      
          },

          (error) => {
            this.setState({ snackbaropenn: true, snackbarmsg: "failed" });
          }
        );
      //  this.props.SelectArticle();
      //window.location.reload();
    }
  }

  call1 = () => {
    fetch(
      `https://localhost/couplagetel/calling.php?exten=${this.props.tel1}&number=${this.props.tel1}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) =>
        //console.log(data)
        window.alert(data)
      );
  };

  call2 = () => {
    fetch(
      `https://localhost/couplagetel/calling.php?exten=${this.props.tel2}&number=${this.props.tel2}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) =>
        //console.log(data)
        window.alert(data)
      );
  };

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    let ReleveClientModalClose = () => this.setState({ openReleveClientModal: false });
    let MailModalClose = () => this.setState({ openMailModal: false });
    const { darck, classes } = this.props;
    const result = convertToDinarsAndMillimes(this.props.montant);
    this.props.css.css.forEach((caisse, index) => {
    console.log(`Caisse ${index + 1} lib:`, caisse.lib);
    });
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropenn}
          autoHideDuration={2000}
          onClose={this.snackbarClosee}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClosee}
              style={{
                
                color: `${darck ? "#020F64" : "#fff"}`
              }}

            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
       
        </Snackbar>

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Détails MVT Caisse {this.props.numopr} </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
               marginBottom: "15px", 
            }}
          >
       
       <Row style={{ display: "flex", alignItems: "stretch"}}> 
 
       <Col sm={12}>
            <Card  className="card0" style={{ height: "100%"}}>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  borderTop: `${darck ? "" : "1px solid #fff"}`,
                  borderRight: `${darck ? "" : "1px solid #fff"}`,
                  borderLeft: `${darck ? "" : "1px solid #fff"}`,
                  borderBottom: `${darck ? "" : "1px solid #fff"}`,
                }}
              >
                <Row style={{ marginLeft: "10px" }}>
                  <Col sm={3}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: `${darck ? "#172431" : "#fff"}`,
                       
                      }}
                    >
                      Libellé 
                    </p>
                    {this.props.libelle === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}`}}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.libelle}
                      </p>
                    )}
                  </Col>
                  {/* <Col sm={6}>
                    
                  </Col> */}
                  <Col sm={3}>
                    <p
                      style={{
                        color: `${darck ? "#172431" : "#fff"}`,
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Date 
                    </p>
                    {this.props.datopr === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        { moment(this.props.datopr).format("DD/MM/YYYY")}
                    
                      </p>
                    )}
                  </Col>
                  {/* <Col sm={6}>
                  
                  </Col> */}
                {/* </Row>

                <Row style={{ marginLeft: "10px" }}> */}
                  <Col sm={4}>
                    <p
                      style={{
                        color: `${darck ? "black" : "#fff"}`,
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                     Mode Réglement
                    </p>
                    {this.props.modreg === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.modreg}
                      </p>
                    )}
                  </Col>
                  {/* <Col sm={6}>
                 
                  </Col> */}
                  <Col sm={2}>
                    <p
                      style={{
                        color: `${darck ? "#172431" : "#fff"}`,
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Caisse
                    </p>
                    {this.props.numcais === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.numcais}
                      </p>
                    )}
                  </Col>
                  {/* <Col sm={6}>
                 
                  </Col> */}
                </Row>
              </Card.Body>
            </Card>
       </Col>
       </Row>
       <Row style={{ display: "flex", alignItems: "stretch" }}>
  <Col sm={6}>
    <Card className="card0" style={{ height: "100%", marginTop: "15px" }}>
      <Card.Body
        style={{
          backgroundColor: `${darck ? "#fff" : "#172431"}`,
          borderTop: `${darck ? "" : "2px solid #fff"}`,
          borderRight: `${darck ? "" : "2px solid #fff"}`,
          borderLeft: `${darck ? "" : "2px solid #fff"}`,
          borderBottom: `${darck ? "" : "2px solid #fff"}`,
          borderRadius: "5px",
       
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <Col sm={6}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: `${darck ? "#172431" : "#fff"}`,
              }}
            >
              Montant
            </p>
          </Col>
          <Col sm={6}>
            {this.props.montant === "" ? (
              <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
            ) : (
              <p
                style={{
                  color: `${darck ? "gray" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                {Number(this.props.montant).toFixed(3)}
              </p>
            )}
          </Col>
          <Col sm={6}>
            <p
              style={{
                color: `${darck ? "#172431" : "#fff"}`,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              sens
            </p>
          </Col>
          <Col sm={6}>
            {this.props.sens === 'd' ? (
              <p style={{ color: `${darck ? "gray" : "#fff"}` }}>Recette</p>
            ) : (
              <p
                style={{
                  color: `${darck ? "gray" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                Depense
              </p>
            )}
          </Col>
        </Row>

        <Row style={{ marginLeft: "10px" }}>
          <Col sm={6}>
            <p
              style={{
                color: `${darck ? "black" : "#fff"}`,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Nature dépense
            </p>
          </Col>
          <Col sm={6}>
            {this.props.depense === "" ? (
              <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
            ) : (
              <p
                style={{
                  color: `${darck ? "gray" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                {this.props.depense}
              </p>
            )}
          </Col>

          {this.props.sens === 'c' ? (
            <>
              <Col sm={6}>
                <p
                  style={{
                    color: `${darck ? "#172431" : "#fff"}`,
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  Caisse Destination
                </p>
              </Col>
              <Col sm={6}>
                {this.props.numcais === "" ? (
                  <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                ) : (
                  <p
                    style={{
                      color: `${darck ? "gray" : "#fff"}`,
                      fontSize: "12px",
                    }}
                  >
                    {this.props.numcais}
                  </p>
                )}
              </Col>
            </>
          ) : null}
        </Row>
      </Card.Body>
    </Card>
  </Col>

  <Col sm={6}>
    <Card className="card0" style={{ height: "100%", marginTop: "15px" }}>
      <Card.Body
        style={{
          backgroundColor: `${darck ? "#fff" : "#172431"}`,
          borderTop: `${darck ? "" : "2px solid #fff"}`,
          borderRight: `${darck ? "" : "2px solid #fff"}`,
          borderLeft: `${darck ? "" : "2px solid #fff"}`,
          borderBottom: `${darck ? "" : "2px solid #fff"}`,
          borderRadius: "5px",
   
        }}
      >
        <Row style={{ marginLeft: "10px" }}>
          <Col sm={6}>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                color: `${darck ? "#172431" : "#fff"}`,
              }}
            >
              N° cheque
            </p>
          </Col>
          <Col sm={6}>
            {this.props.numchq === "" ? (
              <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
            ) : (
              <p
                style={{
                  color: `${darck ? "gray" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                {this.props.numchq}
              </p>
            )}
          </Col>
          <Col sm={6}>
            <p
              style={{
                color: `${darck ? "#172431" : "#fff"}`,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Date echéance
            </p>
          </Col>
          <Col sm={6}>
            {this.props.datech === 'd' ? (
              <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
            ) : (
              <p
                style={{
                  color: `${darck ? "gray" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                {moment(this.props.datech).format("DD/MM/YYYY")}
              </p>
            )}
          </Col>
        </Row>

        <Row style={{ marginLeft: "10px" }}>
          <Col sm={6}>
            <p
              style={{
                color: `${darck ? "black" : "#fff"}`,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Banque
            </p>
          </Col>
          <Col sm={6}>
            {this.props.matban === "" ? (
              <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
            ) : (
              <p
                style={{
                  color: `${darck ? "gray" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                {this.props.matban}
              </p>
            )}
          </Col>
          <Col sm={6}>
            <p
              style={{
                color: `${darck ? "black" : "#fff"}`,
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              Employé
            </p>
          </Col>
          <Col sm={6}>    
            {this.props.Emp === "" ? (
          <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
            ) : (
              <p
                style={{
                  color: `${darck ? "gray" : "#fff"}`,
                  fontSize: "12px",
                }}
              >
                {this.props.Emp}
              </p>
            )} 
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Col>
</Row>


            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                        this.props.email !== "" &&
                          action.name === "Mail" &&
                          this.openMail();
                        this.props.email === "" &&
                          action.name === "Mail" &&
                          this.nomail();
                        action.name === "Modifier" && this.openModifier();
                        action.name === "Supprimer" &&
                          this.deleteclient(this.props.codeclient) &&
                          this.props.onHide();
                        action.name === "Relevé" && this.openReleveClient();
                      }}
                    />
                  ))}
                        {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
                {/* <Button
                  // variant="primary"
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "70px",
                    backgroundColor: "#4153AF",
                    border: "1px solid #4153AF",
                  }}
                  onClick={this.openReleveClient}
                >
                  Relevé
                </Button> */}
              </Col>
            </Row>

            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
 
  
              <h1 style={{ fontSize: "30px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>

              <br />
             <h6 style={{  fontSize: "20px",marginLeft: "850px"}}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
             <br />
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <> Reçu du N° {this.props.numopr} </>
            
              </h5>
              <br/> <br />
             
              <br />
               {this.props.css && this.props.css.css && this.props.css.css.length > 0 ? ( this.props.css.css.map((caisse, index) => (<>
              {this.props.numcais===caisse.code ?(<> <p style={{ fontSize: "16px", marginTop: "-10px" ,marginLeft: "25px",}} key={index}> Caisse : {this.props.numcais} {caisse.lib}</p></>):null} </> ))
              ) : ( this.props.css.css.map((caisse, index) => (<>
              {this.props.numcais===caisse.code ?(<> <p style={{ fontSize: "16px", marginTop: "-10px" ,marginLeft: "25px",}} key={index}> Caisse :</p></>):null} </> ))
              )}
             <br /> 
             {this.props.nomenclatures && this.props.nomenclatures.nomenclatures && this.props.nomenclatures.nomenclatures.length > 0 ? ( this.props.nomenclatures.nomenclatures.map((reg, index) => (<>
              {this.props.modreg===reg.code ?(<> <p style={{ fontSize: "16px", marginTop: "-10px" ,marginLeft: "25px",}} key={index}> Réglement :{" "} {this.props.modreg}{" "}{reg.lib}{" "}N°{this.props.numchq} {" "}</p></>):null} </> ))
              ) : ( this.props.nomenclatures.nomenclatures.map((reg, index) => (<>
              {this.props.modreg===reg.code ?(<> <p style={{ fontSize: "16px", marginTop: "-10px" ,marginLeft: "25px",}} key={index}> Réglement : </p></>):null} </> ))
              )}
                <br />  
                <p style={{ fontSize: "16px", marginTop: "-10px" ,marginLeft: "25px",}}>
               
                Banque : {this.props.matban}<br /><br />
                Libellé: {this.props.libelle} <br /><br />
                Montant En Chiffres:{" "} {this.props.montant} <br /><br />
                Montant En Lettres:{" "} {result}..... <br /><br />
                Date du réglement : {" "} { moment(this.props.datopr).format("DD/MM/YYYY")}<br /><br />  
                </p>
                {this.props.depenses && this.props.depenses.depenses && this.props.depenses.depenses.length > 0 ? ( this.props.depenses.depenses.map((depn, index) => (<>
              {this.props.depense===depn.code ?(<> <p style={{ fontSize: "16px", marginTop: "-10px" ,marginLeft: "25px",}} key={index}> Nature : {this.props.depense} {depn.lib}</p></>):null} </> ))
              ) : ( this.props.css.css.map((depn, index) => (<>
              {this.props.depense===depn.code ?(<> <p  style={{ fontSize: "16px", marginTop: "-10px" ,marginLeft: "25px",}} key={index}> Nature :</p></>):null} </> ))
              )}
               
            
              <br />
              <h6 style={{  fontSize: "20px",marginLeft: "700px",fontWeight: "bolder"}}> Signature & Cachet </h6>
             
             
          
            
            </div>
          </div>









            <ModifierMVTCAISModal
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              numopr={this.props.numopr}
              libelle={this.props.libelle}
              numcais={this.props.numcais}
              montant={this.props.montant}
              modreg={this.props.modreg}
              depense={this.props.depense}
              VENDEUR={this.props.VENDEUR}
              datopr={this.props.datopr}
              numchq={this.props.numchq}
              matban={this.props.matban}
              datech={this.props.datech}
              sens={this.props.sens}
              heur={this.props.heur}
              numoprdebit={this.props.numoprdebit}
              Emp={this.props.Emp}
            />
       

        
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    DataTables: state.DataTablesReducer,
    tests: state.tests,
    SearchingResult: state.SearchingReducer,
    nomenclatures: state.nomenclatures,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    Caisses:state.Caisses,
    css: state.css, 
     depenses:state.depenses,
  };
}





function numberToFrenchWords(number) {
  const units = ['ZÉRO', 'UN', 'DEUX', 'TROIS', 'QUATRE', 'CINQ', 'SIX', 'SEPT', 'HUIT', 'NEUF'];
  const tens = ['', '', 'VINGT', 'TRENTE', 'QUARANTE', 'CINQUANTE', 'SOIXANTE', 'SOIXANTE-DIX', 'QUATRE-VINGT', 'QUATRE-VINGT-DIX'];
  const teens = ['DIX', 'ONZE', 'DOUZE', 'TREIZE', 'QUATORZE', 'QUINZE', 'SEIZE', 'DIX-SEPT', 'DIX-HUIT', 'DIX-NEUF'];

  if (number < 10) return units[number];
  if (number < 20) return teens[number - 10];
  if (number < 100) {
      if (number % 10 === 0) return tens[Math.floor(number / 10)];
      if (number < 70) return tens[Math.floor(number / 10)] + '-' + units[number % 10];
      if (number < 80) return 'SOIXANTE-' + teens[number % 10];
      return 'QUATRE-VINGT-' + units[number % 10];
  }
  if (number < 1000) {
      if (number % 100 === 0) return units[Math.floor(number / 100)] + ' CENT';
      return units[Math.floor(number / 100)] + ' CENT ' + numberToFrenchWords(number % 100);
  }
  if (number < 10000) {
      if (number % 1000 === 0) return units[Math.floor(number / 1000)] + ' MILLIMES';
      return units[Math.floor(number / 1000)] + ' MILLIMES ' + numberToFrenchWords(number % 1000);
  }
  return '';
}

function convertToDinarsAndMillimes(number) {
  const integerPart = Math.floor(number);
  const decimalPart = Math.round((number - integerPart) * 1000);

  let dinars = numberToFrenchWords(integerPart) + ' DINARS';
  let millimes = '';

  // Convertir les millimes uniquement si la partie décimale n'est pas zéro
  if (decimalPart > 0) {
    millimes = ' ET ' + numberToFrenchWords(decimalPart) + ' MILIMES';
  }

  return dinars + millimes;
}

export default connect(mapStateToProps, null)(GetMVTCAIByID);
