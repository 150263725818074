import Axios from "axios";

export const GetDBFolder = (a) => {
  return (dispatch) => {
    Axios.get(`https://www.24-crm.tn/webservice/api/Auth?&coddos=${a}`).then(
      (response) => {
        return dispatch({ type: "GET_DB", payload: response.data });
      }
    );
  };
};
