const initialState = {
    Memos: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "Memos_SELECT":
        return {
          ...state,
          memos: action.payload,
        };
      default:
        return state;
    }
  }
  