import React, { Component } from "react";
import { Modal, Card, Row, FormGroup, Col, Accordion } from "react-bootstrap";
import "../styling/Styles.css";
import "./bl.scss";
import "./Responsivebl.css";
import axios from 'axios'; 
import { TextField, Button, Snackbar, IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";
import { GetNumFacDevis } from "../../redux/actions/GetNumfacDevis";
import { SelectArticle } from "../../redux/actions/GetArticles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import LigBLArticle from "./LigBLArticle";
import { SelectBLCod } from "../../redux/actions/GetBLcod";
import { SelectBL } from "../../redux/actions/GetBL";
import translate from "../../i18n/translate";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());

var date = curr.toISOString().substr(0, 10);

class AddBLModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
  
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      NETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,
      rechs: [],
      regimecli: "",
      pmp: "",
      nextsubmit: false,
      avance: 0,
      AVANCE: 0,
      REMISE: 0,
      mgc: "",
      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],

      snackbaropen: false,
      snackbarmsg: ",",
      username: username,

      typfac: "",
      numfac: "",
      typfacTab: [],
      numbcTab: [],
 
      artstock:[],
      
 
      facOrBl: "",
      numnum: 0,
      magasin: [],
      MG: "",
      DEP: "",

      // valtimbre: 0,
      netnetapayer: 0,
      NETNETAPAYER: 0,
      clicked: false,

      rep: "",
      btnEnable: false,
      representant: [],
      GMM: "",
    };
  }

  componentDidMount() {
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetNumFacDevis(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectBLCod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    fetch(
      `https://www.24-crm.tn/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });

    this.setState({ tab: [] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.facOrBl != this.state.facOrBl) {
      fetch(
        `https://www.24-crm.tn/webservice/api/LigBCDV?type=BC&numfac=${this.state.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            tab: data.map((el) => {
              return {
                codearticle: el.codart,
                codtvaa: el.codtva,
                qte: el.quantite,
                des: el.desart,
                puht: el.priuni,
                remisea: el.remise,
                tva: el.tautva,
                puttcnet: el.ttclig,
                totalht: el.montht,
                unitee: el.unite,
                faudec: el.fodart,
                codbar: el.CB,
                pmp: el.pmp,
              };
            }),
          });
        })

        .then((res) => {
          const SumQte =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte), 0);
          const SumRemiseArticle =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
              0
            );
          const SumHtBrut =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
          const SumTva =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseInt(v.qte) *
                  parseFloat(v.puht) *
                  ((100 - parseFloat(v.remisea)) / 100) *
                  ((100 - parseFloat(this.state.remiseg)) / 100) *
                  (parseFloat(v.tva) / 100),
              0
            );
          const SumHtNet =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseFloat(v.totalht) *
                  ((100 - parseFloat(this.state.remiseg)) / 100),
              0
            );

          const SumRemiseGlobale =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + v.totalht * (this.state.remiseg / 100),
              0
            );
          const SumNetAPayer =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                (v.totalht * ((100 - this.state.remiseg) / 100) +
                  v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
              0
            );

          const Avance = (SumNetAPayer * this.state.regimecli) / 100;

          const SumNetNetAPayer = SumNetAPayer + Avance;

          this.setState({
            totalqte: SumQte,
            sumremisearticle: SumRemiseArticle,
            totalhtbrut: SumHtBrut,
            totaltva: SumTva,
            avance: Avance,
            totalhtnet: SumHtNet,
            remiseglobal: SumRemiseGlobale,
            netapayer: SumNetAPayer,
            btnEnabled: true,
            netnetapayer: SumNetNetAPayer,
            // TOTTVA: SumTva,
            // AVANCE: Avance,
            // NETAPAYER: SumNetAPayer,
            // NETNETAPAYER: SumNetNetAPayer,
          });
        });
    }
  }

  submitHandler = (
    tab,
    codtvaa,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    avance,
    netnetapayer,
    pmp
  ) => {
    this.setState({
      tab: tab,
      codtvaa: codtvaa,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      avance: avance,
      netnetapayer: netnetapayer,
      pmp: pmp,
    });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseglobalChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      AVANCE: this.state.avance * ((100 - event.target.value) / 100),
      NETNETAPAYER:
        ((100 - event.target.value) / 100) *
          (this.state.netapayer + this.state.avance) +
        Number(this.state.valtimbre),

      // remiseg: event.target.value,
      // remiseglobal:
      //   ((this.state.totalhtbrut - this.state.sumremisearticle) *
      //     event.target.value) /
      //   100,
      // totalhtnet:
      //   (this.state.totalhtbrut - this.state.sumremisearticle) *
      //   ((100 - event.target.value) / 100),
      // totaltva: this.state.totaltva * ((100 - event.target.value) / 100),
      // netapayer: this.state.netapayer * ((100 - event.target.value) / 100),
      // avance: this.state.avance * ((100 - event.target.value) / 100),
      // netnetapayer:
      //   ((100 - event.target.value) / 100) *
      //     (this.state.netapayer + this.state.avance) +
      //   Number(this.state.valtimbre),
    });
  };

  raisocHandler = (event) => {
    this.setState({ raisonsocial: event.target.value });
  };

  catHandler = (event) => {
    if (event.target.value == "Assujetti") {
      this.setState({ categoriefiscale: 0 });
    } else if (event.target.value == "Non Assujetti") {
      this.setState({ categoriefiscale: 1 });
    } else if (event.target.value == "Exonéré TVA/FODEC") {
      this.setState({ categoriefiscale: 2 });
    } else if (event.target.value == "Exonéré TVA") {
      this.setState({ categoriefiscale: 3 });
    } else if (event.target.value == "Suspenssion") {
      this.setState({ categoriefiscale: 4 });
    } else if (event.target.value == "Cession à Quai") {
      this.setState({ categoriefiscale: 5 });
    } else {
      this.setState({ categoriefiscale: 6 });
    }
  };

  enregistrer = (event) => {
    event.preventDefault();
    // this.setState({ codeclient: event.target.codcli.value });
    // console.log("numfac=", this.state.numfac);
    // let id = event.target.codbl.value;

    // let cli = "00022";
    //ajouter un élément à la table blbr

    fetch(
      
      `https://www.24-crm.tn/webservice/api/BLBRs?numfac&typfac=BL&codcli=${
        event.target.codcli.value
      }&MT=
      &raisoc=${event.target.raissoc.value}&catfisc=${
        this.state.categoriefiscale
      }&datfac=${this.state.defaultdate}&timbre=${Number(
        this.state.showTimbre
      )}&facture=0&numcom=${this.state.numfac}&tauxNASS=${
        this.state.regimecli
      }&CodDep=${this.state.MG}&codrep=${this.state.rep}&taurem=${
        this.state.remiseg
      }&VENDEUR=${this.props.Auth.username}&coddos=${
        this.props.ConfigDossier.coddos
      }&serveur=${this.props.ConfigDossier.serveur}&user=${
        this.props.ConfigDossier.utilisateur
      }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
        this.props.ConfigDossier.database
      }`,
   
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())

      .then((res) => {
        this.setState({ numnum: res });
        this.state.tab.map((k, index) => {
          //ajouter des lignes à ligblbr
          // for (var i = 0; i < this.state.tab.length; i++) {
          fetch(
            `https://www.24-crm.tn/webservice/api/LigBLBRs/{ID}?numfac=${this.state.numnum}&typfac=BL&numlig=${index}&codart=${k.codearticle}&desart=${k.des}&quantite=${k.qte}&priuni=${k.puht}&remise=${k.remisea}&codtva=${k.codtvaa}&tautva=${k.tva}&fodart=0&coddepp=${this.state.MG}&datfac=${this.state.defaultdate}&unite=${k.unitee}&CB=${k.codbar}&formqt=0&pmp=${k.pmp}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                console.log(result);
              },
              (error) => {
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: false,
                });
              }
            );
        });
      })

      .then(
        (result) => {
          this.props.onHide();

          this.props.SelectBL(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          //exécuter la procédure SommeTTCBl

          fetch(
            `https://www.24-crm.tn/webservice/api/LigBLBRs?FAC=${this.state.numnum}&typfacc=BL&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });

          this.props.SelectBLCod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          //Exécuter la procédure RecalculSoldeCliNew (recalculer le solde du client)
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "Echec" });
        }
      )

      .then((res) => {
        //////////// switch update (code incrémenté)////////////////

        fetch(
          `https://www.24-crm.tn/webservice/api/Switch?code=BL2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "Echec" });
            }
          );
      })
      .then((res) => {
        return fetch(
          `https://www.24-crm.tn/webservice/api/REGCLIs?codeclii=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "POST",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then((res) => {
        // Traitez la réponse ici si nécessaire
        return res.json();
      })
      .then((data) => {
        // Effectuez les actions nécessaires avec les données
        console.log(data);
        // Réalisez le rechargement de la page ici si nécessaire
     // window.location.reload();
      })
      .catch((error) => {
        // Gérez les erreurs ici
        console.error(error);
      });





      
    //Exécuter la procédure UpdateStkBLBR (gestion de stock)
    // .then((res) => {
    //   if (this.state.MG) {
    //     //  setTimeout(() => {
    //     fetch(
    //       `http://www.24-crm.tn/webservice/api/LigBLBRs?FAC=${this.state.numnum}&Typfacc=BL&CODDEPP=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //       {
    //         method: "POST",
    //         header: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json",
    //         },
    //       }
    //     ).then((res) => res.json());
    //     // }, 2000)
    //   }
    // });


  };

  clientHandlerChange = (event) => {
    //recherche du client par codclii
    fetch(
      `https://www.24-crm.tn/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };
  render() {
    const { artstock } = this.state;
    const { rem } = this.state;
    const { darck, classes, alllistcode } = this.props;

    // let addModalClose1 = () => this.setState({ addModalShow1: false });
    let ligModalClose = () => this.setState({ ligModalShow: false });

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

      

   const handleClick = async () => {
    // Utilisation de map pour itérer sur chaque élément de this.state.tab
    this.state.tab.forEach(async (t) => {
      // ID du produit à envoyer dans la requête
      const productId = t.codearticle; // Utilisez directement la valeur, pas besoin des accolades
      // console.log(productId)
    
      // Données du produit à envoyer dans la requête
      const product = {
        quantity: t.qte, // Utilisez directement la valeur
        // Ajoutez d'autres propriétés du produit si nécessaire

      };
    

      // adresse pour client 
      try {
      
      // const response = await axios.put(`https://api.my-home-plus.com/api/product/${productId}`, product);
        const response1 = await axios.put(`https://${this.state.URLCLT}/api/product/${productId}`, product);
     
        // Traiter la réponse de l'API ici
       // console.log(response.data);         
        console.log(response1.data);  
      } catch (error) {
        // Gérer les erreurs de l'API ici
        console.error('Erreur lors de la requête Axios :', error);
      }
      console.log(productId)
      console.log(product)
      console.log(this.state.URLCLT)
    });
   
  };

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={5000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b> {translate("Ajouter Bon de livraison")}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.enregistrer}
              className={`${darck ? "" : classes.root}`}
            >
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  {this.state.GMM ? (
                    <Row form>
                      <Col sm={4}>
                        <FormGroup>
                          {
                            this.props.codbls.codbls && (
                              // this.props.codbls.codbls.map((t) => (
                              <TextField
                                id="standard-basic"
                                label={translate("N° BL")} 
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                // value={t.valeur}
                                defaultValue={
                                  this.props.codbls.codbls[0] &&
                                  this.props.codbls.codbls[0].valeur
                                }
                                fullWidth
                                name="codbl"
                               // disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )
                            // ))
                          }
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          defaultValue={this.state.defaultdate}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </Col>

                      {/* *** */}

                      {/* <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.representant}
                            getOptionLabel={(option) => `${option.nom}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    rep: getOptionLabel.codrep,
                                  })
                                : this.setState({
                                    rep: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Représentant"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        representant: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="representant"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col> */}

                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            value={{
                              code: this.state.MG,
                              lib: this.state.DEP,
                            }}
                            className="ajouter-client-input"
                            options={this.state.magasin}
                            getOptionLabel={(option) =>
                              ` ${option.code}  ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MG: getOptionLabel.code,
                                    DEP: getOptionLabel.lib,
                                  })
                                : this.setState({
                                    MG: "",
                                    DEP: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Magasin"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        magasin: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>

                      {/* *** */}
                    </Row>
                  ) : (
                    <Row form>
                      <Col sm={4}>
                        <FormGroup>
                          {
                            this.props.codbls.codbls && (
                              // this.props.codbls.codbls.map((t) => (
                              <TextField
                                // className="card add-input"
                                id="standard-basic"
                                label={translate("№ BL")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                // value={t.valeur}
                                defaultValue={
                                  this.props.codbls.codbls[0] &&
                                  this.props.codbls.codbls[0].valeur
                                }
                                fullWidth
                                name="codbl"
                               // disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )
                            // ))
                          }
                        </FormGroup>
                      </Col>
                      {/* <Col sm={5}></Col> */}
                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          defaultValue={this.state.defaultdate}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </Col>

                      {/* *** */}

                      {/* <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.representant}
                            getOptionLabel={(option) => `${option.nom}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    rep: getOptionLabel.codrep,
                                  })
                                : this.setState({
                                    rep: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Représentant"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/REPREST?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        representant: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="representant"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col> */}
                    </Row>
                  )}

                  <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: "grey" }}>
                              {translate("Raison Social")}
                            </Grid>
                            <Grid item>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  checked={this.state.gilad}
                                  onChange={this.handleChange("gilad")}
                                  value="gilad"
                                  color="primary"
                                />
                              </ThemeProvider>
                            </Grid>
                            <Grid item style={{ color: "#3f51b5" }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.codcli}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    regimecli: getOptionLabel.regimecli,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      // : getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("Code client")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="codcli"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.clients.clients
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    regimecli: getOptionLabel.regimecli,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                    cemail: getOptionLabel.email,
                                    valtimbre:
                                      // : getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("Raison Social")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raissoc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      this.state.showButtonModalPassager &&
                      !this.state.numfac ? (
                        <Col sm={5}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Raison sociale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="raissoc"
                            value={this.state.raisonsocial || ""}
                            onChange={this.raisocHandler}
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                      ) : this.state.showButtonModalPassager &&
                        this.state.numfac ? (
                          <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison Social"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              disabled
                              fullWidth
                              name="raissoc"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison Social"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.raisonsocial}
                              fullWidth
                              name="raissoc"
                              disabled
                              // disabled={
                              //   this.state.codeclient === "100" ? false : true
                              // }
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label={translate("Code client")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {this.state.showButtonModalPassager &&
                    !this.state.numfac ? (
                      <Col sm={4}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={this.state.cat}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel.label == "Assujetti"
                                ? this.setState({ categoriefiscale: 0 })
                                : getOptionLabel.label == "Non Assujetti"
                                ? this.setState({ categoriefiscale: 1 })
                                : getOptionLabel.label == "Exonéré TVA/FODEC"
                                ? this.setState({ categoriefiscale: 2 })
                                : getOptionLabel.label == "Exonéré TVA"
                                ? this.setState({ categoriefiscale: 3 })
                                : getOptionLabel.label == "Suspenssion"
                                ? this.setState({ categoriefiscale: 4 })
                                : getOptionLabel.label == "Cession à Quai"
                                ? this.setState({ categoriefiscale: 5 })
                                : this.setState({ categoriefiscale: 6 });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Cat Fiscale"
                                margin="normal"
                                //variant="outlined"
                                fullWidth
                                name="catfisc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : this.state.showButtonModalPassager &&
                      this.state.numfac ? (
                      <>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label={translate("Cat Fiscale")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Non Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA/FODEC")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Suspenssion")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Cession à Quai")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Réduction TVA")}{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Cat Fiscale")}{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label={translate("Cat Fiscale")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Non Assujetti")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA/FODEC")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Exonéré TVA")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Suspenssion")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Cession à Quai")}{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Réduction TVA")}{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              {translate("Cat Fiscale")}{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    )}

                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label={"Remise"}
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="remise"
                        value={this.state.remiseg}
                        onChange={this.remiseglobalChange}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    {this.state.codeclient ? (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.numbcTab}
                            getOptionLabel={(option) => ` BC: ${option.numfac}`}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    facOrBl: getOptionLabel,
                                    numfac: getOptionLabel.numfac,
                                    typfac: getOptionLabel.typfac,
                                    remiseg: getOptionLabel.taurem,
                                    raisonsocial: getOptionLabel.raisoc,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    MG: getOptionLabel.CodDep,
                                    DEP: "",
                                  })
                                : this.setState({
                                    numfac: "",
                                    facOrBl: "",
                                    typfac: "",
                                    remiseg: "",
                                    raisonsocial: "",
                                    categoriefiscale: "",
                                    MG: "",
                                    DEP: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Bon de Commande"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                // className="card add-input"
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/BCDVCLIs?typfac=BC&vendeur=${this.props.Auth.username}&grp=${this.props.Auth.fct}&codcliss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        numbcTab: result,
                                        typfacTab: result.map(
                                          (el) => el.typfac
                                        ),
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                   
                    ) : null}

                    {/* <Col sm={2}>
                      <FormGroup style={{ marginTop: "40px" }}>
                        {this.state.showTimbre ? (
                          <p style={{ color: "grey" }}>Timbre: ✔</p>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup style={{ marginTop: "40px" }}>
                        {this.state.showForfitaire === 1 ? (
                          <p style={{ color: "grey" }}>Forfitaire: ✔</p>
                        ) : null}
                      </FormGroup>
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11} id="libl">
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "16px",
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label={translate("Liste des Articles")}
                                variant="outlined"
                            

                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1} className="addbl">
                            {this.state.GMM ? (
                              this.state.btnEnable ? (
                                <label>
                                  <h5>
                                    <Tooltip
                                      title={translate(
                                        "Ajouter article"
                                      )}
                                    >
                                      <Fab
                                        style={{
                                          backgroundColor: "#3f51b5",
                                          color: "white",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              ) : (
                                <label>
                                  <h5>
                                    <Tooltip
                                      title={translate(
                                        "Ajouter article"
                                      )}
                                    >
                                      <Fab
                                        disabled
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              )
                            ) : this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip
                                    title={translate(
                                      "Ajouter article"
                                    )}
                                  >
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip
                                    title={translate(
                                      "Ajouter article"
                                    )}
                                  >
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <div className="tabbl2">
                        {/* <label style={{color: `${darck ? "#F00020" : "#F00020"}`,textAlign: "center", fontSize: "12px" }}>Vérifier stock article</label> */}
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                             <th>Stock</th>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>
                                {" "}
                                {translate("Désignation")}{" "}
                              </th>
                              <th> {translate("Quantité")} </th>
                              <th> {translate("PUHT")} </th>
                              <th> {translate("Remise")} </th>
                              <th> {translate("TVA")} </th>
                              <th> {translate("PUTTCNet")} </th>
                              <th> {translate("TotalHT")} </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              textAlign: "center",
                              color: `${darck ? "" : "#fff"}`,
                            }}
                          >
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}
                              onClick={() => { 

                                fetch(
                                  `https://www.24-crm.tn/webservice/api/LigBCDV?codartticle=${t.codearticle}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                  {
                                    method: "GET",
                                    header: {
                                      Accept: "application/json",
                                      "Content-Type": "application/json",
                                    },
                                  }
                                )
                                  .then((res) => res.json())
                                  .then((result) => {
                                    this.setState({artstock: result});}); }} >
                              
                            
                                <td>
                                  <span>{t.codearticle}</span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span> {t.des} </span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {t.qte}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puht).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.remisea).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.totalht).toFixed(2)}</span> </td> </tr> ))} </tbody></table></div>
                                 
                            <label style={{color: `${darck ? "#F00020" : "#F00020"}`,textAlign: "center", fontSize: "14px" }}>
                             {artstock.map(x => <div> Stock final: {x.stkfin}</div>)} </label>
                          
                          




                            





                            </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>







              {/* //////////////// Footer //////////////////// */}
              <Card style={{ marginTop: "10px" }} id="bl1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Brut")}
                      </p>
                      <p style={{ color: `${darck ? "black" :"#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Article")}
                      </p>
                      <p style={{ color: `${darck ? "black" :"#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Globale")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Net")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total TVA")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("TotalTTC")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>

                   

                    <Col
                      sm={4}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.avance).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.AVANCE).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={4}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Net à Payer")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="bl2">
                <Card.Body style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                  <Row className="rowbl">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Brut")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Article")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Remise Globale")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="bl">
                      <Divider
                        style={{
                          marginTop: "-8px",
                          backgroundColor: `${darck ? "grey" :"#fff"}`,
                        }}
                      ></Divider>
                    </Col>
                  </Row>
                  <Row className="rowbl">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total HT Net")}
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Total TVA")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("TotalTTC")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row style={{ margin: "5px , 0" }}>
                    <Col id="bl">
                      <Divider
                        style={{
                          marginTop: "-8px",
                          backgroundColor: `${darck ? "grey" :"#fff"}`,
                        }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbl">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.avance).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}`}}>
                          {Number(this.state.AVANCE).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        {translate("Net à Payer")}
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                {this.state.tab.length === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous 
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                      type="submit"
                      onClick={handleClick}
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <LigBLArticle
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          tabArticle={this.state.tab}
          rem={rem}
          numfaccc={this.props.codbls.codbls.map((nu) => parseInt(nu.valeur))}
          totalqte={this.state.totalqte}
          sumremisearticle={this.state.sumremisearticle}
          totalhtbrut={this.state.totalhtbrut}
          totaltva={this.state.totaltva}
          totalhtnet={this.state.totalhtnet}
          remiseglobal={this.state.remiseglobal}
          netapayer={this.state.netapayer}
          avance={this.state.avance}
          regimecli={this.state.regimecli}
          btnEnabled={this.state.btnEnabled}
          netnetapayer={this.state.netnetapayer}
          datfac={this.state.defaultdate}
          valtimbre={this.state.valtimbre}
          GMM={this.state.GMM}
          MG={this.state.MG}
          raisonsocial={this.state.raisonsocial}
       
        />

      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    GetNumFacDevis: (a, b, c, d, e) => dispatch(GetNumFacDevis(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectBLCod: (a, b, c, d, e) => dispatch(SelectBLCod(a, b, c, d, e)),
    SelectBL: (a, b, c, d, e) => dispatch(SelectBL(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    codeclient:state.codeclient,
    clients: state.clients,
    numfac: state.numfac,
    articles: state.articles,
    codbls: state.codbls,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddBLModal));
