const initialState = {
  codav: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "AV_COD":
      return {
        ...state,
        codav: action.payload,
      };
    default:
      return state;
  }
}
