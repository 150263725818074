import React, { Component ,useState,useEffect } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {TextField,Checkbox,Snackbar,IconButton,FormHelperText,} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import { Form } from 'semantic-ui-react';
import {InputBase, FormLabel,} from "@material-ui/core";
import { createMuiTheme} from "@material-ui/core/styles";
import { SelectClient } from "../../redux/actions/GetClients";
import { SelectReglement } from "../../redux/actions/GetReg"; 
import { SelectNomenclatureSC } from "../../redux/actions/GetNomeSituation";
import { SelectNomenclature } from "../../redux/actions/GetNomenclature";
import { SelectNomenclatureBQ } from "../../redux/actions/GetNomeBQ";
import { SelectNomenclatureAG } from "../../redux/actions/GetNomeAG";
import { SelectNomenclatureCS } from "../../redux/actions/GetNomeCS"; 
import { GetBordereaux } from "../../redux/actions/GetBord";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Table } from "react-bootstrap";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Fab, Breadcrumbs,} from "@material-ui/core";
import { SelectAllNome } from "../../redux/actions/GetAllNome";
import { SelectCatNome } from "../../redux/actions/GetCatNome";
import { emphasize } from "@material-ui/core/styles";
import moment from 'moment';
import Chip from "@material-ui/core/Chip";
import "./re.scss";
import { GetRECod } from "../../redux/actions/GetRECod";
import { CategoryOutlined, SatelliteTwoTone } from "@material-ui/icons";












const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddBordereaux extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NumBrd:"",
      sum:  0,
      NBpiece:0,
      defaultdate: date,
      numreg: "",
      codagence: "",
      libagence: "",
      CodeCCb: "",
      CodeCCb:[],
      codereg:"",
      libccb: "",
      chdec: "",
      codeclient: "",
      snackbaropen: false,
      Bordereaux:[],
      rechs: [],
      ccb:"",
      ccb:[],
      Escompter:"",
      listebl:"",
      datFech: date,
      datDech: date,


      
      libbqvers:"",
      defaultdate: date,
      NomRemettant:"",
      BORD: [
        { code: "0", label: "VIREMENT BANCAIRE" },
        { code: "1", label: "REMISE CHEQUES" },
        { code: "2", label: "REMISE D'EFFETS A L'ENCAISSEMENT" },
        { code: "3", label: "REMISE D'EFFETS A L'ESCOMPTE" },
        { code: "4", label: "VERSEMENT D'ESPACE" },
      ],
      selectedBordereau: null,  // State to track selected option
   };
  }
  componentDidMount() {
    this.setState({
      rechercheValue: "",
      codeclient: "",
      raisonsocial: "",
    });
    fetch(
      `https://www.24-crm.tn/webservice/api/CLIENTs?codclii&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          rechs: data,
          rechercheclicked: true,
        });
      });

      this.props.SelectClient(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.GetRECod(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.SelectNomenclature(
        "MR",
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.SelectNomenclatureBQ(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.SelectNomenclatureAG(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.SelectNomenclatureCS(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.GetBordereaux(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.SelectNomenclatureSC(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.SelectReglement(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
{/* jdiddaa hedhy */}
      this.props.SelectAllNome(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      this.props.SelectCatNome(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
      }



      handleChange = event => {
        const { target: { value } } = event;
        this.setState(state => ({
          value: value,
        }));
      };






  firstrechdatHandler = (event) => {
    this.setState({ firstdate: event.target.value });
  };
  secondrechdatHandler = (event) => {
    this.setState({ seconddate: event.target.value });
  };

  getLabelByCode = (code) => {
    const bordItem = this.state.BORD.find((item) => item.code === code);  
   
 //   console.log("==============bordItem", bordItem);
    return bordItem ? bordItem.label : "";
 
  };

 // Définissez une méthode pour gérer les changements de la case à cocher
handleCheckboxChange = (event) => {
  const isChecked = event.target.checked;
  const valeur = isChecked ? 1 : 0; // 1 si coché, 0 sinon

  // Mettez à jour l'état
  this.setState({ Escompter: valeur });

  // Utilisez la valeur (0 ou 1) comme vous le souhaitez
  console.log("Valeur de la case à cocher : ", valeur);
}
  
submitHandler = (event) => {
  event.preventDefault();    

  const libelleTitreBord = this.state.selectedBordereau ? this.state.selectedBordereau.label : '';
  
  // Fetch request for BordereauVersements
  fetch(
    `https://www.24-crm.tn/webservice/api/BordereauVersements?NumBord=${this.props.codBords.codBords[0].valeur}&idbv&codccb=${this.state.codbqvers}&DatOp=${this.state.defaultdate}&Vers=${this.state.Escompter}&CodBanque=${this.state.codbqvers}&TitreBord=${libelleTitreBord}&NbChq=${this.state.NBpiece}&Total=${this.state.sum}&ref=${this.state.Ref||''}&remettant=${this.state.remettant||''}&rib=${this.state.ccb}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
  .then((response) => response.json())
  .then(() => {
    // Fetch requests for Verser
    this.state.tab
      .filter((t) => t.select === true)
      .forEach((t) => {
        fetch(
          `https://www.24-crm.tn/webservice/api/Verser?numreg=${t.numreg}&idvs&datreg=${t.datreg}&codcli=${t.codcli}&numchq=${t.numchq}&raisoc=${t.raisoc}&modreg=${t.modreg}&monreg=${t.reste}&monver=${t.reste}&NUMBORD=${this.props.codBords.codBords[0].valeur}&verser=v&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
      });

    // Fetch request for Switch
    fetch(
      `https://www.24-crm.tn/webservice/api/Switch?code=BV2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => res.json())
    .then((result) => {
      console.log(result);
      this.setState({
        snackbaropen: true,
        snackbarmsg: "Enregistré sous le N° " + this.props.codBords.codBords[0].valeur,
      });
   //   window.location.reload();
    })
    .catch((error) => {
      this.setState({ snackbaropen: true, snackbarmsg: "failed" });
    });
  })
  .catch((error) => {
    this.setState({ snackbaropen: true, snackbarmsg: "failed" });
  });
};





render() { 
  // Récupérer la valeur à partir de this.props.codres.codres[0] et ajouter 1
const valeur = parseInt(this.props.codres.codres[0]?.valeur) + 1 || 1; // Si la valeur n'est pas définie, commencez à partir de 1
//console.log("===================================",valeur);
// console.log("===================================",this.props.codBords.codBords[0]?.valeur);
// Récupérez le code du TitreBord (remplacez 'codeTitreBord' par le code réel)
//const codeTitreBord = "1"; // Exemple : codeTitreBord pourrait être une variable dynamique






// Find the selected item in the BORD list, ensuring BORD is defined
const codeTitreBord = this.state.BORD?.BORD?.[0]?.label || ''; 

// Find the selected item in the BORD list, ensuring BORD is defined
const selectedBordereau = this.state.BORD?.BORD?.find(
  (item) => item.label === codeTitreBord
);
// console.log("==============libelleTitreBord",codeTitreBord);


// Utilisez la fonction pour obtenir le libellé correspondant
const libelleTitreBord = this.getLabelByCode(codeTitreBord);



    const {value} = this.state;
    const { darck, classes } = this.props;
    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });
return ( 
<div className="container">
<Snackbar
  anchorOrigin={{ vertical: "top", horizontal: "right" }}
  open={this.state.snackbaropen}
  autoHideDuration={2000}
  onClose={this.snackbarClose}
  message={<span id="message-id"> {this.state.snackbarmsg} </span>}
  action={[
    <IconButton
      key="close"
      color="inherit"
      onClick={this.snackbarClose}>
      x
    </IconButton>,
  ]}
></Snackbar>

<Modal
  onHide={this.props.onHide}
  show={this.props.show}
  size="lg"
  aria-labelledby="contained-modal-title-vcenter"
  centered
  style={{ padding: "5px" }}
>
  <Modal.Header
    closeButton
    style={{
      backgroundColor: `${darck ? "#fff" : "#172431"}`,
      color: `${darck ? "#020F64" : "#fff"}`,
      borderTop: `${darck ? "" : "2px solid #fff"}`,
      borderRight: `${darck ? "" : "2px solid #fff"}`,
      borderLeft: `${darck ? "" : "2px solid #fff"}`,
      borderBottom: `none`,
    }}
  >
    <Modal.Title id="contained-modal-title-vcenter">
      <b>Ajouter Bordereau Bancaire</b>
    </Modal.Title>
  </Modal.Header>

  <Modal.Body
    style={{
      backgroundColor: `${darck ? "#fff" : "#172431"}`,
      borderTop: `none`,
      borderRight: `${darck ? "" : "2px solid #fff"}`,
      borderLeft: `${darck ? "" : "2px solid #fff"}`,
      borderBottom: `${darck ? "" : "2px solid #fff"}`,
    }}
  >   <form
      autocomplete="off"
      onSubmit={this.submitHandler}
      className={`${darck ? "" : classes.root}`}
    >
      <Row form>
        <Col sm={12}>
          <Card className="card0" style={{ height: "100%" }}>
            <Card.Body
              style={{
                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                borderTop: `${darck ? "" : "2px solid #fff"}`,
                borderRight: `${darck ? "" : "2px solid #fff"}`,
                borderLeft: `${darck ? "" : "2px solid #fff"}`,
                borderBottom: `${darck ? "" : "2px solid #fff"}`,
                borderRadius: "5px",
              }}
            >
              <Row form>
                <Col sm={6}>
                  <FormGroup style={{ overflow: "hidden" }}>
                  </FormGroup>
                </Col>
              </Row>

{/* /////////////////////////////////////////////   Declaration Numéro de Reglement        //////////////////////////////////////////////////////////// */}
                      <div class="col">
                      <Row form>
                      <Col sm={4}>
                      <FormGroup>
                        {/* {this.props.codres.codres.map((t) => ( */}
                        <TextField
                          id="standard-basic"
                          label="№ Bordereau"
                          margin="dense" 
                          variant="outlined"
                          value={parseInt(
                            this.props.codBords.codBords[0] &&
                            this.props.codBords.codBords[0].valeur
                          )}
                          fullWidth
                          name="NumBrd"
                         
                          // className="card add-input"
                          disabled
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                             
                            },
                          }}
                        /></FormGroup>
                      </Col> 
                   
{/* //////////////////////////////////////////////////  N° ref //////////////////////////////////////////////////////////////////////////////// */}
                     <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="N° Ref"
                              //label="Nom de remettantèce"
                              margin="dense"
                              fullWidth
                              name="Ref"
                              variant="outlined"
                              value={this.state.Ref}  // Bind the input value to the state
                              onChange={(event) => this.setState({ Ref: event.target.value })}  // Update state on change
                            
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
 {/* //////////////////////////////////////////////////  DECLARATION Nom de remettant  //////////////////////////////////////////////////////////////////////////////// */}
<Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Nom de remettant"
                              //label="Nom de remettantèce"
                              margin="dense"
                              fullWidth
                              name="numpiece"
                              variant="outlined"
                              value={this.state.remettant}  // Bind the input value to the state
                              onChange={(event) => this.setState({ remettant: event.target.value })}  // Update state on change
                            
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        </Row>
{/* ////////////////////////////////////////////////////////// Declaration BANQUE ////////////////////////////////////////////////////////////////////////////////// */}
                      <Row form>
                      <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //options={this.props.bqs.bqs}
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqvers: getOptionLabel.code,
                                      libbqvers: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqvers: "",
                                      libbqvers: "",
                                    });
                         if ( getOptionLabel !== null ) {
      fetch(
      `https://www.24-crm.tn/webservice/api/BordereauVersements?CodeBqCcb=${getOptionLabel.code}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      )
        .then((res) => res.json())
        .then((data) => this.setState({ CodeCCb: data, clicked: true ,}));} 
         else
        {this.setState({ ccb:"",});} }}
              renderInput={(params) => (
              <TextField
                                  label="Bq vers"
                                  margin="dense"
                                  fullWidth              
                                  name="bqvers"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqvers}
                            </FormHelperText>
                          </FormGroup>
                        </Col>
 {/* ////////////////////////////////////////////////  Declaration Code CCB       /////////////////////////////////////////////////////////////////////////// */}
                        <Col sm={4}>
                        <FormGroup>
                          <Autocomplete
                              id="outlined-select-currency"
                              includeInputInList
                              options={this.state.CodeCCb}
                              getOptionLabel={(option) => `${option.CodeCCb}`}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                    ccb: getOptionLabel.CodeCCb,
                                    })
                                  : this.setState({
                                    ccb: "",
                                    });
                              }}
                              renderInput={(params) => (
                                  <TextField
                                label="CCB"
                                value={this.state.ccb}
                                name="codeccb"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                      </FormGroup>
                      </Col>     
                    
{/* /////////////////////////////////////////////////////////////// DECLARATION  DATE /////////////////////////////////////////////////////////////////////// */}
                      <Col sm={4}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        margin="dense"
                        type="date"
                        fullWidth
                        variant="outlined"
                        defaultValue={this.state.defaultdate}
                        // className="card add-input"

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                      </Row>
                     
{/* ////////////////////////////////////////////// Declaration Reglement /////////////////////////////////////////////////////////////////////////////////// */}
                       <Row form>
                      <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.nomenclatures.nomenclatures}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codmodreg: getOptionLabel.code,
                                  libmodreg: getOptionLabel.lib,
                                  chdec: getOptionLabel.chdec,
                                })
                              : this.setState({
                                  codmodreg: "",
                                  libmodreg: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Mode Règ"
                              margin="dense"
                              fullWidth
                              name="modreg"
                              variant="outlined"
                              // className="card add-input"
                              required
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                      </Col>
{/* ///////////////////////////////////////////////////// Declaration Client /////////////////////////////////////////////////////////////////////////////////// */}
                        <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.state.rechs.filter((el) =>
                            el.codcli
                              .toUpperCase()
                              .includes(this.state.rechercheValue.toUpperCase())
                          )}
                          getOptionLabel={(option) => option.codcli}
                          inputValue={this.state.codeclient}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ codeclient: newInputValue });
                          }}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  codeclient: getOptionLabel.codcli,
                                  soldfacbl: getOptionLabel.soldfacbl,
                                })
                              : this.setState({
                                  raisonsocial: "",
                                  codeclient: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Code client"
                              margin="dense"
                              fullWidth
                              value={this.state.codeclient}
                              // onChange={this.clientCodehandlerChange}
                              name="codcli"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>    
                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.state.rechs.filter((el) =>
                            el.raisoc
                              .toUpperCase()
                              .includes(this.state.rechercheValue.toUpperCase())
                          )}
                          getOptionLabel={(option) => option.raisoc}
                          inputValue={this.state.raisonsocial}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ raisonsocial: newInputValue });
                          }}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  codeclient: getOptionLabel.codcli,
                                  soldfacbl: getOptionLabel.soldfacbl,
                                })
                              : this.setState({
                                  raisonsocial: "",
                                  codeclient: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField 
                              // {...params}
                              label="Raison Sociale"
                              margin="dense"
                              fullWidth
                              
                              value={this.state.raisonsocial}
                              onChange={this.clientHandlerChange}
                              name="raisonsocial"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                      </div>
{/* //////////////////////////////////////////////////  DECLARATION BORDEREAU  //////////////////////////////////////////////////////////////////////////////// */}
<Row form>
<Col sm={8}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.BORD}
                           
                            getOptionLabel={(option) => option.label}
                            value={this.state.selectedBordereau || null}  // Set the selected value here
                            onChange={(event, newValue) => {
                               this.setState({ selectedBordereau: newValue });
                            //  this.setState({ selectedBordereau: newValue ?  newValue.label: newValue.label });
                            }}
                            renderInput={(params) => (
                             
                              <TextField
                                // {...params}
                                label="Bordereau"
                                margin="dense"
                              
                               
                                // margin="normal"
                                variant="outlined"
                                fullWidth
                                name="Bordereau"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,  }, }}  />
                                    
                                    
                                    )}/>
                                    </FormGroup>
                                  
             
                                    </Col>






                                    
{/* ////////////////////////////////////////////// DECLARATION ESCOMPTE /////////////////////////////////////////////////////////////////////////////////////////// */}

<Col sm={4}>
  <Form.Field >
    A Escompter
    <Checkbox
      name="escompte"
      checked={this.state.Escompter}
      onChange={this.handleCheckboxChange}
      inputProps={{ 'aria-label': 'Checkbox A' }}
    />
  </Form.Field>
</Col>
 </Row> </Card.Body> </Card> </Col> </Row><br />
{/* ///////////////////////////////////////////////////////////////////// DECLARATIN DATE FILTRE ////////////////////////////////////////////////////////////////// */}
<Card className="card0" style={{ height: "100%" }}>
            <Card.Body
              style={{
                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                borderTop: `${darck ? "" : "2px solid #fff"}`,
                borderRight: `${darck ? "" : "2px solid #fff"}`,
                borderLeft: `${darck ? "" : "2px solid #fff"}`,
                borderBottom: `${darck ? "" : "2px solid #fff"}`,
                borderRadius: "5px",
              }}
            >
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
  
                  <>
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date début
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.defaultdate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  </> 
       <Row> 
        
         {/* <label>TEST date debit : {this.state.firstdate}</label> 
         <label>TEST date fin : {this.state.seconddate}</label>  */}
               
            </Row>
            
{/* //////////////////////////////////////////////// DECLARATIN DES FILTRES//////////////////////////////////////////////////////////////////////////// */}
<Row> 
<Col sm={9}></Col> 
 <Col sm={5}>
<label style={{ color: `${darck ? "" : "#fff"}` }} >
  <input style={{ color: `${darck ? "" : "#fff"}` }} 
              type="radio"
              name="letter"
               value="reg"
              checked={value === "reg"}
              onChange={this.handleChange}  />{"    "}Date de la piéce</label> 
              </Col>
              <Col sm={8}>
<label style={{ color: `${darck ? "" : "#fff"}` }} >
<input style={{ color: `${darck ? "" : "#fff"}` }} 
              type="radio"
              name="letter"
               value="ech"
              checked={value === "ech"}
            onChange={this.handleChange}   />{"    "}Date d'Echéance</label>
</Col>


<label style={{ color: `${darck ? "" : "#fff"}` }} > /{value}</label> 
<Col sm={8}></Col>  <Col sm={8}>





{value === "reg" ? 
   <Button  color="primary" 
  onClick={() => {
   fetch(
   ` https://www.24-crm.tn/webservice/api/BordereauVersements?codeclient=${this.state.codeclient}&datDreg=${this.state.firstdate}&datFreg=${this.state.seconddate}&codmodreg=${this.state.codmodreg}&libbqvers=${this.state.codbqvers}&CodeCCb=${this.state.ccb}&Escompter=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database} `,
    { method: "GET",
      header: {
       Accept: "application/json",
        "Content-Type": "application/json",  }, }   )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            tab: data,
            show: true, }); }); }}  >Afficher des reglements par date </Button>    :"" }   


            
            {value === "ech" ? 
   <Button  color="primary"  
  onClick={() => {
   fetch(
   ` https://www.24-crm.tn/webservice/api/BordereauVersements?codeclient=${this.state.codeclient}&datDech=${this.state.firstdate}&datFech=${this.state.seconddate}&codmodreg=${this.state.codmodreg}&libbqvers=${this.state.codbqvers}&CodeCCb=${this.state.ccb}&Escompter=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database} `,
    { method: "GET",
      header: {
       Accept: "application/json",
        "Content-Type": "application/json",  }, }   )
        .then((response) => response.json())
        .then((data) => {
          this.setState({
            tab: data,
            show: true, }); }); }}  >Afficher des reglements par date Echéance</Button> :"" } </Col> </Row></Card.Body> </Card> 




            
<Row style={{ height: "83%" }} className={`${darck ? "" : classes.root}`}>
<Col sm={12}>
<Card style={{ height: "100%", marginTop: "5px" }}>
<Card.Body   style={{  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                      borderTop: `none`,
                                      borderRight: `${darck ? "" : "2px solid #fff"}`,
                                      borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                      borderBottom: `${darck ? "" : "2px solid #fff"}`, }} >
{/* /////////////////////////////////////////////////////////// DECLARATIN TABLEAUX DE PIECES//////////////////////////////////////////////////////////////////////// */}
         <Row>
         <Col>

            
            {this.state.show ? (
  <div className="tabd28">
    <h5 style={{ color: `${darck ? "" : "#fff"}` }}>Détails</h5>
    <table>
      <thead>
        <tr>
          <th>A.verser</th>
          <th>N°Rég</th>
          <th>Date Rég</th>
          <th>N°Piece</th>
          <th>Raison Sociale</th>
          <th>Montant</th>
          <th>Echéance</th>
          <th>Modreg</th>
          <th>Sit.Actuelle</th>
         
        </tr>
      </thead>
      <tbody
        style={{
          textAlign: "center",
          color: `${darck ? "" : "#fff"}`,
        }}
      >
        {this.state.tab.map((t, i) => (
          <tr key={i}>
            <td>
              <Form.Field>
                <input
                  type="checkbox"
                  checked={t.select || false}
                  onChange={(e) => {
                    let value = e.target.checked;

                    // Update the select property for the corresponding item in tab
                    let updatedTab = [...this.state.tab];
                    updatedTab[i].select = value;
                    console.log("value", value,i);
                    console.log("reste", t.reste);
                    // Update the sum and NBpiece state values
                    this.setState({
                      tab: updatedTab,
                      NBpiece: value ? this.state.NBpiece + 1 : this.state.NBpiece - 1,
                      sum: value ? this.state.sum + t.reste : this.state.sum - t.reste,
                    });
                  }}
                />
              </Form.Field>
            </td>

            <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{t.numreg}</span>
            </td>
            <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{moment(t.datreg).format("DD/MM/YYYY")}</span>
            </td>
            <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{t.numchq}</span>
            </td>
            <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{t.raisoc}</span>
            </td>
            {/* <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{t.reste}</span>
            </td>*/}   
             
{/* { this.state.modreg="esp" ?(         <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
         
  <input
    type="text"
    value={t.reste}
    onChange={(e) => {
      let updatedTab = [...this.state.tab];
      updatedTab[i].reste = e.target.value; // Update the value of reste
      this.setState({ tab: updatedTab });
    }}
    style={{
      color: `${darck ? "#182532" : "#fff"}`,
      backgroundColor: `${darck ? "#fff" : "#182532"}`, // Optional: control background color
      border: "none", // Optional: style the input field
      width: "100%", // Optional: make it fit inside the table cell
    }}
  />
</td>):( <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{t.reste}</span>
            </td>)}  */}


{
  this.state.selectedBordereau.label === "VERSEMENT D'ESPACE"?(
    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
      <input
        type="number" // Set input type to number
        value={t.reste}
        onChange={(e) => {
          let updatedTab = [...this.state.tab];
          updatedTab[i] = { ...updatedTab[i], reste: Number(e.target.value) }; // Update and convert the value to number
          this.setState({ tab: updatedTab });
        }}
        style={{
          color: `${darck ? "#182532" : "#fff"}`,
          backgroundColor: `${darck ? "#fff" : "#182532"}`,
          border: "none",
          width: "100%",
        }}
      />
    </td>
  ) : (
    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
      <span>{t.reste}</span>
    </td>
  )
}



            <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{moment(t.datech).format("DD/MM/YYYY")}</span>
            </td>
            <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{t.modreg}</span>
            </td>
            <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
              <span>{t.situation}</span>
            </td>
          
          </tr>
        ))}
      </tbody>
    </table>
  </div>
) : null}
            
            
            </Col>  </Row> </Card.Body> </Card> </Col></Row> 
       
{/* /////////////////////////////////////////////////////////// DECLARATIN TABLEAUX DE PIECES////////////////////////////////////////////////////////////////////// */}
<br></br>
<Row>
<Col sm={1}></Col> 
<h6 style={{ color: "rgb(23, 162, 184)" }}>Total     :    {this.state.sum} </h6> 
<Col sm={8}></Col>  
<h6 style={{ color: "rgb(23, 162, 184)" }}>Nbr Piéces:    {this.state.NBpiece} </h6>
</Row>
{/* ///////////////////////////////////////////////////////////// */}
 <Row><Col sm={8}></Col>  <Col sm={4}>

  {/* ////////////////////////////// Button Enregistrer /////////////////////////////// */}
 <Button
  type="submit"
  color="primary"
  style={{ width: "100%" }}
  // onClick={(event) => {
  //   event.preventDefault();    
  //   const libelleTitreBord = this.state.selectedBordereau ? this.state.selectedBordereau.label : '';
                                                            
  //   fetch(
  //     `https://www.24-crm.tn/webservice/api/BordereauVersements?NumBord=${this.props.codBords.codBords[0].valeur}&idbv&codccb=${this.state.codbqvers}&DatOp=${this.state.defaultdate}&Vers=${this.state.Escompter}&CodBanque=${this.state.codbqvers}&TitreBord=${libelleTitreBord}&NbChq=${this.state.NBpiece}&Total=${this.state.sum}&ref=${this.state.Ref}&remettant=${this.state.remettant}&rib=${this.state.ccb}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //     {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   );

  //   // fetch(
      
  //   //   `https://www.24-crm.tn/webservice/api/Verser?numreg=${this.state.tab[0].numreg}&idvs&datreg=${this.state.tab[0].datreg}&raisoc=${this.state.tab[0].raisoc}&modreg&monreg=${this.state.tab[0].reste}&monver=${this.state.tab[0].reste}&NUMBORD=${this.props.codBords.codBords[0].valeur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //   //   {
  //   //     method: "POST",
  //   //     headers: {
  //   //       Accept: "application/json",
  //   //       "Content-Type": "application/json",
  //   //     },
  //   //   }
  //   // );
  
  //   this.state.tab
  //   .filter((t) => t.select === true)
  //   .forEach((t) => {
  //     fetch(
  //       `https://www.24-crm.tn/webservice/api/Verser?numreg=${t.numreg}&idvs&datreg=${t.datreg}&codcli=${t.codcli}&numchq=${t.numchq}&raisoc=${t.raisoc}&modreg=${t.modreg}&monreg=${t.reste}&monver=${t.reste}&NUMBORD=${this.props.codBords.codBords[0].valeur}&verser=v&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //   });
  //   window.location.reload();
  // }}
>
  Enregistrer
</Button> 



 </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return {
    GetBordereaux: (a, b, c, d, e) => dispatch(GetBordereaux(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    GetRECod: (a, b, c, d, e) => dispatch(GetRECod(a, b, c, d, e)),
    SelectNomenclature: (cat, a, b, c, d, e) =>
      dispatch(SelectNomenclature("MR", a, b, c, d, e)),
    SelectNomenclatureBQ: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
    SelectNomenclatureAG: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureAG(a, b, c, d, e)),
    SelectNomenclatureCS: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureCS(a, b, c, d, e)),
    SelectNomenclatureSC: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureSC(a, b, c, d, e)),
    SelectReglement: (a, b, c, d, e) =>
      dispatch(SelectReglement(a, b, c, d, e)),


      SelectAllNome: (a, b, c, d, e) => dispatch(SelectAllNome(a, b, c, d, e)),
      SelectCatNome: (a, b, c, d, e) => dispatch(SelectCatNome(a, b, c, d, e)),


  };
}
function mapStateToProps(state) {
  return {
    codBords:state.codBords,
    clients: state.clients,
    codres: state.codres,
    ags: state.ags,
    bqs: state.bqs,
    codcli: state.codcli,
    nomenclatures: state.nomenclatures,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    allnomes: state.allnomes,
    catnomes: state.catnomes,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddBordereaux));


