export const setDossier = (a, b, c, d) => {
  return (dispatch) => {
    dispatch({
      type: "SET_CONFIG_DOSSIER",
      payload: { coddos: a, serveur: b, utilisateur: c, pwd_serveur: d },
    });
  };
};
export const setDataBase = (a) => {
  return (dispatch) => {
    dispatch({
      type: "SET_DATA_BASE",
      payload: a,
    });
  };
};
