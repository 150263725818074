import {
  Checkbox,
  IconButton,
  Snackbar,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Tab, Table, Tabs } from "react-bootstrap";
// import clsx from "clsx";
// import { lighten, makeStyles } from "@material-ui/core/styles";
import { Row } from "reactstrap";
import "./facture.scss";

import { connect } from "react-redux";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
// import { setFactureAvecCumulQte } from "../../redux/actions/setFactureAvecCumulQte";
import { SelectFacCod } from "../../redux/actions/GetCodFac";
import { SelectFacture } from "../../redux/actions/GetFacture";

const AddFactureAvecCumulQte = (props) => {
  const [key, setKey] = useState("Facture");
  //   const [orderBy, setOrderBy] = useState("");
  //   const [order, setOrder] = useState("asc");
  const [resscharge, setResscharge] = React.useState(0);
  const [numnum, setNumnum] = useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("codcli");
  const [selected, setSelected] = React.useState([]);
  const [blselected, setBLselected] = React.useState([]);
  const [detailTab, setdetailTab] = React.useState([]);

  const [searchBL, setSearchBL] = React.useState([]);

  const [IsSearchBL, setIsSearchBL] = React.useState(false);
  const [snackbaropen, setsnackbaropen] = React.useState(false);
  const [snackbarmsg, setsnackbarmsg] = React.useState("");
  const DATE_OPTIONS = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  useEffect(() => {
    props.SelectValTimbre(
      props.ConfigDossier.database,
      props.ConfigDossier.coddos,
      props.ConfigDossier.serveur,
      props.ConfigDossier.utilisateur,
      props.ConfigDossier.pwd_serveur
    );
  }, []);
  useEffect(() => {
    props.SelectFacture(
      props.ConfigDossier.coddos,
      props.ConfigDossier.serveur,
      props.ConfigDossier.utilisateur,
      props.ConfigDossier.pwd_serveur,
      props.ConfigDossier.database
    );
    setdetailTab([]);
    setBLselected([]);
  }, [snackbaropen]);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const classes = {
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  };

  const handleSelectBLAllClick = (event) => {
    if (event.target.checked) {
      for (let n = 0; n < props.listebl.length; n++) {
        setdetailTab(
          detailTab.push({
            numfac: props.listebl[n].numfac,
            montant: props.listebl[n].mntbon,
            datfac: props.listebl[n].datfac,
            typfac: props.listebl[n].typfac,
            monreg: Math.min(
              props.listebl[n].reste,
              props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
            ),
            regant: Number(
              props.listebl[n].mntbon - props.listebl[n].reste
            ).toFixed(3),
          })
        );
      }
      setdetailTab(detailTab);
      const newSelecteds = props.listebl.map((n) => ({
        numfac: n.numfac,
        montant: n.mntbon,
        datfac: n.datfac,
        typfac: n.typfac,
        monreg: Math.min(
          n.reste,
          props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
        ),
        regant: Number(n.mntbon - n.reste).toFixed(3),
      }));

      setBLselected(newSelecteds);

      return;
    }

    // if (selected.length == props.listeFacture.length) {
    //   for (let n = 0; n < props.listeFacture.length; n++) {
    //     setdetailTab(
    //       detailTab.push({
    //         numfac: props.listeFacture[n].numfac,
    //         montant: props.listeFacture[n].mntbon,
    //         datfac: props.listeFacture[n].datfac,
    //         typfac: props.listeFacture[n].typfac,
    //         monreg: Math.min(
    //           props.listeFacture[n].reste,
    //           props.montReg - detailTab.reduce((a, v) => a + v.monreg, 0)
    //         ),
    //         regant: Number(
    //           props.listeFacture[n].mntbon - props.listeFacture[n].reste
    //         ).toFixed(3),
    //       })
    //     );
    //   }
    //   setdetailTab(detailTab);
    // }
    //   setdetailTab(detailTab3);
    // }
    setBLselected([]);
    setdetailTab(selected);
  };

  const handleBLClick = (event, obj) => {
    const selectedIndex = blselected.map((el) => el.numfac).indexOf(obj.numfac);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(blselected, obj);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(blselected.slice(1));
    } else if (selectedIndex === blselected.length - 1) {
      newSelected = newSelected.concat(blselected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        blselected.slice(0, selectedIndex),
        blselected.slice(selectedIndex + 1)
      );
    }

    setBLselected(newSelected);
    setdetailTab(newSelected);
  };

  const isblSelected = (obj) =>
    blselected.filter((el) => el.numfac == obj.numfac).length > 0;




  // const enregistrer = () => {
  //   let c = 0;
  //   var testvaleur = 0;
  //   fetch(
  //     `http://www.24-crm.com/webservice/api/FACCLIs?numfact=${detailTab
  //       .map((el) => el.numfac)
  //       .join(",")}&typfact=BL&coddos=${props.ConfigDossier.coddos}&serveur=${
  //       props.ConfigDossier.serveur
  //     }&user=${props.ConfigDossier.utilisateur}&pwd=${
  //       props.ConfigDossier.pwd_serveur
  //     }&database=${props.ConfigDossier.database}`,
  //     {
  //       method: "POST",
  //       header: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         result &&
  //           result.map((f, index) => {
  //             fetch(
  //               `http://www.24-crm.com/webservice/api/FACCLIs?numfac&typfac=FT&codcli=${
  //                 props.codeclient
  //               }&raisoc=${props.raisonsocial}&catfisc=${
  //                 props.categoriefiscale
  //               }&datfac=${props.defaultdate}&timbre=${props.timbre}&smhtb=${
  //                 f.tothtb
  //               }&smremart=${f.totremart}&smremglo=${f.totremglo}&smhtn=${
  //                 f.tothtn
  //               }&smtva=${f.tottva}&mntbon=${
  //                 parseFloat(f.totmntbon) + parseFloat(props.valtimbre)
  //               }&reste=${
  //                 parseFloat(f.totreste) + parseFloat(props.valtimbre)
  //               }&pj=''&codrep=${
  //                 props.Auth.code
  //               }&numcom=''&typcom=FT&valtimbre=${props.valtimbre}&tauxNASS=${
  //                 f.tauxNASS
  //               }&smNASS=${f.totnass}&Vendeur=${props.Auth.username}&coddos=${
  //                 props.ConfigDossier.coddos
  //               }
  //               &km&serveur=${props.ConfigDossier.serveur}&user=${
  //                 props.ConfigDossier.utilisateur
  //               }&pwd=${props.ConfigDossier.pwd_serveur}&database=${
  //                 props.ConfigDossier.database
  //               }`,
  //               {
  //                 method: "POST",
  //                 header: {
  //                   Accept: "application/json",
  //                   "Content-Type": "application/json",
  //                 },
  //               }
  //             )
  //               .then((res) => res.json())

  //               .then((ress) => {
  //                 setsnackbaropen(true);

  //                 setsnackbarmsg("Enregistré sous le N° " + ress);

  //                 for (let i = 0; i < detailTab.length; i++) {
  //                   fetch(
  //                     `https://www.24-crm.tn/webservice/api/BLBRs?idddd=${ress}&typfaccsss=BL&numfacccc=${detailTab[i].numfac}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
  //                     {
  //                       method: "PUT",
  //                       header: {
  //                         Accept: "application/json",
  //                         "Content-Type": "application/json",
  //                       },
  //                     }
  //                   );
  //                 }

  //                 fetch(
  //                   `https://www.24-crm.tn/webservice/api/FACCLIs?numfactsss=${detailTab
  //                     .map((el) => el.numfac)
  //                     .join(",")}&typfactsss=bl&coddos=${
  //                     props.ConfigDossier.coddos
  //                   }&serveur=${props.ConfigDossier.serveur}&user=${
  //                     props.ConfigDossier.utilisateur
  //                   }&pwd=${props.ConfigDossier.pwd_serveur}&database=${
  //                     props.ConfigDossier.database
  //                   }`,
  //                   {
  //                     method: "POST",
  //                     header: {
  //                       Accept: "application/json",
  //                       "Content-Type": "application/json",
  //                     },
  //                   }
  //                 )
  //                   .then((res) => res.json())
  //                   .then((data) => {
  //                     data.map((el, index) => {
  //                       fetch(
  //                         ` http://www.24-crm.com/webservice/api/LigFACCLIs/{ID}?numfac=${ress}&typfac=FT&numlig=${index}&codart=${el.codart}&quantite=${el.sqte}&fodart=${el.fodart}&desart=${el.desart}&datfac=${props.defaultdate}&priuni=${el.priuni}&remise=${el.remise}&unite=${el.unite}&codtva=${el.codtva}&CB=${el.CB}&tautva=${el.tautva}&montht=${el.montht}&ttclig=${el.ttclig}&formqt=0&pmp=${el.pmp}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
  //                         {
  //                           method: "POST",
  //                           header: {
  //                             Accept: "application/json",
  //                             "Content-Type": "application/json",
  //                           },
  //                         }
  //                       ).then((res) => {
  //                         res.json();
  //                       });
  //                     });
  //                   });
  //               });
  //           });
  //         props.SelectFacCod(
  //           props.ConfigDossier.coddos,
  //           props.ConfigDossier.serveur,
  //           props.ConfigDossier.utilisateur,
  //           props.ConfigDossier.pwd_serveur,
  //           props.ConfigDossier.database
  //         );
  //       },

  //       (error) => {
  //         setsnackbaropen(true);
  //         setsnackbarmsg("failed");
  //       }
  //     )

  //     .then((res) => {})

  //     .then(() => {
  //       fetch(
  //         `http://www.24-crm.com/webservice/api/REGCLIs?codeclii=${props.codeclient}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
  //         {
  //           method: "POST",
  //           header: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //     })
  //     .then((res) => {
  //       //////////// switch update ////////////////
  //       setTimeout(() => {
  //         fetch(
  //           `http://www.24-crm.com/webservice/api/Switch?code=FC2&valeur&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
  //           {
  //             method: "PUT",
  //             header: {
  //               Accept: "application/json",
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         )
  //           .then((res) => res.json())
  //           .then(
  //             (result) => {
  //               setdetailTab([]);
  //               setBLselected([]);
  //             },
  //             (error) => {
  //               setsnackbaropen(true);
  //               setsnackbarmsg("failed");
  //               // setState({ snackbaropen: true, snackbarmsg: "failed" });
  //             }
  //           );
  //         // window.location.reload();
  //       }, 3000);
  //     });
  //   // props.onHide();
  // };






  const enregistrer = () => {
    let c = 0;

    fetch(
 
      `https://www.24-crm.tn/webservice/api/FACCLIs?numfact=${detailTab
        .map((el) => el.numfac)
        .join(",")}&typfact=BL&coddos=${props.ConfigDossier.coddos}&serveur=${
        props.ConfigDossier.serveur
      }&user=${props.ConfigDossier.utilisateur}&pwd=${
        props.ConfigDossier.pwd_serveur
      }&database=${props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json()) 

      .then(
        (result) => {
          result &&
            result.map((f, index) => {
              fetch(
                `https://www.24-crm.tn/webservice/api/FACCLIs?numfac&typfac=FT&codcli=${
                  props.codeclient
                }&raisoc=${props.raisonsocial}&catfisc=${
                  props.categoriefiscale
                }&datfac=${props.defaultdate}&timbre=${props.timbre}&smhtb=${
                  f.tothtb
                }&smremart=${f.totremart}&smremglo=${f.totremglo}&smhtn=${
                  f.tothtn
                }&smtva=${f.tottva}&mntbon=${
                  parseFloat(f.totmntbon) + parseFloat(props.valtimbre)
                }&reste=${
                  parseFloat(f.totreste) + parseFloat(props.valtimbre)
                }&pj=''&codrep=${
                  props.Auth.code
                }&numcom=${props.codfacs.codfacs.map(
                  (t) => t.valeur
                )}&typcom=FT&valtimbre=${props.valtimbre}&tauxNASS=${
                  f.tauxNASS
                }&smNASS=${f.totnass}&Vendeur=${props.Auth.username}&coddos=${
                  props.ConfigDossier.coddos
                }&serveur=${props.ConfigDossier.serveur}&user=${
                  props.ConfigDossier.utilisateur
                }&pwd=${props.ConfigDossier.pwd_serveur}&database=${
                  props.ConfigDossier.database
                }`,
                {
                  method: "POST",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              ) 
                .then((res) => res.json())
                .then((result) => {
                  setsnackbaropen(true);
                  setsnackbarmsg("Enregistré sous le N° " + result);
                  let blafact=detailTab[0].numfac;
                    for (let j= 1 ; j< detailTab.length;j++)
                    {
                     blafact='\-'+blafact+'-,\-'+detailTab[j].numfac+'\-'
                    }
                 
                    fetch(
                      `https://www.24-crm.tn/webservice/Api/FACClIs?numfactsss=${blafact}&typfactsss=BL&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
                      {
                        method: "POST",
                        header: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                        },
                      }
                    )
                      .then((res) => res.json())
                      .then((data) => {
                        data.map((el, index) => {
                      //  for (let i = 0; i < detailTab.length; i++) {
                          fetch(
                            `https://www.24-crm.tn/webservice/api/LigFACCLIs/{ID}?numfac=${result}&typfac=FT&numlig=FT${index}${index}&codart=${el.codart}&quantite=${el.sqte}&fodart=${el.fodart}&desart=${el.desart}&datfac=${new Date().toISOString()}&priuni=${el.priuni}&remise=${el.remise}&unite=${el.unite}&codtva=${el.codtva}&tautva=${el.tautva}&montht=${el.sht}&ttclig=${el.sttc}&CB=${el.CB}&formqt=0&pmp=${el.pmp}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
                            {
                              method: "POST",
                              header: {
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                            }
                          )
                          
                      });
                      })
                      .then((res) => {
                        fetch(
                          `https://www.24-crm.tn/webservice/api/BLBRs?idddd=${
                            props.codfacs.codfacs.map((el) => el.valeur)[0]
                          }&typfaccsss=BL&numfacccc=${
                            blafact
                          }&coddos=${props.ConfigDossier.coddos}&serveur=${
                            props.ConfigDossier.serveur
                          }&user=${props.ConfigDossier.utilisateur}&pwd=${
                            props.ConfigDossier.pwd_serveur
                          }&database=${props.ConfigDossier.database}`,
                          {
                            method: "PUT",
                            header: {
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                      });
//                  }
                });
            });
        },
        (error) => {
          setsnackbaropen(true);
          setsnackbarmsg("failed");
        }
      )
      .then((res) => {
        fetch(
          `https://www.24-crm.tn/webservice/api/REGCLIs?codeclii=${props.codeclient}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
          {
            method: "POST",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
      })
      .then((res) => {
        /////////Switch////////

        setTimeout(() => {
          fetch(
            `https://www.24-crm.tn/webservice/api/Switch?code=FC2&valeur&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`,
            {
              method: "PUT",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                console.log(result);
                window.location.reload();
              },
              (error) => {
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "failed",
                });
              }
            )
            .then((res) => {
             // window.location.reload(); 
            });
        }, 3000);
      });

    // this.setState({ snackbaropen: true, snackbarmsg: result });
    props.SelectFacCod(
      props.ConfigDossier.coddos,
      props.ConfigDossier.serveur,
      props.ConfigDossier.utilisateur,
      props.ConfigDossier.pwd_serveur,
      props.ConfigDossier.database
    );
  };







  


  const rechercheHandlerBL = (a) => {
    if (a != "") {
      fetch(
        `http://www.24-crm.com/webservice/api/BLBRs/${a}?codcliii=${props.codeclient}&coddos=${props.ConfigDossier.coddos}&serveur=${props.ConfigDossier.serveur}&user=${props.ConfigDossier.utilisateur}&pwd=${props.ConfigDossier.pwd_serveur}&database=${props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) => {
          setIsSearchBL(true);
          setSearchBL(data);
        });
    } else {
      setIsSearchBL(false);
    }
  };
  const snackbarClose = () => {
    setsnackbaropen(false);
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbaropen}
        autoHideDuration={2000}
        onClose={snackbarClose}
        message={<span id="message-id"> {snackbarmsg} </span>}
        action={[
          <IconButton key="close" color="inherit" onClick={snackbarClose}>
            x
          </IconButton>,
        ]}
      ></Snackbar>
      <Modal size="xl" show={props.show} onHide={props.onHide} centered>
        <Modal.Header
          closeButton
          style={{ backgroundColor: "white", color: "#020F64" }}
        >
          <Modal.Title>
            <b>Ajouter une facture avec cumul des quantitées</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            style={{ color: "rgb(23, 162, 184)" }}
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="BL" title="Bon de Livraison" active>
              <TextField
                id="filled-full-width"
                placeholder="Recherche BL"
                fullWidth
                margin="dense"
                onChange={(e) => rechercheHandlerBL(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <div className="tabft">
                <table>
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    numSelected={blselected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectBLAllClick}
                    rowCount={props.listebl.length}
                  />
                  <tbody>
                    {stableSort(
                      IsSearchBL ? searchBL : props.listebl,
                      getComparator(order, orderBy)
                    ).map((test, index) => {
                      const isItemblSelected = isblSelected({
                        numfac: test.numfac,
                        smtva: test.smtva,
                        smhtn: test.smhtn,
                        smremglo: test.smremglo,
                        smremart: test.smremart,
                        smhtb: test.smhtb,
                        tauxfodec: test.tauxfodec,
                        valtimbre:
                          test.timbre &&
                          props.valtimbres.valtimbres.map(
                            (el) => el.valtimbre
                          )[0],

                        montant: test.mntbon,
                        datfac: test.datfac,
                        typfac: test.typfac,
                        regant: Number(test.mntbon - test.reste).toFixed(3),
                        reste: test.reste,
                        monreg: Math.min(
                          test.reste,
                          props.montReg -
                            detailTab.reduce((a, v) => a + v.monreg, 0)
                        ),
                      });
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          onClick={(event) =>
                            handleBLClick(event, {
                              numfac: test.numfac,
                              montant: test.mntbon,
                              datfac: test.datfac,
                              typfac: test.typfac,
                              reste: test.reste,
                              smtva: test.smtva,
                              smhtn: test.smhtn,
                              smremglo: test.smremglo,
                              smremart: test.smremart,
                              smhtb: test.smhtb,
                              tauxfodec: test.tauxfodec,
                              valtimbre:
                                test.timbre &&
                                props.valtimbres.valtimbres.map(
                                  (el) => el.valtimbre
                                )[0],

                              monreg: Math.min(
                                test.reste,
                                props.montReg -
                                  detailTab.reduce((a, v) => a + v.monreg, 0)
                              ),
                              regant: Number(test.mntbon - test.reste).toFixed(
                                3
                              ),
                            })
                          }
                          role="checkbox"
                          // aria-checked={isItemblSelected}
                          tabIndex={-1}
                          key={{
                            numfac: test.numfac,
                            montant: test.mntbon,
                          }}
                          // selected={isItemblSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemblSelected}
                              color="primary"
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <td>{test.numfac}</td>
                          <td>{test.typfac}</td>
                          <td>
                            {new Date(test.datfac).toLocaleDateString(
                              "fr",
                              DATE_OPTIONS
                            )}
                          </td>

                          <td>{test.raisoc}</td>

                          <td>{test.codcli}</td>

                          <td>{test.mntbon.toFixed(3)}</td>
                          <td>{Number(test.mntbon - test.reste).toFixed(3)}</td>
                          <td>{test.reste.toFixed(3)}</td>
                        </TableRow>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm={6}>
              <h5>Détails</h5>
              <div className="tabde">
                <table>
                  <thead>
                    <tr>
                      <th>Numéro</th>
                      <th>Type</th>
                      <th>Date</th>
                      <th>Montant</th>
                      {/* <th>Reg.Ant</th>
                    <th>Reglement</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {detailTab.map((el) => (
                      <tr>
                        <td>{el.numfac}</td>
                        <td>{el.typfac}</td>

                        <td>{new Date(el.datfac).toLocaleDateString()}</td>
                        <td>{el.montant.toFixed(3)}</td>
                        {/* <td>{el.regant}</td>
                      <td>{el.monreg.toFixed(3)}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
            <Col sm={{ span: 6 }}>
              <Table>
                <TableBody>
                  {/* <TableRow rowSpan={4}>
                  <TableCell>Montant Total</TableCell>
                  <TableCell>
                    {props.listeFacture
                      .reduce((c, v) => c + parseFloat(v.mntbon), 0)
                      .toFixed(3)}
                  </TableCell>
                </TableRow> */}
                  <TableRow rowSpan={4}>
                    <TableCell>Total HT brut</TableCell>
                    <TableCell>
                      {detailTab
                        .reduce((c, v) => c + parseFloat(v.smhtb), 0)
                        .toFixed(3)}
                    </TableCell>
                  </TableRow>
                  <TableRow rowSpan={4}>
                    <TableCell>Remise</TableCell>
                    <TableCell>
                      {detailTab
                        .reduce((c, v) => c + parseFloat(v.smremglo), 0)
                        .toFixed(3)}
                    </TableCell>
                  </TableRow>
                  <TableRow rowSpan={4}>
                    <TableCell align="left">Total HT net</TableCell>
                    <TableCell>
                      {detailTab
                        .reduce((c, v) => c + parseFloat(v.smhtn), 0)
                        .toFixed(3)}
                    </TableCell>
                  </TableRow>
                  <TableRow rowSpan={4}>
                    <TableCell align="left">Total TVA</TableCell>
                    <TableCell>
                      {detailTab
                        .reduce((c, v) => c + parseFloat(v.smtva), 0)
                        .toFixed(3)}
                    </TableCell>
                  </TableRow>
                  <TableRow rowSpan={4}>
                    <TableCell align="left">Total Fodec</TableCell>
                    <TableCell>
                      {detailTab
                        .reduce((c, v) => c + parseFloat(v.tauxfodec), 0)
                        .toFixed(3)}
                    </TableCell>
                  </TableRow>

                  <TableRow rowSpan={4}>
                    <TableCell align="left">Timbre Fiscal</TableCell>
                    <TableCell>{Number(props.valtimbre).toFixed(3)}</TableCell>
                  </TableRow>
                  <TableRow rowSpan={4}>
                    <TableCell align="left">Total TTC</TableCell>
                    <TableCell>
                      {Number(
                        detailTab.reduce((c, v) => c + v.montant, 0) +
                          props.valtimbre
                      ).toFixed(3)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Col>
          </Row>
          <Col md={12}>
            <Button variant="primary" onClick={enregistrer}>
              Enregistrer
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    SelectValTimbre: (a, b, c, d, e) =>
      dispatch(SelectValTimbre(a, b, c, d, e)),
    SelectFacture: (a, b, c, d, e) => dispatch(SelectFacture(a, b, c, d, e)),
    SelectFacCod: (a, b, c, d, e) => dispatch(SelectFacCod(a, b, c, d, e)),
  };
};
const mapStateToProps = (state) => {
  return {
    valtimbres: state.valtimbres,
    codfacs: state.codfacs,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddFactureAvecCumulQte);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  { id: "numfac", numeric: true, disablePadding: true, label: "N°" },
  { id: "typfac", numeric: true, disablePadding: true, label: "type" },
  { id: "datfac", numeric: true, disablePadding: true, label: "Date" },
  {
    id: "raisoc",
    numeric: true,
    disablePadding: false,
    label: "Raison Sociale",
  },
  {
    id: "codcli",
    numeric: true,
    disablePadding: false,
    label: "Code Client",
  },

  { id: "mntbon", numeric: true, disablePadding: false, label: "Montant" },
  {
    id: "",
    numeric: true,
    disablePadding: false,
    label: "Tot.Reg",
  },
  {
    id: "reste",
    numeric: true,
    disablePadding: false,
    label: "Reste",
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
