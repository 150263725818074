import React, { Component } from "react";
import "./HomePage.scss";
import { Row, Card, Col } from "react-bootstrap";
import { Divider } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { SelectTopclient } from "../../redux/actions/Top5";
import { SelectClient } from "../../redux/actions/GetClients";
import FullWidthTabs from "./Tab";
import TabTop from "./TabTop";
import TabBLFT from "./TabBLFT";
import ChargeGraph from "./ChargeGraph";
import { SelectSumCharge } from "../../redux/actions/SumCharge";
import TabTwo from "./TabTwo";
import image1 from "../../Login/contactsyros.png";
import translate from "../../i18n/translate";
import LineChart from "./LineChart";

class HomePage extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");
    const fct = localStorage.getItem("fct");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      show: false,
      loggedIn,
      tpk: token,
      user: username,
      activeTab: "2",
      token: token,
      username: username,
      fct: fct,
    };
  }
  componentDidMount() {
    this.props.SelectTopclient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectSumCharge(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    document.title = "24CRM";
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  };

  render() {
    const { darck } = this.props;
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        {this.state.fct === "200" ? (
          // <h3> Bienvenu dans SYROS {this.state.username} </h3>
          <div className="contact-page" style={{ width: "100%" }}>
            <img
              src={image1}
              alt="Contact"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ) : (
          // <div className="scroll">
          <>
            <Row>
              <Col sm={4} style={{ marginBottom: "15px" }}>
                <Card className={darck ? "card111" : "card111Dark"}>
                  <Card.Body>
                    <Row>
                      <Col sm={6}>
                        <p className={darck ? "p1" : "p1Darck"}>{translate("Total Charge")}</p>
                      </Col>
                      <Col sm={6}>
                        {this.props.charges.charges.map((t) => (
                          <p className={darck ? "p2" : "p2Darck"}>{Number(t.somme).toFixed(3)}</p>
                        ))}
                      </Col>
                    </Row>
                    <Divider
                      style={{
                        background: `${darck ? "#0f2535" : "#fff" }`,
                        marginBottom: "5px",
                        marginTop: "-10px",
                      }}
                    ></Divider>
                    <p className={darck ? "p4" : "p4Darck"}>
                      {" "}
                      {translate("Total Charge des Fournisseurs par jour")}{" "}
                    </p>
                    <ChargeGraph />
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={4} style={{ marginBottom: "15px" }}>
                <Card className={darck ? "card111" : "card111Dark"}>
                  <Card.Body>
                    <TabTwo />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} style={{ marginBottom: "15px" }}>
                <Card className={darck ? "card111" : "card111Dark"}>
                  <Card.Body>
                    <TabBLFT />
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <br />
            <Row>
            <Col sm={4} style={{ marginBottom: "15px" }}>
                <Card className={darck ? "card111" : "card111Dark"}>
                  <Card.Body>
                    <FullWidthTabs></FullWidthTabs>
                  </Card.Body>
                </Card>
              </Col>

              <Col sm={4} style={{ marginBottom: "15px" }}>
                <Card className={darck ? "card111" : "card111Dark"}>
                  <Card.Body>
                    <TabTop />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm={4} style={{ marginBottom: "15px" }}>
                <Card className={darck ? "card111" : "card111Dark"}>
                <Card.Body>
                <p className={darck ? "p1" : "p1Darck"} style={{ margin: "10px",textAlign:"center" }}>
                   {translate("Total Montant des Reglements Client/Fournisseur par jour")}
                  </p>
                    <LineChart />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
          // </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectTopclient: (a, b, c, d, e) =>
      dispatch(SelectTopclient(a, b, c, d, e)),

    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    SelectSumCharge: (a, b, c, d, e) =>
      dispatch(SelectSumCharge(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    tops: state.tops,
    clients: state.clients,
    charges: state.charges,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
