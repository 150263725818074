const initialState = {
  username: "",
  code: "",
  fct: "",
  loading: false,
  isAuth: false,
  token: "",
};
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGOUT_USER":
      return initialState;
    case "USER_AUTH":
      return {
        username: action.payload.username,
        code: action.payload.code,
        fct: action.payload.fct,

        isAuth: action.payload.isAuth,
        token: action.payload.token,
      };
    case "SHOW_LOADER":
      return { ...state, loading: true };
    case "HIDE_LOADER":
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default AuthReducer;
