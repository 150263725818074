import React, { Component } from "react";
import { Modal, Card, Row, Col, Alert } from "react-bootstrap";
import "../styling/Styles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LanguageIcon from "@material-ui/icons/Language";
import { Divider, Snackbar, IconButton } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ListAltIcon from '@material-ui/icons/ListAlt';
import { connect } from "react-redux";
import ReactToPrint from "react-to-print";
import {Fab} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import ModifierMVTCAISModal from "./ModifierMVTCAISModal";

import MailModal from "./MailModal";
import moment from 'moment';
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import translate from "../../i18n/translate";
import {  FormGroup, Button } from "reactstrap";
import { TextField} from "@material-ui/core";
import _ from 'lodash';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
 // { icon: <EditIcon />, name: "Modifier" },
  //{ icon: <DeleteOutlineIcon />, name: "Supprimer" },
 // { icon: <ListAltIcon />, name: "Relevé" },
];

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class GetRapportCAIByID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      openReleveClientModal: false,
      openMailModal: false,
      snackbaropen: false,
      snackbaropenn: false,
      snackbarmsg: "",
      defaultdate: date,
      rapportdepense:[],
      rapportcumul:[],
      firstdate: date,
      seconddate: date,
    };
  }

  // componentDidMount() {
  //   document.getElementById("tell").innerHTML =
  //     '<a href="tel:' + this.props.tel1 + '">' + this.props.tel1 + "</a>";
  // }



  componentDidMount() {
    this.setState({
      rechercheValue: "",
      codeclient: "",
      raisonsocial: "",
    });

      fetch(
      `https://www.24-crm.tn/webservice/api/caisse?cais1=${this.props.numcais}&cais2=${this.state.codcaisse}&datbeb=${this.state.firstdate}&datfin=${this.state.seconddate}&Vend=${this.state.NomVend||'%'}&sold=${this.state.JD||0}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          rapportcumul: data,
          rechercheclicked: true,
        });
      });
   

      }

  handleOpen = () => {
    this.setState({ open: true });
    
   
      fetch(
        `https://www.24-crm.tn/webservice/api/caisse?cais1=${this.props.numcais}&cais2=${this.state.codcaisse}&datbeb=${this.state.firstdate}&datfin=${this.state.seconddate}&Vend=${this.state.NomVend||'%'}&sold=${this.state.JD||0}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
        )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          rapportcumul: data,
          rechercheclicked: true,
        });
      })
    
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    this.setState({ openMailModal: true });
  };

  openModifier = () => {
    this.setState({ openModifierModal: true });
  };

  openReleveClient = () => {
    this.setState({ openReleveClientModal: true });
  };

  nomail = () => {
    this.setState({ snackbaropen: true });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarClosee = (event) => {
    this.setState({ snackbaropenn: false });
  };




  totalMontantcredit = () => {

    return this.state.rapportcumul.reduce((acc, item) => acc + (item.credit || 0), 0);
  };

  totalMontantdebit = () => {

    return this.state.rapportcumul.reduce((acc, item) => acc + (item.debit || 0), 0);
  };
 

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    let ReleveClientModalClose = () => this.setState({ openReleveClientModal: false });
    let MailModalClose = () => this.setState({ openMailModal: false });
    const { darck, classes } = this.props;
    const result = convertToDinarsAndMillimes(this.props.montant);
    this.props.css.css.forEach((caisse, index) => {
    console.log(`Caisse ${index + 1} lib:`, caisse.lib);
    });
    const groupedData1 = _.groupBy(this.state.rapportdepense, 'Lib');

    //console.log("rapportdepense",this.state.rapportdepense)
    console.log("rapportcumul",this.state.rapportcumul)
    console.log("vendd",this.props.vends.vends)
    return (
      
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropenn}
          autoHideDuration={2000}
          onClose={this.snackbarClosee}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClosee}
              style={{
                
                color: `${darck ? "#020F64" : "#fff"}`
              }}

            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
       
        </Snackbar>

        <Modal
          {...this.props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Journal de Caisses Recette / Dépense  </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
               marginBottom: "15px", 
            }}
          >
       
       <Row style={{ display: "flex", alignItems: "stretch"}}> 
 
       <Col sm={12}>
            <Card  className="card0" style={{ height: "100%"}}>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  borderTop: `${darck ? "" : "1px solid #fff"}`,
                  borderRight: `${darck ? "" : "1px solid #fff"}`,
                  borderLeft: `${darck ? "" : "1px solid #fff"}`,
                  borderBottom: `${darck ? "" : "1px solid #fff"}`,
                }}
              >
              <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              style={{ margin: "0" }}
                              // className="card add-input"
                              id="standard-basic"
                              label="Caisse"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="caiss"
                              defaultValue={this.props.numcais}
                           
                              disabled
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                           {/* //////////////////////libelle//////////////////////////// */}
                    <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Libellé"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              disabled
                              name="raisoc"
                              defaultValue={this.props.lib}
                             // value={this.props.libelle}  // Set value from state
                              onChange={(e) => this.setState({ libelle: e.target.value })} 
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                              <FormGroup>
                                <Autocomplete
                                  id="include-input-in-list"
                                  includeInputInList
                                  className="ajouter-client-input"
                                  options={this.props.css.css}
                                  defaultValue={{ code: this.props.numcais }}
                                  getOptionLabel={(option) => option.code}
                                  onChange={(event, getOptionLabel) => {
                                    if (getOptionLabel) {
                                      this.setState({
                                        codcaisse: getOptionLabel.code,
                                        libcaisse: getOptionLabel.lib,
                                        libelle: getOptionLabel.lib, // Update libelle directly here
                                      });
                                    } else {
                                      this.setState({
                                        codcaisse: "",
                                        libcaisse: "",
                                        libelle: "", // Reset libelle if no option is selected
                                      });
                                    }
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      label="Caisse"
                                      margin="dense"
                                      fullWidth
                                      name="Caisse"
                                      variant="outlined"
                                      required
                                      InputProps={{
                                        ...params.InputProps,
                                        className: !darck ? this.props.classes.input : null,
                                      }}
                                      inputProps={params.inputProps}
                                      id={params.id}
                                      InputLabelProps={{
                                        style: {
                                          color: !darck ? "#fff" : null,
                                        },
                                      }}
                                    />
                                  )}
                                />
                                {/* <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libcaisse}</FormHelperText> */}
                              </FormGroup>
                            </Col>
                           {/* //////////////////////libelle//////////////////////////// */}
                    
                        <Col sm={6}>
                                <FormGroup style={{ overflow: "hidden" }}>
                                  <TextField
                                    id="standard-basic"
                                    label=" "
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    disabled
                                    name="labelle"
                                    value={this.state.libelle} // Use state value directly here
                                    onChange={(e) => this.setState({ libelle: e.target.value })}
                                    InputProps={{
                                      className: !darck ? this.props.classes.input : null,
                                    }}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                      
                        <Col sm={6}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        onChange={(e) => this.setState({ firstdate: e.target.value })} 
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.state.firstdate)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                    </Col>
                    <Col sm={6}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        onChange={(e) => this.setState({ seconddate: e.target.value })} 
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.state.seconddate)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                    </Col>
                    <br/>
                  
                    <Col sm={12}></Col>
                    <Col sm={12}>
  <div>
    <label>
      <input
        type="checkbox"
        checked={this.state.JD}
        onChange={(e) => this.setState({ JD: e.target.checked ? 1 : 0 })}
      />
      Ne pas affiche le solde de départ
    </label>
  </div>

 
  
</Col>
  <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.vends.vends}
                          getOptionLabel={(option) => option.Nom}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  NomVend: getOptionLabel.Nom,
                                  NomVendlibelle: getOptionLabel.Libellé,
                                })
                              : this.setState({
                                coddepense: "",
                                  libdepense: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Utilisateur"
                              margin="dense"
                              fullWidth
                              name="Utilisateur"
                              variant="outlined"
                             
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        </FormGroup>
                    </Col>

                      </Row>
              </Card.Body>
            </Card>
       </Col>


      

       </Row>



            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                        // this.props.email !== "" &&
                        //   action.name === "Mail" &&
                        //   this.openMail();
                        // this.props.email === "" &&
                        //   action.name === "Mail" &&
                        //   this.nomail();
                        // action.name === "Modifier" && this.openModifier();
                        // action.name === "Supprimer" &&
                        //   this.deleteclient(this.props.codeclient) &&
                        //   this.props.onHide();
                        // action.name === "Relevé" && this.openReleveClient();
                      }}
                    />
                  ))}
                        {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
            
              </Col>
            </Row>

            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  

  

  <Col>  <h4 style={{ fontSize: "25px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h4>
              <h6 style={{ marginLeft: "830px", marginTop: "5px" ,  }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
     
             
             </Col>
              <h5
                style={{
                  marginLeft: "400px",
                  flexDirection: "column",
                  marginTop: "15px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
              
                <> Journal Des Caisses  Recette / Dépense </>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
          
             Date du &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; au &nbsp;
             { moment(this.state.seconddate).format("DD/MM/YYYY")}
            
            
  
              
              </h5>
            <br/>   <br/> 

     <Col> 
      <Row style={{ marginBottom: "5px" }}>
                      <Col>
                        <Divider style={{ backgroundColor: "grey" }} />
                     
                      </Col>
                      
                    </Row>
       <Row style={{ marginBottom: "5px" }}>
                      <Col>
                        <Divider style={{ backgroundColor: "grey" }} />
                     
                      </Col>
                      
                    </Row>
      {/* <h6
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "55px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                 Caisse  {"{"}{this.props.numcais}{"}"} :  {this.props.lib} 
              </h6> */}
                </Col> 
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "0px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >    
              {/* <br/>   */}
<table
  style={{
    textAlign: "center",
    borderStyle: "10px",
    width: "100%",
    marginTop: "15px",
  }}
>

 <tbody>

  {Object.entries(_.groupBy(this.state.rapportcumul, 'LibCaisse')).map(([lib, group], i) => (
    <React.Fragment key={`group-${i}`} style={{ marginTop: "20px" }}>
      {/* Affiche le nom du groupe */}
      <tr>
        <td colSpan="8" style={{ fontWeight: "bold", backgroundColor: "#fff", height: "60px", textAlign: "left" }}>
        Caisse: {lib}
        </td>
      </tr>

      {/* Affiche l'entête de tableau pour chaque groupe */}
      <tr
        style={{
          borderBottom: "1px solid grey",
          border: "1px solid grey",
          height: "30px",
        }}
      >
        <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Date")}</th>
        <th style={{ width: "15%", border: "1px solid gray" }}>{translate("Opération")}</th>
        <th style={{ width: "15%", border: "1px solid gray" }}>{translate("Libellé")}</th>
        <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Mode")}</th>
        <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Ech")}</th>
        <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Numéro")}</th>
        <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Recette")}</th>
        <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Dépense")}</th>
        <th style={{ width: "10%", border: "1px solid gray" }}>{translate("Solde")}</th>
         </tr>

      {/* Affiche les lignes du groupe */}
      {group.map((t, j) => (
       
        <tr key={`${i}-${j}`} style={{ textAlign: "center", height: "30px", border: "1px solid gray" }}>
        
            {/* Date */}
            <td style={{ color: "black", width: "10%", border: "1px solid gray" }}>
            {t.Date ? moment(t.Date).format("DD/MM/YYYY") : '--'}
          </td>

        {/* caisse */}
        <td style={{ border: "1px solid gray" }}>
            <span style={{ color: t.Lib ? "black" : "#182532" }}>{ t.Lib || '--'}</span>
          </td>
         
          {/* Libellé */}
          <td style={{ border: "1px solid gray" }}>
            <span style={{ color: t.Tier ? "black" : "#182532" }}>{t.Tier +" "+ t.RaiSoc || '--'}</span>
          </td>

         {/* modreg */}
         <td style={{ border: "1px solid gray" }}>
            <span style={{ color: t.modreg ? "black" : "#182532" }}>{t.modreg  || '--'}</span>
          </td>
          {/* pdatech */}
          <td style={{ color: "black", width: "10%", border: "1px solid gray" }}>
            {t.pdatech ? moment(t.pdatech).format("DD/MM/YYYY") : '--'}
           </td>
          {/* Numchq */}
          <td style={{ border: "1px solid gray" }}>
            <span style={{ color: t.NumChq ? "black" : "#182532" }}>{t.NumChq  || '--'}</span>
          </td>
           {/* Recette */}
           <td style={{ border: "1px solid gray" }}>
            <span style={{ color: t.debit ? "black" : "#182532" }}>{t.debit  || '--'}</span>
          </td>
            {/* depense */}
            <td style={{ border: "1px solid gray" }}>
            <span style={{ color: t.credit ? "black" : "#182532" }}>{t.credit  || '--'}</span>
          </td>
          {/* montant */}
          <td style={{ border: "1px solid gray" }}>
            <span style={{ color: t.Solde ? "black" : "#182532" }}>{Number(t.Solde).toFixed(2)  || '--'}</span>
          </td>
        
         
        </tr>
      ))}

      {/* Ligne des montant */}
      <tr style={{ fontWeight: "bold", backgroundColor: "#fff", height: "30px" }}>
     
        <td style={{ border: "1px solid gray" }} colSpan="6">  Total  Période Caisse  {group.reduce((total, t) => (t.caisse || '--'))}:</td>
       
        <td style={{ border: "1px solid gray" }}>
          {Number(group.reduce((total, t) => total + (t.debit || 0), 0)).toFixed(2)}
        </td>
        <td style={{ border: "1px solid gray" }}>
          {Number(group.reduce((total, t) => total + (t.credit || 0), 0)).toFixed(2)}
        </td>
        <td style={{ border: "1px solid gray" }}>
          {Number(group.reduce((total, t) =>  (t.Solde || 0), 0)).toFixed(2)}
        </td>
      </tr>
   {/* Ligne des montant */}
   <tr style={{ fontWeight: "bold", backgroundColor: "#fff", height: "30px" }}>
     
     <td style={{ border: "1px solid gray" }} colSpan="6">  Total Général Caisse {group.reduce((total, t) => (t.caisse || '--'))}:</td>
    
     <td style={{ border: "1px solid gray" }}>
       {Number(group.reduce((total, t) => total + (t.debit || 0), 0)).toFixed(2)}
     </td>
     <td style={{ border: "1px solid gray" }}>
       {Number(group.reduce((total, t) => total + (t.credit || 0), 0)).toFixed(2)}
     </td>
     <td style={{ border: "1px solid gray" }}>
       {Number(group.reduce((total, t) =>  (t.Solde || 0), 0)).toFixed(2)}
     </td>
   </tr>   
 
    </React.Fragment> 
   
  ))}
 
   {/* Ligne des totaux */}
  <tr style={{ fontWeight: "bold", backgroundColor: "#fff", height: "30px" }}>
       
        <td style={{ border: "1px solid gray" }} colSpan="6"> Total Général de la Période :</td>
       
        <td style={{ border: "1px solid gray" }}>
          {Number(this.totalMontantdebit()).toFixed(2)}
        </td>
        <td style={{ border: "1px solid gray" }}>
          {Number(this.totalMontantcredit()).toFixed(2)}
        </td>
        <td style={{ border: "1px solid gray" }}>
        {Number(Number(this.totalMontantdebit()) - Number(this.totalMontantcredit())).toFixed(3)}
        </td>
      </tr>
      <tr style={{ fontWeight: "bold", backgroundColor: "#fff", height: "30px" }}>
       
       <td style={{ border: "1px solid gray" }} colSpan="6"> Totaux Généraux :</td>
      
       <td style={{ border: "1px solid gray" }}>
         {Number(this.totalMontantdebit()).toFixed(2)}
       </td>
       <td style={{ border: "1px solid gray" }}>
         {Number(this.totalMontantcredit()).toFixed(2)}
       </td>
       <td style={{ border: "1px solid gray" }}>
       {Number(Number(this.totalMontantdebit()) - Number(this.totalMontantcredit())).toFixed(3)}
       </td>
     </tr>
 

  
</tbody>



</table>

<br/>   <br/> 

                <br />
           
      

           
           
               
              </div>
            
          
            
            </div>
          </div>









            <ModifierMVTCAISModal
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              numopr={this.props.numopr}
              libelle={this.props.libelle}
              numcais={this.props.numcais}
              montant={this.props.montant}
              modreg={this.props.modreg}
              depense={this.props.depense}
              VENDEUR={this.props.VENDEUR}
              datopr={this.props.datopr}
              numchq={this.props.numchq}
              matban={this.props.matban}
              datech={this.props.datech}
              sens={this.props.sens}
              heur={this.props.heur}
              numoprdebit={this.props.numoprdebit}
              Emp={this.props.Emp}
            />
       

        
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    DataTables: state.DataTablesReducer,
    tests: state.tests,
    SearchingResult: state.SearchingReducer,
    nomenclatures: state.nomenclatures,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    Caisses:state.Caisses,
    css: state.css, 
     depenses:state.depenses,
     vends:state.vends,
  };
}





function numberToFrenchWords(number) {
  const units = ['ZÉRO', 'UN', 'DEUX', 'TROIS', 'QUATRE', 'CINQ', 'SIX', 'SEPT', 'HUIT', 'NEUF'];
  const tens = ['', '', 'VINGT', 'TRENTE', 'QUARANTE', 'CINQUANTE', 'SOIXANTE', 'SOIXANTE-DIX', 'QUATRE-VINGT', 'QUATRE-VINGT-DIX'];
  const teens = ['DIX', 'ONZE', 'DOUZE', 'TREIZE', 'QUATORZE', 'QUINZE', 'SEIZE', 'DIX-SEPT', 'DIX-HUIT', 'DIX-NEUF'];

  if (number < 10) return units[number];
  if (number < 20) return teens[number - 10];
  if (number < 100) {
      if (number % 10 === 0) return tens[Math.floor(number / 10)];
      if (number < 70) return tens[Math.floor(number / 10)] + '-' + units[number % 10];
      if (number < 80) return 'SOIXANTE-' + teens[number % 10];
      return 'QUATRE-VINGT-' + units[number % 10];
  }
  if (number < 1000) {
      if (number % 100 === 0) return units[Math.floor(number / 100)] + ' CENT';
      return units[Math.floor(number / 100)] + ' CENT ' + numberToFrenchWords(number % 100);
  }
  if (number < 10000) {
      if (number % 1000 === 0) return units[Math.floor(number / 1000)] + ' MILLIMES';
      return units[Math.floor(number / 1000)] + ' MILLIMES ' + numberToFrenchWords(number % 1000);
  }
  return '';
}

function convertToDinarsAndMillimes(number) {
  const integerPart = Math.floor(number);
  const decimalPart = Math.round((number - integerPart) * 1000);

  let dinars = numberToFrenchWords(integerPart) + ' DINARS';
  let millimes = '';

  // Convertir les millimes uniquement si la partie décimale n'est pas zéro
  if (decimalPart > 0) {
    millimes = ' ET ' + numberToFrenchWords(decimalPart) + ' MILIMES';
  }

  return dinars + millimes;
}

export default connect(mapStateToProps, null)(GetRapportCAIByID);
