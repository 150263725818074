const intialState = [];
const SetAffectationRegFrsReducer = (state = intialState, action) => {
  switch (action.type) {
    case "AFF_REG_FRS":
      return action.payload;

    default:
      return state;
  }
};
export default SetAffectationRegFrsReducer;
