const intialState = {
  coddos: "",
  serveur: "",
  utilisateur: "",
  pwd_serveur: "",
  database: "",
};
const SetDossierReducer = (state = intialState, action) => {
  switch (action.type) {
    case "SET_CONFIG_DOSSIER":
      return {
        coddos: action.payload.coddos,
        serveur: action.payload.serveur,
        utilisateur: action.payload.utilisateur,
        pwd_serveur: action.payload.pwd_serveur,
      };
    case "SET_DATA_BASE":
      return { ...state, database: action.payload };

    default:
      return state;
  }
};
export default SetDossierReducer;
