import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import { FormGroup, Col, Row } from "reactstrap";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { SelectArticle } from "../../redux/actions/GetArticles";
import { TextField, Fab, IconButton } from "@material-ui/core";
import "../styling/Styling.scss";
import Center from "react-center";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import "./ss.scss";
import { SelectUser } from "../../redux/actions/DevisClient";
import { SelectClient } from "../../redux/actions/GetClients";
import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

// const roundTo = require("round-to");

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class ModifierLigDevis extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: 0,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      snackbaropen: false,
      snackbarfail: false,
      editModalShow: false,
      qtte: 0,
      btnEnabled: false,
      gilad: true,
      snackbarmsg: ",",
      numlig: 0,
      artligs: [],
      numligs: [],
      defaultdate: date,
      shown: true,
      openEditModal: false,
      giladd: true,
      codtvaa: 0,
      pmps: "",
      avance: 0,
      rechs: [],
      rechsc: [],
      regimecli: 0,
      stkfin: 0,
      changeButton: false,

      remiseg: 0,
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",

      datebcc: "",

      username: username,
      clicked: false,
      clickeda: false,
    };
  }
  componentDidMount() {
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.sameTable();
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );

    this.setState({
      remiseg: this.props.taurem,
      raisonsocial: this.props.raisonsociale,
      codeclient: this.props.client,
      categoriefiscale: this.props.catfisc,
      regimecli: this.props.regimecli,
      datebcc: new Date(this.props.datfac)
        .toLocaleDateString()
        .substring(0, 10)
        .split("/")
        .reverse()
        .join("-"),
    });
  }

  datHandler = (event) => {
    this.setState({ datebcc: event.target.value });
  };

  raisocHandler = (event) => {
    this.setState({ raisonsocial: event.target.value });
  };

  remiseglobalChange = (event) => {
    this.setState({ remiseg: event.target.value });
  };

  articleHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/ARTICLEs?codartt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clickeda: true }));
      window.location.reload();
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechsc: data, clicked: true }));
    
  };
  
  puhtHandler = (event) => {
    this.setState({
      puht: event.target.value,
      totalht:
        this.state.qte *
        event.target.value *
        ((100 - this.state.remisea) / 100),
      puttcnet: Number(
        event.target.value *
          (1 + this.state.tva / 100) *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
    });
  };

  remiseHandler = (event) => {
    this.setState({
      remisea: event.target.value,
      totalht: Number(
        this.state.qte * this.state.puht * ((100 - event.target.value) / 100)
      ).toFixed(3),
      puttcnet: (
        this.state.puht *
        (1 + this.state.tva / 100) *
        ((100 - event.target.value) / 100)
      ).toFixed(3),
    });
  };

  puttcHandler = (event) => {
    this.setState({
      puttcnet: event.target.value,
      puht: (
        event.target.value /
        ((100 - this.state.remisea) / 100) /
        (1 + this.state.tva / 100)
      ).toFixed(3),
      totalht: (
        this.state.qte *
        ((event.target.value /
          ((100 - this.state.remisea) / 100) /
          (1 + this.state.tva / 100)) *
          ((100 - this.state.remisea) / 100))
      ).toFixed(3),
    });
  };

  modifiermodification = (event) => {
    // event.preventDefault();
    const newtab = this.state.tab.concat({
      codart: this.state.codearticle,
      desart: this.state.des,
      quantite: this.state.qte,
      unite: this.state.unite,
      priuni: this.state.puht,
      remise: this.state.remisea,
      tautva: this.state.tva,
      montht: this.state.totalht,
      PUTTCNET: this.state.puttcnet,
      pmp: this.state.pmps,
      codtva: this.state.codtvaa,
    });
    const SumQte =
      newtab && newtab.reduce((a, v) => a + parseInt(v.quantite), 0);
    const SumRemiseArticle =
      newtab &&
      newtab.reduce(
        (a, v) => a + (parseInt(v.quantite) * v.priuni * v.remise) / 100,
        0
      );
    const SumHtBrut =
      newtab && newtab.reduce((a, v) => a + parseInt(v.quantite) * v.priuni, 0);
    const SumTva =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          parseInt(v.quantite) *
            v.priuni *
            ((100 - v.remise) / 100) *
            (v.tautva / 100),
        0
      );
    const SumHtNet =
      newtab &&
      newtab.reduce((a, v) => a + v.montht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      newtab &&
      newtab.reduce((a, v) => a + v.montht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          (v.montht * ((100 - this.props.rem) / 100) +
            v.quantite *
              v.priuni *
              ((100 - v.remise) / 100) *
              (v.tautva / 100)),
        0
      );
    const Avance = (SumNetAPayer * this.state.regimecli) / 100;
    const SumNetNetAPayer =
      Number(SumNetAPayer) + Number(this.props.valtimbre) + Avance;

    this.setState({
      tab: newtab,

      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      avance: Avance,
      netnetapayer: SumNetNetAPayer,
      changeButton: false,
    });

    this.setState({
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: 0,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      codtvaa: 0,
      pmps: "",
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (
      this.state.tab.filter((el) => el.codart == this.state.codearticle)
        .length > 0
    ) {
      alert("article existant, veuillez modifier la quantité ");
    } else {
      const newtab = this.state.tab.concat({
        codart: this.state.codearticle,
        desart: this.state.des,
        quantite: this.state.qte,
        unite: this.state.unite,
        priuni: this.state.puht,
        remise: event.target.remisea.value,
        tautva: this.state.tva,
        montht: this.state.totalht,
        PUTTCNET: this.state.puttcnet,
        pmp: this.state.pmps,
        codtva: this.state.codtvaa,
      });
      const SumQte =
        newtab && newtab.reduce((a, v) => a + parseInt(v.quantite), 0);
      const SumRemiseArticle =
        newtab &&
        newtab.reduce(
          (a, v) => a + (parseInt(v.quantite) * v.priuni * v.remise) / 100,
          0
        );
      const SumHtBrut =
        newtab &&
        newtab.reduce((a, v) => a + parseInt(v.quantite) * v.priuni, 0);
      const SumTva =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            parseInt(v.quantite) *
              v.priuni *
              ((100 - v.remise) / 100) *
              (v.tautva / 100),
          0
        );
      const SumHtNet =
        newtab &&
        newtab.reduce(
          (a, v) => a + v.montht * ((100 - this.props.rem) / 100),
          0
        );
      const SumRemiseGlobale =
        newtab &&
        newtab.reduce((a, v) => a + v.montht * (this.props.rem / 100), 0);
      const SumNetAPayer =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            (v.montht * ((100 - this.props.rem) / 100) +
              v.quantite *
                v.priuni *
                ((100 - v.remise) / 100) *
                (v.tautva / 100)),
          0
        );
      const Avance = (SumNetAPayer * this.state.regimecli) / 100;
      const SumNetNetAPayer =
        Number(SumNetAPayer) + Number(this.props.valtimbre) + Avance;

      this.setState({
        tab: newtab,

        totalqte: SumQte,
        sumremisearticle: SumRemiseArticle,
        totalhtbrut: SumHtBrut,
        totaltva: SumTva,
        totalhtnet: SumHtNet,
        remiseglobal: SumRemiseGlobale,
        netapayer: SumNetAPayer,
        snackbaropen: true,
        btnEnabled: true,
        avance: Avance,
        netnetapayer: SumNetNetAPayer,
      });

      this.setState({
        codearticle: "",
        qte: "",
        totalht: 0,
        des: "",
        unite: "",
        puht: 0,
        remisea: 0,
        tva: 0,
        puttcnet: 0,
        faudec: "N",
        codtvaa: 0,
        pmps: "",
      });
    }
  };

  sameTable = () => {
    fetch(
      `https://www.24-crm.tn/webservice/api/LigBCDV?type=DV&numfac=${this.props.numfacc}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          tab: data,
        });
      });
  };

  qteHandler = (event) => {
    this.setState({
      qte: event.target.value,
      puttcnet: this.state.puht * (1 + this.state.tva / 100),
      totalht:
        event.target.value *
        this.state.puht *
        ((100 - this.state.remisea) / 100),
    });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarFailClose = (event) => {
    this.setState({ snackbarfail: false });
  };

  deleteRow = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );
    const Avance = (SumNetAPayer * this.state.regimecli) / 100;
    const SumNetNetAPayer =
      Number(SumNetAPayer) + Number(this.props.valtimbre) + Avance;

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbarfail: true,
      avance: Avance,
      netnetapayer: SumNetNetAPayer,
      btnEnabled: true,
    });
  };

  ///////////// delete for modification without snackbaropende ///////////
  deleteRowMod = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.quantite), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce(
        (a, v) => a + (parseInt(v.quantite) * v.priuni * v.remise) / 100,
        0
      );
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.quantite) * v.priuni, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.quantite) *
            v.priuni *
            ((100 - v.remise) / 100) *
            (v.tautva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.montht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.montht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.montht * ((100 - this.props.rem) / 100) +
            parseInt(v.quantite) *
              v.priuni *
              ((100 - v.remise) / 100) *
              (v.tautva / 100)),
        0
      );
    const Avance = (SumNetAPayer * this.state.regimecli) / 100;
    const SumNetNetAPayer =
      Number(SumNetAPayer) + Number(this.props.valtimbre) + Avance;

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      avance: Avance,
      netnetapayer: SumNetNetAPayer,
      btnEnabled: true,
    });
  };

  enregistrer = () => {
    /////////////// Update bcdv //////////////////////
    fetch(
      `https://www.24-crm.tn/webservice/api/BCDVCLIs?numfac=${this.props.numfacc}&typfac=DV&datfac=${this.state.datebcc}&codcli=${this.state.codeclient}&raisoc=${this.state.raisonsocial}&taurem=${this.state.remiseg}&catfisc=${this.state.categoriefiscale}&timbre=${this.props.droitdetimbre}&valtimbre=${this.props.valtimbre}&tauxNASS=${this.state.regimecli}&smNASS=${this.state.avance}&vendeur=${this.props.Auth.username}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "PUT",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    ////////// first part delete ///////////////////
    fetch(
      `https://www.24-crm.tn/webservice/api/LigBCDV/${this.props.numfacc}?typfacc=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "DELETE",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        // this.setState({ enrsnackbaropen: true, snackbarmsg: result });

        ///////////// second part add new table to database //////////////

        this.state.tab.map((k, index) => {
          // for (var i = 0; i < this.state.tab.length; i++) {
          fetch(
            `https://www.24-crm.tn/webservice/api/LigBCDV/{ID}?numfac=${
              this.props.numfacc
            }&typfac=DV&numlig=${index + 10}&codart=${k.codart}&quantite=${
              k.quantite
            }&fodart=0&desart=${k.desart}&datfac=${this.props.datfac}&priuni=${
              k.priuni
            }&remise=${k.remise}&unite${k.unite}&codtva=${k.codtva}&tautva=${
              k.tautva
            }&pmp=${k.pmp}&coddos=${this.props.ConfigDossier.coddos}&serveur=${
              this.props.ConfigDossier.serveur
            }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
              this.props.ConfigDossier.pwd_serveur
            }&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                //   this.setState({ enrsnackbaropen: true, snackbarmsg: result });

                //////////////////// third party /////////////////

                fetch(
                  `https://www.24-crm.tn/webservice/api/LigBCDV?FAC=${this.props.numfacc}&typfacc=DV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                  {
                    method: "POST",
                    header: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then((res) => res.json())
                  .then(
                    (result) => {
                     // window.location.reload();
                      this.setState({
                        snackbaropen: true,
                        snackbarmsg: "Modification avec succés",
                      });
                    },
                    (error) => {
                      this.setState({
                        snackbaropen: true,
                        snackbarmsg: "failed",
                      });
                    }
                  );
                // window.alert(result);
              },
              (error) => {
                this.setState({
                  enrsnackbaropen: true,
                  snackbarmsg: "failed",
                });
              }
            );
        });
      });
  };

  render() {
    let EditModalClose = () => this.setState({ openEditModal: false });
    const {
      quantt,
      remiss,
      artid,
      totalhtt,
      puttcnett,
      numligg,
      numfacccc,
      prixunix,
    } = this.state;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarClose}
            severity="success"
          >
            Article ajouté
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.snackbarfail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarFailClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarFailClose}
            severity="error"
          >
            Article supprimé
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.enrsnackbaropen}
          autoHideDuration={2000}
          onClose={this.enrsnackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.enrsnackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className={`${darck ? "" : classes.root}`}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Modifier Devis № {this.props.numfacc}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <Card style={{ marginBottom: "10px" }}>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                <Row style={{ marginBottom: "-20px", marginTop: "-20px" }}>
                  <Col sm={8}>
                    <FormGroup>
                      <Typography component="div">
                        <Grid
                          component="label"
                          container
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid>
                            <b style={{ color: `${darck ? "" : "#fff"}` }}>
                              Chercher client par :
                            </b>
                          </Grid>
                          &nbsp;&nbsp;
                          <Grid
                            item
                            style={{ color: `${darck ? "grey" : "#fff"}` }}
                          >
                            Raison sociale
                          </Grid>
                          <Grid item>
                            <ThemeProvider theme={theme}>
                              <Switch
                                checked={this.state.giladd}
                                onChange={this.handleChange("giladd")}
                                value="giladd"
                                color="primary"
                              />
                            </ThemeProvider>
                          </Grid>
                          <Grid item style={{ color: "#3f51b5" }}>
                            <span style={{ color: !darck ? "#fff" : null }}>
                              Code client
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                    </FormGroup>
                  </Col>

                  {this.state.giladd ? (
                    <Col sm={4} style={{  marginBottom: "15px" ,paddingLeft: "105px"}}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          // options={this.state.rechsc}
                          options={
                            this.state.clicked
                              ? this.state.rechsc
                              : this.props.clients.clients
                          }
                          getOptionLabel={(option) => option.codcli}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  remiseg: getOptionLabel.remise,
                                  codeclient: getOptionLabel.codcli,
                                  categoriefiscale: getOptionLabel.catfisc,
                                  btnEnable: true,
                                  showTimbre: getOptionLabel.timbre,
                                  showForfitaire: getOptionLabel.regimecli,
                                  showButtonModalPassager:
                                    getOptionLabel.passager,
                                  cemail: getOptionLabel.email,
                                  regimecli: getOptionLabel.regimecli,
                                })
                              : this.setState({
                                  raisonsocial: this.props.raisonsociale,
                                  remiseg: this.props.taurem,
                                  codeclient: this.props.client,
                                  categoriefiscale: this.props.catfisc,
                                  regimecli: this.props.regimecli,
                                  btnEnable: false,
                                  showTimbre: false,
                                  showForfitaire: 0,
                                  showButtonModalPassager: false,
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Code client "
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              onChange={this.clientHandlerChange}
                              name="codcli"
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <Col sm={4}style={{  marginBottom: "15px" ,paddingLeft: "105px"}}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          // options={this.props.clients.clients}
                          // options={this.state.rechsc}
                          options={
                            this.state.clicked
                              ? this.state.rechsc
                              : this.props.clients.clients
                          }
                          getOptionLabel={(option) => option.raisoc}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  remiseg: getOptionLabel.remise,
                                  codeclient: getOptionLabel.codcli,
                                  categoriefiscale: getOptionLabel.catfisc,
                                  btnEnable: true,
                                  showTimbre: getOptionLabel.timbre,
                                  showForfitaire: getOptionLabel.regimecli,
                                  showButtonModalPassager:
                                    getOptionLabel.passager,
                                  cemail: getOptionLabel.email,
                                  regimecli: getOptionLabel.regimecli,
                                })
                              : this.setState({
                                  raisonsocial: this.props.raisonsociale,
                                  remiseg: this.props.taurem,
                                  codeclient: this.props.client,
                                  categoriefiscale: this.props.catfisc,
                                  regimecli: this.props.regimecli,
                                  btnEnable: false,
                                  showTimbre: false,
                                  showForfitaire: 0,
                                  showButtonModalPassager: false,
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Raison sociale"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              onChange={this.clientHandlerChange}
                              name="raissoc"
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>

                <Row style={{ marginBottom: "-20px" }}>
                  <Col sm={3}>
                    <FormGroup>
                      <TextField
                        id="standard-basic"
                        label="Code client"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        value={this.state.codeclient}
                        fullWidth
                        name="codcli"
                        disabled
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </FormGroup>
                  </Col>




                  {/* {this.state.giladd ? (
                    <Col sm={3}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          // options={this.state.rechsc}
                          options={
                            this.state.clicked
                              ? this.state.rechsc
                              : this.props.clients.clients
                          }
                          getOptionLabel={(option) => option.codcli}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  remiseg: getOptionLabel.remise,
                                  codeclient: getOptionLabel.codcli,
                                  categoriefiscale: getOptionLabel.catfisc,
                                  btnEnable: true,
                                  showTimbre: getOptionLabel.timbre,
                                  showForfitaire: getOptionLabel.regimecli,
                                  showButtonModalPassager:
                                    getOptionLabel.passager,
                                  cemail: getOptionLabel.email,
                                  regimecli: getOptionLabel.regimecli,
                                })
                              : this.setState({
                                  raisonsocial: this.props.raisonsociale,
                                  remiseg: this.props.taurem,
                                  codeclient: this.props.client,
                                  categoriefiscale: this.props.catfisc,
                                  regimecli: this.props.regimecli,
                                  btnEnable: false,
                                  showTimbre: false,
                                  showForfitaire: 0,
                                  showButtonModalPassager: false,
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Code client "
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.codeclient}
                              fullWidth
                              onChange={this.clientHandlerChange}
                              name="codcli"
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <Col sm={4}style={{  marginBottom: "15px" ,paddingLeft: "105px"}}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          // options={this.props.clients.clients}
                          // options={this.state.rechsc}
                          options={
                            this.state.clicked
                              ? this.state.rechsc
                              : this.props.clients.clients
                          }
                          getOptionLabel={(option) => option.raisoc}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  remiseg: getOptionLabel.remise,
                                  codeclient: getOptionLabel.codcli,
                                  categoriefiscale: getOptionLabel.catfisc,
                                  btnEnable: true,
                                  showTimbre: getOptionLabel.timbre,
                                  showForfitaire: getOptionLabel.regimecli,
                                  showButtonModalPassager:
                                    getOptionLabel.passager,
                                  cemail: getOptionLabel.email,
                                  regimecli: getOptionLabel.regimecli,
                                })
                              : this.setState({
                                  raisonsocial: this.props.raisonsociale,
                                  remiseg: this.props.taurem,
                                  codeclient: this.props.client,
                                  categoriefiscale: this.props.catfisc,
                                  regimecli: this.props.regimecli,
                                  btnEnable: false,
                                  showTimbre: false,
                                  showForfitaire: 0,
                                  showButtonModalPassager: false,
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Raison sociale"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              onChange={this.clientHandlerChange}
                              name="raissoc"
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  )} */}


                  <Col sm={4}>
                    <FormGroup>
                      <TextField
                        id="standard-basic"
                        label="Raison sociale"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        value={this.state.raisonsocial}
                        fullWidth
                        name="raissoc"
                        disabled={
                          this.state.codeclient === "100" ? false : true
                        }
                        onChange={this.raisocHandler}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col sm={2}>
                    <TextField
                      id="standard-basic"
                      label="Remise Globale %"
                      // className="card add-input"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="remise"
                      onChange={this.remiseglobalChange}
                      value={this.state.remiseg}
                      InputProps={{
                        className: !darck ? this.props.classes.input : null,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                    />
                  </Col>

                  <Col sm={3}>
                    <TextField
                      id="standard-basic"
                      label="Date"
                      // className="card add-input"
                      margin="dense"
                      variant="outlined"
                      type="date"
                      fullWidth
                      name="datfac"
                      onChange={this.datHandler}
                      value={this.state.datebcc}
                      InputProps={{
                        className: !darck ? this.props.classes.input : null,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: {
                          color: !darck ? "#fff" : null,
                        },
                      }}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                <form
                  autocomplete="off"
                  onSubmit={this.submitHandler}
                  className={`${darck ? "" : classes.root}`}
                >
                  <Row>
                    <b style={{ color: `${darck ? "" : "#fff"}`, marginLeft: "10px" }}>
                      Chercher article
                    </b>

                    <b style={{ marginTop: "10px", marginLeft: "10px" }}>
                      {this.state.faudec === "A" ? (
                        <p style={{ color: "grey" }}>Fodec: ✔</p>
                      ) : null}
                    </b>
                  </Row>
                  <Row from>
                    <Col sm={3}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          //   options={this.props.articles.articles}
                          // options={this.state.rechs}
                          options={
                            this.state.clicked
                              ? this.state.rechs
                              : this.props.articles.articles
                          }
                          getOptionLabel={(option) => option.codart}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codearticle: getOptionLabel.codart,
                                  des: getOptionLabel.desart,
                                  unite: getOptionLabel.unite,
                                  puht: Number(getOptionLabel.PUDHT).toFixed(3),
                                  remisea: getOptionLabel.remise,
                                  tva: getOptionLabel.tautva,
                                  faudec: getOptionLabel.typfodec,
                                  stkfin: getOptionLabel.stkfin,
                                  codtvaa: getOptionLabel.codtva,
                                  codbar: getOptionLabel.codbar,
                                  pmp: getOptionLabel.pmp,
                                })
                              : this.setState({
                                  codearticle: "",
                                  totalht: 0,
                                  des: "",
                                  unite: "",
                                  puht: 0,
                                  remisea: 0,
                                  stkfin: 0,
                                  codtvaa: "",
                                  codbar: "",
                                  tva: 0,
                                  faudec: "N",
                                  pmp: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Code"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              onChange={this.articleHandlerChange}
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup style={{ fontSize: "9px" }}>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          //   options={this.props.articles.articles}
                          // options={this.state.rechs}
                          options={
                            this.state.clicked
                              ? this.state.rechs
                              : this.props.articles.articles
                          }
                          getOptionLabel={(option) => option.desart}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codearticle: getOptionLabel.codart,
                                  des: getOptionLabel.desart,
                                  unite: getOptionLabel.unite,
                                  puht: Number(getOptionLabel.PUDHT).toFixed(3),
                                  remisea: getOptionLabel.remise,
                                  tva: getOptionLabel.tautva,
                                  faudec: getOptionLabel.typfodec,
                                  stkfin: getOptionLabel.stkfin,
                                  codtvaa: getOptionLabel.codtva,
                                  codbar: getOptionLabel.codbar,
                                  pmp: getOptionLabel.pmp,
                                })
                              : this.setState({
                                  codearticle: "",
                                  totalht: 0,
                                  des: "",
                                  unite: "",
                                  puht: 0,
                                  remisea: 0,
                                  stkfin: 0,
                                  tva: 0,
                                  codtvaa: "",
                                  codbar: "",
                                  faudec: "N",
                                  pmp: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Désignation"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              inputStyle={{
                                color: "red",
                              }}
                              onChange={this.articleHandlerChange}
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          //   options={this.props.articles.articles}
                          // options={this.state.rechs}
                          options={
                            this.state.clicked
                              ? this.state.rechs
                              : this.props.articles.articles
                          }
                          getOptionLabel={(option) => option.codbar}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codearticle: getOptionLabel.codart,
                                  des: getOptionLabel.desart,
                                  unite: getOptionLabel.unite,
                                  puht: Number(getOptionLabel.PUDHT).toFixed(3),
                                  remisea: getOptionLabel.remise,
                                  tva: getOptionLabel.tautva,
                                  faudec: getOptionLabel.typfodec,
                                  stkfin: getOptionLabel.stkfin,
                                  codtvaa: getOptionLabel.codtva,
                                  codbar: getOptionLabel.codbar,
                                  pmp: getOptionLabel.pmp,
                                })
                              : this.setState({
                                  codearticle: "",
                                  totalht: 0,
                                  des: "",
                                  unite: "",
                                  puht: 0,
                                  remisea: 0,
                                  stkfin: 0,
                                  tva: 0,
                                  codtvaa: "",
                                  codbar: "",
                                  faudec: "N",
                                  pmp: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              label="Code à Barre"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              onChange={this.articleHandlerChange}
                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    
                  </Row>

                  <Row form>
                    <Col sm={3}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Code article"
                          value={this.state.codearticle}
                          disabled
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Désignation"
                          value={this.state.des}
                          disabled
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={1}>
                      <FormGroup>
                        {this.state.des === "" ? (
                          <TextField
                            id="standard-basic"
                            label="Qte"
                            type="number"
                            disabled
                            // InputLabelProps={{
                            //   shrink: true,
                            // }}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        ) : (
                          <TextField
                            id="standard-basic"
                            label="Qte"
                            type="number"
                            inputStyle={{ textAlign: "center" }}
                            value={this.state.qte}
                            onChange={this.qteHandler}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                                textAlign: "center",
                              },
                              shrink: true,
                            }}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={2}>
                      <FormGroup>
                        <TextField
                          value={`Stock: ${this.state.stkfin}`}
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          disabled
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col sm={2}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Unité"
                          value={this.state.unite}
                          fullWidth
                          disabled
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        {this.state.numligs.map((t) => (
                          <TextField
                            id="standard-basic"
                            label="numlig"
                            value={t.Column1 + 1}
                            name="numligg"
                            fullWidth
                            disabled
                            style={{ display: "none" }}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        ))}
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="PU HT"
                          value={this.state.puht}
                          fullWidth
                          name="puht"
                          onChange={this.puhtHandler}
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Remise %"
                          value={this.state.remisea}
                          fullWidth
                          name="remisea"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          onChange={this.remiseHandler}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="TVA"
                          value={this.state.tva}
                          fullWidth
                          disabled
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="PU TTC Net"
                          value={Number(this.state.puttcnet).toFixed(3)}
                          fullWidth
                          onChange={this.puttcHandler}
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Total HT"
                          value={Number(this.state.totalht).toFixed(3)}
                          fullWidth
                          disabled
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.changeButton ? (
                    <Center>
                      <Button
                        style={{ width: "320px" }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.modifiermodification();
                        }}
                      >
                        Enregistrer les modifications
                      </Button>
                    </Center>
                  ) : this.state.des === "" ? (
                    <Center>
                      <Button
                        disabled
                        style={{ width: "250px" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Ajouter
                      </Button>
                    </Center>
                  ) : (
                    <Center>
                      <Button
                        style={{ width: "250px" }}
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Ajouter
                      </Button>
                    </Center>
                  )}
                </form>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                {/* <div className="lig-table"> */}
                <div className="tabd28">
                  <table
                    className={`${darck ? "" : classes.root}`}
                    style={{
                      marginTop: "10px",
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    }}
                  >
                    <thead style={{ fontSize: "12px", textAlign: "center" }}>
                      <tr>
                        <th>Code</th>
                        <th style={{ width: "40%" }}>Désignation</th>
                        <th>Quantité</th>
                        <th>PUHT</th>
                        <th>Remise</th>
                        <th>TVA</th>
                        <th>TotalHT</th>
                        <th>Puttcnet</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        overflowY: "auto",
                        display: "block",
                        maxHeight: "10em",
                      }}
                    >
                      {this.state.tab.map((t, i) => (
                        <tr key={i}>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <span>{t.codart}</span>
                          </td>
                          <td
                            style={{
                              color: `${darck ? "" : "#fff"}`,
                              fontSize: "12px",
                              width: "40%",
                            }}
                          >
                            {t.desart}
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <span>{t.quantite}</span>
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <span>{Number(t.priuni).toFixed(3)}</span>
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <span>{Number(t.remise).toFixed(2)}</span>
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <span>{Number(t.tautva).toFixed(2)}</span>
                          </td>

                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <span>{Number(t.montht).toFixed(3)}</span>
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <span>{Number(t.PUTTCNET).toFixed(3)}</span>
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <Tooltip title="Modifier cet article">
                              <Fab size="small">
                                <EditIcon
                                  style={{}}
                                  onClick={() => {
                                    this.setState({
                                      codearticle: t.codart,
                                      des: t.desart,
                                      unite: t.unite,
                                      puht: Number(t.priuni).toFixed(3),
                                      remisea: t.remise,
                                      tva: t.tautva,
                                      faudec: t.typfodec,
                                      qte: t.quantite,
                                      totalht: t.montht,
                                      puttcnet: t.PUTTCNET,
                                      pmps: t.pmp,
                                      codtvaa: t.codtva,
                                      avance: t.avance,
                                      changeButton: true,
                                    });
                                    this.deleteRowMod(i);
                                  }}
                                />
                              </Fab>
                            </Tooltip>
                          </td>
                          <td style={{ color: `${darck ? "" : "#fff"}` }}>
                            <Tooltip title="Supprimer cet article">
                              <Fab size="small">
                                <DeleteIcon
                                  style={{}}
                                  onClick={() => this.deleteRow(i)}
                                />
                              </Fab>
                            </Tooltip>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            {!this.state.btnEnabled ? (
              <Button disabled variant="contained" style={{ width: "40%" }}>
                Enregistrer
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(0, 8, 126)",
                  color: "white",
                  width: "40%",
                }}
                onClick={() => {
                  this.enregistrer();
                  this.props.onHide();
                  this.props.onHide01();
                 // window.alert("Modification enregistré");
                  this.props.SelectUser(
                    this.props.ConfigDossier.coddos,
                    this.props.ConfigDossier.serveur,
                    this.props.ConfigDossier.utilisateur,
                    this.props.ConfigDossier.pwd_serveur,
                    this.props.ConfigDossier.database
                  );
                }}
              >
                Enregistrer
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectUser: (a, b, c, d, e) => dispatch(SelectUser(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    articles: state.articles,
    clients: state.clients,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierLigDevis));
