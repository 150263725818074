import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "../styling/Styles.css";
import {
  Grid,
  Paper,
  Divider,  Checkbox,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { Label, Col, Row, Table } from "reactstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { Button } from "reactstrap";
import { Alert } from "reactstrap";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ModifierArticleModal from "./ModifierArticleModal";
import { connect } from "react-redux";
import { SelectArticle } from "../../redux/actions/GetArticles";
// import PhotoComponent from './PhotoComponent';
import { SelectMemo } from "../../redux/actions/GetMemo";


const handleFileSelect = (base64) => {
  this.setPhotoBase64(base64);
};
const actions = [
  // { icon: <PrintIcon />, name: "Imprimer" },
  // { icon: <MailOutlineIcon />, name: "Mail" },
  { icon: <EditIcon />, name: "Modifier" },
  { icon: <DeleteOutlineIcon />, name: "Supprimer" },
];

class GetArticleByIdModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tt: "dqsd",
      tableunite: [
        { libele: "KG", name: "KG" },
        { libele: "PI", name: "PI" },
      ],
      tabletva: [
        { code: 0, label: 0 },
        { code: 1, label: 6 },
        { code: 2, label: 12 },
        { code: 3, label: 18 },
        { code: 4, label: 18 },
        { code: 5, label: 29 },
        { code: 6, label: 10 },
      ],
      van: [
        { code: "V", label: "Sur Vente" },
        { code: "A", label: "Sur Achat" },
        { code: "N", label: "Sans Fodec" },
      ],
      unite: "",
      codeabarre: "",
      designation: "",
      anchorEl: null,
      open: false,
      hidden: false,
      openMailModal: false,
      openModifierModal: false,
      snackbaropen: false,
      snackbarmsg: "",
      showp: false,
      GMM: "",
      ECO: "",
      magasin: [],
      memolgg: [],
    };
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  componentDidMount() {
    this.props.SelectMemo(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    fetch(
      `https://www.24-crm.tn/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });


      fetch(
        `https://www.24-crm.tn/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
      )
        .then((res) => res.json())
        .then((data) => {
          data.map((el) => this.setState({ ECO: el.ECO }));
          data.map((el) => console.log("dossier", el.ECO));
        });


  }

  componentDidUpdate(prevprops, prevstate) {
    if (prevprops.codearticle != this.props.codearticle) {
      fetch(
        `https://www.24-crm.tn/webservice/api/Magasin?codaart=${this.props.codearticle}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((res) => res.json())
        .then((data) => this.setState({ magasin: data }));
   
        fetch(
          `https://www.24-crm.tn/webservice/api/Memos?codeart=${this.props.codearticle}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
        )
        
          .then((res) => res.json())
          .then((data) => this.setState({ memolgg: data }));
   
   
      }
   
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  codeabarrechange = (event) => {
    this.setState({
      codeabarre: event.target.value,
    });
  };

  showprix = (event) => {
    this.setState({
      showp: !this.state.showp,
    });
  };

  designationchange = (event) => {
    this.setState({
      designation: event.target.value,
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    window.alert(this.state.unite);
  };

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose1 = () => {
    this.setState({ anchorEl: null });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    this.setState({ openMailModal: true });
  };

  openModifier = () => {
    this.setState({ openModifierModal: true });
  };

  affiche = () => {
    window.alert(this.state.totalqte);
  };



  
  
  deletearticle(articleid) {
    if (window.confirm("êtes-vous sûr de vouloir supprimer cet article?")) {
      fetch(
        `https://www.24-crm.tn/webservice/api/ARTICLEs?codartt=${articleid}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.props.onHide();
            this.props.SelectArticle(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );

            this.setState({ snackbaropen: true, snackbarmsg: result });
            window.location.reload();
          },
          (error) => {
            this.setState({ snackbaropen: true, snackbarmsg: "failed" });
          }
        );
    }
  }

  render() {
    // console.log("photo",this.props.photo)
    
   //  console.log(" memo ecomm",this.props.Ecomm )

    // console.log("memo",this.state.memolgg)
 
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    const { darck } = this.props;

    let emailModalClose = () => this.setState({ openMailModal: false });
    let modifierModalClose = () => this.setState({ openModifierModal: false });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Détails Article 
                {/* {this.props.designationarticle} */}
                </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                                style={{
                                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                  borderTop: `none`,
                                  borderRight: `${darck ? "" : "2px solid #fff"}`,
                                  borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                  borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                }}
          >
            <div>
              <Grid item xs={12} style={{ marginTop: "5px", width: "100%" }}>
                <Grid container justify="center">
                  <Grid item style={{ width: "100%" }}>
                  
                    <Paper
                                      style={{
                                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                        border: `${darck ? "" : "2px solid #fff"}`,
                                      }}
                    >
                    
                  {this.state.ECO?(
                      <Row>
                      <Col
                          sm={2}
                          style={{
                            // width: "50%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                            <img
                            src={`data:image/*;base64,${this.props.photo}`}
                            alt="Pas-Photo"
                            style={{ width: '70px', height: '70px',marginBottom:"5px",marginTop:"5px" }}
                            
                          />
                         
                        </Col>
                        <Col
                          sm={5}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          
                          {this.props.designationarticle === "" ? (
                            <h3 style={{ color: `${darck ? "" : "#fff"}`}}>--</h3>
                          ) : (
                           <p style={{ color: `${darck ? "gray" : "#fff"}` , fontSize: "larger" }}>
                            {this.props.codearticle}
                          </p>  
                          )} 
                         <h5 style={{ color: `${darck ? "" : "#fff"}`}}> {this.props.designationarticle} </h5>
                        </Col>

                        <Col
                          sm={1}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {this.state.GMM ? (
                            <span
                              style={{
                                color: `${darck ? "#DC004E" : "#fff"}`,
                                fontSize: "larger",
                                fontWeight: "bold",
                              }}
                            >
                              Stock Global
                            </span>
                          ) : (
                            <span
                              style={{
                                color: `${darck ? "#DC004E" : "#fff"}`,
                                fontSize: "larger",
                                fontWeight: "bold",
                              }}
                            >
                              Stock
                            </span>
                          )}
                          <span
                            style={{ fontWeight: "bold",
                                     fontSize: "20px" ,
                                     color: `${darck ? "" : "#fff"}` }}
                          >
                            {this.props.stockarticle}
                          </span>
                        </Col>
                       
                         <Col
                          sm={2}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >  
                 
                     
                            <Label style={{ fontWeight: "bold" ,color: `${darck ? "" : "#fff"}` }}>
                              E_commerce
                            </Label>
                          
                            {this.props.Ecomm? (
                             <span style={{color: `${darck ? "green" : "#fff"}`,fontWeight: "bold",fontSize: "20px"}}> ✓</span>
                            ) : (
                              <span style={{color: `${darck ? "#DC004E" : "#fff"}`,fontWeight: "bold",fontSize: "15px"}}> X </span>
                              // &nbsp;&nbsp;
                            )}
                        
                       
                      
                        </Col>
                        <Col
                          sm={1}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >  
                 
                      
                            <Label style={{ fontWeight: "bold" ,color: `${darck ? "" : "#fff"}` }}>
                             Catégorie
                              
                            </Label>
                       
                          {this.props.CatArt ===null ?(  <span style={{color: `${darck ? "" : "#fff"}`}}>--</span>):( 
                             <span style={{color: `${darck ? "#020F64" : "#fff"}`}}> {this.props.CatArt}</span>)}
                        
                     
                       
                      
                        </Col> 
                      
                      </Row>
                      ):(
                        <Row>
                      <Col
                          sm={3}
                          style={{
                            // width: "50%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                            <img
                            src={`data:image/*;base64,${this.props.photo}`}
                            alt="Pas-Photo"
                            style={{ width: '70px', height: '70px',marginBottom:"5px",marginTop:"5px" }}
                            
                          />
                         
                        </Col>
                        <Col
                          sm={5}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          
                          {this.props.designationarticle === "" ? (
                            <h3 style={{ color: `${darck ? "" : "#fff"}`}}>--</h3>
                          ) : (
                           <p style={{ color: `${darck ? "gray" : "#fff"}` , fontSize: "larger" }}>
                            {this.props.codearticle}
                          </p>  
                          )} 
                         <h5 style={{ color: `${darck ? "" : "#fff"}`}}> {this.props.designationarticle} </h5>
                        </Col>

                        <Col
                          sm={2}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          {this.state.GMM ? (
                            <span
                              style={{
                                color: `${darck ? "#DC004E" : "#fff"}`,
                                fontSize: "larger",
                                fontWeight: "bold",
                              }}
                            >
                              Stock Global
                            </span>
                          ) : (
                            <span
                              style={{
                                color: `${darck ? "#DC004E" : "#fff"}`,
                                fontSize: "larger",
                                fontWeight: "bold",
                              }}
                            >
                              Stock
                            </span>
                          )}
                          <span
                            style={{ fontWeight: "bold",
                                     fontSize: "20px" ,
                                     color: `${darck ? "" : "#fff"}` }}
                          >
                            {this.props.stockarticle}
                          </span>
                        </Col>
                     
                      </Row>
                      )}
                    </Paper>

<br/>
                    <Paper
                                      style={{
                                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                        border: `${darck ? "" : "2px solid #fff"}`,
                                      }}
                    
                    
                    >
                    
                    
                    
                    
                    {this.state.ECO?(<> <Row>
                         <Col
                          sm={12}
                          style={{
                          //  width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            marginLeft:"5px",
                       
                          }}
                        >
                         <span
                            style={{ 
                              // fontWeight: "bold",
                              //        fontSize: "20px" ,
                                     color: `${darck ? "" : "#fff"}` }}
                          >
                            <h6   
                              style={{
                                color: `${darck ? "#DC004E" : "#fff"}`,
                                fontSize: "larger",
                                fontWeight: "bold",
                                fontfamily: "serif"
                              }}>Description:</h6>
                             
                         
                          </span>
                          <br/>
                        
                      
                        </Col>
                        <Col
                          sm={11}
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            marginLeft:"55px",
                             marginTop:"-25px"
                          }}
                        >  {this.state.memolgg.map((el) => (
                          
                          <span style={{color: `${darck ? "" : "#fff"}`}}>{el.memolig}</span>
                      
                             ))}</Col>
                              
                   
                      </Row> 
                      </>
                      ):null}
                    
                     
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

              <Row sm={12} style={{ marginTop: "20px" }}>
                <Col sm={6}>
                  <Grid container justify="center">
                    <Grid item style={{ width: "100%" }}>
                      <Paper
                        style={{
                          width: "100%",
                          backgroundColor: `${darck ? "#fff" : "#172431"}`,
                          border: `${darck ? "" : "2px solid #fff"}`,
                        }}
                      >
                    
                        <Row sm={12} style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" ,color: `${darck ? "" : "#fff"}` }}>
                              Code à barre
                            </Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.codeabarrearticle === "" ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{this.props.codeabarrearticle}</p>
                            )}
                          </Col>
                        </Row>

                        <Row sm={12} style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" , color: `${darck ? "" : "#fff"}` }}>
                              PU Vente HT
                            </Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.pudht === "" ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{Number(this.props.pudht).toFixed(3)}</p>
                            )}
                          </Col>
                        </Row>

                        <Row sm={12} style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" , color: `${darck ? "" : "#fff"}`}}>
                              Taux TVA
                            </Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.tvaarticle === "" ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{Number(this.props.tvaarticle).toFixed(2)}</p>
                            )}
                          </Col>
                        </Row>

                        <Row sm={12} style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" , color: `${darck ? "" : "#fff"}`}}>
                              PU Vente TTC
                            </Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.puventettc === "" ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{Number(this.props.puventettc).toFixed(3)}</p>
                            )}
                          </Col>
                        </Row>
                      </Paper>
                    </Grid>
                  </Grid>
                </Col>
                <Col sm={6}>
                  <Grid container justify="center">
                    <Grid item style={{ width: "100%" }}>
                      <Paper
                        style={{
                          width: "100%",
                          backgroundColor: `${darck ? "#fff" : "#172431"}`,
                          border: `${darck ? "" : "2px solid #fff"}`,
                        }}
                      >
                      
                        <Row sm={12}style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" , color: `${darck ? "" : "#fff"}` }}>
                              Famille
                            </Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.famillearticle === "" ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{this.props.famillearticle}</p>
                            )}
                          </Col>
                        </Row>

                        <Row sm={12}style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" , color: `${darck ? "" : "#fff"}`}}>
                              Sous Famille
                            </Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.sousfamillearticle === "" ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{this.props.sousfamillearticle}</p>
                            )}
                          </Col>
                        </Row>

                        <Row sm={12}style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" , color: `${darck ? "" : "#fff"}`}}>Unité</Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.unitearticle === "" ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{this.props.unitearticle}</p>
                            )}
                          </Col>
                        </Row>

                        <Row sm={12}style={{ marginLeft: "10px" }}>
                          <Col sm={7}>
                            <Label style={{ fontWeight: "bold" , color: `${darck ? "" : "#fff"}`}}>
                              Reférence Fournisseur
                            </Label>
                          </Col>
                          <Col sm={5}>
                            {this.props.reffrsarticle === "" ||
                            this.props.reffrsarticle === " " ? (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>--</p>
                            ) : (
                              <p style={{color: `${darck ? "" : "#fff"}`}}>{this.props.reffrsarticle}</p>
                            )}
                          </Col>
                        </Row>
                      </Paper>
                    </Grid>
                  </Grid>
                </Col>
              </Row>

              <Row style={{ marginTop: "15px" }}>
                <Col sm={6}>
                  <div>
                    <Button
                      style={{
                        width: "50%",
                        height: "50px",
                        marginBottom: "5px",
                      }}
                      aria-describedby={id}
                      variant="contained"
                      color="primary"
                      // onClick={this.handleClick}
                      onClick={this.showprix}
                    >
                      <MonetizationOnIcon /> P.U.R
                    </Button>
                  </div>
                </Col>

                <Col sm={3}>
                  {this.props.typefodecarticle === "V" ? (
                    <Alert
                      color={"success"}
                      style={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                    >
                      Fodec sur Vente
                    </Alert>
                  ) : this.props.typefodecarticle === "A" ? (
                    <Alert
                      color={"success"}
                      style={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                    >
                      Fodec sur Achat
                    </Alert>
                  ) : (
                    <Alert
                      color={"danger"}
                      style={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                    >
                      Sans Fodec
                    </Alert>
                  )}
                </Col>

                <Col sm={3}>
                  {this.props.typedcarticle === "V" ? (
                    <Alert
                      color={"success"}
                      style={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                    >
                      D.C sur Vente
                    </Alert>
                  ) : this.props.typedcarticle === "A" ? (
                    <Alert
                      color={"success"}
                      style={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                    >
                      D.C sur Achat
                    </Alert>
                  ) : (
                    <Alert
                      color={"danger"}
                      style={{
                        width: "100%",
                        height: "50px",
                        fontSize: "15px",
                      }}
                    >
                      Sans D.C
                    </Alert>
                  )}
                </Col>
              </Row>
              <Divider />
              <br />
              <Row style={{ marginBottom: "15px" }}>
                <Col sm={12}>
                  {this.state.showp ? (
                    <Card  style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}>
                      {/* <CardHeader
                        avatar={<MonetizationOnIcon />}
                        title="Liste des prix"
                      /> */}
                      <CardContent>
                        <Row>
                          <Col
                            sm={3}
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span style={{ color: "gray", fontSize: "larger" , color: `${darck ? "" : "#fff"}`}}>
                              P.U.R Brut HT
                            </span>
                            <span
                              style={{ fontWeight: "bold", fontSize: "20px" , color: `${darck ? "" : "#fff"}`}}
                            >
                              {Number(this.props.purevientbrut).toFixed(3)}
                            </span>
                          </Col>

                          <Col
                            sm={3}
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span style={{ color: "gray", fontSize: "larger" , color: `${darck ? "" : "#fff"}`}}>
                              Remise Frs (%)
                            </span>
                            <span
                              style={{ fontWeight: "bold", fontSize: "20px" , color: `${darck ? "" : "#fff"}`}}
                            >
                              {Number(this.props.remisefrs).toFixed(2)}
                            </span>
                          </Col>

                          <Col
                            sm={3}
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span style={{  fontSize: "larger" , color: `${darck ? "gray" : "#fff"}` }}>
                              P.U.R Net HT
                            </span>
                            <span
                              style={{ fontWeight: "bold", fontSize: "20px" , color: `${darck ? "" : "#fff"}`}}
                            >
                              {Number(this.props.purevientnetht).toFixed(3)}
                            </span>
                          </Col>

                          <Col
                            sm={3}
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span style={{  color: `${darck ? "" : "#fff"}`, fontSize: "larger" }}>
                              P.U.R Net TTC
                            </span>
                            <span
                              style={{ fontWeight: "bold", fontSize: "20px" , color: `${darck ? "" : "#fff"}` }}
                            >
                              {Number(this.props.purevientnetttc).toFixed(3)}
                            </span>
                          </Col>
                        </Row>
                      </CardContent>
                    </Card>
                  ) : null}
                </Col>

              
              </Row>

              {this.state.GMM ? (
                <Row >
                  <Card style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}>
                    <div className="tabblstk">
                      <table>
                        <thead>
                          <tr>
                            <th style={{color: `${darck ? "" : "#fff"}`}}>Magasin</th>
                            <th style={{color: `${darck ? "" : "#fff"}`}}>Stock</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.magasin.map((el, i) => (
                            <tr key={i}>
                              <td>
                                <span style={{color: `${darck ? "" : "#fff"}`}}>{el.CodDep}</span>
                              </td>
                              <td>
                                <span style={{color: `${darck ? "" : "#fff"}`}}>{el.StkFin}</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card>
                </Row>
              ) : null}
            </div>
          </Modal.Body>
          <Row>
          <Col sm={12}>
                  <div>
                    {this.props.Auth.fct == "100" ? (
                      <SpeedDial
                        style={{
                          position: "absolute",
                          bottom: "5px",
                          right: "10px",
                       
                        }}
                        ariaLabel="SpeedDial openIcon example"
                        hidden={this.state.hidden}
                        icon={<EditIcon openIcon={<EditIcon />} />}
                        onClose={this.handleClose}
                        onOpen={this.handleOpen}
                        open={this.state.open}
                        FabProps={{ size: "small" }}
                      >
                        {actions.map((action) => (
                          <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={() => {
                              this.handleClose();
                              // action.name == "Mail" && this.openMail();
                              action.name == "Modifier" && this.openModifier();
                              action.name == "Supprimer" &&
                                this.deletearticle(this.props.codearticle) &&
                                this.props.onHide();
                            }}
                          />
                        ))}
                      </SpeedDial>
                    ) : null}
                    <ModifierArticleModal
                      show={this.state.openModifierModal}
                      onHide={modifierModalClose}
                      onHide01={this.props.onHide}
                      designationarticle={this.props.designationarticle}
                      codearticle={this.props.codearticle}
                      codeabarrearticle={this.props.codeabarrearticle}
                      famillearticle={this.props.famillearticle}
                      reffrsarticle={this.props.reffrsarticle}
                      remisearticle={this.props.remisearticle}
                      stockarticle={this.props.stockarticle}
                      pvttcarticle={this.props.pvttcarticle}
                      typefodecarticle={this.props.typefodecarticle}
                      typedcarticle={this.props.typedcarticle}
                      pudht={this.props.pudht}
                      unitearticles={this.props.unitearticle}
                      tvaarticle={this.props.tvaarticle}
                      sousfamillearticle={this.props.sousfamillearticle}
                      tauxdcarticle={this.props.tauxdcarticle}
                      purevientbrut={this.props.purevientbrut}
                      remisefrs={this.props.remisefrs}
                      purevientnetht={this.props.purevientnetht}
                      purevientnetttc={this.props.purevientnetttc}
                      margeprbrut={this.props.margeprbrut}
                      margeprrnet={this.props.margeprrnet}
                      puventeht={this.props.puventeht}
                      puventettc={this.props.puventettc}
                      codtva={this.props.codtva}
                      codfrs={this.props.codfrs}
                      photo={this.props.photo}
                      Ecomm={this.props.Ecomm}
                      CatArt={this.props.CatArt}
                      memolgg={this.state.memolgg}
                      memolig={this.props.memolig}
                    />
                    {/* <EmailModal show={this.state.openMailModal} onHide={emailModalClose} /> */}
                  </div>
                </Col>
                </Row>
        </Modal>

        {/* ////////////////////////////// print article ////////////////////////////// */}

        <div style={{ display: "none" }}>
          <div
            ref={(el) => (this.componentRef = el)}
            style={{ margin: "15px", borderStyle: "solid", height: "98%" }}
          >
            <Paper
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                marginTop: "120px",
                backgroundColor: `${darck ? "#fff" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}

            >
              <h2>
                <u>{this.props.designationarticle}</u>
              </h2>
              <p style={{ color: "gray", fontSize: "35px" }}>
                {this.props.codearticle}
              </p>
            </Paper>

            <div
              style={{
                marginLeft: "100px",
                marginRight: "100px",
                marginTop: "60px",
                fontSize: "25px",
              }}
            >
              <Paper>
                <Row>
                  <Col>
                    <Paper style={{
                       width: "100%" ,
                       backgroundColor: `${darck ? "#fff" : "#172431"}`,
                       border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                      <Row>
                        <Col>
                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>Code à barre</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.codeabarrearticle}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>PU HT</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.pudht}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>TVA</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.tvaarticle}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>Remise (%)</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.remisearticle}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>Fodec</Col>
                            <Col>
                              {this.props.typefodecarticle === "V" ? (
                                <Label style={{color: `${darck ? "" : "#fff"}`}}>Vente</Label>
                              ) : this.props.typefodecarticle === "A" ? (
                                <Label style={{color: `${darck ? "" : "#fff"}`}}>Achat</Label>
                              ) : (
                                <Label style={{color: `${darck ? "" : "#fff"}`}}>∅</Label>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Paper>
                  </Col>

                  <Col>
                    <Paper style={{ 
                      width: "100%",
                      marginLeft: "100px",
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`
                       }}>
                      <Row>
                        <Col>
                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>Famille</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.famillearticle}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>Sous Famille</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.sousfamillearticle}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>Unité</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.unitearticle}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>Reférence Frs</Col>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>{this.props.reffrsarticle}</Col>
                          </Row>

                          <Row style={{ marginBottom: "20px" }}>
                            <Col style={{color: `${darck ? "" : "#fff"}`}}>D.C</Col>
                            <Col>
                              {this.props.typedcarticle === "V" ? (
                                <Label style={{color: `${darck ? "" : "#fff"}`}}>Vente</Label>
                              ) : this.props.typedcarticle === "A" ? (
                                <Label style={{color: `${darck ? "" : "#fff"}`}}>Achat</Label>
                              ) : (
                                <Label style={{color: `${darck ? "" : "#fff"}`}}>∅</Label>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Paper>
                  </Col>
                </Row>
              </Paper>
            </div>

            <div
              style={{
                marginLeft: "100px",
                marginRight: "100px",
                marginTop: "60px",
                fontSize: "25px",
              }}
            >
              <Table
                style={{
                  textAlign: "center",
                  borderStyle: "solid",
                }}
              >
                <thead style={{ fontWeight: "bold" }}>
                  <tr>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>PU Brut HT</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>Remise Frs (%)</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>PU Net HT</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>PU Net TTC</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>Marge/ P.R Brut (%)</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>Marge/ P.R Net (%)</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>PU Vente HT</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>PU Vente TTC</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.purevientbrut}</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.remisefrs}</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.purevientnetht}</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.purevientnetttc}</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.margeprbrut}</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.margeprrnet}</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.puventeht}</td>
                    <td style={{color: `${darck ? "" : "#fff"}`}}>{this.props.puventettc}</td>
                  </tr>
                </tbody>
              </Table>
            </div>

            <div>
              <Row style={{ margin: "100px", fontSize: "38px" }}>
                <Col
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span style={{color: `${darck ? "gray" : "#fff"}`, fontSize: "larger" }}>
                    STOCK
                  </span>
                  <span style={{ fontWeight: "bold", color: `${darck ? "" : "#fff"}` }}>
                    {this.props.stockarticle}
                  </span>
                </Col>

                <Col
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ color: `${darck ? "gray" : "#fff"}`, fontSize: "larger" }}>
                    P.V TTC
                  </span>
                  <span style={{ fontWeight: "bold" ,color: `${darck ? "" : "#fff"}`}}>
                    {this.props.pvttcarticle}
                  </span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectMemo: (a, b, c, d, e) => dispatch(SelectMemo(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    nomes: state.nomes,
    sousfamilles: state.sousfamilles,
    ConfigDossier: state.ConfigDossier,
    Auth: state.Auth,
    darck: state.darck.darck,
    memos: state.memos,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetArticleByIdModal);
