import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { LogoutUser } from "../../redux/actions/AuthAction";
const imgMyimageexample = require("./k.jpg");

const divStyle = {
  width: "100%",
  height: "100vh",
  // minHeight: '640px',
  backgroundImage: `url(${imgMyimageexample})`,
  backgroundSize: "cover",
};

class Logout extends Component {
  constructor(props) {
    super(props);
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("fct");

    localStorage.removeItem("persist:root");

    this.state = {};
  }
  componentDidMount() {
    this.props.LogoutUser();
  }

  render() {
    return (
      <div style={divStyle}>
        <Redirect to="/" />
      </div>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    LogoutUser: () => dispatch(LogoutUser()),
  };
}
export default connect(null, mapDispatchToProps)(Logout);
