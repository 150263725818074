import React, { Component } from "react";
import {
  Modal,
  Card,
  Row,
  FormGroup,
  Col,
  Alert,
  Accordion,
} from "react-bootstrap";
import "../styling/Styles.css";
import "./bl.scss";

import { TextField, Button, Snackbar, IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
import { SelectArticle } from "../../redux/actions/GetArticles";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import LigREArticle from "./LigREArticle";
import { SelectRECode } from "../../redux/actions/GetRECode";
import { SelectRE } from "../../redux/actions/GetRE";
import translate from "../../i18n/translate";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

import "./Responsivebrf.css";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const roundTo = require("round-to");

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddREModal extends Component {
  constructor(props) {
    super(props);
    // const username = localStorage.getItem("username");

    this.state = {
      name: "",
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      NETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,
      rechs: [],

      nextsubmit: false,

      snackbaropen: false,
      snackbarmsg: ",",

      valtimbre: 0,
      netnetapayer: 0,
      NETNETAPAYER: 0,
      clicked: false,
      numfac: "",
      typfac: "",
      typfacTab: [],
      numfacTab: [],
      facOrBl: "",
      numnum: "",
      magasin: [],
      MG: "",
      GMM: "",
      DEP: "",
    };
  }

  componentDidMount() {
    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectRECode(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.setState({ tab: [] });
    fetch(
      `https://www.24-crm.tn/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });
    this.setState({ tab: [] });
  }
  componentDidUpdate(prevprops, prevstate) {
    if (prevstate.facOrBl != this.state.facOrBl) {
      // select  de la table V_CalcLIGBLBR
      // this.state.typfac == "BL"
      //   ? fetch(
      //       `http://www.24-crm.tn/webservice/api/LigBLBRs?type=${this.state.typfac}&numfac=${this.state.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      //     )
      //       .then((response) => response.json())
      //       .then((data) =>
      //         this.setState({
      //           tab: data.map((el) => {
      //             return {
      //               codearticle: el.codart,
      //               qte: el.quantite,
      //               des: el.desart,
      //               puht: el.priuni,
      //               remisea: el.remise,
      //               tva: el.tautva,
      //               puttcnet: el.PUTTCNET,
      //               totalht: el.montht,
      //               remise: el.remise,
      //               unite: el.unite,
      //               faudec: el.typfodec,
      //             };
      //           }),
      //         })
      //       )
      //       .then((res) => {
      //         console.log(this.state.tab);

      //         const SumQte =
      //           this.state.tab &&
      //           this.state.tab.reduce((a, v) => a + parseInt(v.qte), 0);
      //         const SumRemiseArticle =
      //           this.state.tab &&
      //           this.state.tab.reduce(
      //             (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
      //             0
      //           );
      //         const SumHtBrut =
      //           this.state.tab &&
      //           this.state.tab.reduce(
      //             (a, v) => a + parseInt(v.qte) * v.puht,
      //             0
      //           );
      //         const SumTva =
      //           this.state.tab &&
      //           this.state.tab.reduce(
      //             (a, v) =>
      //               a +
      //               parseInt(v.qte) *
      //                 parseFloat(v.puht) *
      //                 ((100 - parseFloat(v.remisea)) / 100) *
      //                 ((100 - parseFloat(this.state.remiseg)) / 100) *
      //                 (parseFloat(v.tva) / 100),
      //             0
      //           );

      //         const SumHtNet =
      //           this.state.tab &&
      //           this.state.tab.reduce(
      //             (a, v) =>
      //               a +
      //               parseFloat(v.totalht) *
      //                 ((100 - parseFloat(this.state.remiseg)) / 100),
      //             0
      //           );
      //         const SumRemiseGlobale =
      //           this.state.tab &&
      //           this.state.tab.reduce(
      //             (a, v) => a + v.totalht * (this.state.remiseg / 100),
      //             0
      //           );
      //         const SumNetAPayer =
      //           this.state.tab &&
      //           this.state.tab.reduce(
      //             (a, v) =>
      //               a +
      //               (v.totalht * ((100 - this.state.remiseg) / 100) +
      //                 v.qte *
      //                   v.puht *
      //                   ((100 - v.remisea) / 100) *
      //                   (v.tva / 100)),
      //             0
      //           );
      //         const SumNetNetAPayer =
      //           SumNetAPayer + parseFloat(this.state.valtimbre);

      //         this.setState({
      //           totalqte: SumQte,
      //           sumremisearticle: SumRemiseArticle,
      //           totalhtbrut: SumHtBrut,
      //           totaltva: SumTva,
      //           totalhtnet: SumHtNet,
      //           remiseglobal: SumRemiseGlobale,
      //           netapayer: SumNetAPayer,

      //           btnEnabled: true,
      //           netnetapayer: SumNetNetAPayer,
      //         });
      //       })
      //  :
      fetch(
        `https://www.24-crm.tn/webservice/api/LIGBEREs?type=BE&numfac=${this.state.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) =>
          this.setState({
            tab: data.map((el) => {
              return {
                codearticle: el.codart,
                qte: el.quantite,
                des: el.desart,
                puht: el.priuni,
                remisea: el.remise,
                tva: el.tautva,
                puttcnet: el.ttclig,
                totalht: el.montht,
                unite: el.unite,
                faudec: el.typfodec,
                codbar: el.codbar,
              };
            }),
          })
        )
        .then((res) => {
          const SumQte =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte), 0);
          const SumRemiseArticle =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
              0
            );
          const SumHtBrut =
            this.state.tab &&
            this.state.tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
          const SumTva =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseInt(v.qte) *
                  parseFloat(v.puht) *
                  ((100 - parseFloat(v.remisea)) / 100) *
                  ((100 - parseFloat(this.state.remiseg)) / 100) *
                  (parseFloat(v.tva) / 100),
              0
            );
          const SumHtNet =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                parseFloat(v.totalht) *
                  ((100 - parseFloat(this.state.remiseg)) / 100),
              0
            );
          const SumRemiseGlobale =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) => a + v.totalht * (this.state.remiseg / 100),
              0
            );
          const SumNetAPayer =
            this.state.tab &&
            this.state.tab.reduce(
              (a, v) =>
                a +
                (v.totalht * ((100 - this.state.remiseg) / 100) +
                  v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
              0
            );
          const SumNetNetAPayer =
            SumNetAPayer + parseFloat(this.state.valtimbre);

          this.setState({
            totalqte: SumQte,
            sumremisearticle: SumRemiseArticle,
            totalhtbrut: SumHtBrut,
            totaltva: SumTva,
            totalhtnet: SumHtNet,
            remiseglobal: SumRemiseGlobale,
            netapayer: SumNetAPayer,

            btnEnabled: true,
            netnetapayer: SumNetNetAPayer,
          });
        });
    }
  }

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    netnetapayer
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      netnetapayer: netnetapayer,
    });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseglobalChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      AVANCE: this.state.avance * ((100 - event.target.value) / 100),
      NETNETAPAYER: ((100 - event.target.value) / 100) * this.state.netapayer,
    });
  };

  raisocHandler = (event) => {
    // //Exécuter la procédure FacCliandBLByCodCli
    // fetch(
    //   `http://www.24-crm.tn/webservice/api/FACCLIs?codcliss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //   {
    //     method: "POST",
    //     header: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((result) => {
    //     console.log(result);
    //     this.setState({
    //       numfacTab: result,
    //     });
    //   });

    this.setState({ raisonsocial: event.target.value });
  };

  enregistrer = (event) => {
    event.preventDefault();
    if (window.confirm("voulez vous enregistrer ce Bon de retour Fournisseur ?") == true) {
      let id = event.target.codeblbr.value;

      //ajouter un bon de retour

      fetch(
       // `https://www.24-crm.tn/webservice/api/Switch?code=RE2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,

        `https://www.24-crm.tn/webservice/api/BEREs?numfac&typfac=RE&datfac=${
          this.state.defaultdate
        }&codfrs=${event.target.codcli.value}&raisoc=${
          event.target.raissoc.value
        }&pj=${this.state.numfac}&taurem=${
          this.state.remiseg}&catfisc=0&typach=${this.state.typfac
          }&Vendeur=${this.props.Auth.username}&CodDep=${this.state.MG}&valtimbre=${this.state.valtimbre
          }&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur
          }&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur
          }&database=${this.props.ConfigDossier.database}`,

        {
          method: "POST",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((res) => {
          this.setState({ numnum: res });
          this.state.tab.map((k, index) => {
            //Ajouter les articles à la table ligblbr
            fetch(
              `https://www.24-crm.tn/webservice/api/LigBEREs/{ID}?numfac=${res}&typfac=RE&numlig=${index}&codart=${k.codearticle}&quantite=${k.qte}&fodart=0&desart=${k.des}&datfac=${this.state.defaultdate}&priuni=${k.puht}&remise=${k.remisea}&montht=${k.totalht}&unite=${k.unite}&pmp=${k.pmp}&CB=${k.codbar}&coddepp=${this.state.MG}&codtva=3&tautva=${k.tva}&formqt=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  console.log(result);
                },
                (error) => {
                  this.setState({
                    snackbaropen: true,
                    snackbarmsg: "Erreur Ligne",
                  });
                }
              );
          });
        })
        .then(
          (result) => {
            this.props.onHide();

            this.props.SelectRE(
              this.props.Auth.username,
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
            //Exécuter la procédure SommeTTCBL
            fetch(
              `https://www.24-crm.tn/webservice/api/LigBEREs?FACc=${this.state.numnum}&typfacc=RE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            this.setState({
              snackbaropen: true,
              snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
            });
            this.props.SelectRECode(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );

             window.location.reload();
          },
          (error) => {
            this.setState({ snackbaropen: true, snackbarmsg: "erruer N2 " });
          }
        )

        .then((res) => {
          //////////// switch update ////////////////

          fetch(
            `https://www.24-crm.tn/webservice/api/Switch?code=RE2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,

            {
              method: "PUT",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          ).then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
             // window.location.reload();
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "failed" });
            }
          )
          .then((res) => {
           window.location.reload();
          });
          //   window.location.reload();
        });

      //Calcul Solde fournisseur

      fetch(
        `https://www.24-crm.tn/webservice/api/FOURNISSEURs?Codfrssss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "POST",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      //   Exécuter la procédure UpdateStkBLBR
      // setTimeout(() => {
      //   fetch(
      //     `http://www.24-crm.tn/webservice/api/LigBLBRs?FAC=${id}&Typfacc=re&CODDEPP=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      //     {
      //       method: "POST",
      //       header: {
      //         Accept: "application/json",
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   )
      //     .then((res) => res.json())
      //     .then(
      //       (result) => {
      //         console.log("mise a jour stock avec succée");
      //         console.log(result);
      //       },
      //       (error) => {
      //         this.setState({ snackbaropen: true, snackbarmsg: "failed" });
      //       }
      //     );

      //   window.location.reload();
      // }, 2000);
    }
  };

  clientHandlerChange = (event) => {
    this.setState({ codeclient: event.target.value });
    //get client by codcli
    fetch(
      `https://www.24-crm.tn/webservice/api/fournisseurs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };
  ligModalClose = () => this.setState({ ligModalShow: false });
  addModalClose1 = () => this.setState({ addModalShow1: false });

  render() {
    // const { dvnumfac, dvraisoc, rem, clientmail } = this.state;

    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b> {translate("Ajouter Bon de Retour Fournisseur")} </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.enregistrer}
              className={`${darck ? "" : classes.root}`}
            >
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row form>
                    <Col sm={4}>
                      <FormGroup>
                        <TextField
                          // className="card add-input"
                          id="standard-basic"
                          label="N° BR"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined" //variant="outlined"
                          value={parseInt(
                            this.props.codbrs.codbrs[0] &&
                              this.props.codbrs.codbrs[0].valeur
                          )}
                          fullWidth
                          name="codeblbr"
                          disabled
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col sm={5}></Col> */}
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        type="date"
                        fullWidth
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    
                    {this.state.GMM ? (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            value={{
                              code: this.state.MG,
                              lib: this.state.DEP,
                            }}
                            className="ajouter-client-input"
                            options={this.state.magasin}
                            getOptionLabel={(option) =>
                              ` ${option.code}  ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MG: getOptionLabel.code,
                                    DEP: getOptionLabel.lib,
                                  })
                                : this.setState({
                                    MG: "",
                                    DEP: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Magasin"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null, }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,},
                                }}
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        magasin: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>

                  <Row form>
                  <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: "grey" }}>
                              {translate("Raison Social")}
                            </Grid>
                            <Grid item>
                              
                                <Switch
                                  checked={this.state.gilad}
                                  onChange={this.handleChange("gilad")}
                                  value="gilad"
                                  color="primary"
                                />
                              
                            </Grid>
                            <Grid item style={{ color: "#3f51b5" }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.fournisseurs.fournisseurs
                            }
                            getOptionLabel={(option) => option.codfrs}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    codeclient: getOptionLabel.codfrs,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,

                                    cemail: getOptionLabel.email,
                                    valtimbre: getOptionLabel.timbre
                                      ? this.props.valtimbre
                                      : 0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    codeclient: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Code Fournisseur"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined" //variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="codcli"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.fournisseurs.fournisseurs
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    codeclient: getOptionLabel.codfrs,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,

                                    cemail: getOptionLabel.email,
                                    valtimbre: getOptionLabel.timbre
                                      ? this.props.valtimbre
                                      : 0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    codeclient: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    showForfitaire: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label={translate("Raison Social")}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined" //variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raissoc"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      <Col sm={5}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label={translate("Raison Social")}
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.raisonsocial}
                            fullWidth
                            name="raissoc"
                            disabled
                            // onChange={this.raisocHandler}

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code Fournisseur"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label={translate("Remise Globale %")}
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        fullWidth
                        name="remise"
                        value={this.state.remiseg}
                        onChange={this.remiseglobalChange}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    {/* <Col sm={3}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.state.numfacTab}
                          // getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  typfac: getOptionLabel.typfac,
                                })
                              : this.setState({
                                  typfac: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              id="standard-basic"
                              label="F/BL"
                              margin="normal"
                              //variant="outlined"
                              fullWidth
                              name="type"
                              // value={this.state.remiseg}
                              // onChange={this.remiseglobalChange}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col> */}
                    {this.state.codeclient ? (
                      <Col sm={4}>
                        <FormGroup>
                          {console.log(this.state.numfacTab)}
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.numfacTab}
                            getOptionLabel={(option) =>
                              `${option.typach}: ${option.numfac}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    facOrBl: getOptionLabel,
                                    numfac: getOptionLabel.numfac,
                                    typfac: getOptionLabel.typach,
                                  })
                                : this.setState({
                                    numfac: "",
                                    typfac: "",
                                    facOrBl: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="N° Bon d'entrée"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste  facture par code client
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/BEREs?typfac=BE&codcliss=${this.state.codeclient}&origin=FR&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        numfacTab: result,
                                        typfacTab: result.map(
                                          (el) => el.typfac
                                        ),
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="bon d'entree"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row style={{ flexWrap: "nowrap" }}>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "16px",
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label={translate("Liste des Articles")}
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1}>
                            {this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip
                                    title={translate(
                                      "Ajouter article"
                                    )}
                                  >
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                        left:"-15px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px", left:"-15px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <div className="tabbl2">
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>
                                {" "}
                                {translate("Désignation")}{" "}
                              </th>
                              <th> {translate("Quantité")} </th>
                              <th> {translate("PUHT")} </th>
                              <th> {translate("Remise")} </th>
                              <th> {translate("TVA")} </th>
                              <th> {translate("PUTTCNet")} </th>
                              <th> {translate("TotalHT")} </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}>
                                <td>
                                  <span>{t.codearticle}</span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span> {t.des} </span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {t.qte}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puht).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.remisea).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.totalht).toFixed(2)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <Card style={{ marginTop: "10px" }} id="brfr1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "-25px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Nete
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="brfr2">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row className="rowbrf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HTBrut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="brf">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbrf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="brf">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbrf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col id="brf">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbrf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur Timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        0.000
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  {!(this.state.tab.length > 0) ? (
                    <Button
                      variant="contained"
                      //type="submit"
                      onClick={() => this.test()}
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      {translate("Enregistrer tous")}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // color="secondary"
                      type="submit"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                    >
                      {translate("Enregistrer tous")}
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <LigREArticle
          submitHandler={this.submitHandler}
          tabArticle={this.state.tab}
          show={this.state.ligModalShow}
          onHide={this.ligModalClose}
          rem={this.state.remiseglobal}
          numfaccc={
            this.props.codbrs.codbrs &&
            this.props.codbrs.codbrs.map((nu) => parseInt(nu.valeur))
          }
          totalqte={this.state.totalqte}
          sumremisearticle={this.state.sumremisearticle}
          totalhtbrut={this.state.totalhtbrut}
          totaltva={this.state.totaltva}
          totalhtnet={this.state.totalhtnet}
          remiseglobal={this.state.remiseglobal}
          netapayer={this.state.netapayer}
          btnEnabled={this.state.btnEnabled}
          netnetapayer={this.state.netnetapayer}
          datfac={this.state.defaultdate}
          valtimbre={this.state.valtimbre}
          GMM={this.state.GMM}
          MG={this.state.MG}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectFournisseur: (a, b, c, d, e) =>
      dispatch(SelectFournisseur(a, b, c, d, e)),
    // GetNumFacDevis: (a, b, c, d, e) => dispatch(GetNumFacDevis(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectRECode: (a, b, c, d, e) => dispatch(SelectRECode(a, b, c, d, e)),
    SelectRE: (f, a, b, c, d, e) => dispatch(SelectRE(f, a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    fournisseurs: state.fournisseurs,
    numfac: state.numfac,
    articles: state.articles,
    brs: state.brs,
    codbrs: state.codbrs,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddREModal));
