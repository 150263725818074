import React, { Component } from "react";
import { Modal, Card, Row, Col } from "react-bootstrap";
import "../styling/Styles.css";
import "./bl.scss";
import Typography from "@material-ui/core/Typography";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { SelectBLLig } from "../../redux/actions/GetBLLig";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import PrintIcon from "@material-ui/icons/Print";
import ReceiptIcon from "@material-ui/icons/Receipt";
import EditIcon from "@material-ui/icons/Edit";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ModifierBLModal from "./ModifierBLModal";
// import ProgressBar from "../commande-client-devis/ProgressBar";
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import ReactToPrint from "react-to-print";
import HomeIcon from "@material-ui/icons/Home";
import PhoneIcon from "@material-ui/icons/Phone";
import { SelectBL } from "../../redux/actions/GetBL";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { SelectFacCod } from "../../redux/actions/GetCodFac";
import translate from "../../i18n/translate";
import { LOCALES } from "../../i18n";
import "./Responsivebl.css";




// const roundTo = require("round-to");

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class GetBLByIdModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      ligid: "",
      blid: "",
      tab: [],
      newtab: [],
      todaydate: date,

      codearticle: 0,
      des: "",
      qte: 0,
      unite: 0,
      puht: 0,
      faudec: 0,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      totalht: 0,

      totalqte: 0,
      netapayer: 0,
      // tabtab: [],
      clientimp: [],
      Assiett:[],
      snackbaropen: false,
      snackbarmsg: "",
      username: username,
      numfacture: "",
    };
  }

  componentDidMount() {
    this.props.SelectBLLig(
      this.props.blid,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFacCod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  handleOpen = () => {
    this.setState({ open: true });
    //avoir le client de code = codeclient
    fetch(
      `https://www.24-crm.tn/webservice/api/Clients?codeclient=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ clientimp: data }));
 //avoir Assiette/TVA/Montant
      fetch(
        `https://www.24-crm.tn/webservice/Api/TVA?numfacture=${this.props.blid}&type=BL&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((response) => response.json())
        .then((data) => this.setState({ Assiett: data }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    // this.setState({ openMailModal: true });
  };

  noupdate = () => {
    window.alert(
      "Impossible de modifier Bon de livraison ... Bon de livraison est déja facturé"
    );
  };


  openModifier = () =>{ 
    if ((this.props.annuler === "0")) {
          this.setState({ openModifierModal: true, blid: this.props.blid });
          this.props.blligs.blligs.map((t) => {
            this.setState({ ligid: t.id });
          });
        } else {
          alert("Bon de livraison annulé, il faut le réactiver !");
        }
    };









annuler = () => {
    // update annuler=1
    this.props.annuler === "0"
      ? this.props.facture === "0"
        ? fetch(
            `https://www.24-crm.tn/webservice/api/BLBRs?idd=${this.props.blid}&typfaccs=BL&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "PUT",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then((result) => {
              this.props.onHide();
              this.props.SelectBL(
                this.props.ConfigDossier.coddos,
                this.props.ConfigDossier.serveur,
                this.props.ConfigDossier.utilisateur,
                this.props.ConfigDossier.pwd_serveur,
                this.props.ConfigDossier.database
              );
              // Exécuter la procédure FinirAnnulBLBR
              fetch(
                `https://www.24-crm.tn/webservice/api/BLBRs?fac=${this.props.blid}&Typfacs=BL&NumComs=&Typcoms=&CODDEPs=${this.props.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                {
                  method: "POST",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              );

              this.setState({ snackbaropen: true, snackbarmsg: result });
            })
            .then(() => window.location.reload())
        : window.alert(
            "Bon de livraison déja facturé et ne peut pas être annulé!"
          )
      : window.alert("Bon de livraison déja annulé");
  };
  activer = () => {
    // update annuler=0
    this.props.annuler !== "0"
      ? fetch(
          `https://www.24-crm.tn/webservice/api/BLBRs?idD=${this.props.blid}&typfacC=BL&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            this.props.onHide();
            this.props.SelectBL(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );
            //Exécuter la procédure SommeTTCBL
            fetch(
              `https://www.24-crm.tn/webservice/api/LigBLBRs?FAC=${this.props.blid}&Typfacc=BL&CODDEPP=${this.props.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  //  this.setState({ snackbaropen: true, snackbarmsg: result });
                  // window.location.reload();
                },
                (error) => {
                  this.setState({
                    snackbaropen: true,
                    snackbarmsg: "failed",
                  });
                }
              );
            //Exécuter la procédure RecalculSoldeCliNew
            fetch(
              `https://www.24-crm.tn/webservice/api/REGCLIs?codeclii=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            );

            this.setState({ snackbaropen: true, snackbarmsg: result });
          })
          .then(() => window.location.reload())
      : window.alert("Bon de livraison déja activée");
  };

  imprimer = () => {
    window.alert("imprimer");
  };

  nonsupprimer = () => {
    window.alert(
      "Vous devez annuler ce bon de livraison pour que vous puissiez le supprimer"
    );
  };

  facturer = () => {
    // console.log("testidddd", this.props.codfacs.codfacs);
    //console.log("testblid", this.props.blid);
    //update facturer=idddd

    this.props.facture === "0"
      ? this.props.annuler === "0"
        ? fetch(
            `https://www.24-crm.tn/webservice/api/BLBRs?idddd=${
              this.props.codfacs.codfacs.map((el) => el.valeur)[0]
            }
            &typfaccsss=BL&numfacccc=${this.props.blid}&coddos=${
              this.props.ConfigDossier.coddos
            }&serveur=${this.props.ConfigDossier.serveur}&user=${
              this.props.ConfigDossier.utilisateur
            }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
              this.props.ConfigDossier.database
            }`,
            {
              method: "PUT",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            //.then(() => window.location.reload())
            .then((result) => {
              this.props.onHide();
              this.props.SelectBL(
                this.props.ConfigDossier.coddos,
                this.props.ConfigDossier.serveur,
                this.props.ConfigDossier.utilisateur,
                this.props.ConfigDossier.pwd_serveur,
                this.props.ConfigDossier.database
              );

              ////////////// 2nd part post data in ligfacli //////////
              this.props.tabtab.map((k, index) => {
                // for (var i = 0; i < this.props.tabtab.length; i++) {
                fetch(
                  `https://www.24-crm.tn/webservice/api/LigFACCLIs/{ID}?numfac=${this.props.codfacs.codfacs.map(
                    (t) => t.valeur
                  )}&typfac=FT&numlig=${k.numlig}&codart=${k.codart}&quantite=${
                    k.quantite
                  }&fodart=0&desart=${k.desart}&datfac=${
                    this.state.todaydate
                  }&priuni=${k.priuni}&remise=${k.remise}&unite${
                    k.unite
                  }&codtva=${k.codtva}&tautva=${k.tautva}&montht=${
                    k.montht
                  }&ttclig=${k.ttclig}&coddos=${
                    this.props.ConfigDossier.coddos
                  }&serveur=${this.props.ConfigDossier.serveur}&user=${
                    this.props.ConfigDossier.utilisateur
                  }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
                    this.props.ConfigDossier.database
                  }`,
                  {
                    method: "POST",
                    header: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      this.setState({
                        snackbaropen: true,
                        snackbarmsg: result,
                      });

                      // window.alert(result);
                    },
                    (error) => {
                      this.setState({
                        snackbaropen: true,
                        snackbarmsg: "failed",
                      });
                    }
                  );
                // }
              });

              ////////////// 3rd part post data in factable //////////
              fetch(
                `https://www.24-crm.tn/webservice/api/FACCLIs?numfac=${this.props.codfacs.codfacs.map(
                  (t) => t.valeur
                )}&typfac=FT&codcli=${this.props.client}&raisoc=${
                  this.props.raisonsociale
                }&pj=''&codrep=${this.props.Auth.code}&catfisc=${
                  this.props.catfisc
                }&datfac=${this.state.todaydate}&timbre=${
                  this.props.droitdetimbre
                }&smhtb=${this.props.totalhtbrut}&smremart=${
                  this.props.remiselignes
                }&smremglo=${this.props.remiseglobale}&smhtn=${
                  this.props.totalhtnet
                }&smtva=${this.props.totaltva}&mntbon=${
                  parseFloat(this.props.totalttc) +
                  parseFloat(this.props.valtimbree)
                }&reste=${
                  parseFloat(this.props.reste) +
                  parseFloat(this.props.valtimbree)
                }&numcom=${this.props.codfacs.codfacs.map(
                  (t) => t.valeur
                )}&typcom=FT&valtimbre=${this.props.valtimbree}&Vendeur=${
                  this.props.Auth.username
                }&tauxNASS=${this.props.regimecli}&smNASS=${
                  this.props.avance
                }&CodDep=${this.props.MG}&coddos=${
                  this.props.ConfigDossier.coddos
                }&serveur=${this.props.ConfigDossier.serveur}&user=${
                  this.props.ConfigDossier.utilisateur
                }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
                  this.props.ConfigDossier.database
                }`,
                {
                  method: "POST",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((res) => res.json())
                ////////// update switch /////////////
                .then((res) => {
                  this.setState({ numfacture: res });
                  fetch(
                    `https://www.24-crm.tn/webservice/api/Switch?code=FC2&valeur=${this.props.codfacs.codfacs.map(
                      (t) => parseInt(t.valeur) + 1
                    )}&coddos=${this.props.ConfigDossier.coddos}&serveur=${
                      this.props.ConfigDossier.serveur
                    }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
                      this.props.ConfigDossier.pwd_serveur
                    }&database=${this.props.ConfigDossier.database}`,
                    {
                      method: "PUT",
                      header: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  );
                })

                .then(
                  (result) => {
                    //recalculer new solde client
                    fetch(
                      `https://www.24-crm.tn/webservice/api/REGCLIs?codeclii=${this.props.client}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                      {
                        method: "POST",
                        header: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                        },
                      }
                    );
                    this.setState({
                      snackbaropen: true,
                      snackbarmsg: result,
                    });

                    this.setState({
                      snackbaropen: true,
                      snackbarmsg:
                        "Facturé sous le N° " + this.state.numfacture,
                    });

                    //// window.alert(result);
                  },
                  (error) => {
                    this.setState({
                      snackbaropen: true,
                      snackbarmsg: "failed",
                    });
                  }
                )
                .then(() => window.location.reload());
            })
        : window.alert("Bon de livraison annulé, il faut le réactivé")
      : window.alert("Bon de livraison déja facturé");
  };

  supprimer = () => {
    if (
      window.confirm("êtes-vous sûr de vouloir supprimer cet bon de livraison?")
    ) {
      //supprimer l'élément de la table ligblbr
      fetch(
        `https://www.24-crm.tn/webservice/api/LigBLBRs/${this.props.blid}?typfacc=BL&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
        });

      //supprimer l'élément de la table blbr
      fetch(
        `https://www.24-crm.tn/webservice/api/BLBRs/${this.props.blid}?typfacc=BL&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.props.onHide();
          this.props.SelectBL(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

          this.setState({ snackbaropen: true, snackbarmsg: result });
        })
        .then(() => window.location.reload());
    }
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };











  render() {
    const actions =
      // { icon: <PrintIcon />, name: "Imprimer" },
      this.props.Auth.fct == "100"
        ? this.props.locale === LOCALES.FRENCH
          ? [
              { icon: <EditIcon />, name: "Modifier" },
              {
                icon:
                  this.props.annuler === "0" ? (
                    <CancelPresentationIcon />
                  ) : (
                //     <SlideshowIcon />
                //   ),
                // name: this.props.annuler === "0" ? "Annuler" : "Activer",
                <DeleteOutlineIcon />
              ),
            name: this.props.annuler === "0" ? "Annuler" : "Supprimer",
              },
             // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
               { icon: <FileCopyIcon />, name: "Facturer" },
            ]
          : [
              { icon: <EditIcon />, name: "Edit" },
              {
                icon:
                  this.props.annuler === "0" ? (
                    <CancelPresentationIcon />
                  ) : (
                //     <SlideshowIcon />
                //   ),
                // name: this.props.annuler === "0" ? "Cancel" : "Activate",
                <DeleteOutlineIcon />
              ),
            name: this.props.annuler === "0" ? "Cancel" : "Remove",
              },
             // { icon: <DeleteOutlineIcon />, name: "Remove" },
              { icon: <FileCopyIcon />, name: "Charge" },
            ]
        : this.props.locale === LOCALES.FRENCH
        ? [
            { icon: <EditIcon />, name: "Modifier" },
            {
              icon:
                this.props.annuler === "0" ? (
                  <CancelPresentationIcon />
                ) : (
                  <SlideshowIcon />
                ),
              name: this.props.annuler === "0" ? "Annuler" : "Activer",
            },
          ]
        : [
            { icon: <EditIcon />, name: "Edit" },
            {
              icon:
                this.props.annuler === "0" ? (
                  <CancelPresentationIcon />
                ) : (
                  <SlideshowIcon />
                ),
              name: this.props.annuler === "0" ? "Cancel" : "Activate",
            },
          ];
          const altText = this.props.ConfigDossier.coddos+".jpg";
    const DATE_OPTIONS = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    let ModifierModalClose = () =>
      this.props.annuler === "0"
        ? this.setState({ openModifierModal: false })
        : alert("");

    const { ligidd, bcidd, tabb } = this.state;

    const { darck } = this.props;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >

          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>
                {" "}
                {translate("Bon de Livraison N°")} 
                {this.props.blid} {translate("du")}{" "}
                {new Date(this.props.datebl).toLocaleDateString(DATE_OPTIONS)}{" "}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Card>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                {this.props.MG ? (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {translate("Client")}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("Raison Social")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "#020f64" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h3">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Bon de Commande N° :{this.props.editer} 
                        </Label>




                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.pj}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={3}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Magasin
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.MG}
                      </Typography>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {translate("Client")}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.client}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h2">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          {" "}
                          {translate("Raison Social")}{" "}
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.raisonsociale}
                      </Typography>
                    </Col>

                    <Col style={{ textAlign: "center" }} sm={4}>
                      <Typography variant="h6" component="h3">
                        <Label
                          style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                        >
                          Bon de Commande N°
                        </Label>
                      </Typography>
                      <Typography
                        style={{
                          color: `${darck ? "grey" : "#fff"}`,
                          fontSize: "18px",
                        }}
                      >
                        {this.props.pj}
                      </Typography>
                    </Col>
                  </Row>
                )}
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }}>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                <div className="tabbl2">
                  <table stripped>
                    <thead>
                      <tr style={{ textAlign: "center", fontSize: "12px" }}>
                        <th>Code</th>
                        <th style={{ width: "37%" }}>
                          {" "}
                          {translate("Désignation")}{" "}
                        </th>
                        <th> {translate("Quantité")} </th>
                        {/* <th>Unité</th> */}
                        <th> {translate("PUHT")} </th>
                        <th> {translate("Remise")} </th>
                        <th> {translate("TVA")} </th>
                        <th> {translate("PUTTCNet")} </th>
                        <th> {translate("TotalHT")} </th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        overflowY: "auto",
                        display: "block",
                        maxHeight: "10em",
                      }}
                    >
                      {this.props.tabtab.map((t, i) => (
                        <tr key={i} style={{ textAlign: "center" }}>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{t.codart}</span>
                          </td>
                          <td
                            style={{
                              fontSize: "12px",
                              width: "37%",
                              color: `${darck ? "#020f64" : "#fff"}`,
                            }}
                          >
                            {t.desart}
                          </td>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{t.quantite}</span>
                          </td>
                          {/* <td>
                            <span>{t.unite}</span>
                          </td> */}
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{Number(t.priuni).toFixed(3)}</span>
                          </td>

                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{Number(t.remise).toFixed(2)}</span>
                          </td>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{Number(t.tautva).toFixed(2)}</span>
                          </td>

                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{Number(t.PUTTCNET).toFixed(3)}</span>
                          </td>
                          <td
                            style={{ color: `${darck ? "#020f64" : "#fff"}` }}
                          >
                            <span>{Number(t.montht).toFixed(3)}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Card.Body>
            </Card>

            <Card style={{ marginTop: "10px" }} id="bl1">
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                <Row style={{ marginTop: "10px" }}>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total HT Brut")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Remise Article")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Remise Globale")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total HT Net")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total TVA")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col>
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "-25px" }}>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total TTC")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(
                        parseFloat(this.props.totalttc) -
                          parseFloat(this.props.avance)
                      ).toFixed(3)}
                    </p>
                  </Col>

                  {/* <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    > */}
                      {/* Total Quantité */}
                      {/* Valeur de Timbre */}
                    {/* </p> */}
                    {/* <p style={{ color: `${darck ? "black" : "#fff"}` }}> */}
                      {/* {this.props.totalqte} */}
                      {/* {this.state.totalqte} */}
                      {/* {this.props.sumqt} */}
                      {/* {Number(this.props.valtimbre).toFixed(3)} */}
                      {/* 0.000 */}
                    {/* </p> */}
                  {/* </Col> */}

                  <Col
                    sm={4}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Avance Impôt
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* {Number(this.state.totaltva).toFixed(3)} */}
                      {Number(this.props.avance).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    sm={3}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Net à Payer")}
                    </p>

                    <p
                      style={{
                        color: `${darck ? "black" : "#fff"}`,
                        fontWeight: "bold",
                      }}
                    >
                      {/* {Number(this.props.totalttc).toFixed(3)} */}
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ////////Responsive////////// */}

            <Card style={{ marginTop: "10px" }} id="bl2">
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}
              >
                <Row className="rowbl">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total HT Brut")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtbrut).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Remise Article")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiselignes).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Remise Globale")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.remiseglobale).toFixed(3)}
                    </p>
                  </Col>
                </Row>
                <Row style={{ marginBottom: "10px" }}>
                  <Col id="bl">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>
                <Row className="rowbl">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total HT Net")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totalhtnet).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total TVA")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(this.props.totaltva).toFixed(3)}
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Total TTC")}
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {Number(
                        parseFloat(this.props.totalttc) -
                          parseFloat(this.props.avance)
                      ).toFixed(3)}
                    </p>
                  </Col>
                </Row>

                {/* ///////////////////3eme/////////////////////////////////// */}

                <Row style={{ marginBottom: "10px" }}>
                  <Col id="bl">
                    <Divider style={{ backgroundColor: "grey" }} />
                  </Col>
                </Row>

                <Row className="rowbl">
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Valeur Timbre
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      0.000
                    </p>
                  </Col>

                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: `${darck ? "darkslateblue" : "#fff"}`,
                        marginBottom: "-5px",
                      }}
                    >
                      Avance Impôt
                    </p>
                    <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                      {/* {Number(this.state.totaltva).toFixed(3)} */}
                      {Number(this.props.avance).toFixed(3)}
                    </p>
                  </Col>
                  <Col
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        color: "rgb(220, 0, 78)",
                        fontWeight: "bold",
                        marginBottom: "-5px",
                      }}
                    >
                      {translate("Net à Payer")}
                    </p>

                    <p
                      style={{
                        color: `${darck ? "black" : "#fff"}`,
                        fontWeight: "bold",
                      }}
                    >
                      {/* {Number(this.props.totalttc).toFixed(3)} */}
                      {Number(parseFloat(this.props.totalttc)).toFixed(3)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* ///////////////////SpeedDial//////////////////////////////// */}
            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{
                    position: "absolute",
                    bottom: "-20px",
                    right: "10px",
                  }}
                  FabProps={{ size: "small" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                >
                  {actions.map((action) =>
                    this.props.locale === LOCALES.FRENCH ? (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                          this.handleClose();
                          action.name == "Imprimer" && this.imprimer();
                          action.name == "Modifier" &&
                            this.props.facture !== "0" &&
                            this.noupdate();
                          action.name == "Modifier" &&
                            this.props.facture === "0" &&
                            this.openModifier();

                          action.name == "Supprimer" &&
                            this.props.annuler === "1" &&
                            this.supprimer();
                          action.name == "Supprimer" &&
                            this.props.annuler === "0" &&
                            this.nonsupprimer();

                          action.name == "Annuler" && this.annuler();
                          action.name == "Activer" && this.activer();
                          action.name == "Facturer" && this.facturer();
                        }}
                      />
                    ) : (
                      <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={() => {
                          this.handleClose();
                          action.name == "Print" && this.imprimer();
                          action.name == "Edit" &&
                            this.props.facture !== "0" &&
                            this.noupdate();
                          action.name == "Edit" &&
                            this.props.facture === "0" &&
                            this.openModifier();

                          action.name == "Remove" &&
                            this.props.annuler === "1" &&
                            this.supprimer();
                          action.name == "Remove" &&
                            this.props.annuler === "0" &&
                            this.nonsupprimer();

                          action.name == "Cancel" && this.annuler();
                          action.name == "Activate" && this.activer();
                          action.name == "Charge" && this.facturer();
                        }}
                      />
                    )
                  )}
                  {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
<br></br>
                  {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <ReceiptIcon />
                        </Fab>
                      )}
                      content={() => this.ticketRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <ReceiptIcon />
                        </Fab>
                      )}
                      content={() => this.ticketRef}
                    />
                  )}
                </SpeedDial>
              </Col>
            </Row>

            <ModifierBLModal
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              ligidd={this.state.ligid}
              tabb={this.props.tabtab}
              blid={this.props.blid}
              datebl={this.props.datebl}
              onHide01={this.props.onHide}
              taurem={this.props.taurem}
              catfisc={this.props.catfisc}
              client={this.props.client}
              raisonsociale={this.props.raisonsociale}
              droitdetimbre={this.props.droitdetimbre}
              avanceimpot={this.props.avanceimpot}
              facture={this.props.facture}
              regimecli={this.props.regimecli}
            />
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: "none",
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          ></Modal.Footer>
        </Modal>

          {/* Ajout de l'image de logo avec une URL */}
        


        <div style={{ display: "none" }}>
          <div ref={(el) => (this.componentRef = el)}
            style={{ margin: "40px  35px", height: "94%" }}>
          
    {/* logoetat */}        
          <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
          


        
            <h1 style={{ fontSize: "50px", fontWeight: "bolder" }}> {this.props.InfoEntreprise[0].lib}</h1>
            <p style={{ fontSize: "20px", marginTop: "-10px" }}>
              {this.props.InfoEntreprise[0].spe}
              <br />
              Adresse : {this.props.InfoEntreprise[0].adr},{this.props.InfoEntreprise[0].ville},{this.props.InfoEntreprise[0].pays}<br />
              Tél 1 :{this.props.InfoEntreprise[0].tel1} <br/>
              Tél 2 :{this.props.InfoEntreprise[0].tel2} <br/>
              Email:{" "}{this.props.InfoEntreprise[0].email} <br/>
              Maricule Fiscale   :   {this.props.InfoEntreprise[0].codtva} <br/>
             RIB: {this.props.InfoEntreprise[0].rib}{" "}
              {this.props.InfoEntreprise[0].matban}
              {this.props.InfoEntreprise[0].agence}{" "}
              {this.props.InfoEntreprise[0].ville} <br />
               {translate("Etabli par")}: {this.props.vendeur}  </p>
        
        
        
        
        
            <h5  style={{
                marginLeft: "130px",
                flexDirection: "column",
                marginTop: "25px",
                marginBottom: "-35px",
                fontWeight: "bolder", }} >
              {translate("Bon de Livraison")} № {this.props.blid}{" "}
              <span>{this.props.annuler !== "0" ? "Annulé" : null}</span>
            </h5>
            <Row>
              <Col>
                <h6 style={{ marginLeft: "215px", marginTop: "40px" }}>
                  Date:{" "}
                  {new Date(this.props.datebl).toLocaleDateString(DATE_OPTIONS)}
                </h6>
              </Col>
              <Col>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h6>
                    {translate("Code client")} : {this.props.client}
                  </h6>
                  {this.state.clientimp.map((t) => (
                    <h6 style={{ marginRight: "20px" }}>
                      <PhoneIcon /> {t.tel1} / {t.tel2}
                    </h6>
                  ))}
                </div>

                <div
                  style={{
                    marginTop: "5px",
                    borderStyle: "solid",
                    height: "150px",
                    width: "95%",
                    borderRadius: "20px",
                    marginBottom: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                    <h3> {this.props.raisonsociale} </h3>
                    {this.state.clientimp.map((t) =>
                      t.adr === "" ? (
                        <h5>
                          <HomeIcon /> --
                        </h5>
                      ) : (
                        <h5>
                          <HomeIcon /> {t.adr}
                        </h5>
                      )
                    )}

                    {this.state.clientimp.map((t) =>
                      t.ville === "" ? <h5>{t.ville}</h5> : <h5>--</h5>
                    )}
                  </div>
                </div>

                {this.state.clientimp.map((t) =>
                  t.codtva === "" ? (
                    <h6>{translate("Code TVA")} : --</h6>
                  ) : (
                    <h6>
                      {translate("Code TVA")} : {t.codtva}
                    </h6>
                  )
                )}
              </Col>
            </Row>
            <br />
            <div
              style={{
                marginLeft: "5px",
                marginTop: "10px",
                width: "99%",
                minHeight: "540px",
                padding: "20px 25px",
              }}
            >
              <table
                style={{
                  textAlign: "center",
                  borderStyle: "5px",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <thead
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    fontWeight: "bold",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                >
                    <tr style={{ borderBottom: "1px solid grey" }}>
                    <th>{translate("Code")}</th>
                    <th style={{ width: "35%" }}>{translate("Désignation")}</th>
                    <th>{translate("Qté")}</th>
                    <th>{translate("UN")}</th>
                    <th>{translate("P.U.H.T")}</th>
                    <th>{translate("TVA")}</th>
                    <th>{translate("Pu Ttc Net")}</th>
                    <th>{translate("Rem")}</th>
                    <th>{translate("Mont.HT")}</th> </tr> 
                </thead>

                <tbody>
                  {this.props.tabtab.map((t, i) => (
                    <tr
                      key={i}
                      style={{
                        textAlign: "center",
                        height: "60px",
                        borderBottom: "1px solid grey",
                      }}
                    >
                      <td>{t.codart}</td>
                      <td style={{ width: "37%" }}>{t.desart}</td>
                      <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}>{t.quantite}</td>
                      <td><span>{t.unite}</span></td> 
                      <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}> {Number(t.priuni).toFixed(3)} </td>
                      <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}> {Number(t.tautva).toFixed(2)} </td>
                      <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}> {Number(t.ttclig).toFixed(3) / Number(t.quantite)} </td>
                      <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}> {Number(t.remise).toFixed(2)} </td>
                      <td style={{ color: `${darck ? "darkslateblue" : "#fff"}` }}> {Number(t.montht).toFixed(3)} </td> </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* <br /> */}

{/* Nouvelle modification sur l'etat d'impression pour correction TVA */}
<div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  marginLeft: "5px",
                }}
              >
                {/* <table
                  style={{
                    textAlign: "center",
                    borderStyle: "solid",
                    width: "450px",
                    borderWidth: "1px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >    
                      <tr>
                      <th>T.V.A</th>
                      <th>Assiette</th>
                      <th>Montant</th>  </tr>  </thead> */}
                                         
{/* ////////////////////       SI TVA = 0%      /////////////////// */}
{/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 0 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))} */}

{/* ////////////////////       SI TVA = 7%      /////////////////// */}
                  {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 7 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))}
                       */}
{/* ////////////////////       SI TVA = 13%      /////////////////// */}

                  {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 13 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))} */}
{/* ////////////////////       SI TVA = 19%      /////////////////// */}

                {/* {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 19 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))}  */}
                      
                      {/* {this.state.Assiett.map((t, i) => (
                      <tbody key={i}>
                      <tr style={{ height: "50px" }}>
                          <td>{t.TauxTva}</td>
                          <td>{t.Assiette}</td>
                          <td>{t.Montant}</td> </tr>  </tbody> ))}
{/* 
{this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 0 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))}

                  {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 7 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))}
                      

                  {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 13 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                       </tr>  ) : null} </tbody> ))}


                {this.props.tabtab.map((t, i) => (
                    <tbody key={i}>
                      {Number(t.tautva) === 19 ? (
                        <tr style={{ height: "50px" }}>
                          <td>{Number(t.tautva).toFixed(2)}</td>
                          <td>{Number(t.montht).toFixed(3)}</td>
                          <td>{Number(t.ttclig-t.montht).toFixed(3)}</td>
                        </tr>
                      ) : null}  </tbody> ))}  */} 
                {/* </table> */}

                <table
                          style={{
                            textAlign: "center",
                            borderStyle: "solid",
                            width: "450px",
                            borderWidth: "1px",
                          }}
                        >
                          <thead
                            style={{
                              textAlign: "center",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            <tr>
                              <th>{translate("TVA")}</th>
                              <th>{translate("Assiette")}</th>
                              <th>{translate("Montant")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* Initialize the totals */}
                            {(() => {
                              const tvaGroups = {};

                              // Grouping by TVA and summing the values
                              this.props.tabtab.forEach((t) => {
                                const tvaRate = Number(t.tautva).toFixed(2);

                                if (!tvaGroups[tvaRate]) {
                                  tvaGroups[tvaRate] = { assiette: 0, montant: 0 };
                                }

                                tvaGroups[tvaRate].assiette += Number(t.montht);
                                tvaGroups[tvaRate].montant += Number(t.ttclig - t.montht);
                              });

                              // Render the rows for each TVA group
                              return Object.keys(tvaGroups).map((tvaRate) => (
                                <tr key={tvaRate} style={{ height: "50px" }}>
                                  <td>{tvaRate}</td>
                                  <td>{tvaGroups[tvaRate].assiette.toFixed(3)}</td>
                                  <td>{tvaGroups[tvaRate].montant.toFixed(3)}</td>
                                </tr>
                              ));
                            })()}
                          </tbody>
                        </table>





              </div>
{/* //////////////////////////////////////////Total quantité//////////////////////////////////////*/}
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  marginTop: "20px",
                }}
              >
                <h5>
                  <b> {translate("Total quantité")}: </b>
                </h5>
                &nbsp;&nbsp;&nbsp; <h5>{this.props.sumqt}</h5>
              </div>

              <table
                style={{
                  borderStyle: "solid",
                  width: "270px",
                  marginRight: "10px",
                  borderWidth: "1px",
                }}
              >
                {Number(this.props.remiseglobale) != 0 ? (
                  <>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp;{translate("Total HT Brut")}:
                      </td>
                      <td> {Number(this.props.totalhtbrut).toFixed(3)}</td>
                    </tr>
                    <tr style={{ height: "50px" }}>
                      <td style={{ fontWeight: "bold" }}>
                        &nbsp;&nbsp;&nbsp; {translate("Remise Globale")} :
                      </td>
                      <td> {Number(this.props.remiseglobale).toFixed(3)}</td>
                    </tr>
                  </>
                ) : null}

                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    &nbsp;&nbsp;&nbsp;{translate("Total HT Net")}:
                  </td>
                  <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                </tr>
                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;{translate("Total TVA")}:
                  </td>
                  <td>{Number(this.props.totaltva).toFixed(3)}</td>
                </tr>

                <tr style={{ height: "50px" }}>
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    &nbsp;&nbsp;&nbsp;{translate("Net à Payer")}:
                  </td>
                  {/* <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {Number(this.props.totalttc).toFixed(3)}{" "}
                  </td> */}
                  <td style={{ fontWeight: "bold" }}>
                    {" "}
                    {Number(parseFloat(this.props.totalttc)).toFixed(3)}{" "}
                  </td>
                </tr>
              </table>
            </div>
            {/* <br />
            <br /> */}

            {/* //////////////////////// footer TEST ///////////////////////////// */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                marginTop: "20px",
                justifyContent: "space-between",
                marginLeft: "5px",
              }}
            >
              <div
                style={{
                  borderStyle: "solid",
                  borderColor: "grey",
                  width: "350px",
                  marginLeft: "5px",
                  borderWidth: "1px",
                  height: "120px",
                  // borderRadius: "20px",
                  textAlign: "left",
                }}
              >
                &nbsp;&nbsp;&nbsp;Notes:
              </div>

              <div
                style={{
                  borderStyle: "solid",
                  borderColor: "grey",
                  width: "350px",
                  borderWidth: "1px",
                  height: "120px",
                  // borderRadius: "20px",
                  textAlign: "left",
                }}
              >
                &nbsp;&nbsp;&nbsp;{translate("Signature Client")}:
              </div>

              <div
                style={{
                  borderStyle: "solid",
                  borderColor: "grey",
                  width: "340px",
                  marginRight: "10px",
                  borderWidth: "1px",
                  height: "120px",
                  // borderRadius: "20px",
                  textAlign: "left",
                }}
              >
                &nbsp;&nbsp;&nbsp;{translate("Signature & cachet")}:
              </div>
            </div>
          </div>

          <div style={{ display: "none" }}>
            <div ref={(el) => (this.ticketRef = el)}>
              <h1
                style={{
                  fontSize: "100px",
                  textAlign: "center",
                  fontWeight: "bolder",
                  marginBottom: "40px",
                  marginTop: "200px",
                }}
              >
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>

              <h5
                style={{
                  marginLeft: "120px",
                  flexDirection: "column",
                  marginTop: "120px",
                  marginBottom: "40px",
                  fontWeight: "bolder",
                  fontSize: "100px",
                }}
              >
                Bon de livraison № {this.props.devisid}{" "}
              </h5>
              <Row>
                <Col>
                  <h6
                    style={{
                      marginLeft: "170px",
                      marginTop: "60px",
                      fontSize: "50px",
                    }}
                  >
                    {new Date(this.props.datebl).toLocaleDateString(
                      DATE_OPTIONS
                    )}
                  </h6>
                </Col>
                <Col>
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      alignItems: "center",
                      marginTop: "50px",
                      flexDirection: "row",
                      textAlign: "center",
                      justifyContent: "space-between",
                    }}
                  ></div>

                  <div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      <h3
                        style={{
                          fontSize: "80px",
                          marginTop: "70px",
                          marginRuight: "35px",
                        }}
                      >
                        {" "}
                        {this.props.raisonsociale}{" "}
                      </h3>
                    </div>
                  </div>

                  {this.state.clientimp.map((t) =>
                    t.codtva === "" ? (
                      <h6 style={{ fontSize: "65px", marginTop: "50px" }}>
                        Code TVA : --
                      </h6>
                    ) : (
                      <h6 style={{ fontSize: "65px", marginTop: "50px", marginLeft:"-200px" }}>
                        Code TVA : {t.codtva}
                      </h6>
                    )
                  )}
                </Col>
              </Row>
              <br />
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "50px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >
                <table
                  style={{
                    textAlign: "center",
                    borderStyle: "5px",
                    width: "100%",
                    marginTop: "60px",
                    paddingTop: "15px",
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                      fontSize: "50px",
                      paddingTop: "10px",
                    }}
                  >
                    <tr
                      style={{
                        borderBottom: "1px solid grey",
                        paddingBottom: "30px",
                      }}
                    >
                      <th style={{ paddingBottom: "30px" }}>Qté</th>
                      <th style={{ width: "37%", paddingBottom: "30px" }}>
                        Article
                      </th>

                      <th style={{ paddingBottom: "30px" }}>PUHT</th>

                      <th style={{ paddingBottom: "30px" }}>TotalHT</th>
                    </tr>
                  </thead>

                  <tbody style={{ fontSize: "45px" }}>
                    {this.props.tabtab.map((t, i) => (
                      <tr
                        key={i}
                        style={{
                          textAlign: "center",
                          height: "60px",
                          borderBottom: "1px solid grey",
                        }}
                      >
                        <td style={{ paddingBottom: "45px" }}>{t.quantite}</td>
                        <td style={{ width: "37%", paddingBottom: "45px" }}>
                          {t.desart}
                        </td>

                        <td style={{ paddingBottom: "45px" }}>
                          {Number(t.priuni).toFixed(3)}
                        </td>

                        <td style={{ paddingBottom: "45px" }}>
                          {Number(t.montht).toFixed(3)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <br />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  textAlign: "center",
                  marginTop: "160px",
                  justifyContent: "space-between",
                  marginLeft: "5px",
                }}
              >
                <div
                  style={{
                    width: "400px",
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "center",
                  }}
                >
                  <h5>
                    <b style={{ fontSize: "40px" }}>Nbre d'article: </b>
                  </h5>
                  &nbsp;&nbsp;&nbsp;{" "}
                  <h5 style={{ fontSize: "40px" }}>{this.props.sumqt}</h5>
                </div>

                <table
                  style={{
                    borderStyle: "solid",
                    width: "700px",
                    fontSize: "50px",
                    paddingBottom: "35px",
                    marginRight: "50px",
                    borderWidth: "1px",
                  }}
                >
                  {Number(this.props.remiseglobale) != 0 ? (
                    <>
                      <tr style={{ height: "50px" }}>
                        <td style={{ fontWeight: "bold" }}>
                          &nbsp;&nbsp;&nbsp;Total.H.T Brut:
                        </td>
                        <td> {Number(this.props.totalhtbrut).toFixed(3)}</td>
                      </tr>
                      <tr style={{ height: "50px" }}>
                        <td style={{ fontWeight: "bold" }}>
                          &nbsp;&nbsp;&nbsp;Remise Globale:
                        </td>
                        <td> {Number(this.props.remiseglobale).toFixed(3)}</td>
                      </tr>
                    </>
                  ) : null}

                  <tr style={{ height: "50px" }}>
                    <td style={{ fontWeight: "bold" }}>
                      &nbsp;&nbsp;&nbsp;Total.H.T Net:
                    </td>
                    <td>{Number(this.props.totalhtnet).toFixed(3)}</td>
                  </tr>
                  <tr style={{ height: "50px" }}>
                    <td style={{ fontWeight: "bold" }}>
                      {" "}
                      &nbsp;&nbsp;&nbsp;Total TVA:
                    </td>
                    <td>{Number(this.props.totaltva).toFixed(3)}</td>
                  </tr>
                  <tr style={{ height: "50px" }}>
                    <td style={{ fontWeight: "bold" }}>
                      {" "}
                      &nbsp;&nbsp;&nbsp;Timbre fiscal:
                    </td>
                    <td>{Number(this.props.valtimbre).toFixed(3)}</td>
                  </tr>
                  <tr style={{ height: "50px" }}>
                    <td style={{ fontWeight: "bold" }}>
                      {" "}
                      &nbsp;&nbsp;&nbsp;Net à Payer:
                    </td>
                    <td style={{ fontWeight: "bold" }}>
                      {" "}
                      {Number(
                        parseFloat(this.props.totalttc) +
                          parseFloat(this.props.valtimbre)
                      ).toFixed(3)}{" "}
                    </td>
                  </tr>
                </table>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectBLLig: (a, b, c, d, e, f) => dispatch(SelectBLLig(a, b, c, d, e, f)),
    SelectBL: (a, b, c, d, e) => dispatch(SelectBL(a, b, c, d, e)),
    SelectFacCod: (a, b, c, d, e) => dispatch(SelectFacCod(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    blligs: state.blligs,
    codfacs: state.codfacs,
    // codfacs: 20220040,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    InfoEntreprise: state.InfoDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetBLByIdModal);
