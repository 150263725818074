import Axios from "axios";

export const SelectFacture = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.tn/webservice/api/FACCLIs?typew=F&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
    ).then((response) => {
      return dispatch({ type: "FACTURE_SELECT", payload: response.data });
    });
  };
};
