import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import Content from "../../components/content/Content";
import ConnectedSideBar from "../../components/sidebar/SideBar";
import { connect } from "react-redux";
const Wrapper = (props) => {
  const { darck } = props;
  const [isOpen, setOpen] = useState(true);
  const toggle = () => setOpen(!isOpen);
  return (
    <div className="App wrapper" style={{ backgroundColor: `${darck ? "#fff" : "#172431"}` }}>
      <ConnectedSideBar toggle={toggle} isOpen={isOpen} locale={props.locale} />
      <Content toggle={toggle} isOpen={isOpen} locale={props.locale} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    SideBarTitles: state.SideBarTitles,
    Auth: state.Auth,
    darck: state.darck.darck,
  };
};

export default connect(mapStateToProps)(Wrapper);