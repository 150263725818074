import React, { Component } from "react";

import { Table, Button, Card, Row, Col, Form } from "react-bootstrap";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
// import AddIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { connect } from "react-redux";
import { SelectAllNome } from "../../redux/actions/GetAllNome";

import {
  TextField,
  Snackbar,
  IconButton,
  Fab,
  Breadcrumbs,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { SelectCatNome } from "../../redux/actions/GetCatNome";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import "./nome.scss";
import SettingsIcon from "@material-ui/icons/Settings";
import EditElement from "./editElement";
import AddElement from "./addElement";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class Nomenclature extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cat: "",
      tab: [],
      show: false,
      snackbaropen: false,
      snackbarmsg: ",",
      code: "",
      lib: "",
      chdec: "",
      changeButton: false,
      addModalShow: false,
      addModalShow1: false,
    };
  }

  componentDidMount() {
    this.props.SelectAllNome(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectCatNome(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  catHandlerChange = () => {
    fetch(
      `https://www.24-crm.tn/webservice/api/Nome?cat=${this.state.cat}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ tab: data, show: true }));
  };

  supprimer = (index) => {
    if (window.confirm("êtes-vous sûr de vouloir supprimer ce nomenclature?")) {
      fetch(
        `https://www.24-crm.tn/webservice/api/Nome?catcat=${this.state.cat}&codecode=${index}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((result) => {
          this.setState({ snackbaropen: true, snackbarmsg: result });
          window.location.reload();
        });
    }
  };

  libHandler = (event) => {
    this.setState({ lib: event.target.value });
  };
  chdecHandler = (event) => {
    this.setState({ chdec: event.target.value });
  };

  codeHandler = (event) => {
    this.setState({ code: event.target.value });
  };

  submitHandler = (event) => {
    event.preventDefault();
    if (event.target.code.value && event.target.lib.value) {
      fetch(
        `https://www.24-crm.tn/webservice/api/Nome?cat=${this.state.cat}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.filter((el) => el.code == this.state.code).length > 0) {
            alert("code existant");
          } else {
            fetch(
              `https://www.24-crm.tn/webservice/api/Nome?cat=${this.state.cat}&code=${this.state.code}&lib=${this.state.lib}&chdec=${this.state.chdec}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  this.setState({ snackbaropen: true, snackbarmsg: result });
                  window.location.reload();
                },
                (error) => {
                  this.setState({ snackbaropen: true, snackbarmsg: "failed" });
                }
              );
          }
        });
    } else {
      alert("il faut donner le code et le libellé");
    }
  };

  modifierHandler = (event) => {
    event.preventDefault();

    fetch(
      `https://www.24-crm.tn/webservice/api/Nome?cat=${this.state.cat}&code=${this.state.code}&lib=${this.state.lib}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "PUT",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ snackbaropen: true, snackbarmsg: result });
          // window.location.reload();
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  render() {
    const { darck, classes  } = this.props;
    let addModalClose = () => this.setState({ addModalShow: false });
   let addModalClose1 = () => this.setState({ addModalShow1: false });
    return (
      <div style={{ height: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <StyledBreadcrumb
            component="a"
            href="/"
            label="Paramètres"
            icon={<SettingsIcon fontSize="small" />}
          />
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{ color: "rgb(63, 81, 181)" }}
            href="/nomenclature"
            label="Nomenclature"
            icon={<ListAltIcon fontSize="small" />}
          />
        </Breadcrumbs>

        <Row style={{ height: "83%" }} className={`${darck ? "" : classes.root}`}>
          <Col sm={12}>
            <Card style={{ height: "100%", marginTop: "5px" }}>
              <Card.Body
                                    style={{
                                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                      borderTop: `none`,
                                      borderRight: `${darck ? "" : "2px solid #fff"}`,
                                      borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                      borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                    }}
              >
                <Button
                  onClick={() => {
                    this.setState({
                      addModalShow1: true,
                    });
                    //   this.deleteRowMod(i);
                  }}
                >
                  Add Categorie
                </Button>
                {/*<h4 style={{ marginBottom: "30px" }}>
                  {" "}
                  Nomenclature &nbsp;{" "}
                  <span style={{ color: "rgb(23, 162, 184)" }}>
                    {" "}
                    {this.state.cat}{" "}
                  </span>{" "}
                </h4> */}

                <Row style={{ width: "70%", margin: "auto" }} form>
                  <Col>
                    <Form.Group controlId="code">
                      <Autocomplete
                        id="include-input-in-list"
                        includeInputInList
                        className="ajouter-client-input"
                        options={this.props.catnomes.catnomes}
                        getOptionLabel={(option) => option.cat+option.lib}
                        onChange={(event, getOptionLabel) => {
                          getOptionLabel
                            ? fetch(
                                `https://www.24-crm.tn/webservice/api/Nome?cat=${getOptionLabel.cat}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                              )
                                .then((response) => response.json())
                                .then((data) =>
                                  this.setState({
                                    tab: data,
                                    cat: getOptionLabel.cat,
                                    show: true,
                                  })
                                )
                            : this.setState({
                                cat: "",
                                show: false,
                                code: "",
                                lib: "",
                                chdec: "",
                                changeButton: false,
                              });
                        }}
                        renderInput={(params) => (
                          <TextField
                            // {...params}
                            label="Catégorie"
                            margin="normal"
                            fullWidth
                            //  onChange={this.catHandlerChange}
                            name="cat"
                            InputProps={{
                              ...params.InputProps,
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            inputProps={params.inputProps}
                            id={params.id}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {this.state.show ? (
                      <div className="tabnome">
                        <Table striped hover size="sm">
                          <thead
                            style={{ background: "#454d55", color: "white" }}
                          >
                            <tr>
                              <th>Code</th>
                              <th>Libellé</th>
                              <th>Valeur</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              textAlign: "center",
                              height: "500px",
                              color: `${darck ? "" : "#fff"}`,
                            }}
                          >
                            {this.state.tab.map((t, i) => (
                              <tr key={i}>
                                <td> {t.code} </td>
                                <td> {t.lib} </td>
                                <td> {t.chdec} </td>

                                <td>
                                  <Tooltip title="Editer">
                                    <Fab size="small">
                                      <EditIcon
                                        style={{}}
                                        onClick={() => {
                                          this.setState({
                                            code: t.code,
                                            lib: t.lib,
                                            chdec: t.chdec,
                                            changeButton: true,
                                            addModalShow: true,
                                          });
                                          //   this.deleteRowMod(i);
                                        }}
                                      />
                                    </Fab>
                                  </Tooltip>
                                  &nbsp;&nbsp;&nbsp;
                                  <Tooltip title="Supprimer">
                                    <Fab size="small">
                                      <DeleteIcon
                                        style={{}}
                                        onClick={() => this.supprimer(t.code)}
                                      />
                                    </Fab>
                                  </Tooltip>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    ) : null}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <EditElement
          catP={this.state.cat}
          codeP={this.state.code}
          libP={this.state.lib}
          chdecP={this.state.chdec}
          show={this.state.addModalShow}
          onHide={addModalClose}
        />
        <AddElement
          catP={this.state.cat}
          codeP={this.state.code}
          libP={this.state.lib}
          chdecP={this.state.chdec}
          show={this.state.addModalShow1}
          onHide={addModalClose1}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectAllNome: (a, b, c, d, e) => dispatch(SelectAllNome(a, b, c, d, e)),
    SelectCatNome: (a, b, c, d, e) => dispatch(SelectCatNome(a, b, c, d, e)),
  };
}



function mapStateToProps(state) {
  return {
    allnomes: state.allnomes,
    catnomes: state.catnomes,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(Nomenclature));

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
