const initialState = {
    codCais: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "CaisseCode":
        return {
          ...state,
          codCais: action.payload,
        };
     
      default:
        return state;
    }
  }
  