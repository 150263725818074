import Axios from "axios";

export const SelectValTimbre = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.tn/webservice/api/Vendeur?BDsql=${a}&coddos=${b}&serveur=${c}&user=${d}&pwd=${e}&database=PSDOSSIER`
    ).then((response) => {
      return dispatch({
        type: "VALTIMBRE_SELECT",
        payload: response.data,
      });
    });
  };
};
