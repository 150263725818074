const initialState = {
    Caisses: [
    ]
}
export default function (state = initialState, action) {
    switch (action.type) {
        case "CAISSE_SELECT":
            return {
                ...state,
                Caisses: action.payload
            }
        default:
            return state;
    };
}
