export const AuthUser = (username, code, fct, token) => (dispatch) => {
  dispatch({
    type: "USER_AUTH",
    payload: {
      username: username,
      code: code,
      token: token,
      isAuth: true,
      fct: fct,
    },
  });
};
export const LogoutUser = () => (dispatch) => {
  dispatch({
    type: "LOGOUT_USER",
  });
};

export const showLoader = () => (dispatch) => {
  dispatch({
    type: "SHOW_LOADER",
  });
};

export const hideLoader = () => (dispatch) => {
  dispatch({
    type: "HIDE_LOADER",
  });
};
