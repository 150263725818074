import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {
  TextField,
  Grid,
  Snackbar,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import { GetCodfrs } from "../../../redux/actions/GetCodFrs";
import { connect } from "react-redux";
import { SelectFournisseur } from "../../../redux/actions/GetFournisseur";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class AddFmodal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timbre: 0,
      soustraitant: 0,
      snackbaropen: false,
      snackbarmsg: ",",
      fod: 0,
    };
  }

  componentDidMount() {
    this.props.GetCodfrs(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  submitHandler = (event) => {
    event.preventDefault();

    fetch(
      `https://www.24-crm.tn/webservice/api/FOURNISSEURs?codfrs=${
        event.target.codfrs.value
      }&raisoc=${event.target.raisoc.value}&tel1=${
        event.target.tel1.value
      }&tel2=${event.target.tel2.value}&email=${event.target.email.value}&adr=${
        event.target.adr.value
      }&sitweb=${event.target.sitweb.value}&nom=${
        event.target.nom.value
      }&ville=${event.target.ville.value}&cp=${event.target.cp.value}&fax=${
        event.target.acontacter.value
      }&compte=${event.target.compte.value}&timbre=${Number(
        this.state.timbre
      )}&STrt=${event.target.soustraitant.value}&tauxfodec=${Number(
        this.state.fod
      )}&autreimp=${event.target.impot.value}&CodeTVA=${
        event.target.identifiant.value
      }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
        this.props.ConfigDossier.serveur
      }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
        this.props.ConfigDossier.pwd_serveur
      }&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.onHide();
          this.props.SelectFournisseur(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

          this.setState({ snackbaropen: true, snackbarmsg: result });
          this.props.GetCodfrs(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );


          window.location.reload();
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
  };
  render() {
    const { darck, classes  } = this.props;
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Fournisseur</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `none`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                      }}
          >
            <form autocomplete="off" 
                  onSubmit={this.submitHandler}
                  className={`${darck ? "" : classes.root}`}>
              <Row>
                <Col sm={6}>
                  <Card className="card0">
                    <Card.Body
                                          style={{
                                            backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                            borderTop: `${darck ? "" : "2px solid #fff"}`,
                                            borderRight: `${darck ? "" : "2px solid #fff"}`,
                                            borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                            borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                            borderRadius: "5px",
                                          }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            {this.props.codfrss.codfrss.map((cod) => ( 
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Code Fournisseur"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="codfrs"
                              value={(parseInt(cod.Column1) + 1).toString().padStart(4,'0')}
                              required
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                           ))} 
                          </FormGroup>
                        </Col>
                        <Col sm={6}></Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Raison Sociale"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="raisoc"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="A Contacter"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="nom"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Email"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="email"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Adresse"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="adr"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Ville"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="ville"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="number"
                              label="Code Postal"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="cp"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="number"
                              label="Téléphone 1"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="tel1"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="number"
                              label="Téléphone 2"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="tel2"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="A Contacter"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="acontacter"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Site Web"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="sitweb"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={6}>
                  <Card className="card0">
                    <Card.Body 
                                          style={{
                                            backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                            borderTop: `${darck ? "" : "2px solid #fff"}`,
                                            borderRight: `${darck ? "" : "2px solid #fff"}`,
                                            borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                            borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                            borderRadius: "5px",
                                          }}>
                      <Row form>
                        <Col>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Identifiant unique"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="identifiant"
                              required
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Autre Impots"
                              type="number"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="impot"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* ////////////////////////////////////////////////// */}

                      {/* <Row form>
                        <Col>
                          <FormGroup>
                            <TextField
                              className="card add-input"
                              id="standard-basic"
                              label="Taux Fodec"
                              margin="dense"
                              type="number"
                              variant="outlined"
                              fullWidth
                              name="tauxfodec"
                              defaultValue={this.state.fod}
                            />
                          </FormGroup>
                        </Col>
                      </Row> */}

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col>
                          <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Compte comptable"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="compte"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col>
                          <FormGroup>
                            <Grid
                              item
                              style={{ marginTop: "12px", textAlign: "center" }}
                            >
                              <Checkbox
                                name="timbre"
                                color="primary"
                                checked={this.state.timbre}
                                onChange={this.handleChange("timbre")}
                                value={this.state.timbre}
                              />
                               <span  style={{  color: `${darck ? "#020F64" : "#fff"}`}} > Timbre Fiscale </span>  
                            </Grid>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col>
                          <FormGroup>
                            <Grid
                              item
                              style={{ marginTop: "12px", textAlign: "center" }}
                            >
                              <Checkbox
                                name="fod"
                                color="primary"
                                checked={this.state.fod}
                                onChange={this.handleChange("fod")}
                                value={this.state.fod}
                              />
                            <span  style={{  color: `${darck ? "#020F64" : "#fff"}`}} >   Taux Fodec 1% </span>
                            </Grid>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FormGroup>
                            <Grid
                              item
                              style={{ marginTop: "12px", textAlign: "center" }}
                            >
                              <Checkbox
                                name="soustraitant"
                                color="primary"
                                checked={this.state.soustraitant}
                                onChange={this.handleChange("soustraitant")}
                                value={this.state.soustraitant}
                              />
                            <span  style={{  color: `${darck ? "#020F64" : "#fff"}`}} >   Sous Traitant </span>
                            </Grid>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Button color="primary" style={{ width: "100%" }}>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    GetCodfrs: (a, b, c, d, e) => dispatch(GetCodfrs(a, b, c, d, e)),
    SelectFournisseur: (a, b, c, d, e) => {dispatch(SelectFournisseur(a, b, c, d, e))},
  };
}

function mapStateToProps(state) {
  return {
    codfrss: state.codfrss,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddFmodal));
