import React, { Component } from "react";
import { connect } from "react-redux";
// import "../Commande-client-devis/Styling.css";
import { getClientHeader } from "../../redux/actions/ClientActions";
// import "./ClientsFournisseurs.scss";
import { withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import "../../App.css";
import { SelectTest } from "../../redux/actions/TestAction";
import AddIcon from "@material-ui/icons/Add";
import InputBase from "@material-ui/core/InputBase";
import { Row, Col } from "reactstrap";
import AddMVTCAISModal from "./add-modal/AddMVTCAISModal";
import GetRegBLsCAIByID from "./GetRegBLsCAIByID";
import { Redirect } from "react-router-dom";
import { SelectClient } from "../../redux/actions/GetClients";
import {SelectCaisse} from "../../redux/actions/GetCaisse";
import SearchIcon from "@material-ui/icons/Search";
import HomeIcon from "@material-ui/icons/Home";
import PersonIcon from "@material-ui/icons/Person";
import { Link } from "react-router-dom";
import "./style.css";
import {
  Breadcrumbs,
  Paper,
  TableSortLabel,
  Button,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { SelectNomenclatureCS } from "../../redux/actions/GetNomeCS";

import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';
 
import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ListAltIcon from '@material-ui/icons/ListAlt';
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import translate from "../../i18n/translate";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import moment from 'moment';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];
const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class RegBLS extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      addModalShow: false,
      GetByIdModalShow: false,
      loggedIn,
      defaultdate: date,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      order: "asc",
      orderBy: "",
      raisoc: "",
      Details:[],
    };
  }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  toggle = () => this.setState({ modal: !this.state.modal });

  componentDidMount() {
    console.log("detaille",this.state.Details)
    this.props.getClientHeader();
    
    this.props.SelectCaisse(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database);

      this.props.SelectNomenclatureCS(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
    document.title = "Fiche Client";
  }

  rechercheHandler = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/caisse?cs=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, rechercheclicked: true }));
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  getTotalsoldfac = () => {
    let totalsoldfac = 0;
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
       
        totalsoldfac+= parseFloat(Number(article.soldfac));
    })
  }
  else{
    this.props.clients.clients.forEach((article) => {
      totalsoldfac+= parseFloat(Number(article.soldfac));
    })
  };
    return totalsoldfac.toFixed(3); // arrondi à 4 chiffres après la virgule
  };





  render() {


    const rowCount = stableSort(
      this.props.Caisses.Caisses,
      getComparator(this.state.order, this.state.orderBy)
    ).length;
    
    console.log(`Nombre de lignes affichées clients: ${rowCount}`);
    const { darck } = this.props;
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };
    let addModalClose1 = () => this.setState({ addModalShow: false });
    let GetByIdModalClose = () => this.setState({ GetByIdModalShow: false });

    const {

    
    numcais,
    lib,
   chdec
    } = this.state;
    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }

    return (
      <div>
        <main className="gestion-des-clients">
          <Breadcrumbs aria-label="breadcrumb" fontSize="small">
            <Link to={"/"}>
              <StyledBreadcrumb
                component="a"
                label="Vente"
                icon={
                  <HomeIcon
                    style={{ color: `${darck ? "" : "#fff"}` }}
                    fontSize="small"
                  />
                }
                style={{
                  color: `${darck ? "" : "#fff"}`,
                  backgroundColor: `${darck ? "" : "#172431"}`,
                  border: "2px solid #fff",
                }}
              />
            </Link>
            {/* <div>/</div> */}
            <StyledBreadcrumb
              component="a"
              color="Primary"
              style={{
                color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: `${darck ? "" : "2px solid #fff"}`,
              }}
              // href="/fiche-client"
              label="Réglements sur BLS"
              icon={<PersonIcon fontSize="small" />}
            />
            
          {/* <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            onClick={this.exportToExcel}
            // href="/fiche-article"
            label="Exporter vers Excel"
            icon={<CloudUploadOutlined fontSize="small" />}
          /> */}
          </Breadcrumbs>

          <br />
          <Paper
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // elevation={0.5}
            elevation={2}
          >
            <Row style={{ flexWrap: "nowrap" }}>
              {/* <InputGroupText>
              <SearchIcon className="search-icon" color="primary" />
            </InputGroupText> */}

              <Col sm={10}>
                <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
                  <SearchIcon
                    style={{ color: `${darck ? "" : "#fff"}` }}
                    color="primary"
                  />

                  &nbsp;&nbsp;
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{
                      color: `${darck ? "" : "#fff"}`,
                      backgroundColor: `${darck ? "" : "#172431"}`,
                    }}
                  />
                </div>
              </Col>
              {/* <Col sm={2} style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  color="primary"
                  onClick={() => this.setState({ addModalShow: true })}
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                >
                  <AddIcon
                    style={{
                      color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}`,
                    }}
                  />
                  Rapport de caisse
                </Button>
              
              </Col> */}
            </Row>
          </Paper>

          <br />

          <div className="tabcRap">
            <table striped>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechercheclicked
                    ? this.state.rechs
                    : this.props.css.css,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      paddingRight: "20px",
                      height: "100%",
                    }}
                    key={test.numopr}
                    onClick={() => { 
                      fetch(
                        `https://www.24-crm.tn/webservice/api/caisse?codcss=${test.code}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                        { method: "GET",
                              headers: { Accept: "application/json", "Content-Type": "application/json" },
                            }
                          )
                          .then((response) => response.json())
                          .then((data) => { this.setState({ Details: data }); });
                      this.setState({
                        GetByIdModalShow: true,
                       
                        numcais: test.code,
                       
                        lib:test.lib,
                       chdec:test.chdec
                      });
                    }}
                  >
                    <td>
                      <span style={{ color: "#DF5F10", paddingRight: "25px" }}>
                        {test.code}
                      </span>
                    </td>

                    <td>
                      <span
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingRight: "10px",
                        }}
                      >
                        {test.lib}
                      </span>
                    </td>
                    <td>
                      <span
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingRight: "10px",
                        }}
                      >
                        {test.chdec}
                      </span>
                    </td>
                  
                  </tr>
                ))}

              </tbody>
            </table>
          </div>


   
   <br />

      
      <AddMVTCAISModal
            allCodeClient={this.props.Caisses.Caisses}
            show={this.state.addModalShow}
            onHide={addModalClose1}
          />

          <GetRegBLsCAIByID
            show={this.state.GetByIdModalShow}
            onHide={GetByIdModalClose}
        
            lib={lib}
            numcais={numcais}
            chdec={chdec}
         
            Details={this.state.Details}
          />
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    DataTables: state.DataTablesReducer,
    tests: state.tests,
    SearchingResult: state.SearchingReducer,
    clients: state.clients,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
    Auth: state.Auth,
    Caisses:state.Caisses,
    css:state.css

  };
};
const mapDispatchToProps = (dispatch) => ({
  SelectCaisse: (a, b, c, d, e) => {
    dispatch(SelectCaisse(a, b, c, d, e));
  },
  getClientHeader: () => {
    dispatch(getClientHeader());
  },
  SelectTest: () => {
    dispatch(SelectTest());
  },
  SelectClient: (a, b, c, d, e) => {
    dispatch(SelectClient(a, b, c, d, e));
  },
  SelectNomenclatureCS: (a, b, c, d, e) =>
  dispatch(SelectNomenclatureCS(a, b, c, d, e)),

  SelectCaisse: (a, b, c, d, e) => {
    dispatch(SelectCaisse(a, b, c, d, e));
  },
});
const ConnectedRegBLS = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegBLS);
export default ConnectedRegBLS;

// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "numopr",
    numeric: false,
    disablePadding: true,
    label: "Code",
  },

  {
    id: "libelle",
    numeric: true,
    disablePadding: false,
    label: "Libellé",
  },
  {
    id: "numcais",
    numeric: true,
    disablePadding: false,
    label: "Divers",
  },

  // {
  //   id: "montant",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Montant",
  // },

  // { id: "modreg", numeric: false, disablePadding: false, label: "Mode" },

  // {
  //   id: "depense",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Dépense",
  // },
  // {
  //   id: "VENDEUR",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Utilisateur",
  // },
];

function EnhancedTableHead(props) {
  const { classes, darck, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
    },
  },
}))(Chip);
