const initialState = {
    codBords: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "BordCode":
        return {
          ...state,
          codBords: action.payload,
        };
     
      default:
        return state;
    }
  }
  
  