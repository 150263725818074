import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {
  TextField,
  Checkbox,
  Grid,
  Snackbar,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { GetCodcli } from "../../redux/actions/GetCodcli";
import { SelectClient } from "../../redux/actions/GetClients";
import { SelectNomenclatureBQ } from "../../redux/actions/GetNomeBQ";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class AddElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: 0,
      timbre: 0,
      soustraitant: 0,
      snackbaropen: false,
      snackbarmsg: ",",
      codbqvers:"",
      libbqvers:"",
      
      BANK: [],
      CodeCCb: "",
      CodeCCb:[],

      tablecat: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
      ],

      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],

      categoriefiscale: "",
      attestation: "",
      dateetabli: "",
      datexp: "",
      statutsCodeClient: false,
      code: null,
      lib: null,
      chdec: null,
      categorie: ""
    };
  }

  componentDidMount() {
    this.props.GetCodcli(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureBQ(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

  codeHandler = (event) => {
    this.setState({ code: event.target.value });
  };

  libHandler = (event) => {
    this.setState({ lib: event.target.value });
  };

  chdecHandler = (event) => {
    this.setState({ chdec: event.target.value });
  };

  categorieHandler = (event) => {
    this.setState({ categorie: event.target.value });
  };



  submitHandler = (event) => {
    event.preventDefault();
    if (this.props.catP !== "" && (this.state.code !== null && this.state.code !== "") && (this.state.lib !== null && this.state.lib !== "") && (this.state.chdec !== null && this.state.chdec !== "")) {
      fetch(
        `https://www.24-crm.tn/webservice/api/Nome?cat=${this.props.catP}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.filter((el) => el.code == this.state.code).length > 0) {
            alert("code existant");
          } else {
            fetch(
              `https://www.24-crm.tn/webservice/api/Nome?cat=${this.props.catP}&code=${this.state.code}&lib=${this.state.lib}&chdec=${this.state.chdec}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  this.setState({ snackbaropen: true, snackbarmsg: result });
                  window.location.reload();
                },
                (error) => {
                  this.setState({ snackbaropen: true, snackbarmsg: "failed" });
                }
              );
          }
        });
    } else {
      alert("il faut donner le code et le code libellé et valeur");
    }
  };

  render() {
    const { darck, classes, allCodeClient  } = this.props;
    const { statutsCodeClient } = this.state;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ padding: "0px" }}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Nomenclature : {this.state.categorie} </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.submitHandler}
              className={`${darck ? "" : classes.root}`}
            >
              <Row form>
                <Col sm={12}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}
                    
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Categorie"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="categorie"
                             disabled
                             value= {this.props.catP} 
                              
                     //    value={this.state.code === null ? this.props.codeP : this.state.code}
                              onChange={this.categorieHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Code"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="code"
                              value={this.state.code === null ? this.props.codeP : this.state.code}
                              onChange={this.codeHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Libélle"
                              margin="dense"
                              variant="outlined"
                            //   type="email"
                              fullWidth
                              name="lib"
                              value={this.state.lib === null ? this.props.libP : this.state.lib}
                              onChange={this.libHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>


{/* ---------------------------------------------------------     CCB  ------------------------------------------------- */}
                    
{ this.props.catP=== "CCB" ? 
                      <Row form>      
                      <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              fullWidth 
                              className="ajouter-client-input"
                              //options={this.props.bqs.bqs}
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqvers: getOptionLabel.code,
                                      libbqvers: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqvers: "",
                                      libbqvers: "",
                                    });
                         if ( getOptionLabel !== null ) {
      fetch(
      `https://www.24-crm.tn/webservice/api/BordereauVersements?CodeBqCcb=${getOptionLabel.code}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      )
        .then((res) => res.json())
        .then((data) => this.setState({ CodeCCb: data,}));} 
     
       }}
              renderInput={(params) => (
              <TextField
                                  label="Bank"
                                  margin="dense"
                                  fullWidth              
                                  name="Bank"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,  }, }}  /> )} />


 <FormHelperText style={{color: !darck ? "#fff" : null }}> {this.state.codbqvers} </FormHelperText></FormGroup> </Col> 

                  
              
                  <lable>
                  {this.state.chdec}
                  </lable>
                      <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Code Bank"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.codbqvers : this.state.codbqvers}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null, }}  InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  /> </FormGroup>   </Col>  </Row>  :"" } 





{/* ---------------------------------------------------------     BQ  ------------------------------------------------- */}
{ this.props.catP=== "BQ" ? 
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="compte comptable"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  /> 
                              
                              
                         
                         
                            <TextField
                              id="standard-basic"
                              label="Plafond d'escompte"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  />                             
                              
                              
                              
                              
                              </FormGroup>   </Col> </Row>  :"" } 


{/* ---------------------------------------------------------     TL  ------------------------------------------------- */}
{ this.props.catP=== "TL" ? 
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Type"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  /> </FormGroup>   </Col> </Row>  :"" }                               

{/* ---------------------------------------------------------     MD  ------------------------------------------------- */}
{ this.props.catP=== "MD" ? 
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Marque"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  /> </FormGroup>   </Col> </Row>  :"" }                               


{/* ---------------------------------------------------------     SC  ------------------------------------------------- */}
{ this.props.catP=== "FA" ? 
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Catégorie famille article"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  /> </FormGroup>   </Col> 
                              
                              
                           
                              <Col sm={12}>
                                <FormGroup style={{ overflow: "hidden" }}>
                                  <TextField
                                    id="standard-basic"
                                    label="Commission (%)"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    name="chdec"
                                    value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                                    onChange={this.chdecHandler}
                                    InputProps={{
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  /> </FormGroup>   </Col> </Row>                               
                               :"" } 
{/* ---------------------------------------------------------     SF  ------------------------------------------------- */}
{ this.props.catP=== "SF" ? 
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Famille"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  /> </FormGroup>   </Col> </Row>  :"" }                               
                              
                              
{/* ---------------------------------------------------------     UM  ------------------------------------------------- */}
                    
{ this.props.catP=== "UM" ? 
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="Nombre des Décimales"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  />           
                              
                              
                              </FormGroup>   </Col> </Row>  :"" } 


{/* ---------------------------------------------------------     MR  ------------------------------------------------- */}
                
{ this.props.catP=== "MR" ? 
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              id="standard-basic"
                              label="n°Ordre"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }}  />           
                              




                              <div>
      <label>
        <input
          type="radio"
          name="radioOption"    
        />
      N'est pas une Opération d'encaissement (R.S.etc...)
      </label>

      <label>
        <input
          type="radio"
          name="radioOption"
        />
       Autoriser Rendu
      </label>

      <label>
        <input
          type="radio"
          name="radioOption"      
        />
        Retenue TVA
      </label>
    </div>
                          
    <div>
      <input
        type="text"
        placeholder="Taux de reduction"
        InputLabelProps={{ style: {  color: !darck ? "#fff" : null, },    }} 
      />
    </div>



 </FormGroup>   </Col> </Row>  :"" } 








{/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Button
                          type="submit"
                          color="primary"
                          style={{ width: "100%" }}
                          >
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    GetCodcli: (a, b, c, d, e) => dispatch(GetCodcli(a, b, c, d, e)),
    SelectNomenclatureBQ: (a, b, c, d, e) =>
    dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => {
      dispatch(SelectClient(a, b, c, d, e));
    },
  };
}

function mapStateToProps(state) {
  return {
    bqs: state.bqs,
    codclis: state.codclis,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddElement));
