const intialState = [];
const SetAffectationRegCliReducer = (state = intialState, action) => {
  switch (action.type) {
    case "AFF_REG_CLI":
      return action.payload;

    default:
      return state;
  }
};
export default SetAffectationRegCliReducer;
