import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import "./fac.scss";
import { FormGroup, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { SelectArticle } from "../../redux/actions/GetArticles";
import { TextField, Fab } from "@material-ui/core";
import "../styling/Styling.scss";
import Center from "react-center";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// import EditArticleModal from "./EditArticleModal";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import EditIcon from "@material-ui/icons/Edit";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
// import ADDNumSerie from "./FacSerialNumber";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const roundTo = require("round-to");

class LigBEArticle extends Component {
  constructor(props) {
    super(props);

    this.handleChangeModal = this.handleChangeModal.bind(this);

    this.state = {
      codearticle: "",
      qte: "1",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      addModalShow: false,
      // addModalClose1: false,
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      totalhtnet: 0,
      remiseglobal: 0,
      mnttva: "",
      netapayer: 0,
      snackbaropen: false,
      snackbarfail: false,
      editModalShow: false,
      qtte: 0,
      btnEnabled: false,
      gilad: true,
      rechs: [],
      codtva: "",

      snackbarmsg: ",",
      netnetapayer: 0,
      totalfod: 0,
      clicked: false,
      fod: 0,
      mntfod: 0,
      stkfin: 0,
      changeButton: false,
    };
  }

  componentDidMount() {
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }
  handleChangeModal = (event) => {
    if (event.key === "Enter") {
      this.setState({ addModalShow: true });
    }
  };

  // articleHandlerChange = (event) => {
  //   fetch(
  //     `http://www.24-crm.com/webservice/api/ARTICLEs?codartt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
  //   )
  //     .then((response) => response.json())
  //     .then((data) => this.setState({ rechs: data, clicked: true }));
  // };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarFailClose = (event) => {
    this.setState({ snackbarfail: false });
  };

  puttcHandler = (event) => {
    this.setState({
      puttcnet: event.target.value,
      puht: (
        event.target.value /
        Number((100 - this.state.remisea) / 100) /
        (1 + this.state.tva / 100)
      ).toFixed(3),
      totalht:
        (event.target.value /
          ((100 - this.state.remisea) / 100) /
          (1 + this.state.tva / 100)) *
        ((100 - this.state.remisea) / 100),
      mnttva:
        (
          event.target.value /
          Number((100 - this.state.remisea) / 100) /
          (1 + this.state.tva / 100)
        ).toFixed(3) *
        ((100 - this.state.remisea) / 100) *
        (this.state.tva / 100),
    });
  };

  tvaChange = (event) => {
    this.setState({
      tva: event.target.value,
      puttcnet:
        Number(this.state.puht) +
        Number(this.state.puht * (this.state.fod / 100)) +
        ((Number(this.state.puht) +
          Number(this.state.puht * (this.state.fod / 100))) *
          event.target.value) /
          100,
      mnttva:
        ((Number(this.state.puht) +
          Number(this.state.puht * (this.state.fod / 100))) *
          event.target.value) /
        100,
    });
    event.target.value == 19
      ? this.setState({ codtva: "3" })
      : event.target.value == 13
      ? this.setState({ codtva: "2" })
      : event.target.value == 7
      ? this.setState({ codtva: "1" })
      : this.setState({ codtva: "0" });
  };

  fodChange = () => (event) => {
    this.setState({
      fod: event.target.checked,
      totalht:
        Number(this.state.puht) +
        Number(this.state.puht * (event.target.checked / 100)),
      puttcnet:
        Number(this.state.puht) +
        Number(this.state.puht * (event.target.checked / 100)) +
        ((Number(this.state.puht) +
          Number(this.state.puht * (event.target.checked / 100))) *
          this.state.tva) /
          100,
      mnttva:
        ((Number(this.state.puht) +
          Number(this.state.puht * (event.target.checked / 100))) *
          this.state.tva) /
        100,
      mntfod: Number(this.state.puht * (event.target.checked / 100)),
    });
  };

  puhtHandler = (event) => {
    this.setState({
      puht: event.target.value,
      totalht:
        Number(event.target.value) +
        Number(event.target.value * (this.state.fod / 100)),
      puttcnet:
        Number(event.target.value) +
        Number(event.target.value * (this.state.fod / 100)) +
        ((Number(event.target.value) +
          Number(event.target.value * (this.state.fod / 100))) *
          this.state.tva) /
          100,
      mnttva:
        ((Number(event.target.value) +
          Number(event.target.value * (this.state.fod / 100))) *
          this.state.tva) /
        100,
      mntfod: Number(event.target.value * (this.state.fod / 100)),
    });
  };

  onCellChange = (event) => {
    this.setState({ codearticle: event.target.value });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  deleteRow = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumHtBrut = tab && tab.reduce((a, v) => a + Number(v.puht), 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a + (Number(v.puht) + Number(v.puht * (v.fod / 100))) * (v.tva / 100),
        0
      );
    const SumFodec =
      tab && tab.reduce((a, v) => a + Number(v.puht * (v.fod / 100)), 0);
    const SumHtNet = tab && tab.reduce((a, v) => a + v.totalht, 0);
    const SumNetAPayer =
      tab &&
      tab.reduce((a, v) => a + (v.totalht + v.totalht * (v.tva / 100)), 0);
    const SumNetNetAPayer = SumNetAPayer + parseFloat(this.props.valtimbre);

    this.setState({
      tab,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalfod: SumFodec,
      totalhtnet: SumHtNet,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      netnetapayer: SumNetNetAPayer,
    });
  };

  deleteRowMod = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumHtBrut = tab && tab.reduce((a, v) => a + Number(v.puht), 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a + (Number(v.puht) + Number(v.puht * (v.fod / 100))) * (v.tva / 100),
        0
      );
    const SumFodec =
      tab && tab.reduce((a, v) => a + Number(v.puht * (v.fod / 100)), 0);
    const SumHtNet = tab && tab.reduce((a, v) => a + v.totalht, 0);
    const SumNetAPayer =
      tab &&
      tab.reduce((a, v) => a + (v.totalht + v.totalht * (v.tva / 100)), 0);
    const SumNetNetAPayer = SumNetAPayer + parseFloat(this.props.valtimbre);

    this.setState({
      tab,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalfod: SumFodec,
      totalhtnet: SumHtNet,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      netnetapayer: SumNetNetAPayer,
    });
  };

  submitHandlers = (event) => {
    event.preventDefault();
    const newtab = this.state.tab.concat({
      puht: this.state.puht,
      codtva: this.state.codtva,
      fod: this.state.fod,
      mntfod: this.state.mntfod,
      tva: this.state.tva,
      puttcnet: this.state.puttcnet,
      totalht: this.state.totalht,
      mnttva: this.state.mnttva,
    });

    const SumHtBrut = newtab && newtab.reduce((a, v) => a + Number(v.puht), 0);
    const SumTva =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a + (Number(v.puht) + Number(v.puht * (v.fod / 100))) * (v.tva / 100),
        0
      );
    const SumFodec =
      newtab && newtab.reduce((a, v) => a + Number(v.puht * (v.fod / 100)), 0);
    const SumHtNet = newtab && newtab.reduce((a, v) => a + v.totalht, 0);
    const SumNetAPayer =
      newtab &&
      newtab.reduce((a, v) => a + (v.totalht + v.totalht * (v.tva / 100)), 0);
    const SumNetNetAPayer = SumNetAPayer + parseFloat(this.props.valtimbre);

    this.setState({
      tab: newtab,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalfod: SumFodec,
      totalhtnet: SumHtNet,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      netnetapayer: SumNetNetAPayer,
    });

    this.setState({
      puht: "",
      fod: 0,
      mntfod: 0,
      tva: "",
      puttcnet: 0,
      mnttva: "",
    });
  };

  modifiermodification = (event) => {
    // event.preventDefault();

    const newtab = this.state.tab.concat({
      puht: this.state.puht,
      codtva: this.state.codtva,
      fod: this.state.fod,
      mntfod: this.state.mntfod,
      tva: this.state.tva,
      puttcnet: this.state.puttcnet,
      totalht: this.state.totalht,
      mnttva: this.state.mnttva,
    });
    const SumHtBrut = newtab && newtab.reduce((a, v) => a + Number(v.puht), 0);
    const SumTva =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a + (Number(v.puht) + Number(v.puht * (v.fod / 100))) * (v.tva / 100),
        0
      );
    const SumFodec =
      newtab && newtab.reduce((a, v) => a + Number(v.puht * (v.fod / 100)), 0);
    const SumHtNet = newtab && newtab.reduce((a, v) => a + v.totalht, 0);
    const SumNetAPayer =
      newtab &&
      newtab.reduce((a, v) => a + (v.totalht + v.totalht * (v.tva / 100)), 0);
    const SumNetNetAPayer = SumNetAPayer + parseFloat(this.props.valtimbre);

    this.setState({
      tab: newtab,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalfod: SumFodec,
      totalhtnet: SumHtNet,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      netnetapayer: SumNetNetAPayer,
      changeButton: false,
    });

    this.setState({
      puht: "",
      fod: 0,
      mntfod: 0,
      tva: "",
      puttcnet: 0,
      mnttva: "",
    });
  };

  render() {
    let editModalClose = () => this.setState({ editModalShow: false });
    let addModalClose1 = () => this.setState({ addModalShow: false });

    const { darck, classes } = this.props;

    // let getModalClose = () => this.setState({ ADDNumSerie: false });

    // console.log(
    //   this.state.totalqte,
    //   `remise article =${this.state.sumremisearticle}`
    // );

    return (
      <div className="container">
        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarClose}
            severity="success"
          >
            Article ajouté
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.snackbarfail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarFailClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarFailClose}
            severity="error"
          >
            Article supprimé
          </Alert>
        </Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Articles</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <Row>
              <Col style={{ margin: "0" }}>
                <Card>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    <form
                      autocomplete="off"
                      onSubmit={this.submitHandlers}
                      className={`${darck ? "" : classes.root}`}
                    >
                      <Row form>
                        {this.props.fod ? (
                          <Col sm={3} style={{ textDecoration: "underline" }}>
                            <FormGroup>
                              <Grid
                                item
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <Checkbox
                                  name="fod"
                                  color="primary"
                                  checked={this.state.fod}
                                  onChange={this.fodChange("fod")}
                                  value={this.state.fod}
                                />
                                <span
                                  style={{ color: `${darck ? "" : "#fff"}` }}
                                >
                                  Fodec
                                </span>
                              </Grid>
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={3}>
                            <FormGroup>
                              <Grid
                                item
                                style={{
                                  textAlign: "center",
                                  marginTop: "10px",
                                }}
                              >
                                <Checkbox
                                  name="fod"
                                  color="primary"
                                  checked={this.state.fod}
                                  onChange={this.handleChange("fod")}
                                  value={this.state.fod}
                                  disabled
                                />
                                <span
                                  style={{ color: `${darck ? "" : "#fff"}` }}
                                >
                                  Taux Fodec 1%
                                </span>
                              </Grid>
                            </FormGroup>
                          </Col>
                        )}

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="PU HT"
                              value={this.state.puht}
                              fullWidth
                              name="puht"
                              onChange={this.puhtHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Montant Fodec"
                              value={Number(this.state.mntfod).toFixed(3)}
                              fullWidth
                              disabled
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {this.state.puht ? (
                          <Col sm={2}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="TVA"
                                fullWidth
                                value={this.state.tva}
                                name="tva"
                                onChange={this.tvaChange}
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={2}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="TVA"
                                fullWidth
                                name="tva"
                                value=""
                                disabled
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="PU TTC Net"
                              value={this.state.puttcnet}
                              fullWidth
                              onChange={this.puttcHandler}
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {this.state.changeButton && this.state.puht ? (
                        <Center>
                          <Button
                            style={{ width: "320px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.modifiermodification();
                            }}
                          >
                            Enregistrer les modifications
                          </Button>
                        </Center>
                      ) : this.state.changeButton && this.state.puht ? (
                        <Center>
                          <Button
                            style={{ width: "320px" }}
                            variant="contained"
                            color="primary"
                            disabled
                          >
                            Enregistrer les modifications
                          </Button>
                        </Center>
                      ) : this.state.puht === "" ? (
                        <Center>
                          <Button
                            disabled
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      ) : (
                        <Center>
                          <Button
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      )}
                    </form>
                  </Card.Body>
                </Card>

                <Card style={{ marginTop: "10px" }}>
                  <Card.Body
                    style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}
                  >
                    <div className="tabbe20">
                      <table style={{ marginTop: "10px" }}>
                        <thead
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          <tr>
                            <th>HT</th>
                            <th>Fodec</th>
                            <th>TVA</th>
                            <th>TTC</th>
                            <th>Montant TVA</th>
                            <th></th>
                            
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            overflowY: "auto",
                            display: "block",
                            maxHeight: "10em",
                            textAlign: "center",
                            color: `${darck ? "" : "#fff"}`,
                          }}
                        >
                          {this.state.tab.map((t, i) => (
                            <tr key={i}>
                              {/* <td>
                                <span>{t.codearticle}</span>
                              </td>
                              <td style={{ fontSize: "12px", width: "37%" }}>
                                <span> {t.des} </span>
                              </td>
                              <td>
                                {" "}
                                <span> {t.qte}</span>
                              </td> */}
                              <td>
                                {" "}
                                <span> {Number(t.puht).toFixed(3)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.mntfod).toFixed(3)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.tva).toFixed(3)}</span>
                              </td>

                              <td>
                                {" "}
                                <span> {Number(t.puttcnet).toFixed(3)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.mnttva).toFixed(3)}</span>
                              </td>
                              <td>
                                <Tooltip title="Modifier cet article">
                                  <Fab size="small">
                                    <EditIcon
                                      style={{}}
                                      onClick={() => {
                                        this.setState({
                                          puht: t.puht,
                                          tva: Number(t.tva),
                                          fod: t.fod,
                                          mntfod: t.mntfod,
                                          qte: t.qte,
                                          puttcnet: t.puttcnet,
                                          codtvaa: t.codtvaa,
                                          changeButton: true,
                                        });
                                        this.deleteRowMod(i);
                                      }}
                                    />
                                  </Fab>
                                </Tooltip>
                               
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Tooltip title="Supprimer cet article">
                                  <Fab size="small">
                                    <DeleteIcon
                                      style={{}}
                                      onClick={() => this.deleteRow(i)}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            {/* {
              // !this.state.btnEnabled ? (
              //   <Button disabled variant="contained" style={{ width: "30%" }}>
              //     Enregistrer
              //   </Button>
              // ) : (
              this.state.tab.map((t, i) => ( */}
            <Button
              variant="contained"
              style={{
                backgroundColor: "rgb(0, 8, 126)",
                color: "white",
                width: "30%",
              }}
              onClick={() => {
                this.props.submitHandler(
                  this.state.tab,
                  this.state.totalqte,
                  this.state.sumremisearticle,
                  this.state.totalhtbrut,
                  this.state.totaltva,
                  this.state.totalhtnet,
                  this.state.remiseglobal,
                  this.state.netapayer,
                  this.state.btnEnabled,
                  this.state.netnetapayer,
                  this.state.totalfod
                );
                // this.enregistrer();
                this.props.onHide();
              }}
            >
              Enregistrer
            </Button>
            {/* <Col sm={2} style={{ marginTop: "-15px" }}>
              <div id="" style={{ textAlign: "center" }}>
                <button
                  className="icon-btn add-btn"
                  onClick={() => this.setState({ addModalShow: true })}
                >
                  <div className="add-icon"></div>
                  <div className="btn-txt">Ajouter BE</div>
                </button>
              </div>
            </Col> */}
            {/* ))
              // )
            } */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    articles: state.articles,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(LigBEArticle));
