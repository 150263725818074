import React, { Component } from "react";
import { Button } from "react-bootstrap";
import "./HomePage.scss";
import { connect } from "react-redux";
import { SelectTopclient } from "../../redux/actions/Top5";
import { SelectTopFrs } from "../../redux/actions/Top5Frs";
import CircleChart from "./CircleChart";
import TopArticle from "./TopArticle";
import translate from "../../i18n/translate";

class TabTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: false,
    };
  }

  componentDidMount() {}

  render() {
    const { darck } = this.props;
    return (
      <div>
        <div>
          <Button
            onClick={() => this.setState({ change: false })}
            variant="light"
            className={darck ? "" : "buttonDarck"}
          >
            {translate("Articles")}
          </Button>{" "}
          &nbsp;
          <Button
            onClick={() => this.setState({ change: true })}
            variant="light"
            className={darck ? "" : "buttonDarck"}
          >
            {translate("Clients")}
          </Button>
        </div>
        <div>
          {this.state.change ? (
            <div>
              <p className={darck ? "p1" : "p1Darck"} style={{ margin: "10px" }}>
                {translate("% Des clients par région")}
              </p>
              <CircleChart />
            </div>
          ) : (
            <div>
              <p className={darck ? "p1" : "p1Darck"} style={{ margin: "10px" }}>
                {translate("% Des articles les plus vendus")}
              </p>

              <TopArticle />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectTopclient: (a, b, c, d, e) =>
      dispatch(SelectTopclient(a, b, c, d, e)),
    SelectTopFrs: (a, b, c, d, e) => dispatch(SelectTopFrs(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    tops: state.tops,
    topfrss: state.topfrss,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabTwo);
