import React, { Component } from "react";
import {
  Modal,
  Card,
  Row,
  FormGroup,
  Col,
  Alert,
  Accordion,
} from "react-bootstrap";
import "../styling/Styles.css";
// import "../commande-client-devis/ss.scss";
import "./be.scss";
import axios from 'axios'; 
import {
  TextField,
  Paper,
  Button,
  Snackbar,
  IconButton,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";

import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { SelectBECod } from "../../redux/actions/GetBECode";
import LigBEArticle from "./LigBEArticle";
import { SelectBE } from "../../redux/actions/GetBE";
import { SelectFacFrsCod } from "../../redux/actions/GetFacFrsCod";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
import { SelectBFCod } from "../../redux/actions/GetBFCod";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import "./Responsivebe.css";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

const roundTo = require("round-to");
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddBEModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      numFac: 0,
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      numnum: "",
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      NETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,
      rechs: [],
      rechsCli: [],
      mnttva: "",
      codetva: "",
      type: "",
      typach: [
        { code: "L", label: "L" },
        { code: "F", label: "F" },
      ],
      nomenclature: [],
      origin: "FR",

      nextsubmit: false,

      snackbaropen: false,
      snackbarmsg: ",",
      codf: "",

      valtimbre: 0,
      netnetapayer: 0,
      NETNETAPAYER: 0,
      catfiscal: "0",
      tauxfod: 0,
      username: username,
      clicked: false,
      errorMsg: "",
      GMM: "",
      magasin: [],
      MG: "",
      ordre: "",
    };
  }

  componentDidMount() {
    this.props.SelectBECod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectBFcod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFacFrsCod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );

    fetch(
      `https://www.24-crm.tn/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });
  }

  typachChange = (event) => {
    this.setState({ type: event.target.value });
    this.props.codfacfrss.codfacfrss.map((t) =>
      this.setState({ codf: t.valeur })
    );
  };
  // OriginChange = (event) => {
  //   this.setState({ type: event.target.value });
  //   this.props.codfacfrss.codfacfrss.map((t) =>
  //     this.setState({ codf: t.valeur })
  //   );
  // };

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    netnetapayer
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      netnetapayer: netnetapayer,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      NETNETAPAYER:
        ((100 - event.target.value) / 100) * this.state.netapayer +
        Number(this.state.valtimbre),
    });
  };

  fournisseurHandlerChange = (event) => {
    this.setState({ codcli: event.target.value });
    fetch(
      `https://www.24-crm.tn/webservice/api/fournisseurs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechsCli: data, clicked: true }));
  };

  enregistrer = (event) => {
    event.preventDefault();
    this.setState({
      datfac: this.state.defaultdate,
      codcli: event.target.codcli.value,
      raisoc: event.target.raissoc.value,
      codbe: event.target.codbe.value,
      remise: event.target.remise.value,
      numfacfrs: event.target.numfacfrs.value,
    });
    // if (this.state.origin == "FR") {
    fetch(
      // `/api/BEREs?numfac=${event.target.codbe.value}&typfac=BE&datfac=${event.target.datfac.value}&codfrs=${event.target.codcli.value}&raisoc=${event.target.raissoc.value}&taurem=${event.target.remise.value}&pj=${event.target.pj.value}&typach=${event.target.typach.value}&Vendeur=${this.state.username}`
      `https://www.24-crm.tn/webservice/api/BEREs?numfac&typfac=BE&datfac=${this.state.defaultdate}&Origin=${this.state.origin}&codfrs=${this.state.codeclient}&raisoc=${this.state.raisonsocial}&pj=${event.target.numfacfrs.value}&taurem=${this.state.remiseg}&catfisc=0&tauxfodec=${this.state.tauxfod}&typach=${event.target.typach.value}&Vendeur=${this.props.Auth.username}&valtimbre=${this.state.valtimbre}&CodDep=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState({ numnum: res });

        this.state.tab.map((k, index) => {
          fetch(
            `https://www.24-crm.tn/webservice/api/LigBEREs/{ID}?numfac=${res}&typfac=BE&numlig=${index}&codart=${k.codearticle}&quantite=${k.qte}&fodart=0&desart=${k.des}&datfac=${this.state.defaultdate}&priuni=${k.puht}&remise=${k.remisea}&montht=${k.totalht}&unite${k.unite}&pmp=${k.pmp}&CB=${k.codbar}&codtva=${k.codtva}&tautva=${k.tva}&formqt=0&coddepp=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                //   this.setState({ snackbaropen: true, snackbarmsg: result });

                console.log(result);
                // window.alert(result);
              },
              (error) => {
                this.setState({ snackbaropen: true, snackbarmsg: "failed" });
              }
            );
        });
      })

      .then((res) => {
        if (this.state.origin == "FR") {
          if (this.state.type == "L") {
            fetch(
              `https://www.24-crm.tn/webservice/api/FacFrs?numfac&typfac=BF&datfac=${this.state.defaultdate}&codfrs=${this.state.codeclient}&raisoc=${this.state.raisoc}&numfacfrs=${this.state.numfacfrs}&pj=${this.state.pj}&valtimbre=0&numbe=${this.state.numnum}&taurem=${this.state.remise}&coddep=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then((result) => {
                fetch(
                  `https://www.24-crm.tn/webservice/api/FOURNISSEURs?Codfrssss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                  {
                    method: "POST",
                    header: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );
                // .then((res) => {
                //   fetch(
                //     `http://www.24-crm.com/webservice/api/Switch?code=BF2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                //     {
                //       method: "PUT",
                //       header: {
                //         Accept: "application/json",
                //         "Content-Type": "application/json",
                //       },
                //     }
                //   );
                //   this.setState({
                //     snackbaropen: true,
                //     snackbarmsg: result,
                //   });
           
                // });
              });
          } else {
            fetch(
              `https://www.24-crm.tn/webservice/api/FacFrs?numfac&typfac=FF&datfac=${this.state.datfac}&codfrs=${this.state.codeclient}&raisoc=${this.state.raisoc}&numfacfrs=${this.state.numfacfrs}&pj=${this.state.pj}&valtimbre=${this.state.valtimbre}&smhtb=${this.state.totalhtbrut}&smremart=${this.state.sumremisearticle}&smtva=${this.state.totaltva}&numbe=${this.state.codbe}&taurem=${this.state.remise}&smhtn=${this.state.totalhtnet}&smremglo=${this.state.remiseglobal}&mntbon=${this.state.netnetapayer}&coddep=${this.state.MG}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then((res) => {
                this.state.tab.map((k) => {
                  fetch(
                    `https://www.24-crm.tn/webservice/api/TVA/?NumFac=${res}&TypFac=FF&CodTva=${k.codtva}&TauxTva=${k.tva}&Assiette=${k.totalht}&Montant=${k.mnttva}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                    {
                      method: "POST",
                      header: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                    }
                  )
                    .then((res) => res.json())
                    .then(
                      (res) => {
                        //   this.setState({ snackbaropen: true, snackbarmsg: result });

                        console.log(res);
                        // window.alert(result);
                      },
                      (error) => {
                        this.setState({
                          snackbaropen: true,
                          snackbarmsg: "failed",
                        });
                      }
                    );
                });
              })
              .then((resultt) => {
                fetch(
                  `https://www.24-crm.tn/webservice/api/FOURNISSEURs?Codfrssss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                  {
                    method: "POST",
                    header: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                );
              })

              // fetch(
              //   `http://www.24-crm.com/webservice/api/Switch?code=FF2&valeur
              // &coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              //   {
              //     method: "PUT",
              //     header: {
              //       Accept: "application/json",
              //       "Content-Type": "application/json",
              //     },
              //   }
              // )
              //   .then((res) => res.json())
              //   // .then(() => window.location.reload())
              .then(
                (result) => {
                  console.log(result);
                },
                (error) => {
                  this.setState({
                    snackbaropen: true,
                    snackbarmsg: "failed",
                  });
                }
              );
          }
        }
      })

      .then(
        (result) => {
          this.props.onHide();
          this.props.SelectBECod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

          // fetch(
          //   `https://www.24-crm.tn/webservice/api/LigBEREs?FACc=${this.state.numnum}&typfacc=BE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          //   {
          //     method: "POST",
          //     header: {
          //       Accept: "application/json",
          //       "Content-Type": "application/json",
          //     },
          //   }
          // );

          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.SelectBECod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      )

      .then((res) => {
        fetch(
          `https://www.24-crm.tn/webservice/api/Switch?code=BE2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
              window.location.reload();
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "failed" });
            }
          )
          .then((res) => {
           
          });
      });

  };

  render() {
    let ligModalClose = () => this.setState({ ligModalShow: false });

    const { rem } = this.state;

    const { darck, classes } = this.props;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    const handleClick = async () => {
      // Utilisation de map pour itérer sur chaque élément de this.state.tab
      this.state.tab.forEach(async (t) => {
        // ID du produit à envoyer dans la requête
        const productId = t.codearticle; // Utilisez directement la valeur, pas besoin des accolades
        // console.log(productId)
      
        // Données du produit à envoyer dans la requête
        const product = {
          quantity: parseInt( t.qte,10), // Utilisez directement la valeur
          // Ajoutez d'autres propriétés du produit si nécessaire
  
        };
      
  
        // adresse pour client 
        try {
        
         //const response = await axios.put(`https://api.my-home-plus.com/api/product/qty/${productId}`, product);
          const response1 = await axios.put(`https://${this.state.URLCLT}/api/product/qty/${productId}`, product);
       
          // Traiter la réponse de l'API ici
        //  console.log(response.data);         
          console.log(response1.data);  
        } catch (error) {
          // Gérer les erreurs de l'API ici
          console.error('Erreur lors de la requête Axios :', error);
        }
        console.log(productId)
        console.log(product)
        console.log(this.state.URLCLT)
      });
     
    };
    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Bon d'Entrée</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.enregistrer}
              className={`${darck ? "" : classes.root}`}
            >
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  {this.state.GMM ? (
                    <Row form>
                      <Col sm={3}>
                        <FormGroup>
                          {/* {this.props.codbes.codbes.map((t) => ( */}
                          <TextField
                            // className="card add-input"
                            id="standard-basic"
                            label="№ BE"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            defaultValue={
                              this.props.codbes.codbes[0] &&
                              this.props.codbes.codbes[0].valeur
                            }
                            fullWidth
                            name="codbe"
                          //  disabled

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                          {/* ))} */}
                        </FormGroup>
                      </Col>
                      {/* <Col sm={5}></Col> */}
                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          name="datfac"
                          defaultValue={this.state.defaultdate}

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </Col>

                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            defaultValue={{
                              code: "FR",
                              lib: "FOURNISSEUR",
                            }}
                            options={this.state.nomenclature}
                            getOptionLabel={(option) =>
                              ` ${option.code} : ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    origin: getOptionLabel.code,
                                    btnEnable: true,
                                  })
                                : this.setState({
                                    origin: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Origine"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/Nome?cat=OR&ordre=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        nomenclature: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            // className="ajouter-client-input"
                            options={this.state.magasin}
                            getOptionLabel={(option) =>
                              ` ${option.code}: ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MG: getOptionLabel.code,
                                  })
                                : this.setState({
                                    MG: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Magasin"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                disabled={params.disabled}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        magasin: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  ) : (
                    <Row form>
                      <Col sm={4}>
                        <FormGroup>
                          {/* {this.props.codbes.codbes.map((t) => ( */}
                          <TextField
                            // className="card add-input"
                            id="standard-basic"
                            label="№ BE"
                  
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            defaultValue={
                              this.props.codbes.codbes[0] &&
                              this.props.codbes.codbes[0].valeur
                            }
                            fullWidth
                            name="codbe"
                          //  disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                          {/* ))} */}
                        </FormGroup>
                      </Col>
                      {/* <Col sm={5}></Col> */}
                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          name="datfac"
                          defaultValue={this.state.defaultdate}
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </Col>

                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="outlined-select-currency"
                            includeInputInList
                            options={this.state.nomenclature}
                            getOptionLabel={(option) =>
                              ` ${option.code} : ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    origin: getOptionLabel.code,
                                    btnEnable: true,
                                  })
                                : this.setState({
                                    origin: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Origine"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"

                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}

                                onFocus={() => {
                                  //liste des origines
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/Nome?cat=OR&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        nomenclature: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  {this.state.origin == "FR" ? (
                    <>
                      <Row form>
                        <Col sm={4} id="switch">
                          <FormGroup style={{ marginTop: "25px" }}>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "gray" : "#fff"}`,
                                  }}
                                >
                                  Raison sociale
                                </Grid>
                                <Grid item>
                                  <ThemeProvider theme={theme}>
                                    <Switch
                                      checked={this.state.gilad}
                                      onChange={this.handleChange("gilad")}
                                      value="gilad"
                                      color="primary"
                                    />
                                  </ThemeProvider>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "#3f51b5" : "#fff"}`,
                                  }}
                                >
                                  Code
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Col>
                        {this.state.gilad ? (
                          <Col sm={3}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                // options={this.state.rechs}
                                options={
                                  this.state.clicked
                                    ? this.state.rechs
                                    : this.props.fournisseurs.fournisseurs
                                }
                                getOptionLabel={(option) => option.codfrs}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        codeclient: getOptionLabel.codfrs,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        tauxfod: getOptionLabel.tauxfodec,
                                        cemail: getOptionLabel.email,
                                        codetva: getOptionLabel.CodeTVA,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        codeclient: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        tauxfod: 0,
                                        showButtonModalPassager: false,
                                        codetva: "",
                                      });
                                  getOptionLabel && getOptionLabel.timbre
                                    ? this.setState({
                                        valtimbre: this.props.valtimbre,
                                      })
                                    : this.setState({ valtimbre: 0 });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Code Fournisseur"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.fournisseurHandlerChange}
                                    name="codcli"
                                    required
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    disabled={params.disabled}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={5}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                // options={this.state.rechs}
                                options={
                                  this.state.clicked
                                    ? this.state.rechs
                                    : this.props.fournisseurs.fournisseurs
                                }
                                getOptionLabel={(option) => option.raisoc}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        codeclient: getOptionLabel.codfrs,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        tauxfod: getOptionLabel.tauxfodec,
                                        cemail: getOptionLabel.email,
                                        codetva: getOptionLabel.CodeTVA,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        codeclient: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        tauxfod: 0,
                                        showButtonModalPassager: false,
                                        codetva: "",
                                      });
                                  getOptionLabel && getOptionLabel.timbre
                                    ? this.setState({
                                        valtimbre: this.props.valtimbre,
                                      })
                                    : this.setState({ valtimbre: 0 });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Raison sociale"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.fournisseurHandlerChange}
                                    name="raisssoc"
                                    required
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    disabled={params.disabled}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        )}
                        {this.state.gilad ? (
                          <Col sm={5}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.raisonsocial}
                                fullWidth
                                name="raisssoc"
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={3}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Code Fournisseur"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.codeclient}
                                fullWidth
                                name="codcli"
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      <TextField
                        id="standard-basic"
                        label="Raison sociale"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        style={{ display: "none" }}
                        value={this.state.raisonsocial}
                        fullWidth
                        name="raissoc"
                        disabled
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Code Fournisseur"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        style={{ display: "none" }}
                        value={this.state.codeclient}
                        fullWidth
                        name="codcli"
                        disabled
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />

                      <Row>
                        <Col sm={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Type"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="typach"
                            onChange={this.typachChange}
                            required
                            
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          >
                            {this.state.typach.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {this.state.errorMsg && (
                            <FormHelperText style={{ color: "red" }}>
                              champ type obligatoire!
                            </FormHelperText>
                          )}
                        </Col>
                        <Col sm={4}>
                          <TextField
                            id="standard-basic"
                            label="Remise Globale %"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="remise"
                            onChange={this.remiseChange}
                            // value={this.state.remiseg}

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>
                        <Col sm={5}>
                          <TextField
                            id="standard-basic"
                            label="Numéro de pièce jointe"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="numfacfrs"
                            value={this.state.numfacfrs}
                            required
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : this.state.origin == "CL" ? (
                    <>
                      <Row form>
                        <Col sm={4} id="switch">
                          <FormGroup style={{ marginTop: "25px" }}>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "gray" : "#fff"}`,
                                  }}
                                >
                                  Raison sociale
                                </Grid>
                                <Grid item>
                                  <ThemeProvider theme={theme}>
                                    <Switch
                                      checked={this.state.gilad}
                                      onChange={this.handleChange("gilad")}
                                      value="gilad"
                                      color="primary"
                                    />
                                  </ThemeProvider>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "#3f51b5" : "#fff"}`,
                                  }}
                                >
                                  Code
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Col>
                        {this.state.gilad ? (
                          <Col sm={3}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                // options={this.state.rechs}
                                options={
                                  this.state.clicked
                                    ? this.state.rechsCli
                                    : this.props.clients.clients
                                }
                                getOptionLabel={(option) => option.codcli}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        codeclient: getOptionLabel.codcli,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        cemail: getOptionLabel.email,
                                        codetva: getOptionLabel.CodeTVA,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        codeclient: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        showButtonModalPassager: false,
                                        codetva: "",
                                      });
                                  getOptionLabel && getOptionLabel.timbre
                                    ? this.setState({
                                        valtimbre: this.props.valtimbre,
                                      })
                                    : this.setState({ valtimbre: 0 });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Code Client"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.clientHandlerChange}
                                    name="codcli"
                                    required
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    disabled={params.disabled}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={5}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                // options={this.props.clients.clients}
                                // options={this.state.rechs}
                                options={
                                  this.state.clicked
                                    ? this.state.rechsCli
                                    : this.props.clients.clients
                                }
                                getOptionLabel={(option) => option.raisoc}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        raisonsocial: getOptionLabel.raisoc,
                                        codeclient: getOptionLabel.codcli,
                                        btnEnable: true,
                                        showTimbre: getOptionLabel.timbre,
                                        cemail: getOptionLabel.email,
                                        codetva: getOptionLabel.CodeTVA,
                                      })
                                    : this.setState({
                                        raisonsocial: "",
                                        codeclient: "",
                                        btnEnable: false,
                                        showTimbre: false,
                                        showButtonModalPassager: false,
                                        codetva: "",
                                      });
                                  getOptionLabel && getOptionLabel.timbre
                                    ? this.setState({
                                        valtimbre: this.props.valtimbre,
                                      })
                                    : this.setState({ valtimbre: 0 });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // {...params}
                                    label="Raison sociale"
                                    // className="card add-input"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    onChange={this.clientHandlerChange}
                                    name="raisssoc"
                                    required
                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    disabled={params.disabled}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        )}
                        {this.state.gilad ? (
                          <Col sm={5}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.raisonsocial}
                                fullWidth
                                name="raisssoc"
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : (
                          <Col sm={3}>
                            <FormGroup>
                              <TextField
                                id="standard-basic"
                                label="Code Client"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                value={this.state.codeclient}
                                fullWidth
                                name="codcli"
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>

                      <TextField
                        id="standard-basic"
                        label="Raison sociale"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        style={{ display: "none" }}
                        value={this.state.raisonsocial}
                        fullWidth
                        name="raissoc"
                        disabled
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Code Client"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        style={{ display: "none" }}
                        value={this.state.codeclient}
                        fullWidth
                        name="codcli"
                        disabled
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />

                      <Row>
                        <Col sm={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Type"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="typach"
                            onChange={this.typachChange}
                            required
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          >
                            {this.state.typach.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {this.state.errorMsg && (
                            <FormHelperText style={{ color: "red" }}>
                              champ type obligatoire!
                            </FormHelperText>
                          )}
                        </Col>
                        <Col sm={4}>
                          <TextField
                            id="standard-basic"
                            label="Remise Globale %"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="remise"
                            onChange={this.remiseChange}
                            // value={this.state.remiseg}

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>
                        <Col sm={5}>
                          <TextField
                            id="standard-basic"
                            label="Numéro de pièce jointe"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="numfacfrs"
                            value={this.state.numfacfrs}
                            required
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row form>
                        <Col sm={4} id="switch">
                          <FormGroup style={{ marginTop: "25px" }}>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "grey" : "#fff"}`,
                                  }}
                                >
                                  Raison sociale
                                </Grid>
                                <Grid item>
                                  <ThemeProvider theme={theme}>
                                    <Switch
                                      checked={this.state.gilad}
                                      onChange={this.handleChange("gilad")}
                                      value="gilad"
                                      color="primary"
                                      disabled
                                    />
                                  </ThemeProvider>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    color: `${darck ? "#3f51b5" : "#fff"}`,
                                  }}
                                >
                                  Code
                                </Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Col>

                        <Col sm={3}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Code Fournisseur"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              value={this.state.codeclient}
                              fullWidth
                              name="codcli"
                              disabled
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              label="Raison sociale"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              onChange={this.fournisseurHandlerChange}
                              name="raisssoc"
                              disabled
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <TextField
                        id="standard-basic"
                        label="Raison sociale"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        style={{ display: "none" }}
                        value={this.state.raisonsocial}
                        fullWidth
                        name="raissoc"
                        disabled
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                      <TextField
                        id="standard-basic"
                        label="Code Fournisseur"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        style={{ display: "none" }}
                        value={this.state.codeclient}
                        fullWidth
                        name="codcli"
                        disabled
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />

                      <Row form>
                        <Col sm={3}>
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="Type"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="typach"
                            onChange={this.typachChange}
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          >
                            {this.state.typach.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </TextField>
                          {this.state.errorMsg && (
                            <FormHelperText style={{ color: "red" }}>
                              champ type obligatoire!
                            </FormHelperText>
                          )}
                        </Col>

                        <Col sm={4}>
                          <TextField
                            id="standard-basic"
                            label="Remise Globale %"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="remise"
                            onChange={this.remiseChange}
                            // value={this.state.remiseg}

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>
                        <Col sm={5}>
                          <TextField
                            id="standard-basic"
                            label="Numéro de pièce jointe"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="numfacfrs"
                            value={this.state.numfacfrs}
                            required
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "16px",
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label="Liste des Articles"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1} className="addbe">
                            {this.state.GMM ? (
                              this.state.btnEnable && this.state.MG ? (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        style={{
                                          backgroundColor: "#3f51b5",
                                          width: "40px",
                                          height: "40px",
                                          left: "-15px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              ) : (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        disabled
                                        style={{
                                          
                                          color: "white",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              )
                            ) : this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <div className="tabbl2">
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>Désignation</th>
                              <th>Quantité</th>
                              <th>PU HT</th>
                              <th>Remise</th>
                              <th>TVA</th>
                              <th>PUTTCNet</th>
                              <th>TotalHT</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}>
                                <td>
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {t.codearticle}
                                  </span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {" "}
                                    {t.des}{" "}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {" "}
                                    {t.qte}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {" "}
                                    {Number(t.puht).toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {" "}
                                    {Number(t.remisea).toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {" "}
                                    {Number(t.tva).toFixed(2)}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {" "}
                                    {Number(t.puttcnet).toFixed(3)}
                                  </span>
                                </td>
                                <td>
                                  {" "}
                                  <span
                                    style={{
                                      color: `${darck ? "black" : "#fff"}`,
                                    }}
                                  >
                                    {" "}
                                    {Number(t.totalht).toFixed(2)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}
              <Card id="be1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                    {/* <Col sm={3}>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                    <Col sm={3}>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                    <Col sm={3}>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col> */}
                  </Row>

                  <Row style={{ marginBottom: "-25px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        valeur de timbre
                      </p>
                      {this.state.type == "F" ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.valtimbre).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          0.000
                        </p>
                      )}
                    </Col>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="be2">
                <Card.Body  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                  <Row className="rowbe">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                  <Row className="rowbe">
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>
                  <Row className="rowbe">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row className="rowbe">
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbe">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row className="rowbe">
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>
                  <Row className="rowbe">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" : "#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur timbre
                      </p>
                      {this.state.type == "F" ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.valtimbre).toFixed(3)}
                        </p>
                      ) : (
                        <p>0.000</p>
                      )}
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p
                          style={{
                            color: `${darck ? "black" : "#fff"}`,
                            fontWeight: "bold",
                          }}
                        >
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  {this.state.totalqte === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // color="secondary"
                      type="submit"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                      onClick={handleClick}
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>

        <LigBEArticle
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          numfaccc={this.props.codbes}
          datfac={this.state.defaultdate}
          rem={rem}
          tauxfod={Number(this.state.tauxfod)}
          valtimbre={this.state.valtimbre}
          type={this.state.type}
          GMM={this.state.GMM}
          MG={this.state.MG}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectFournisseur: (a, b, c, d, e) => {
      dispatch(SelectFournisseur(a, b, c, d, e));
    },
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    SelectBECod: (a, b, c, d, e) => dispatch(SelectBECod(a, b, c, d, e)),
    SelectBFcod: (a, b, c, d, e) => dispatch(SelectBFCod(a, b, c, d, e)),
    SelectBE: (a, b, c, d, e) => dispatch(SelectBE(a, b, c, d, e)),
    SelectFacFrsCod: (a, b, c, d, e) =>
      dispatch(SelectFacFrsCod(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    fournisseurs: state.fournisseurs,
    codbes: state.codbes,
    codfacfrss: state.codfacfrss,
    Auth: state.Auth,
    codbf: state.codbf,
    ConfigDossier: state.ConfigDossier,
    valtimbres: state.valtimbres,
    clients: state.clients,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddBEModal));
