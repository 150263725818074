import React, { Component } from "react";
import {Modal, Card, Row,FormGroup,Col,Alert, Accordion,} from "react-bootstrap";
import "../styling/Styles.css";
import "./facture.scss";

import { TextField, Button, Snackbar, IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectClient } from "../../redux/actions/GetClients";
import { GetNumFacDevis } from "../../redux/actions/GetNumfacDevis";
import { SelectArticle } from "../../redux/actions/GetArticles";
import "./Responsivefc.css";
import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

// import AddClientPassagerModal from "../commande-client-devis/AddClientPassagerModal";
import LigFactureArticle from "./LigFactureArticle";
import { SelectFacCod } from "../../redux/actions/GetCodFac";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";

import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

// const roundTo = require("round-to");

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddFactureComptant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      valtimbre: 0,
      totalhtnet: 0,
      remiseglobal: 0,
      avance: 0,
      AVANCE: 0,
      netapayer: 0,
      NETAPAYER: 0,
      netnetapayer: 0,
      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],
      NETNETAPAYER: 0,
      regimecli: "",
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,
      codefacture: "",
      totalttc: 0,
      numnum: 0,
      pmp: "",
      MG: "",
      magasin: [],
      GMM: "",
    };
  }

  componentDidMount() {
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetNumFacDevis(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFacCod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectValTimbre(
      this.props.ConfigDossier.database,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur
    );
    fetch(
      `https://www.24-crm.tn/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ GMM: el.GMM }));
        data.map((el) => console.log("dossier", el.GMM));
      });
  }

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    avance,
    netnetapayer,
    pmp
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      TOTTVA: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      NETAPAYER: netapayer,
      btnEnabled: btnEnabled,
      avance: avance,
      AVANCE: avance,
      netnetapayer: netnetapayer,
      NETNETAPAYER: netnetapayer,
      pmp: pmp,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  raisocHandler = (event) => {
    this.setState({ raisonsocial: event.target.value });
  };

  remiseglobalChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      AVANCE: this.state.avance * ((100 - event.target.value) / 100),
      NETNETAPAYER:
        ((100 - event.target.value) / 100) *
          (this.state.netapayer + this.state.avance) +
        Number(this.state.valtimbre),
    });
  };

  enregistrer = (event) => {
    event.preventDefault();

    // let id = event.target.codfacs.value;

    fetch(
      `https://www.24-crm.tn/webservice/api/FACCLIs?numfac&typfac=FC&codcli=${
        this.state.codeclient
      }&raisoc=${this.state.raisonsocial}&catfisc=${
        this.state.categoriefiscale
      }&datfac=${this.state.defaultdate}&timbre=${
        this.state.showTimbre
      }&smhtb=${this.state.totalhtbrut}&smremart=${
        this.state.sumremisearticle
      }&smremglo=${this.state.remiseglobal}&smhtn=${
        this.state.totalhtnet
      }&smtva=${this.state.TOTTVA}&mntbon=${parseFloat(
        this.state.NETNETAPAYER
      )}&reste=${parseFloat(this.state.NETNETAPAYER)}&pj=''&tauxNASS=${
        this.state.regimecli
      }&smNASS=${this.state.AVANCE}&codrep=${
        this.props.Auth.code
      }&numcom=''&typcom=FC&valtimbre=${this.state.valtimbre}&Vendeur=${
        this.props.Auth.username
      }&CodDep=${this.state.MG}&coddos=${
        this.props.ConfigDossier.coddos
      }&km&serveur=${this.props.ConfigDossier.serveur}&user=${
        this.props.ConfigDossier.utilisateur
      }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
        this.props.ConfigDossier.database
      }`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.props.onHide();
          this.setState({ numnum: result });
          this.state.tab.map((k, index) => {
            fetch(
              ` https://www.24-crm.tn/webservice/api/LigFACCLIs/{ID}?numfac=${
                this.state.numnum
              }
              &typfac=FC&numlig=${index}&codart=${k.codearticle}&quantite=${
                k.qte
              }&fodart=${k.faudec}&desart=${k.des}&datfac=${
                this.state.defaultdate
              }&priuni=${k.puht}&remise=${k.remisea}&unite=${
                k.unite
              }&codtva=3&tautva=${k.tva}&montht=${k.totalht}&ttclig=${
                k.puttcnet * k.qte
              }&CB=${k.codbar}&formqt=0&pmp=${k.pmp}&coddepp=${
                this.state.MG
              }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
                this.props.ConfigDossier.serveur
              }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
                this.props.ConfigDossier.pwd_serveur
              }&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  console.log(result);
                },
                (error) => {
                  this.setState({ snackbaropen: true, snackbarmsg: "failed" });
                }
              );
          });
          //update solde
          fetch(
            `https://www.24-crm.tn/webservice/api/REGCLIs?codeclii=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.SelectFacCod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      )
      // .then((res) => {
      //update stock après facturation
      // fetch(
      // `http://www.24-crm.com/webservice/api/FACCLIs?numfacs=${
      //    this.props.codfacs.codfacs.map((t) => t.valeur)[0]
      //  }&typfacs=FC&coddeppp=&coddos=${
      // this.props.ConfigDossier.coddos
      //   }&serveur=${this.props.ConfigDossier.serveur}&user=${
      //  this.props.ConfigDossier.utilisateur
      //  }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
      // this.props.ConfigDossier.database
      //  }`,
      //  {
      // method: "POST",
      //     header: {
      // Accept: "application/json",
      //  "Content-Type": "application/json",
      //  },
      // }
      //  )
      // .then((res) => res.json())
      // .then((result) => {
      //   console.log(result);
      //  });
      // })

      .then((res) => {
        //////////// switch update ////////////////
        fetch(
          `https://www.24-crm.tn/webservice/api/Switch?code=FC2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "failed" });
            }
          );

        window.location.reload();
      });
  };
  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  render() {
    const { rem } = this.state;
    const { darck, classes } = this.props;

    let ligModalClose = () => this.setState({ ligModalShow: false });

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Facture Au Comptant</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form autocomplete="off" className={`${darck ? "" : classes.root}`}>
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row form>
                    <Col sm={4}>
                      <FormGroup>
                        <TextField
                          // className="card add-input"
                          id="standard-basic"
                          label="N° FC"
                          margin="dense"
                          variant="outlined"
                          defaultValue={this.props.codfacs.codfacs[0].valeur}
                          onChange={(e) =>
                            this.setState({ codefacture: e.target.value })
                          }
                          //variant="outlined"
                          fullWidth
                          name="codfacs"
                          //disabled
                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        type="date"
                        fullWidth
                        name="datfac"
                        // onChange={(e) =>
                        //   this.setState({ defaultdate: e.target.value })
                        // }
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    {this.state.GMM ? (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.magasin}
                            getOptionLabel={(option) =>
                              ` ${option.code}: ${option.lib}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    MG: getOptionLabel.code,
                                  })
                                : this.setState({
                                    MG: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                // label={translate("N° facture")}
                                label="Magasin"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                required
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                                onFocus={() => {
                                  //liste  bon de commande par code client
                                  fetch(
                                    `https://www.24-crm.tn/webservice/api/Nome?cat=MG&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      this.setState({
                                        magasin: result,
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null}

                    {/* <Col sm={4}>
                      <FormGroup>
                        {this.state.showButtonModalPassager ? (
                          <Tooltip title="Ajouter les détails du client passager">
                            <Button
                              style={{
                                backgroundColor: "#3f51b5",
                                color: "white",
                                fontWeight: "bold",
                                width: "100%",
                                marginTop: "20px",
                                height: "40px",
                              }}
                              onClick={() =>
                                this.setState({
                                  addModalShow1: true,
                                  dvnumfac: this.props.numfac.numfac.map(
                                    (numf) => parseInt(numf.valeur, 10) + 1
                                  ),
                                  dvraisoc: this.state.raisonsocial,
                                })
                              }
                            >
                              Détails Client Passager
                            </Button>
                          </Tooltip>
                        ) : null}
                      </FormGroup>
                    </Col> */}
                  </Row>

                  {/* <Row style={{ marginBottom: "-15px" }}>
                                        <Col sm={4}>
                                            <Label><b>Chercher client par:</b></Label>
                                        </Col>
                                    </Row> */}

                  <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid
                              item
                              style={{ color: `${darck ? "grey" : "#fff"}` }}
                            >
                              Raison sociale
                            </Grid>
                            <Grid item>
                              <ThemeProvider theme={theme}>
                                <Switch
                                  checked={this.state.gilad}
                                  onChange={this.handleChange("gilad")}
                                  value="gilad"
                                  color="primary"
                                />
                              </ThemeProvider>
                            </Grid>
                            <Grid
                              item
                              style={{ color: `${darck ? "#3f51b5" : "#fff"}` }}
                            >
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>

                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.props.clients.clients}
                            getOptionLabel={(option) => option.codcli}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    regimecli: getOptionLabel.regimecli,
                                    cemail: getOptionLabel.email,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showForfitaire: 0,
                                    showButtonModalPassager: false,
                                  });
                              getOptionLabel && getOptionLabel.timbre
                                ? this.setState({
                                    valtimbre: Number(
                                      this.props.valtimbres.valtimbres.map(
                                        (t) => parseFloat(t.valtimbre)
                                      )[0]
                                    ).toFixed(3),
                                  })
                                : this.setState({
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Code client"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined" //variant="outlined"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.props.clients.clients}
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    remiseg: getOptionLabel.remise,
                                    codeclient: getOptionLabel.codcli,
                                    categoriefiscale: getOptionLabel.catfisc,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    regimecli: getOptionLabel.regimecli,
                                    cemail: getOptionLabel.email,
                                    showButtonModalPassager:
                                      getOptionLabel.passager,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    regimecli: 0,
                                    showForfitaire: 0,
                                    showButtonModalPassager: false,
                                  });
                              getOptionLabel && getOptionLabel.timbre
                                ? this.setState({
                                    valtimbre: Number(
                                      this.props.valtimbres.valtimbres.map(
                                        (t) => parseFloat(t.valtimbre)
                                      )[0]
                                    ).toFixed(3),
                                  })
                                : this.setState({
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                name="codcli"
                                onChange={(e) =>
                                  this.setState({ codeclient: e.target.value })
                                }
                                //variant="outlined"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      this.state.showButtonModalPassager ? (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison sociale"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined" //variant="outlined"
                              value={this.state.raisonsocial || ""}
                              fullWidth
                              name="raissoc"
                              onChange={this.raisocHandler}
                              required
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={5}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Raison sociale"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined" //variant="outlined"
                              value={this.state.raisonsocial}
                              fullWidth
                              name="raissoc"
                              disabled
                              onChange={this.raisocHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      )
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code client"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {this.state.showButtonModalPassager ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={this.state.cat}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel.label == "Assujetti"
                                ? this.setState({ categoriefiscale: 0 })
                                : getOptionLabel.label == "Non Assujetti"
                                ? this.setState({ categoriefiscale: 1 })
                                : getOptionLabel.label == "Exonéré TVA/FODEC"
                                ? this.setState({ categoriefiscale: 2 })
                                : getOptionLabel.label == "Exonéré TVA"
                                ? this.setState({ categoriefiscale: 3 })
                                : getOptionLabel.label == "Suspenssion"
                                ? this.setState({ categoriefiscale: 4 })
                                : getOptionLabel.label == "Cession à Quai"
                                ? this.setState({ categoriefiscale: 5 })
                                : this.setState({ categoriefiscale: 6 });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Cat Fiscale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined" //variant="outlined"
                                fullWidth
                                name="catfisc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <>
                        <Col sm={2}>
                          <TextField
                            id="standard-basic"
                            label="Cat Fiscale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            fullWidth
                            name="categoriefiscale"
                            value={this.state.categoriefiscale}
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </Col>

                        <Col sm={2} id="cat">
                          {this.state.categoriefiscale === "0" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "1" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Non Assujetti{" "}
                            </p>
                          ) : this.state.categoriefiscale === "2" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA/FODEC{" "}
                            </p>
                          ) : this.state.categoriefiscale === "3" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Exonéré TVA{" "}
                            </p>
                          ) : this.state.categoriefiscale === "4" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Suspenssion{" "}
                            </p>
                          ) : this.state.categoriefiscale === "5" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Cession à Quai{" "}
                            </p>
                          ) : this.state.categoriefiscale === "6" ? (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: "#007bff",
                              }}
                            >
                              Réduction TVA{" "}
                            </p>
                          ) : (
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "40px",
                                color: `${darck ? "#007bff" : "#fff"}`,
                              }}
                            >
                              Cat Fiscale{" "}
                            </p>
                          )}
                        </Col>
                      </>
                    )}

                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Remise Globale %"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined" //variant="outlined"
                        fullWidth
                        name="remise"
                        onChange={this.remiseglobalChange}
                        value={this.state.remiseg}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    <Col sm={2}>
                      <FormGroup style={{ marginTop: "8px" }}>
                        {this.state.showTimbre ? (
                          <Alert
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                            variant={"success"}
                          >
                            <b></b>Timbre✔
                          </Alert>
                        ) : null}
                      </FormGroup>
                    </Col>

                    <Col sm={2}>
                      <FormGroup style={{ marginTop: "20px" }}>
                        {this.state.showForfitaire === 1 ? (
                          <Alert
                            style={{
                              width: "100%",
                              textAlign: "center",
                              fontSize: "12px",
                            }}
                            variant={"success"}
                          >
                            <b style={{ marginTop: "-10px" }}></b>Forfitaire
                          </Alert>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header
                    style={{
                      height: "60px",
                      backgroundColor: `${darck ? "" : "#828282"}`,
                    }}
                  >
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{
                                  fontSize: "16px",
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label="Liste des Articles"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1} className="addfc">
                            {this.state.GMM ? (
                              this.state.btnEnable && this.state.MG ? (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        style={{
                                          backgroundColor: "#3f51b5",
                                          color: "white",
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              ) : (
                                <label>
                                  <h5>
                                    <Tooltip title="Ajouter article">
                                      <Fab
                                        disabled
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        aria-label="add"
                                        onClick={() =>
                                          this.setState({
                                            ligModalShow: true,
                                            rem: this.state.remiseg,
                                          })
                                        }
                                      >
                                        <AddIcon />
                                      </Fab>
                                    </Tooltip>
                                  </h5>
                                </label>
                              )
                            ) : this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        border: `${darck ? "" : "2px solid #fff"}`,
                      }}
                    >
                      <div className="tab28">
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                              <th>Article</th>
                              <th>Désignation</th>
                              <th>Quantité</th>
                              <th>Unité</th>
                              <th>PU HT</th>
                              <th>Fodec</th>
                              <th>Remise</th>
                              <th>TVA</th>
                              <th>TotalHT</th>
                              <th>PUNet</th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              textAlign: "center",
                              color: `${darck ? "" : "#fff"}`,
                            }}
                          >
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}>
                                <td>{t.codearticle}</td>
                                <td style={{ fontSize: "12px" }}>{t.des}</td>
                                <td>{t.qte}</td>
                                <td>{t.unite}</td>
                                <td>{t.puht}</td>
                                <td>
                                  {t.faudec === "A" ? (
                                    <span>✔</span>
                                  ) : (
                                    <span>Ø</span>
                                  )}
                                </td>
                                <td>{t.remisea}</td>
                                <td>{t.tva}</td>
                                <td>{t.totalht}</td>
                                <td>{t.puttcnet}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {/* <Table>
                                                <Thead style={{ textAlign: "center" }}>
                                                    <Tr>
                                                        <Th style={{ width: "10%" }}>Article</Th>
                                                        <Th style={{ width: "20%" }}>Dés</Th>
                                                        <Th style={{ width: "10%" }}>Quantité</Th>
                                                        <Th style={{ width: "10%" }}>Unité</Th>
                                                        <Th style={{ width: "10%" }}>PU HT</Th>
                                                        <Th style={{ width: "7%" }}>Fodec</Th>
                                                        <Th style={{ width: "5%" }}>Remise</Th>
                                                        <Th style={{ width: "5%" }}>TVA</Th>
                                                        <Th style={{ width: "10%" }}>Total HT</Th>
                                                        <Th style={{ width: "13%" }}>PUTTC Net</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody style={{ textAlign: "center" }}>
                                                    {this.state.tab.map(t => (
                                                        <Tr>
                                                            <Td>{t.codearticle}</Td>
                                                            <Td>{t.des}</Td>
                                                            <Td>{t.qte}</Td>
                                                            <Td>{t.unite}</Td>
                                                            <Td>{t.puht}</Td>
                                                            <Td>
                                                                {t.faudec === "A" ? (
                                                                    <span>✔</span>
                                                                ) : (
                                                                        <span>Ø</span>
                                                                    )}
                                                            </Td>
                                                            <Td>{t.remisea}</Td>
                                                            <Td>{t.tva}</Td>
                                                            <Td>{t.totalht}</Td>
                                                            <Td>{t.puttcnet}</Td>
                                                        </Tr>
                                                    ))}
                                                </Tbody>
                                            </Table> */}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}

              <Card style={{ marginTop: "10px" }} id="fc1">
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.TOTTVA).toFixed(3)}{" "}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: "10px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.NETAPAYER).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur timbre
                      </p>

                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {this.state.valtimbre}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.AVANCE).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.NETNETAPAYER).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="fc2">
                <Card.Body style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}>
                  <Row className="rowfc">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HTBrut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="f">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowfc">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TVA
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.TOTTVA).toFixed(3)}{" "}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Total TTC
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.NETAPAYER).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="f">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowfc">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Valeur timbre
                      </p>

                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {this.state.valtimbre}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Avance Impôt
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.AVANCE).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: `${darck ? "darkslateblue" :"#fff"}`,
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      <p  style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.NETNETAPAYER).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  {this.state.totalqte === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // color="secondary"
                      onClick={this.enregistrer}
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
          <Modal.Footer
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
            }}
          ></Modal.Footer>
        </Modal>
        <LigFactureArticle
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          tabArticle={this.state.tab}
          rem={rem}
          totalqte={this.state.totalqte}
          sumremisearticle={this.state.sumremisearticle}
          totalhtbrut={this.state.totalhtbrut}
          totaltva={this.state.totaltva}
          totalhtnet={this.state.totalhtnet}
          remiseglobal={this.state.remiseglobal}
          netapayer={this.state.netapayer}
          avance={this.state.avance}
          regimecli={this.state.regimecli}
          btnEnabled={this.state.btnEnabled}
          netnetapayer={this.state.netnetapayer}
          datfac={this.state.defaultdate}
          valtimbre={this.state.valtimbre}
          GMM={this.state.GMM}
          MG={this.state.MG}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
    GetNumFacDevis: (a, b, c, d, e) => dispatch(GetNumFacDevis(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectFacCod: (a, b, c, d, e) => dispatch(SelectFacCod(a, b, c, d, e)),
    SelectValTimbre: (a, b, c, d, e) =>
      dispatch(SelectValTimbre(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    clients: state.clients,
    numfac: state.numfac,
    articles: state.articles,
    codfacs: state.codfacs,
    Auth: state.Auth,
    valtimbres: state.valtimbres,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddFactureComptant));
