import Axios from "axios";

export const SelectBSCCod = (a, b, c, d, e) => {
  return (dispatch) => {
    Axios.get(
      `https://www.24-crm.tn/webservice/api/bCDVCLIs?typpe=SC&codep=&coddos=${a}&serveur=${b}&user=${c}&pwd=${d}&database=${e}`
    ).then((response) => {
      return dispatch({ type: "BSC_COD", payload: response.data });
    });
  };
};
