import { LOCALES } from "../locales";

export default {
  [LOCALES.FRENCH]: {
    "GESTION COMMERCIALE": "GESTION COMMERCIALE",
    "Gérer vos affaires": "Gérer vos affaires",
    Vente: "Vente",
    Achat: "Achat",
    Factures: "Factures",
    Statistiques: "Statistiques",
    Analyses: "Analyses",
    "Se Connecter": "Se Connecter",
    "Code Dossier": "Code Dossier",
    "Nom d'utilisateur": "Nom d'utilisateur",
    "Mot de Passe": "Mot de Passe",
    "Total Charge": "Total Charge",
    "Total Charge des Fournisseurs par jour":
      "Total Charge des Fournisseurs par jour",
    "Chiffre d'affaire Global": "Chiffre d'affaire Global",
    BL: "BL",
    Facture: "Factures",
    "Total Montant des Factures par jour":
      "Total Montant des Factures par jour",
    "Total Montant des BL par jour": "Total Montant des BL par jour",
    "Raison Social": "Raison Sociale",
    Montant: "Montant",
    Clients: "Clients",
    Fournisseurs: "Fournisseurs",
    "Top 5 Fournisseurs": "Top 5 Fournisseurs",
    "% Des clients par région": "% Des clients par région",
    "% Des articles les plus vendus": "% Des articles les plus vendus",
    Articles: "Articles",
    Année: "Année",
    "Veuillez Patientez...": "Veuillez Patientez...",
    "Se Déconnecter": "Se Déconnecter",
    "Non Autorisé!": "Non Autorisé!",
    "Route de Tunis km 0.5 imm LOULOU": "Route de Tunis km 0.5 imm LOULOU",
    "3000 Sfax Tunisie": "3000 Sfax Tunisie",
    "Date début": "Date début",
    Rechercher: "Rechercher",
    "Date Fin": "Date Fin",
    "Invalide login ou mot de passe":
      "Nom d'utilisateur ou mot de passe invalide!",
    "vérifier votre code Dossier": "Vérifier votre code Dossier",
    "№ Avoir": "№ Avoir",
    "Pièce Jointe": "N°Pièce Jointe",
    "Code client": "Code client",
    "Montant BC effectué / jour": "Montant BC effectué / jour",
    "Montant BL effectué / jour": "Montant BL effectué / jour",
    "Bon de Livraison": "Bon de Livraison",
    "Bon de Commande": "Bon de Commande",
    "êtes-vous sûr de vouloir supprimer cet avoir?":
      "Etes-vous sûr de vouloir supprimer cet avoir?",
    Supprimer: "Supprimer",
    "Avoir N°": "Avoir N°",
    "Date Avoir": "Date Avoir",
    Désignation: "Désignation",
    Quantité: "Quantité",
    PUHT: "PUHT",
    Remise: "Remise",
    TVA: "TVA",
    TotalHT: "TotalHT",
    TotalTTC: "Total TTC",
    "Total HT Brut": "Total HTBrut",
    "Remise Article": "Remise Article",
    "Total TVA": "Total TVA",
    "Valeur de timbre": "Valeur de timbre",
    "Total HT Net": "Total HTNet",
    "Remise Globale": "Remise Globale",
    "Net à Payer": "Net à Payer",
    "sur Facture N°": "sur Facture N°",
    "Code TVA": "Code TVA",
    Assiette: "Assiette",
    "Total quantité": "Total quantité",
    "Timbre fiscal": "Timbre fiscal",
    "Signature Client": "Signature Client",
    "Signature & cachet": "Signature & cachet",
    "Ajouter Bon de livraison": "Ajouter Bon de livraison",
    "Cat Fiscale": "Cat Fiscale",
    Assujetti: "Assujetti",
    "Non Assujetti": "Non Assujetti",
    "Exonéré TVA/FODEC": "Exonéré TVA/FODEC",
    "Exonéré TVA": "Exonéré TVA",
    Suspenssion: "Suspension",
    "Cession à Quai": "Cession à Quai",
    "Réduction TVA": "Réduction TVA",
    "Remise Globale %": "Remise Globale %",
    "Ajouter, Editer et supprimer article":
      "Ajouter, Editer et supprimer article",
    PUTTCNet: "PUTTCNet",
    "Enregistrer tous": "Enregistrer tous",
    "Liste des Articles": "Liste des Articles",
    Annulé: "Annulé",
    Facturé: "Facturé",
    "Non Facturé": "Non Facturé",
    "№ BL": "№ BL",
    Etat: "Etat",
    Enregistrer: "Enregistrer",
    Modifier: "Modifier",
    Annuler: "Annuler",
    Activer: "Activer",
    Facturer: "Facturer",
    "Bon de Livraison N°": "Bon de Livraison N°",
    Société: "Société",
    Adresse: "Adresse",
    Tél: "Tél",
    "Article ajouté": "Article ajouté",
    "Article supprimé": "Article supprimé",
    "Chercher article": "Chercher article",
    "Code article": "Code article",
    "Désignation article": "Désignation article",
    "Stock insuffisant!": "Stock insuffisant!",
    Unité: "Unité",
    "Remise %": "Remise %",
    "Supprimer cet article": "Supprimer cet article",
    "Modifier Bon de livraison №": "Modifier Bon de livraison №",
    "Chercher client par": "Chercher client par",
    "Enregistrer les modifications": "Enregistrer les modifications",
    Ajouter: "Ajouter",
    PUNet: "PUNet",
    "Editer cet article": "Editer cet article",
    "№ BE": "№ BE",
    "Code Fournisseur": "Code Fournisseur",
    "Numéro de facture": "Numéro de facture",
    PJ: "PJ",
    "Bon De Livraison Fournisseur": "Bon De Livraison Fournisseur",
    "N°Pièce Frs": "N°Pièce Frs",
    "N° BE associé": "N° BE associé",
    "Date BL": "Date BL",
    "Ajouter Bon de Retour": "Ajouter Bon de Retour",
    "№ BLBR": "№ BLBR",
    "N° facture": "N° facture",
    "Total TTC": "Total TTC",
    "Bon De Retour": "Bon De Retour",
    "№ BR": "№ BR",
    "Edit ligne article": "Edit ligne article",
    Avoir: "Avoir",
    "Bon de Retour N°": "Bon de Retour N°",
    "Date BR": "Date BR",
    "Modifier Bon de Retour": "Modifier Bon de Retour",
    Vendeur: "Vendeur",
    "Etabli par": "Etabli par",
    Utilisateur: "Utilisateur",
    du: "du",
    "Code à Barre": "Code à Barre",
  },
};
