import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import "./bl.scss";
import AddBLModal from "./AddBlFrsModal";
// import GetBLByIdModal from "./GetBLByIdModal";
import { Redirect } from "react-router-dom";
//import AddModal from "./AddBlFrsModal";
import {
  Breadcrumbs,
  InputBase,
  FormLabel,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import ListAltIcon from "@material-ui/icons/ListAlt";
import HomeIcon from "@material-ui/icons/Home";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { SelectBlFrs } from "../../redux/actions/GetBLFrs";
import GetBFByIdModal from "./GetBFByIdModal";
import "./tabfrs.scss";
import translate from "../../i18n/translate";
import { LOCALES } from "../../i18n";

const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class BonDeLivraisonFournisseur extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      addModalShow: false,
      getBLByIdModalShow: false,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      tabtab: [],

      defaultdate: date,
      firstdate: date,
      seconddate: date,
      rechdats: [],
      showrechbydate: false,

      gilad: true,
      isSearchDate: false,
      order: "asc",
      orderBy: "",
      rechvalue: "",
      numbe: "",
      fodec: 0,
    };
  }

  componentDidMount() {
    this.props.SelectBLFRS(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectValTimbre(
      this.props.ConfigDossier.database,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur
    );
    document.title = "Bon De Livraison Fournisseur";
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });

    this.state.gilad
      ? this.setState({ showrechbydate: false })
      : this.setState({ rechercheclicked: false });
  }; 

  toggle = () => this.setState({ modal: !this.state.modal });

  rechercheHandler = (event) => {
    this.setState({ rechvalue: event.target.value });
    //Recherche facture fournisseur par num ou par raison sociale ou par numfacfrs
    fetch(
      `https://www.24-crm.tn/webservice/api/FacFrs?typfaccc=BF&iddd=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechs: data,
          rechercheclicked: true,
        })
      );
  };

  firstrechdatHandler = (event) => {
    this.setState({ firstdate: event.target.value });
    //recherche par date de début
    fetch(
      `https://www.24-crm.tn/webservice/api/FacFrs?datt=${event.target.value}&dattt=${this.state.seconddate}&typpe=BF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };

  secondrechdatHandler = (event) => {
    this.setState({ seconddate: event.target.value });
    //recherche par date de fin

    fetch(
      `https://www.24-crm.tn/webservice/api/FacFrs?datt=${this.state.firstdate}&dattt=${event.target.value}&typpe=BF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  render() {
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };

    let addModalClose1 = () => this.setState({ addModalShow: false });
    let getModalClose = () => this.setState({ getBLByIdModalShow: false });
    // let exit;
    const {
      blid,
      datebl,
      client,
      raisonsociale,
      totalhtbrut,
      remiselignes,
      remiseglobale,
      totalhtnet,
      totaldc,
      totalcos,
      totalttc,
      totaltva,
      droitdetimbre,
      avanceimpot,
      rem,
      annuler,
      sumqt,
      facture,
      catfisc,
      SumHtBrut,
      taurem,
      reste,
      numfacfrs,
      numbe,
      MG,
    } = this.state;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }

    const { darck } = this.props;

    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label={translate("Achat")}
              icon={
                <HomeIcon
                  fontSize="small"
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // href="/livraison-fournisseur"
            label={translate("Bon De Livraison Fournisseur")}
            icon={<ListAltIcon fontSize="small" />}
          />
        </Breadcrumbs>

        <div>
          <br />
          <Paper
            // elevation={0.5}
            elevation={2}
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
              height: "45px",
            }}
          >
            <Row style={{display : "flex" , flexWrap: "nowrap" ,marginTop:"5px"}}>
              <Col sm={10}>
                <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  onClick={() => this.setState({ isSearchDate: false })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.isSearchDate ? (
                  <>
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      {" "}
                      {translate("Date début")}{" "}
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.defaultdate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  </>
                ) : this.props.locale === LOCALES.FRENCH ? (
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{
                      color: `${darck ? "" : "#fff"}`,
                      backgroundColor: `${darck ? "" : "#172431"}`,
                    }}
                  />
                ) : (
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{
                      color: `${darck ? "" : "#fff"}`,
                      backgroundColor: `${darck ? "" : "#172431"}`,
                    }}
                  />
                )}
              </Col>
              {/* <Col sm={2}>
                <Button
                  outline
                  color="primary"
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                  onClick={() => this.setState({ addModalShow: true })}
                >
                  <AddIcon />
                 <span style={{whiteSpace: "nowrap"}}>BL Fournisseur</span> 
                </Button>
              </Col> */}
            </Row>
          </Paper>
        </div>

        <br />

        {this.state.rechercheclicked && this.state.rechvalue !== "" ? (
          <div className="tabbf">
            <table>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />

              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    key={test.numfac}
                    onClick={() => {
                      //séléctionner de la table V_CalcLIGBERE
                      fetch(
                        `https://www.24-crm.tn/webservice/api/LIGBEREs?type=BE&numfac=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((res) => res.json())
                        .then((result) =>
                          this.setState({
                            tabtab: result,
                          })
                        );
                      //Get facture where typfac=’typfaccc’ et (numfac=’id’)
                      fetch(
                        `https://www.24-crm.tn/webservice/api/FacFrs/${test.numfac}?typfaccc=${test.typfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                            SumHtBrut:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite) * v.priuni,
                                0
                              ),
                            getBLByIdModalShow: true,
                            blid: test.numfac,
                            datebl: test.datfac,
                            client: test.codfrs,
                            raisonsociale: test.raisoc,
                            totalhtbrut: test.smhtb,
                            remiselignes: test.smremart,
                            remiseglobale: test.smremglo,
                            totalhtnet: test.smhtn,
                            totaldc: test.smDC,
                            totalcos: test.smCOS,
                            totalttc: test.mntbon,
                            totaltva: test.smtva,
                            droitdetimbre: test.timbre,
                            avanceimpot: test.ForfaitCli,
                            annuler: test.annuler,
                            reste: test.reste,
                            facture: test.facture,
                            catfisc: test.catfisc,
                            taurem: test.taurem,
                            numfacfrs: test.numfacfrs,
                            numbe: test.numbe,
                            fodec: test.tauxfodec,
                            MG: test.coddep,
                          })
                        );
                    }}
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numfac}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td style={{ color: "#DF5F10" }}>
                      <span>{test.codfrs}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.numfacfrs}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      {test.annuler !== "0" ? (
                        <span
                        // style={{
                        //   width: "50%",

                        //   backgroundColor: "#b2102f",
                        //   color: "white",
                        //   borderRadius: "10px",
                        //   textAlign: "center",
                        // }}
                        >
                          {translate("Annulé")}
                        </span>
                      ) : test.facture !== "0" ? (
                        <span
                        // style={{
                        //   width: "50%",

                        //   backgroundColor: "#357a38",
                        //   color: "white",
                        //   borderRadius: "10px",
                        //   textAlign: "center",
                        // }}
                        >
                          {translate("Facturé")}
                        </span>
                      ) : (
                        <span
                        // style={{
                        //   width: "50%",

                        //   backgroundColor: "#1769aa",
                        //   color: "white",
                        //   borderRadius: "10px",
                        //   textAlign: "center",
                        // }}
                        >
                          Actif
                        </span>
                      )}
                    </td>
                    <td style={{ color: "green" }}>
                      <span>{Number(parseFloat(test.mntbon)).toFixed(3)}</span>
                    </td>
                    <td style={{ color: "#8f2d56" }}>
                      <span>{test.numbe}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : this.state.showrechbydate ? (
          <div className="tabbf">
            <table>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />

              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechdats,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    key={test.numfac}
                    onClick={() => {
                      //séléctionner de la table V_CalcLIGBERE
                      fetch(
                        `https://www.24-crm.tn/webservice/api/LIGBEREs?type=BE&numfac&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((res) => res.json())
                        .then((result) =>
                          this.setState({
                            tabtab: result,
                          })
                        );
                      //Get facture where typfac=’typfaccc’ et (numfac=’id’)
                      fetch(
                        `https://www.24-crm.tn/webservice/api/FacFrs/${test.numfac}?typfaccc=${test.typfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                            SumHtBrut:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite) * v.priuni,
                                0
                              ),
                            getBLByIdModalShow: true,
                            blid: test.numfac,
                            datebl: test.datfac,
                            client: test.codfrs,
                            raisonsociale: test.raisoc,
                            totalhtbrut: test.smhtb,
                            remiselignes: test.smremart,
                            remiseglobale: test.smremglo,
                            totalhtnet: test.smhtn,
                            totaldc: test.smDC,
                            totalcos: test.smCOS,
                            totalttc: test.mntbon,
                            totaltva: test.smtva,
                            droitdetimbre: test.timbre,
                            avanceimpot: test.ForfaitCli,
                            annuler: test.annuler,
                            facture: test.facture,
                            reste: test.reste,
                            catfisc: test.catfisc,
                            taurem: test.taurem,
                            numfacfrs: test.numfacfrs,
                            numbe: test.numbe,
                            fodec: test.tauxfodec,
                            MG: test.coddep,
                          })
                        );
                    }}
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numfac}</span>
                    </td>

                    <td>
                      <span>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span>{test.codfrs}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.numfacfrs}</span>
                    </td>

                    <td>
                      {test.annuler !== "0" ? (
                        <span
                        // variant="extended"
                        // size="small"
                        // color="secondary"
                        // style={{
                        //   width: "50%",

                        //   backgroundColor: "#b2102f",
                        //   color: "white",
                        //   borderRadius: "10px",
                        //   textAlign: "center",
                        // }}
                        >
                          {translate("Annulé")}
                        </span>
                      ) : test.facture !== "0" ? (
                        <span
                        // style={{
                        //   // width: "50%",
                        //   // height: "80%",

                        //   // backgroundColor: "#357a38",
                        //   width: "50%",

                        //   backgroundColor: "#357a38",
                        //   color: "white",
                        //   borderRadius: "10px",
                        //   textAlign: "center",
                        // }}
                        >
                          {translate("Facturé")}
                        </span>
                      ) : (
                        <span
                          style={{ color: `${darck ? "#182532" : "#fff"}` }}
                          // variant="extended"
                          // size="small"
                          // color="primary"
                          // style={{
                          //   // width: "50%",
                          //   // height: "80%",
                          //   // color: "#1769aa",
                          //   width: "50%",

                          //   backgroundColor: "#1769aa",
                          //   color: "white",
                          //   borderRadius: "10px",
                          //   textAlign: "center",
                          // }}
                        >
                          Actif
                        </span>
                      )}
                    </td>
                    <td style={{ color: "green" }}>
                      <span>
                        {Number(
                          parseFloat(test.mntbon) + parseFloat(test.valtimbre)
                        ).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span>{test.numbe}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="tabbf">
            <table>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />

              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.props.blfrs.blfrs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer" }}
                    key={test.id}
                    onClick={() => {
                      //sélection de la  table V_CalcLIGBERE
                      fetch(
                        `https://www.24-crm.tn/webservice/api/LIGBEREs?type=BE&numfac=${test.numbe}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((res) => res.json())
                        .then((result) =>
                          this.setState({
                            tabtab: result,
                          })
                        );
                      //Get facture where typfac=’typfaccc’ et (numfac=’id’)
                      fetch(
                        `https://www.24-crm.tn/webservice/api/FacFrs/${test.numfac}?typfaccc=${test.typfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                            SumHtBrut:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite) * v.priuni,
                                0
                              ),
                            getBLByIdModalShow: true,
                            blid: test.numfac,
                            datebl: test.datfac,
                            client: test.codfrs,
                            raisonsociale: test.raisoc,
                            totalhtbrut: test.smhtb,
                            remiselignes: test.smremart,
                            remiseglobale: test.smremglo,
                            totalhtnet: test.smhtn,
                            totaldc: test.smDC,
                            totalcos: test.smCOS,
                            totalttc: test.mntbon,
                            totaltva: test.smtva,
                            droitdetimbre: test.timbre,
                            avanceimpot: test.ForfaitCli,
                            fodec: test.tauxfodec,
                            annuler: test.annuler,
                            facture: test.facture,
                            reste: test.reste,
                            catfisc: test.catfisc,
                            taurem: test.taurem,
                            numfacfrs: test.numfacfrs,
                            numbe: test.numbe,
                            MG: test.coddep,
                          })
                        );
                    }}
                  >
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numfac}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codfrs}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.numfacfrs}</span>
                    </td>
                    <td>
                      {test.annuler !== "0" ? (
                        <span
                        // variant="extended"
                        // size="small"
                        // color="secondary"
                        // style={{
                        //   width: "50%",

                        //   backgroundColor: "#b2102f",
                        //   color: "white",
                        //   borderRadius: "10px",
                        //   textAlign: "center",
                        // }}
                        >
                          {translate("Annulé")}
                        </span>
                      ) : test.facture !== "0" ? (
                        <span
                          style={{ color: "#4FA2B8" }}
                          // style={{
                          //   // width: "50%",
                          //   // height: "80%",

                          //   // backgroundColor: "#357a38",
                          //   width: "50%",

                          //   backgroundColor: "#357a38",
                          //   color: "white",
                          //   borderRadius: "10px",
                          //   textAlign: "center",
                          // }}
                        >
                          FT: N°{test.pj}
                        </span>
                      ) : (
                        <span
                          style={{ color: `${darck ? "#182532" : "#fff"}` }}
                          // variant="extended"
                          // size="small"
                          // color="primary"
                          // style={{
                          //   // width: "50%",
                          //   // height: "80%",
                          //   // color: "#1769aa",
                          //   width: "50%",

                          //   backgroundColor: "#1769aa",
                          //   color: "white",
                          //   borderRadius: "10px",
                          //   textAlign: "center",
                          // }}
                        >
                          {translate("Non Facturé")}
                        </span>
                      )}
                    </td>
                    <td>
                      <span style={{ color: "green" }}>
                        {Number(
                          parseFloat(test.mntbon) + parseFloat(test.valtimbre)
                        ).toFixed(3)}
                      </span>
                    </td>
                    <td>
                      <span style={{ color: "#8f2d56" }}>{test.numbe}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* <AddModal
          show={this.state.addModalShow}
          onHide={addModalClose1}
          valtimbre={this.props.valtimbres.valtimbres.map((t) =>
            parseFloat(t.valtimbre)
          )}
        /> */}

        <AddBLModal
          show={this.state.addModalShow}
          onHide={addModalClose1}
          valtimbre={Number(
            this.props.valtimbres.valtimbres.map((t) =>
              parseFloat(t.valtimbre)
            )[0]
          ).toFixed(3)}
        />

        {/* {console.log("reste", reste)} */}

        <GetBFByIdModal
          show={this.state.getBLByIdModalShow}
          onHide={getModalClose}
          blid={blid}
          datebl={datebl}
          client={client}
          numfacfrs={numfacfrs}
          raisonsociale={raisonsociale}
          totalhtbrut={totalhtbrut}
          remiselignes={remiselignes}
          remiseglobale={remiseglobale}
          totalhtnet={totalhtnet}
          totaldc={totaldc}
          totalcos={totalcos}
          totalttc={totalttc}
          totaltva={totaltva}
          droitdetimbre={droitdetimbre}
          avanceimpot={avanceimpot}
          rem={rem}
          annuler={annuler}
          reste={reste}
          tabtab={this.state.tabtab}
          sumqt={sumqt}
          facture={facture}
          catfisc={catfisc}
          SumHtBrut={SumHtBrut}
          taurem={taurem}
          numbe={numbe}
          fodec={this.state.fodec}
          MG={MG}
          valtimbre={this.props.valtimbres.valtimbres.reduce(
            (c, t) => c + parseFloat(t.valtimbre),
            0
          )}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectBLFRS: (a, b, c, d, e) => dispatch(SelectBlFrs(a, b, c, d, e)),
    SelectValTimbre: (a, b, c, d, e) =>
      dispatch(SelectValTimbre(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    blfrs: state.blfrs,
    SearchingResult: state.SearchingReducer,
    valtimbres: state.valtimbres,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BonDeLivraisonFournisseur);
// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "numfac",
    numeric: false,
    disablePadding: true,
    label: translate("№ BL"),
  },

  {
    id: "datfac",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },

  {
    id: "codfrs",
    numeric: false,
    disablePadding: false,
    label: translate("Code Fournisseur"),
  },

  {
    id: "raisoc",
    numeric: true,
    disablePadding: false,
    label: translate("Raison Social"),
  },
  {
    id: "pj",
    numeric: true,
    disablePadding: false,
    label: translate("N°Pièce Frs"),
  },
  { id: "facture", numeric: true, disablePadding: false, label: "Etat" },
  {
    id: "mntbon",
    numeric: true,
    disablePadding: false,
    label: translate("Montant"),
  },
  {
    id: "numbe",
    numeric: true,
    disablePadding: false,
    label: translate("N° BE associé"),
  },
];
function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
