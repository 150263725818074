import React, { Component } from "react";
import { Tabs, Tab, Table } from "react-bootstrap";
import "./HomePage.scss";
import { connect } from "react-redux";
import { SelectTopclient } from "../../redux/actions/Top5";
import { SelectTopFrs } from "../../redux/actions/Top5Frs";
import translate from "../../i18n/translate";

class TabTop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.SelectTopclient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectTopFrs(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  render() {
    const { darck } = this.props;
    return (
      <div>
        <Tabs
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
        >
          <Tab className="tttest" eventKey="home" title={translate("Clients")}>
            <p
              className={darck ? "p1" : "p1Darck"}
              style={{ marginTop: "20px" }}
            >
              Top 5 {translate("Clients")}
            </p>
            <div className="tabtopp">
              <Table striped hover size="sm">
                <thead style={{ background: "#454d55", color: "white" }}>
                  <tr>
                    <th>Code</th>
                    <th >{translate("Raison Social")}</th>
                    <th>{translate("Montant")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.tops.tops.map(
                    (t) => (
                      // t.raisoc === "CLIENT PASSAGER" ? null : (
                      <tr key={t.codcli}>
                        <td className={darck ? "td" : "tdDarck"}> {t.codcli} </td>
                        <td className={darck ? "td" : "tdDarck"}> {t.raisoc} </td>
                        <td className={darck ? "td" : "tdDarck"}> {Number(t.sommemntbn).toFixed(3)} </td>
                      </tr>
                    )
                    // )
                  )}
                </tbody>
              </Table>
            </div>
          </Tab>
          <Tab eventKey="profile" title={translate("Fournisseurs")}>
            <p
              className={darck ? "p1" : "p1Darck"}
              style={{ marginTop: "20px" }}
            >
              {translate("Top 5 Fournisseurs")}
            </p>
            <div className="tabtopp">
              <Table striped hover size="sm">
                <thead style={{ background: "#454d55", color: "white" }}>
                  <tr>
                    <th>Code</th>
                    <th>{translate("Raison Social")}</th>
                    <th>{translate("Montant")}</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.topfrss.topfrss.map((t) => (
                    <tr key={t.codfrs}>
                      <td> {t.codfrs} </td>
                      <td> {t.raisoc} </td>
                      <td> {Number(t.sommemntbn).toFixed(3)} </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectTopclient: (a, b, c, d, e) =>
      dispatch(SelectTopclient(a, b, c, d, e)),
    SelectTopFrs: (a, b, c, d, e) => dispatch(SelectTopFrs(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    tops: state.tops,
    topfrss: state.topfrss,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TabTop);
