const initialState = {
    depenses: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "NOMEdp_SELECT":
        return {
          ...state,
          depenses: action.payload,
        };
      default:
        return state;
    }
  }
  