const initialState = {
    AllReleverClient: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "RELEVERCLI_GET":
        return {
          ...state,
          AllReleverClient: action.payload,
        };
      default:
        return state;
    }
  }
  