import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../styling/Styles.css";
import UploadImage from "./UploadImage";
import {
  TextField,
  MenuItem,
  Checkbox,
  CardHeader,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import { FormGroup, Col, Row } from "reactstrap";
import Center from "react-center";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import Typography from "@material-ui/core/Typography";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { SelectNome } from "../../redux/actions/GetNome";
import { connect } from "react-redux";
import { SelectSousFamille } from "../../redux/actions/GetSousFamille";
import { GetCodart } from "../../redux/actions/GetCodart";
import { SelectArticle } from "../../redux/actions/GetArticles";

import Switch from "@material-ui/core/Switch";
import { SelectNomenclature } from "../../redux/actions/GetNomenclature";
import { withStyles } from "@material-ui/styles";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
import { SelectMemo } from "../../redux/actions/GetMemo";
// import ConfirmationDialog from './ConfirmationDialog';
const roundTo = require("round-to");

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});
const theme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        color: "#BEBEBE",
        "&$checked": {
          color: "#BEBEBE",
        },
      },
      track: {
        opacity: 0.2,
        backgroundColor: "#BEBEBE",
        "$checked$checked + &": {
          opacity: 0.7,
          backgroundColor: "#BEBEBE",
        },
      },
    },
  },
});
class AddingArticleModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tt: "dqsd",
      purnhtnet: "",
      remisefrs: "",
      codfrs: "",
      purbrut: "",
      purttc: "",
      tva: 0,
      rechs: [],
      codtva: 0,
      photo: "", // Updated to empty string for base64
      code:"",
      lib:"",
      ECO: "",
      tab: [],
      clicked: false,
      marged: "",
      pudhtt: "",
      pudhttc: "",
       
     
      tabletva: [
        { code: "0", label: "0" },
        { code: "1", label: "7" },
        { code: "2", label: "13" },
      ],

tabTVA:[],

tabCAT:[],
      van: [
        { code: "V", label: "Sur Vente" },
        { code: "A", label: "Sur Achat" },
        { code: "N", label: "Sans Fodec" },
      ],
      unite: "",
      famille: "",
      sousfam: "",
      typfodec: "",

      snackbaropen: false,
      snackbarmsg: ",",
      faudec: 0,
    };
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  componentDidMount() {
    this.props.SelectNome(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectSousFamille(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetCodart(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectMemo(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclature(
      "UM",
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    fetch(
      `https://www.24-crm.tn/webservice/api/Dossier?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=PSDOSSIER`
    )
      .then((res) => res.json())
      .then((data) => {
        data.map((el) => this.setState({ ECO: el.ECO }));
        data.map((el) => console.log("dossier", el.ECO));
      });

    
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  codeabarrechange = (event) => {
    this.setState({
      codeabarre: event.target.value,
    });
  };

  designationchange = (event) => {
    this.setState({
      designation: event.target.value,
    });
  };


  tvaaHandler = (event) => {
    this.setState({
      tva: event.target.value,
      pudhttc: roundTo(this.state.pudhtt * (1 + event.target.value / 100), 3),
      purttc: roundTo(this.state.purnhtnet * (1 + event.target.value / 100), 3),
    });
  };

  brutHandler = (event) => {
    const purnh = roundTo(
      event.target.value * (1 - this.state.remisefrs / 100),
      3
    );
    const pu = roundTo(purnh * (1 + this.state.marged / 100), 3);

    this.setState({
      purbrut: event.target.value,
      purnhtnet: purnh,
      purttc: roundTo(purnh * (1 + this.state.tva / 100), 3),
      pudhtt: pu,
      pudhttc: roundTo(pu * (1 + this.state.tva / 100), 3),
    });
  };

  handleKeyDown = (event) => {
    if (this.state.typfodec === "A") {
      if (event.key === "Enter") {
        const purnh = roundTo(
          Number(event.target.value) *
            (1 + Number(this.state.faudec) / 100) *
            (1 - this.state.remisefrs / 100),
          3
        );
        const pu = roundTo(purnh * (1 + this.state.marged / 100), 3);
        this.setState({
          purbrut:
            Number(event.target.value) * (1 + Number(this.state.faudec) / 100),
          purnhtnet: purnh,
          purttc: roundTo(purnh * (1 + this.state.tva / 100), 3),
          pudhtt: pu,
          pudhttc: roundTo(pu * (1 + this.state.tva / 100), 3),
        });
      }
    }
  };

  remisefrsHandler = (event) => {
    const purnh = roundTo(
      this.state.purbrut * (1 - event.target.value / 100),
      3
    );

    this.setState({
      remisefrs: event.target.value,
      purnhtnet: purnh,
      purttc: roundTo(
        this.state.purbrut *
          (1 - event.target.value / 100) *
          (1 + this.state.tva / 100),
        3
      ),
    });
  };

  margedHandler = (event) => {
    const pudh = roundTo(
      this.state.purnhtnet * (1 + event.target.value / 100),
      3
    );
    const tuut = event.target.value;

    this.setState({
      marged: tuut,
      pudhtt: pudh,
      pudhttc: roundTo(pudh * (1 + this.state.tva / 100), 3),
    });
  };

  ttcHandler = (event) => {
    const pud = roundTo(event.target.value / (1 + this.state.tva / 100), 3);

    this.setState({
      pudhttc: event.target.value,
      pudhtt: pud,
      marged: roundTo(100 * (pud / this.state.purnhtnet - 1), 3),
    });
  };

  htHandler = (event) => {
    this.setState({
      pudhtt: event.target.value,
      pudhttc: roundTo(event.target.value * (1 + this.state.tva / 100), 3),
      marged: roundTo(100 * (event.target.value / this.state.purnhtnet - 1), 3),
    });
  };
 handleFileSelect = (base64String) => {
    this.setState({ photo: base64String });
  };

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    netnetapayer
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      netnetapayer: netnetapayer,
    });
  };


  enregistrer = (event) => {
  event.preventDefault();

  // Demander la confirmation de l'utilisateur
  //const userConfirmed = window.confirm("Voulez-vous vraiment enregistrer cet article en E-commerce ou Non ?");

  // Construire les données de l'article
  const data = {
    codart: event.target.codart.value,
    desart: event.target.desart.value,
    unite: this.state.unite,
    codtva: this.state.codtva,
    tautva: this.state.tva,
    famart: this.state.famille,
    CatArt: this.state.CatArt,
    codbar: event.target.codbar.value,
    reffrs: event.target.reffrs.value,
    codfrs: this.state.codfrs,
    PUDTTC: event.target.PUDTTC.value,
    typfodec: this.state.typfodec,
    typdc: event.target.typdc.value,
    PUDHT: event.target.PUDHT.value,
    sousfam: this.state.sousfam,
    TDC: event.target.TDC.value,
    PURBHT: event.target.PURBHT.value,
    remisefrs: event.target.remisefrs.value,
    PURNHT: event.target.PURNHT.value,
    PURNTTC: event.target.PURNTTC.value,
    margedet: event.target.margedet.value,
    Photo: this.state.photo,
    Ecomm: this.state.Ecomm ? 1 : 0, // Définir Ecomm en fonction de la confirmation de l'utilisateur

   // Ecomm: userConfirmed ? 1 : 0,  // Définir Ecomm en fonction de la confirmation de l'utilisateur
  };
  
if(this.state.ECO==true){ 
  const  memolig= event.target.memolig.value;
  // Envoyer les données à l'API
  fetch(`https://www.24-crm.tn/webservice/api/ARTICLEs?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, 
  {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  .then((res) => res.json())
  .then(
    (result) => {
      this.props.onHide();
      this.props.SelectArticle(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
       // const data1={
      //   nature:'ART',
      //   codlig:data.codart,
     
     // }
     
     fetch(
       `https://www.24-crm.tn/webservice/api/Memos?nature='ART'&codlig=${data.codart}&memolig=${memolig}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
       {
         method: "POST",
         header: {
           Accept: "application/json",
           "Content-Type": "application/json",
         },
         // body: JSON.stringify(data1)
     
       }
     );
      this.props.SelectMemo(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );

      this.setState({ snackbaropen: true, snackbarmsg: result });
      this.props.GetCodart(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
     
    },
    (error) => {
      this.setState({ snackbaropen: true, snackbarmsg: "failed" });
    }
  );
}else{
     // Envoyer les données à l'API
  fetch(`https://www.24-crm.tn/webservice/api/ARTICLEs?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`, 
  {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  })
  .then((res) => res.json())
  .then(
    (result) => {
      this.props.onHide();
      this.props.SelectArticle(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
         this.setState({ snackbaropen: true, snackbarmsg: result });
      this.props.GetCodart(
        this.props.ConfigDossier.coddos,
        this.props.ConfigDossier.serveur,
        this.props.ConfigDossier.utilisateur,
        this.props.ConfigDossier.pwd_serveur,
        this.props.ConfigDossier.database
      );
    
    },
    (error) => {
      this.setState({ snackbaropen: true, snackbarmsg: "failed" });
    }
  );
  }
 
};



  logFormData = (formData) => {
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
  };



  render() {
    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Article</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <div>
              <Form autocomplete="off"
                    onSubmit={this.enregistrer}
                    className={`${darck ? "" : classes.root}`}>
                        {/* //////////////////////////////////////////////////parrtie  tou le champ article //////////////////////////////////////////////// */}
                <Row sm={12}>
                  <Col style={{ margin: "0" }}>
                    <Card>
                      <CardContent 
                                            style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              borderTop: `${darck ? "" : "2px solid #fff"}`,
                                              borderRight: `${darck ? "" : "2px solid #fff"}`,
                                              borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                              borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                              borderRadius: "5px",
                                            }}
                      >
                        <Row form style={{ marginBottom: "0px" }}>
                          <Col md={3}>
                            <Form.Group controlId="codart">
                              {/* {this.props.codarts.codarts.map((cod) => ( */}
                              <TextField
                                // className="card add-input"
                                id="standard-basic"
                                label="Code article"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="codart"
                                required

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              {/* ))} */}
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group controlId="codbar">
                              <TextField
                                // className="card add-input"
                                id="standard-basic"
                                label="Code à barre"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="codbar"
                                

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group controlId="desart">
                              <TextField
                                // className="card add-input"
                                id="standard-basic"
                                label="Désignation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="desart"
                                required
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>

                         
                        </Row>
                        <Row form>
                          <Col md={3}>
                            <Form.Group controlId="unite">
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={this.props.nomenclatures.nomenclatures}
                                getOptionLabel={(option) => option.code}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        unite: getOptionLabel.code,
                                      })
                                    : this.setState({
                                        unite: "",
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // className="card add-input"
                                    // {...params}
                                    label="Unité"
                                    margin="dense"
                                    variant="outlined"
                                    required
                                    name="unite"
                                    fullWidth

                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={3}>
                            <Form.Group controlId="famart">
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={this.props.nomes.nomes}
                                getOptionLabel={(option) => option.code}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        famille: getOptionLabel.code,
                                      })
                                    : this.setState({
                                        famille: "",
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // className="card add-input"
                                    // {...params}
                                    label="Famille"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    name="famart"

                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={3}>
                            <Form.Group controlId="sousfam">
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={this.props.sousfamilles.sousfamilles}
                                getOptionLabel={(option) =>
                                  option.chdec == this.state.famille
                                    ? option.code
                                    : ""
                                }
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        sousfam: getOptionLabel.name,
                                      })
                                    : this.setState({
                                        sousfam: "",
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // className="card add-input"
                                    // {...params}
                                    label="Sous Famille"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth
                                    name="sousfam"

                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Form.Group>
                          </Col>

                          <Col md={3}>
                            <Form.Group controlId="reffrs">
                              <TextField
                                // className="card add-input"
                                type="text"
                                label="Réf Fournisseur"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="reffrs"

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                 

                          <Col sm={2}>
                            <Form.Group controlId="codtva">
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={this.state.tabTVA}
                                getOptionLabel={(option) => option.lib + "%"}
                                onChange={(event, getOptionLabel) => {
                                  this.setState({
                                    tva: Number(getOptionLabel.lib),
                                    codtva: Number(getOptionLabel.code),
                                    
                                    pudhttc: roundTo( this.state.pudhtt   * (1 +Number(getOptionLabel.lib) / 100), 3 ),
                                    purttc:  roundTo(this.state.purnhtnet * (1 +Number(getOptionLabel.lib) / 100), 3 ), });}}
                              
                                    renderInput={(params) => (
                                  <TextField
                                  label="TVA*"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}

                                  onFocus={() => {
                                    //Liste des TVA
                                    fetch(
                                      `https://www.24-crm.tn/webservice/api/Nome?cat=TV&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((result) => {
                                        this.setState({
                                          tabTVA: result,
                                        });
                                      });
                                  }}
                               
                                />
                              )}
                            />
                            </Form.Group>
                          </Col>
                        
{/* ///////////////////////typfode//////////////////////// */}
                          <Col md={3}>
                            <Form.Group controlId="typfodec">
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={this.state.van}
                                getOptionLabel={(option) => option.label}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                        typfodec: getOptionLabel.code,
                                      })
                                    : this.setState({
                                        typfodec: "",
                                      });
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    // className="card add-input"
                                    // {...params}
                                    label="Type Fodec"
                                    margin="dense"
                                    variant="outlined"
                                    // required
                                    name="typfodec"
                                    fullWidth

                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}
                                  />
                                )}
                              />
                            </Form.Group>
                          </Col>
{/* ///////////////////////typdc//////////////////////// */}
                          <Col sm={2}>
                            <Form.Group controlId="typdc">
                              <TextField
                                // className="card add-input"
                                id="outlined-select-currency"
                                select
                                label="Type DC"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="typdc"

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              >
                                {this.state.van.map((option) => (
                                  <MenuItem
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Form.Group>
                          </Col>
{/* ///////////////////////TDC//////////////////////// */}
                          <Col sm={2}>
                            <Form.Group controlId="TDC">
                              <TextField
                                // className="card add-input"
                                label="Taux D.C"
                                id="outlined-margin-dense"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="TDC"

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <FormGroup>
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={
                                  this.state.clicked
                                    ? this.state.rechs
                                    : this.props.fournisseurs.fournisseurs
                                }
                                getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    codfrs: getOptionLabel.codfrs,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,

                                    cemail: getOptionLabel.email,
                                    codetva: getOptionLabel.CodeTVA,
                                    valtimbre:
                                      // getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                  codfrs:""
                                  });
                            }}
                                renderInput={(params) => (
                                  <TextField
                                    // className="card add-input"
                                    // {...params}
                                    label="Fournisseur"
                                    margin="dense"
                                    variant="outlined"
                                    fullWidth

                                    InputProps={{
                                      ...params.InputProps,
                                      className: !darck
                                        ? this.props.classes.input
                                        : null,
                                    }}
                                    inputProps={params.inputProps}
                                    id={params.id}
                                    InputLabelProps={{
                                      style: {
                                        color: !darck ? "#fff" : null,
                                      },
                                    }}

                                    onFocus={() => {
                                      //liste  devis par code client
                                      fetch(
                                        `https://www.24-crm.tn/webservice/api/fournisseurs?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                      )
                                        .then((res) => res.json())
                                        .then((result) => {
                                          this.setState({
                                            rechs: result,
                                          });
                                        });
                                    }}
                                    name="codcli"
                                  />
                                )}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                    
                      </CardContent>
                    </Card>
                  </Col>
                </Row>

                <br />
 {/* //////////////////////////////////////////////////parrtie  image  descp //////////////////////////////////////////////// */}
 {this.state.ECO ? (<>  <Row sm={12}>
                  <Col style={{ margin: "0" }}>
                    <Card>
                      <CardContent 
                                            style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              borderTop: `${darck ? "" : "0px solid #fff"}`,
                                              borderRight: `${darck ? "" : "2px solid #fff"}`,
                                              borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                              borderBottom: `${darck ? "" : "0px solid #fff"}`,
                                              borderRadius: "5px",
                                            }}
                      >
                      
                     

                        <Row>
                 
{/* /////////////////////// ECOMM //////////////////////// */}
                  <Col sm={3}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <Grid
                              item
                              style={{
                                marginTop: "10px",
                                color: `${darck ? "" : "#fff"}`,
                              }}
                            >
                           
                              
                              <Checkbox
                                name="Ecomm"
                                color="primary"
                                //required
                                checked={this.state.Ecomm}
                                onChange={this.handleChange("Ecomm")}
                                value={this.state.Ecomm}
                                style={{ color: `${darck ? "" : "#fff"}` }}
                              />
                              ECOMM
                            </Grid>
                          </FormGroup>
                        </Col>
 {/* /////////////////////// CCAT ART //////////////////////// */}
 {this.state.Ecomm?(<>
  <Col sm={3}>
                            <Form.Group controlId="CAT-ART">
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={this.state.tabCAT}
                                getOptionLabel={(option) => option.lib  }
                                // onChange={(event, getOptionLabel) => {
                                //   this.setState({
                                //     CatArt: Number(getOptionLabel.lib),
                                //     codcat: Number(getOptionLabel.code),
                                //      });}}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                      CatArt: getOptionLabel.lib,
                                      })
                                    : this.setState({
                                        CatArt: "",
                                      });
                                }}
                                    renderInput={(params) => (
                                  <TextField
                                  label="CAT-ART"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  required
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}

                                  onFocus={() => {
                                    //Liste des cat article
                                    fetch(
                                      `https://www.24-crm.tn/webservice/api/Nome?cat=cat&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((result) => {
                                        this.setState({
                                          tabCAT: result,
                                        });
                                      });
                                  }}
                               
                                />
                              )}
                            />
                            </Form.Group>
                          </Col>
                          
 {/* /////////////////////// IMAGE //////////////////////// */}
 <Col sm={3}>
                            <UploadImage 
                              onFileSelect={this.handleFileSelect}
                              darck={this.props.darck}
                              required
                           
                            />
                          </Col> 
 </>):(<>
  <Col sm={3}>
                            <Form.Group controlId="codtva">
                              <Autocomplete
                                id="include-input-in-list"
                                includeInputInList
                                className="ajouter-client-input"
                                options={this.state.tabCAT}
                                getOptionLabel={(option) => option.lib  }
                                // onChange={(event, getOptionLabel) => {
                                //   this.setState({
                                //     CatArt: Number(getOptionLabel.lib),
                                //     codcat: Number(getOptionLabel.code),
                                //      });}}
                                onChange={(event, getOptionLabel) => {
                                  getOptionLabel
                                    ? this.setState({
                                      CatArt: getOptionLabel.lib,
                                      })
                                    : this.setState({
                                        CatArt: "",
                                      });
                                }}
                                    renderInput={(params) => (
                                  <TextField
                                  label="CAT-ART"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}

                                  onFocus={() => {
                                    //Liste des cat article
                                    fetch(
                                      `https://www.24-crm.tn/webservice/api/Nome?cat=cat&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((result) => {
                                        this.setState({
                                          tabCAT: result,
                                        });
                                      });
                                  }}
                               
                                />
                              )}
                            />
                            </Form.Group>
                          </Col>
                          
 {/* /////////////////////// IMAGE //////////////////////// */}
 <Col sm={3}>
                            <UploadImage 
                              onFileSelect={this.handleFileSelect}
                              darck={this.props.darck}
                           
                            />
                          </Col> 
 
 
 
 
 </>)}
                                                

                          </Row>

                          <Row>
{/* ///////////////////////description //////////////////////// */}
{this.state.Ecomm?(<>
  <Col sm={12}>
                            <Form.Group controlId="memolig">
                         
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="description"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="memolig"
                            //    value={this.state.memolig}
                                multiline
                                required
                                rows={2}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
</>):(<>
  <Col sm={12}>
                            <Form.Group controlId="memolig">
                         
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="description"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="memolig"
                            //    value={this.state.memolig}
                                multiline
                               // required
                                rows={2}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
</>)}
                        
                        </Row>
                    
                      </CardContent>
                    </Card>
                  </Col>
                </Row>

                <br /></>):null}
                    
 {/* //////////////////////////////////////////////////parrtie prix //////////////////////////////////////////////// */}

                <Row sm={12}>
                  <Col style={{ margin: "0" }}>
                    <Card>
                      <CardHeader
                        avatar={<MonetizationOnIcon />}
                        title="Prix"
                        style={{
                          height: "20px" ,
                          backgroundColor: `${darck ? "#fff" : "#172431"}`,
                          color: `${darck ? "#020F64" : "#fff"}`,
                          borderTop: `${darck ? "" : "2px solid #fff"}`,
                          borderRight: `${darck ? "" : "2px solid #fff"}`,
                          borderLeft: `${darck ? "" : "2px solid #fff"}`,
                          borderBottom: `none`,
                        }}
                      />
                      <CardContent 
                                            style={{
                                              backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                              borderTop: `${darck ? "" : "2px solid #fff"}`,
                                              borderRight: `${darck ? "" : "2px solid #fff"}`,
                                              borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                              borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                              borderRadius: "5px",
                                            }}
                      >
                        <Row>
                          <Col sm={3}>
                            <Form.Group controlId="PURBHT">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Revient Brut HT"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PURBHT"
                                onChange={this.brutHandler}
                                onKeyDown={this.handleKeyDown}
                                value={this.state.purbrut}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="remisefrs">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="Remise Frs (%)"
                                variant="outlined"
                                fullWidth
                                name="remisefrs"
                                margin="dense"
                                // value={this.state.remisefrs}
                                defaultValue={this.state.remisefrs}
                                onChange={this.remisefrsHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PURNHT">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Revient Net HT"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PURNHT"
                                value={this.state.purnhtnet}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PURNTTC">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Revient Net TTC"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PURNTTC"
                                value={this.state.purttc}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        {/* ////////////////////////////////////////////////////////////////////// */}
                        <Row>
                          <Col sm={3}>
                            <Form.Group controlId="margedet">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="Marge/ P.R Net (%)"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="margedet"
                                // defaultValue={this.state.marged}
                                value={this.state.marged}
                                onChange={this.margedHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PUDHT">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Vente HT"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PUDHT"
                                value={this.state.pudhtt}
                                onChange={this.htHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PUDTTC">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Vente TTC"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PUDTTC"
                                value={this.state.pudhttc}
                                onChange={this.ttcHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                         
                          {/* //////test////////////// */}
                        </Row>
                        <Row>
                          <Col sm={3}>
                            <Form.Group controlId="margedet">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="Marge/ P.R Net (%)"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="margedet"
                                // defaultValue={this.state.marged}
                                value={this.state.marged}
                                onChange={this.margedHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId="PUDHT">
                              <TextField
                                // className="card add-input"
                                id="outlined-basic"
                                label="P.U Vente HT"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                name="PUDHT"
                                value={this.state.pudhtt}
                                onChange={this.htHandler}

                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </Form.Group>
                          </Col>
                          {this.state.ECO? null:(
                             <Col sm={3}>
                             <Col sm={3}>
                               <UploadImage 
                                 onFileSelect={this.handleFileSelect}
                                 darck={this.props.darck}
                              
                               />
                             </Col>
                           
                             </Col>
                          )}
                         
                          {/* //////test////////////// */}
                        
                        
                        </Row>
                      </CardContent>
                    </Card>
                  </Col>
                </Row>
               
                <br />
                <Row>
                  <Col sm={8}></Col>
                  <Col sm={4}>
                    {this.state.tt === "" ? (
                      <Center>
                        <Button
                          disabled
                          style={{ width: "100%" }}
                          variant="outlined"
                          //color="primary"
                          type="submit"
                        >
                          Enregistrer
                        </Button>
                      </Center>
                    ) : (
                      <Center>
                        <Button
                          style={{
                            width: "100%",
                            background: "#020f64",
                            color: "white",
                          }}
                          //color="primary"

                          type="submit"
                        >
                          Enregistrer
                        </Button>
                      </Center>
                    )}
                  </Col>
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectNome: (a, b, c, d, e) => dispatch(SelectNome(a, b, c, d, e)),
    SelectSousFamille: (a, b, c, d, e) =>
      dispatch(SelectSousFamille(a, b, c, d, e)),
    GetCodart: (a, b, c, d, e) => dispatch(GetCodart(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectMemo: (a, b, c, d, e) => dispatch(SelectMemo(a, b, c, d, e)),
    SelectNomenclature: (cat, a, b, c, d, e) =>
      dispatch(SelectNomenclature("UM", a, b, c, d, e)),
   SelectFournisseur: (a, b, c, d, e) =>
      dispatch(SelectFournisseur(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    nomes: state.nomes,
    sousfamilles: state.sousfamilles,
    codarts: state.codarts,
    nomenclatures: state.nomenclatures,
    ConfigDossier: state.ConfigDossier,
    fournisseurs: state.fournisseurs,
    darck: state.darck.darck,
    Memos: state.Memos,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddingArticleModal));
