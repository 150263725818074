import React, { Component } from "react";
import image from "./contactsyros.png";
import image1 from "../../Login/contactsyros.png";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { Col, Row } from "reactstrap";
import PinDropIcon from "@material-ui/icons/PinDrop";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import { Container } from "@material-ui/core";
import translate from "../../i18n/translate";

class Contact extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
    };
  }

  render() {

    const { darck } = this.props;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }

    return (
      <div className="contact-page" style={{ width: "100%", marginTop: "10%" }}>
        <Container>
          <Row style={{ marginLeft: "10%" }}>
            <Col sm={6}>
              <h4 style={{ fontWeight: "bold" ,color : darck ? "rgb(12, 15, 78)" : "#fff"}}>      
                  Contact{" "}  Informations
              </h4>
              <br />
              <div style={{ color : darck ? "rgb(12, 15, 78)" : "#fff"}}>
                <PinDropIcon color= {darck ? "primary" : "#fff"} />{" "}
                Rue Chedly Kallala, Sfax
              </div>
              <br />
              <h6 style={{ fontWeight: "bold" ,color : darck ? "rgb(12, 15, 78)" : "#fff"}}> SAV :<br/></h6> 
              <div style={{ color : darck ? "rgb(12, 15, 78)" : "#fff"}}>
             
                <MailIcon color = {darck ? "primary" : "#fff"} /> sav@24-crm.com
              </div>
              <br />
              <h6 style={{ fontWeight: "bold" ,color : darck ? "rgb(12, 15, 78)" : "#fff"}}> DERECTION:<br/></h6> 
              <div style={{ color : darck ? "rgb(12, 15, 78)" : "#fff"}}>
                <MailIcon color = {darck ? "primary" : "#fff"} /> gm@polysoftco.tn
              </div>
              <br />


              <div style={{ color : darck ? "rgb(12, 15, 78)" : "#fff"}}>
                <PhoneIcon color= {darck ? "primary" : "#fff"} /> +216 74 433 020
              </div>
              <br />
              <div style={{ color : darck ? "rgb(12, 15, 78)" : "#fff"}}>
                <MarkunreadMailboxIcon color= {darck ? "primary" : "#fff"} />{" "}
                {translate("3000 Sfax Tunisie")}
              </div>
              <br />
              <div style={{ fontSize: "24px", fontWeight: "bold" }}>
                <span style={{ color : darck ? "rgb(12, 15, 78)" : "#fff" }}> Polysoft</span>
                <span style={{color : darck ? "rgb(216, 95, 68)" : "#fff" }}>&Co</span>
              </div>
            </Col>
            <Col sm={6}>
              <iframe
                src="https://maps.google.com/maps?q=sfax%20route%20tunis%20km%200.5&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                width="500px"
                height="400px"
              ></iframe>
            </Col>
          </Row>
        </Container>
        { /* <img
          src={image1}
          alt="Contact"
          style={{ width: "100%", height: "100%" }}
        /> */ }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  null
)(Contact);
