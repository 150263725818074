import React, { Component } from "react";
import {
  Modal,
  Card,
  Row,
  FormGroup,
  Col,
  Accordion,
} from "react-bootstrap";
import "../styling/Styles.css";
// import "../commande-client-devis/ss.scss";
import "./fac.scss";

import {
  TextField,
  Button,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";

import Tooltip from "@material-ui/core/Tooltip";
// import PropTypes from "prop-types";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { SelectBECod } from "../../redux/actions/GetBECode";
import LigFacFrs from "./LigFacFrs";
import { SelectBE } from "../../redux/actions/GetBE";
import { SelectFacFrsCod } from "../../redux/actions/GetFacFrsCod";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
// import { SelectBlFrs } from "../../redux/actions/GetBLFrs";
import { SelectBFCod } from "../../redux/actions/GetBFCod";

import { withStyles } from "@material-ui/styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import "./Responsiveff.css";

const roundTo = require("round-to");
var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class AddFacFrsModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      gilad: true,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,
      rechs: [],
      codetva: "",
      type: "",
      typach: [{ code: "F", label: "F" }],
      numnum: 0,

      nextsubmit: false,

      snackbaropen: false,
      snackbarmsg: ",",
      codf: "",

      valtimbre: 0,
      netnetapayer: 0,
      catfiscal: "0",

      username: username,
      clicked: false,
      errorMsg: "",
      fod: 0,
      totalfod: 0,
    };
  }

  componentDidMount() {
    this.props.SelectBECod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectBFcod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFacFrsCod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  typachChange = (event) => {
    this.setState({ type: event.target.value });
    this.props.codfacfrss.codfacfrss.map((t) =>
      this.setState({ codf: t.valeur })
    );
  };

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    netnetapayer,
    totalfod
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      netnetapayer: netnetapayer,
      totalfod: totalfod,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseChange = (event) => {
    this.setState({ remiseg: event.target.value });
  };

  pjChange = (event) => {
    this.setState({ pj: event.target.value });
  };

  numfacfrsChange = (event) => {
    this.setState({ numfacfrs: event.target.value });
  };

  clientHandlerChange = (event) => {
    this.setState({ codcli: event.target.value });
    fetch(
      `https://www.24-crm.tn/webservice/api/fournisseurs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };

  enregistrer = (event) => {
    event.preventDefault();
    this.setState({
      datfac: event.target.datfac.value,
      codcli: event.target.codcli.value,
      raisoc: event.target.raissoc.value,
      numfacfrs: event.target.numfacfrs.value,
    });

    // fetch(
    //   // `/api/BEREs?numfac=${event.target.codbe.value}&typfac=BE&datfac=${event.target.datfac.value}&codfrs=${event.target.codcli.value}&raisoc=${event.target.raissoc.value}&taurem=${event.target.remise.value}&pj=${event.target.pj.value}&typach=${event.target.typach.value}&Vendeur=${this.state.username}`
    //   `http://www.24-crm.com/webservice/api/BEREs?numfac&typfac=BE&datfac=${event.target.datfac.value}&codfrs=${this.state.codeclient}&raisoc=${event.target.raissoc.value}&pj=${event.target.numfacfrs.value}&taurem=${event.target.remise.value}&catfisc=0&typach=F&Vendeur=${this.props.Auth.username}&valtimbre=${this.state.valtimbre}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //   {
    //     method: "POST",
    //     header: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     this.state.tab.map((k, index) => {
    //       fetch(
    //         `http://www.24-crm.com/webservice/api/LigBEREs/{ID}?numfac&typfac=BE&numlig=${index}&codart=${k.codearticle}&quantite=${k.qte}&fodart=0&desart=${k.des}&datfac=${event.target.datfac.value}&priuni=${k.puht}&remise=${k.remisea}&unite${k.unite}&codtva=3&tautva=${k.tva}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //         {
    //           method: "POST",
    //           header: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //           },
    //         }
    //       )
    //         .then((res) => res.json())
    //         .then(
    //           (result) => {
    //             //   this.setState({ snackbaropen: true, snackbarmsg: result });

    //             console.log(result);
    //             // window.alert(result);
    //           },
    //           (error) => {
    //             this.setState({ snackbaropen: true, snackbarmsg: "failed" });
    //           }
    //         );
    //     });
    //   })
    //   .then(
    //     (result) => {
    //       this.props.onHide();
    //       //   this.props.SelectBE();

    //       ///////partie calcul be /////////////////
    //       this.props.codbes.codbes.map((t) =>
    //         fetch(
    //           `http://www.24-crm.com/webservice/api/LIGBEREs?FACc=${t.valeur}&typfacc=BE&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //           {
    //             method: "POST",
    //             header: {
    //               Accept: "application/json",
    //               "Content-Type": "application/json",
    //             },
    //           }
    //         )
    //       );
    //     },
    //     (error) => {
    //       this.setState({ snackbaropen: true, snackbarmsg: "failed" });
    //     }
    //   )

    fetch(
      `https://www.24-crm.tn/webservice/api/FacFrs?numfac&typfac=FF&datfac=${
        this.state.defaultdate
      }&codfrs=${this.state.codeclient}&raisoc=${
        this.state.raisonsocial
      }&numfacfrs=${this.state.numfacfrs}&pj=${this.state.pj}&valtimbre=${
        this.state.valtimbre[0]
      }&smhtb=${this.state.totalhtbrut}&smremart=${
        this.state.sumremisearticle
      }&smtva=${this.state.totaltva}&tauxfodec=${Number(
        this.state.fod
      )}&taurem=${this.state.remise}&smhtn=${this.state.totalhtnet}&smremglo=${
        this.state.remiseglobal
      }&mntbon=${this.state.netnetapayer}&smfodec=${
        this.state.totalfod
      }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
        this.props.ConfigDossier.serveur
      }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
        this.props.ConfigDossier.pwd_serveur
      }&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())

      .then((res) => {
        this.setState({ numnum: res });
        this.state.tab.map((k) => {
          fetch(
            `https://www.24-crm.tn/webservice/api/TVA/?NumFac=${res}&TypFac=FF&CodTva=${k.codtva}&TauxTva=${k.tva}&Assiette=${k.puht}&Montant=${k.mnttva}&MntFodec=${k.mntfod}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            .then(
              (result) => {
                this.setState({
                  snackbaropen: true,
                  snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
                });

                // window.alert(result);
              },
              (error) => {
                this.setState({ snackbaropen: true, snackbarmsg: "failed" });
              }
            );
        });
      })
      .then(
        (resultt) => {

          fetch(
            `https://www.24-crm.tn/webservice/api/FOURNISSEURs?Codfrssss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          // .then((res) => {
          //   fetch(
          //     `http://www.24-crm.com/webservice/api/Switch?code=FF2&valeur=numfac&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          //     {
          //       method: "PUT",
          //       header: {
          //         Accept: "application/json",
          //         "Content-Type": "application/json",
          //       },
          //     }
          //   );

          // });
          window.location.reload();
          // this.props.SelectBECod(
          //   this.props.ConfigDossier.coddos,
          //   this.props.ConfigDossier.serveur,
          //   this.props.ConfigDossier.utilisateur,
          //   this.props.ConfigDossier.pwd_serveur,
          //   this.props.ConfigDossier.database
          // );
        },

        (error) => {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "failed",
          });
        }
      );

    // fetch(
    //   `http://www.24-crm.com/webservice/api/Switch?code=BE2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
    //   {
    //     method: "PUT",
    //     header: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result);
    //     },
    //     (error) => {
    //       this.setState({ snackbaropen: true, snackbarmsg: "failed" });
    //     }
    //   );
  };

  render() {
    let ligModalClose = () => this.setState({ ligModalShow: false });

    const { rem } = this.state;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });
    const { darck , classes } = this.props;
    return (

      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Facture</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `none`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                      }}
          >
            <form autocomplete="off" 
                  onSubmit={this.enregistrer}
                  className={`${darck ? "" : classes.root}`}>
              <Card>
                <Card.Body
                                  style={{
                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                    border: `${darck ? "" : "2px solid #fff"}`,
                                  }}
                >
                  <Row form>
                    <Col sm={4}>
                      <FormGroup>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="№ Facture"
                              // className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="codfacfrss"
                             // disabled
                             defaultValue={this.props.codfacfrss.codfacfrss && this.props.codfacfrss.codfacfrss[0].valeur}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },



                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                      </FormGroup>
                    </Col>
                    {/* <Col sm={5}></Col> */}
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        type="date"
                        fullWidth
                        name="datfac"
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    <Col sm={5}>
                      <TextField
                        id="standard-basic"
                        label="Facture Fournisseur N°"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="numfacfrs"
                        onChange={this.numfacfrsChange}
                        required
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                  </Row>

                  <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: !darck ? "#fff" : "grey" }}>
                              Raison sociale
                            </Grid>
                            <Grid item>
                              <Switch
                                checked={this.state.gilad}
                                onChange={this.handleChange("gilad")}
                                value="gilad"
                                color="primary"
                              />
                            </Grid>
                            <Grid item style={{ color: !darck ? "#fff" : "#3f51b5" }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.fournisseurs.fournisseurs
                            }
                            getOptionLabel={(option) => option.codfrs}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    codeclient: getOptionLabel.codfrs,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    fod: getOptionLabel.tauxfodec,
                                    cemail: getOptionLabel.email,
                                    codetva: getOptionLabel.CodeTVA,
                                    valtimbre: getOptionLabel.timbre
                                      ? this.props.valtimbre
                                      : 0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    codeclient: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    showButtonModalPassager: false,
                                    codetva: "",
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Code Fournisseur"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="codcli"
                                
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.fournisseurs.fournisseurs
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    codeclient: getOptionLabel.codfrs,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,
                                    fod: getOptionLabel.tauxfodec,
                                    cemail: getOptionLabel.email,
                                    codetva: getOptionLabel.CodeTVA,
                                    valtimbre: getOptionLabel.timbre
                                      ? this.props.valtimbre
                                      : 0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    codeclient: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    showButtonModalPassager: false,
                                    codetva: "",
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raisssoc"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      <Col sm={5}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Raison sociale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.raisonsocial}
                            fullWidth
                            name="raisssoc"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code Fournisseur"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <TextField
                    id="standard-basic"
                    label="Raison sociale"
                    // className="card add-input"
                    margin="dense"
                    variant="outlined"
                    style={{ display: "none" }}
                    value={this.state.raisonsocial}
                    fullWidth
                    name="raissoc"
                    disabled
                    InputProps={{
                      className: !darck
                        ? this.props.classes.input
                        : null,
                      style: {
                        color: !darck ? "#fff" : null,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: !darck ? "#fff" : null,
                      },
                    }}
                  />
                  <TextField
                    id="standard-basic"
                    label="Code Fournisseur"
                    // className="card add-input"
                    margin="dense"
                    variant="outlined"
                    style={{ display: "none" }}
                    value={this.state.codeclient}
                    fullWidth
                    name="codcli"
                    disabled
                    InputProps={{
                      className: !darck
                        ? this.props.classes.input
                        : null,
                      style: {
                        color: !darck ? "#fff" : null,
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        color: !darck ? "#fff" : null,
                      },
                    }}
                  />
                  <Row>
                    {/* <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Remise Globale %"
                        margin="normal"
                        //variant="outlined"
                        fullWidth
                        name="remise"
                        onChange={this.remiseChange}
                        // value={this.state.remiseg}
                      />
                    </Col> */}
                    {/* 
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="PJ"
                        margin="normal"
                        fullWidth
                        name="pj"
                        onChange={this.pjChange}
                      />
                    </Col> */}
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header style={{ height: "60px" , backgroundColor: `${darck ? "" : "#828282"}`}}>
                    <Row>
                      <Col sm={12}>
                        <Row>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{ fontSize: "16px" ,
                                         color: `${darck ? "primary" : "#fff"}`,
                                         border: `1px solid ${darck ? "primary" : "#fff"}`,}}
                                icon={<VisibilityIcon />}

                                color="primary"
                                label="Détails"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1} className="addff">
                            {this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter, Editer et supprimer article">
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        left: "-15px",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={(event) =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter, Editer et supprimer article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px",left: "-15px", }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                                           style={{
                                            backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                            border: `${darck ? "" : "2px solid #fff"}`,
                                          }}>
                      <div className="tabbf2">
                        <table>
                          <thead
                            style={{ textAlign: "center", fontSize: "12px" }}
                          >
                            <tr>
                              {/* <th>Code</th>
                              <th style={{ width: "37%" }}>Désignation</th>
                              <th>Quantité</th> */}
                              <th>PU HT</th>
                              <th>Fodec</th>
                              <th>TVA</th>
                              <th>Montant TVA</th>
                              <th>PU TTC</th>
                            </tr>
                          </thead>
                          <tbody
                                                      style={{
                                                        textAlign: "center",
                                                        color: `${darck ? "" : "#fff"}`,
                                                      }}
                          >
                            {this.state.tab.map((t) => (
                              <tr style={{ textAlign: "center" }}>
                                {/* <td>
                                  <span>{t.codearticle}</span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span> {t.des} </span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {t.qte}</span>
                                </td> */}
                                <td>
                                  {" "}
                                  <span> {Number(t.puht).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.mntfod).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.mnttva).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}
              <Card style={{ marginTop: "10px" }} id="ff1">
                <Card.Body
                                  style={{
                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                    border: `${darck ? "" : "2px solid #fff"}`,
                                  }}
                >
                  <Row>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total Fodec
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalfod).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TVA
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totaltva).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "-25px" }}>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TTC
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {/* {Number(this.state.totaltva).toFixed(3)} */}
                        {Number(this.state.netapayer).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      {/* <p
                        style={{ color: "darkslateblue", marginBottom: "-5px" }}
                      >
                        Total Quantité
                      </p>
                      <p style={{ color: "black" }}>{this.state.totalqte}</p> */}
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Valeur timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.valtimbre).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                        {/* {Number(this.state.netapayer).toFixed(3)} */}
                        {Number(this.state.netnetapayer).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="ff2">
                <Card.Body 
                                  style={{
                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                    border: `${darck ? "" : "2px solid #fff"}`,
                                  }}>
                  <Row className="rowff">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total Fodec
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalfod).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="ff">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowff">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TVA
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totaltva).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TTC
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {/* {Number(this.state.totaltva).toFixed(3)} */}
                        {Number(this.state.netapayer).toFixed(3)}
                      </p>
                    </Col>
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Valeur timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.valtimbre).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="ff">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowff">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                        {/* {Number(this.state.netapayer).toFixed(3)} */}
                        {Number(this.state.netnetapayer).toFixed(3)}
                      </p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  {this.state.totalhtbrut === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      // color="secondary"
                      type="submit"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>

        <LigFacFrs
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          // numfaccc={this.props.codbes.codbes.map(
          //   (nu) => parseInt(nu.valeur, 10) + 1
          // )}
          numfaccc={0}
          datfac={this.state.defaultdate}
          rem={rem}
          valtimbre={this.state.valtimbre}
          fod={this.state.fod}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectFournisseur: (a, b, c, d, e) => {
      dispatch(SelectFournisseur(a, b, c, d, e));
    },
    SelectBECod: (a, b, c, d, e) => dispatch(SelectBECod(a, b, c, d, e)),
    SelectBFcod: (a, b, c, d, e) => dispatch(SelectBFCod(a, b, c, d, e)),
    SelectBE: (a, b, c, d, e) => dispatch(SelectBE(a, b, c, d, e)),
    SelectFacFrsCod: (a, b, c, d, e) =>
      dispatch(SelectFacFrsCod(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    fournisseurs: state.fournisseurs,
    codbes: state.codbes,
    codfacfrss: state.codfacfrss,
    Auth: state.Auth,
    codbf: state.codbf,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddFacFrsModal));
