import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import "./bl.scss";
import { FormGroup, Col, Row} from "reactstrap";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";

import { SelectArticle } from "../../redux/actions/GetArticles";
import { TextField, Fab, IconButton } from "@material-ui/core";
import "../styling/Styling.scss";
import Center from "react-center";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import EditRowModal from "./EditRowModal";
import { SelectBLBR } from "../../redux/actions/GetBLBR";
import { Redirect } from "react-router-dom";
import { SelectClient } from "../../redux/actions/GetClients";
import translate from "../../i18n/translate";

class ModifierBLBRModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      snackbaropen: false,
      snackbarfail: false,
      editModalShow: false,
      qtte: 0,
      pmp: "",
      btnEnabled: false,
      gilad: true,
      snackbarmsg: "",
      enrsnackbaropen: false,
      fsnackbaropen: false,

      openEditModal: false,
      idel: 0,
      reload: false,

      giladd: true,

      rechs: [],
      rechsc: [],

      changeButton: false,

      remiseg: 0,
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",

      datebcc: "",

      username: username,
      clicked: false,
      clickeda: false,

      stkfin: 0,
      yesno: "",
    };
  }

  componentDidMount() {
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.sameTable();
    this.props.SelectClient(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );

    this.setState({
      remiseg: this.props.taurem,
      raisonsocial: this.props.raisonsociale,
      codeclient: this.props.client,
      categoriefiscale: this.props.catfisc,
      datebcc: new Date(this.props.datebl)
        .toLocaleDateString()
        .substring(0, 10)
        .split("/")
        .reverse()
        .join("-"),
    });
  }

  datHandler = (event) => {
    this.setState({ datebcc: event.target.value });
  };

  raisocHandler = (event) => {
    this.setState({ raisonsocial: event.target.value });
  };

  remiseglobalChange = (event) => {
    this.setState({ remiseg: event.target.value });
  };

  remiseHandler = (event) => {
    this.setState({
      remisea: event.target.value,
      totalht: Number(
        this.state.qte * this.state.puht * ((100 - event.target.value) / 100)
      ).toFixed(3),
      puttcnet: (
        this.state.puht *
        (1 + this.state.tva / 100) *
        ((100 - event.target.value) / 100)
      ).toFixed(3),
    });
  };

  puhtHandler = (event) => {
    this.setState({
      puht: event.target.value,
      totalht:
        this.state.qte *
        event.target.value *
        ((100 - this.state.remisea) / 100),
      puttcnet: Number(
        event.target.value *
          (1 + this.state.tva / 100) *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
    });
  };

  puttcHandler = (event) => {
    this.setState({
      puttcnet: event.target.value,
      puht: (
        event.target.value /
        ((100 - this.state.remisea) / 100) /
        (1 + this.state.tva / 100)
      ).toFixed(3),
      totalht: (
        this.state.qte *
        ((event.target.value /
          ((100 - this.state.remisea) / 100) /
          (1 + this.state.tva / 100)) *
          ((100 - this.state.remisea) / 100))
      ).toFixed(3),
    });
  };

  articleHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/ARTICLEs?codartt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clickeda: true }));
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechsc: data, clicked: true }));
  };

  sameTable = () => {
    fetch(
      `https://www.24-crm.tn/webservice/Api/LigBLBRs?type=BR&numfac=${this.props.blid}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          tab: data,
          //    sumqt: data && data.reduce((a, v) => a + parseInt(v.quantite), 0),
        })
      );
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarFailClose = (event) => {
    this.setState({ snackbarfail: false });
  };

  enrsnackbarClose = (event) => {
    this.setState({ enrsnackbaropen: false });
  };

  fsnackbarClose = (event) => {
    this.setState({ fsnackbaropen: false });
  };

  /////// modiifier les modifications /////////////
  modifiermodification = (event) => {
    // event.preventDefault();

    const newtab = this.state.tab.concat({
      codart: this.state.codearticle,
      desart: this.state.des,
      quantite: this.state.qte,
      unite: this.state.unite,
      priuni: this.state.puht,
      remise: this.state.remisea,
      tautva: this.state.tva,
      montht: this.state.totalht,
      PUTTCNET: this.state.puttcnet,
      pmp: this.state.pmp,
    });
    const SumQte = newtab && newtab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      newtab &&
      newtab.reduce(
        (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
        0
      );
    const SumHtBrut =
      newtab && newtab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      newtab &&
      newtab.reduce(
        (a, v) => a + v.totalht * ((100 - this.props.rem) / 100),
        0
      );
    const SumRemiseGlobale =
      newtab &&
      newtab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      newtab &&
      newtab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
        0
      );

    this.setState({
      tab: newtab,

      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      changeButton: false,
    });

    this.setState({
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unite: "",
      puht: "",
      remisea: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "N",
      stkfin: 0,
      pmp: "",
    });
  };

  /////// submit pour le bouton ajouter /////////////

  submitHandler = (event) => {
    event.preventDefault();

    if (
      this.state.tab.filter((el) => el.codart == this.state.codearticle)
        .length > 0
    ) {
      alert("article existant, veuillez modifier la quantité");
    } else {
      const newtab = this.state.tab.concat({
        codart: this.state.codearticle,
        desart: this.state.des,
        quantite: this.state.qte,
        unite: this.state.unite,
        priuni: this.state.puht,
        remise: event.target.remisea.value,
        tautva: this.state.tva,
        montht: this.state.totalht,
        PUTTCNET: this.state.puttcnet,
        pmp: this.state.pmp,
      });
      const SumQte = newtab && newtab.reduce((a, v) => a + parseInt(v.qte), 0);
      const SumRemiseArticle =
        newtab &&
        newtab.reduce(
          (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
          0
        );
      const SumHtBrut =
        newtab && newtab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
      const SumTva =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100),
          0
        );
      const SumHtNet =
        newtab &&
        newtab.reduce(
          (a, v) => a + v.totalht * ((100 - this.props.rem) / 100),
          0
        );
      const SumRemiseGlobale =
        newtab &&
        newtab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
      const SumNetAPayer =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            (v.totalht * ((100 - this.props.rem) / 100) +
              v.qte * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100)),
          0
        );

      this.setState({
        tab: newtab,

        totalqte: SumQte,
        sumremisearticle: SumRemiseArticle,
        totalhtbrut: SumHtBrut,
        totaltva: SumTva,
        totalhtnet: SumHtNet,
        remiseglobal: SumRemiseGlobale,
        netapayer: SumNetAPayer,
        snackbaropen: true,
        btnEnabled: true,
      });

      this.setState({
        codearticle: "",
        qte: "",
        totalht: 0,
        des: "",
        unite: "",
        puht: "",
        remisea: 0,
        tva: 0,
        pmp: "",
        puttcnet: 0,
        faudec: "N",
      });
    }
  };

  onCellChange = (event) => {
    this.setState({ codearticle: event.target.value });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  deleteRow = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbarfail: true,
      btnEnabled: true,
    });
  };

  ///////////// delete for modification without snackbaropende ///////////
  deleteRowMod = (index) => {
    // // update stock
    // fetch(
    //   `/api/LigBLBRs?fac=${this.props.blid}&Typfacs=BR&NumComs=&Typcoms=&GMMs=&CODDEPs=&GNSs=`,
    //   {
    //     method: "POST",
    //     header: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       console.log("mise a jour stock avec succée");
    //       console.log(result);
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) * v.puht * ((100 - v.remisea) / 100) * (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      btnEnabled: true,
    });
  };

  qteHandler = (event) => {

    this.setState({
      qte: event.target.value,
      puttcnet: Number(this.state.puht * (1 + this.state.tva / 100)).toFixed(3),
      totalht: Number(
        event.target.value *
          this.state.puht *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
    });

    this.state.stkfin >= parseInt(event.target.value)
      ? this.setState({ yesno: "yes" })
      : this.setState({ yesno: "no" });
  };

  enregistrer = () => {
    ///////////////// Update blbrs ////////////
    fetch(
      `https://www.24-crm.tn/webservice/api/BLBRs?numfac=${this.props.blid}&typfac=BR&datfac=${this.state.datebcc}&codcli=${this.state.codeclient}&raisoc=${this.state.raisonsocial}&taurem=${this.state.remiseg}&catfisc=${this.state.categoriefiscale}&timbre=${this.props.droitdetimbre}&ForfaitCli=${this.props.avanceimpot}&VENDEUR=${this.props.Auth.username}&facture=${this.props.facture}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "PUT",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      // ////////// updae stock ///////////////////
      // fetch(
      //   `http://www.24-crm.com/webservice/api/LigBLBRs?fac=${this.props.blid}&Typfacs=BR&NumComs=&Typcoms=&GMMs=&CODDEPs=&GNSs=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      //   {
      //     method: "POST",
      //     header: {
      //       Accept: "application/json",
      //       "Content-Type": "application/json",
      //     },
      //   }
      // )
      //   .then((res) => res.json())
      .then((result) => {
        console.log(result);
        ////////// first part delete ///////////////////
        fetch(
          `https://www.24-crm.tn/webservice/api/LigBLBRs/${this.props.blid}?typfacc=BR&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "DELETE",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((result) => {
            console.log(result);
            window.location.reload()
            ///////////// second part add new lig to table ligblbr//////////////

            this.state.tab.map(
              (k, index) => {
                fetch(
                  `https://www.24-crm.tn/webservice/api/LigBLBRs/{ID}?numfac=${
                    this.props.blid
                  }&typfac=BR&numlig=${index + 10}&codart=${
                    k.codart
                  }&quantite=${k.quantite}&fodart=0&desart=${k.desart}&datfac=${
                    this.props.datebl
                  }&priuni=${k.priuni}&remise=${k.remise}&unite${
                    k.unite
                  }&codtva=3&pmp=${k.pmp}&tautva=${k.tautva}&coddos=${
                    this.props.ConfigDossier.coddos
                  }&serveur=${this.props.ConfigDossier.serveur}&user=${
                    this.props.ConfigDossier.utilisateur
                  }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
                    this.props.ConfigDossier.database
                  }`,
                  {
                    method: "POST",
                    header: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  }
                )
                  .then((res) => res.json())
                  .then(
                    (result) => {
                      console.log(result);

                      //// third part calcul with vue/////////////
                      fetch(
                        `https://www.24-crm.tn/webservice/api/LigBLBRs?FAC=${this.props.blid}&typfacc=BR&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                        {
                          method: "POST",
                          header: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                          },
                        }
                      )
                        .then((res) => res.json())
                        .then(
                          (result) => {
                            console.log(result);
                          },
                          (error) => {
                            this.setState({
                              snackbaropen: true,
                              snackbarmsg: "failed",
                            });
                          }
                        );
                    },
                    (error) => {
                      this.setState({
                        enrsnackbaropen: true,
                        snackbarmsg: "failed",
                      });
                    }
                  );
              },
              (error) => {
                this.setState({
                  enrsnackbaropen: true,
                  snackbarmsg: "failed",
                });
              }
            );
          });
        // setTimeout(() => {
        //   ///////////// 4th part  total calcul ///////////////
        //   fetch(
        //     `http://www.24-crm.com/webservice/api/LigBLBRs?FAC=${this.props.blid}&Typfacc=br&CODDEPP=&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        //     {
        //       method: "POST",
        //       header: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   )
        //     .then((res) => res.json())
        //     .then(
        //       (result) => {
        //         window.location.reload();
        //       },
        //       (error) => {
        //         this.setState({
        //           snackbaropen: true,
        //           snackbarmsg: "failed",
        //         });
        //       }
        //     );
        // }, 1000);
      })
      // .then(() => );
  };

  render() {
    let editModalClose = () => this.setState({ editModalShow: false });
    let EditModalClose = () => this.setState({ openEditModal: false });
    const { darck, classes } = this.props;
    const {
      quantt,
      remiss,
      artid,
      totalhtt,
      puttcnett,
      numligg,
      numfacccc,
      prixunix,
    } = this.state;

    if (this.state.reload) return <Redirect to="/bon-de-retour-livraison" />;
    // data =
   
    return (
      <div className="container">
        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarClose}
            severity="success"
          >
            {translate("Article ajouté")}
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.snackbarfail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarFailClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarFailClose}
            severity="error"
          >
            {translate("Article supprimé")}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.enrsnackbaropen}
          autoHideDuration={2000}
          onClose={this.enrsnackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.enrsnackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton 
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}>
            <Modal.Title id="contained-modal-title-vcenter">
              <b>
                {/* {translate("Return slip modifier")} № {this.props.blid} */}
                Bon de retour № {this.props.blid}
              </b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}>
            <Row>
              <Col style={{ margin: "0px" }} >
                <Card style={{ marginBottom: "10px" }}>
                  <Card.Body style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}>
                    <Row style={{ marginBottom: "-20px", marginTop: "-20px" }}>
                      <Col sm={8}>
                        <FormGroup>
                          <Typography component="div">
                            <Grid
                              component="label"
                              container
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid style={{ color: `${darck ? "" : "#fff"}` }}>
                                <b > {translate("Chercher client par")} :</b>
                              </Grid>
                              &nbsp;&nbsp;
                              <Grid item style={{ color: "grey" }}>
                                {translate("Raison Social")}
                              </Grid>
                              <Grid item>
                                <Switch
                                  checked={this.state.giladd}
                                  onChange={this.handleChange("giladd")}
                                  value="giladd"
                                  color="primary"
                                />
                              </Grid>
                              <Grid item style={{ color: "#3f51b5" }}>
                                {translate("Code client")}
                              </Grid>
                            </Grid>
                          </Typography>
                        </FormGroup>
                      </Col>

                      {this.state.giladd ? (
                        <Col sm={4} style={{  marginBottom: "15px" ,paddingLeft: "105px"}}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              // options={this.state.rechsc}
                              options={
                                this.state.clicked
                                  ? this.state.rechsc
                                  : this.props.clients.clients
                              }
                              getOptionLabel={(option) => option.codcli}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      raisonsocial: getOptionLabel.raisoc,
                                      remiseg: getOptionLabel.remise,
                                      codeclient: getOptionLabel.codcli,
                                      categoriefiscale: getOptionLabel.catfisc,
                                      btnEnable: true,
                                      showTimbre: getOptionLabel.timbre,
                                      showForfitaire: getOptionLabel.regimecli,
                                      showButtonModalPassager:
                                        getOptionLabel.passager,
                                      cemail: getOptionLabel.email,
                                    })
                                  : this.setState({
                                      raisonsocial: this.props.raisonsociale,
                                      remiseg: this.props.taurem,
                                      codeclient: this.props.client,
                                      categoriefiscale: this.props.catfisc,
                                      btnEnable: false,
                                      showTimbre: false,
                                      showForfitaire: 0,
                                      showButtonModalPassager: false,
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={translate("Code client")}
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.clientHandlerChange}
                                  name="codcli"
                                 
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <Col sm={4} style={{  marginBottom: "15px" ,paddingLeft: "105px"}}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              // options={this.state.rechsc}
                              options={
                                this.state.clicked
                                  ? this.state.rechsc
                                  : this.props.clients.clients
                              }
                              getOptionLabel={(option) => option.raisoc}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      raisonsocial: getOptionLabel.raisoc,
                                      remiseg: getOptionLabel.remise,
                                      codeclient: getOptionLabel.codcli,
                                      categoriefiscale: getOptionLabel.catfisc,
                                      btnEnable: true,
                                      showTimbre: getOptionLabel.timbre,
                                      showForfitaire: getOptionLabel.regimecli,
                                      showButtonModalPassager:
                                        getOptionLabel.passager,
                                      cemail: getOptionLabel.email,
                                    })
                                  : this.setState({
                                      raisonsocial: this.props.raisonsociale,
                                      remiseg: this.props.taurem,
                                      codeclient: this.props.client,
                                      categoriefiscale: this.props.catfisc,
                                      btnEnable: false,
                                      showTimbre: false,
                                      showForfitaire: 0,
                                      showButtonModalPassager: false,
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={translate("Raison Social")}
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.clientHandlerChange}
                                  name="raissoc"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      )}
                    </Row>

                    <Row style={{ marginBottom: "-20px" }}>
                      <Col sm={2}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code client"
                            className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={5}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label={translate("Raison Social")}
                            className="card add-input"
                            margin="dense"
                            variant="outlined" //variant="outlined"
                            value={this.state.raisonsocial}
                            fullWidth
                            name="raissoc"
                            disabled={
                              this.state.codeclient === "100" ? false : true
                            }
                            onChange={this.raisocHandler}
                          />
                        </FormGroup>
                      </Col>

                      <Col sm={2}>
                        <TextField
                          id="standard-basic"
                          label={translate("Remise Globale %")}
                          className="card add-input"
                          margin="dense"
                          variant="outlined" //variant="outlined"
                          fullWidth
                          name="remise"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={this.remiseglobalChange}
                          value={this.state.remiseg}
                        />
                      </Col>

                      <Col sm={3}>
                        <TextField
                          id="standard-basic"
                          label="Date"
                          className="card add-input"
                          margin="dense"
                          variant="outlined"
                          type="date"
                          fullWidth
                          name="datfac"
                          onChange={this.datHandler}
                          value={this.state.datebcc}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          // defaultValue={new Date(this.props.datebl)
                          //   .toISOString()
                          //   .substr(0, 10)}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Body style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}>
                    <form autocomplete="off" onSubmit={this.submitHandler}>
                      <Row form>
                        <b style={{ color: `${darck ? "" : "#fff"}` }}>Chercher article par :</b>
                        <b style={{ marginTop: "10px", marginLeft: "10px" }}>
                          {this.state.faudec === "A" ? (
                            <p style={{ color: "grey" }}>Fodec: ✔</p>
                          ) : null}
                        </b>
                      </Row>

                      <Row form>
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unitee: getOptionLabel.unite,
                                      puht: Number(
                                        getOptionLabel.PUDHT
                                      ).toFixed(3),
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      stkfin: getOptionLabel.stkfin,
                                      codtvaa: getOptionLabel.codtva,
                                      pmp: getOptionLabel.pmp,
                                      codbar: getOptionLabel.codbar,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unitee: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "",
                                      stkfin: 0,
                                      codtvaa: "",
                                      codbar: "",
                                      pmp: "",
                                      qte: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={translate("Code article")}
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.desart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unitee: getOptionLabel.unite,
                                      puht: Number(
                                        getOptionLabel.PUDHT
                                      ).toFixed(3),
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      stkfin: getOptionLabel.stkfin,
                                      codtvaa: getOptionLabel.codtva,
                                      pmp: getOptionLabel.pmp,
                                      codbar: getOptionLabel.codbar,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unitee: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "",
                                      stkfin: 0,
                                      qte: "",
                                      codbar: "",
                                      codtvaa: "",
                                      puttcnet: 0,
                                      pmp: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={translate("Désignation article")}
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codbar}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unitee: getOptionLabel.unite,
                                      puht: Number(
                                        getOptionLabel.PUDHT
                                      ).toFixed(3),
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      stkfin: getOptionLabel.stkfin,
                                      codtvaa: getOptionLabel.codtva,
                                      pmp: getOptionLabel.pmp,
                                      codbar: getOptionLabel.codbar,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unitee: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "",
                                      stkfin: 0,
                                      qte: "",
                                      codbar: "",
                                      codtvaa: "",
                                      puttcnet: 0,
                                      pmp: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={translate("Code à Barre")}
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col sm={3}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Code article")}
                              value={this.state.codearticle}
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Désignation")}
                              value={this.state.des}
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={1}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Qte"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={this.state.qte}
                              onChange={this.qteHandler}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              value={`Stock: ${this.state.stkfin}`}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Unité")}
                              value={this.state.unite}
                              fullWidth
                              disabled
                              size="small"
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("PUHT")}
                              value={this.state.puht}
                              fullWidth
                              name="puht"
                              onChange={this.puhtHandler}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Remise %")}
                              value={this.state.remisea}
                              fullWidth
                              name="remisea"
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              onChange={this.remiseHandler}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("TVA")}
                              value={this.state.tva}
                              fullWidth
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("PUTTCNet")}
                              value={this.state.puttcnet}
                              fullWidth
                              onChange={this.puttcHandler}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("TotalHT")}
                              value={Number(this.state.totalht).toFixed(3)}
                              fullWidth
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {this.state.changeButton ? (
                        <Center>
                          <Button
                            disabled={
                              this.state.yesno === "no" ||
                              this.state.qte === "" ||
                              this.state.stkfin <= 0 ||
                              parseInt(this.state.qte) <= 0
                                ? true
                                : false
                            }
                            style={{ width: "320px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.modifiermodification();
                            }}
                          >
                            {translate("Enregistrer les modifications")}
                          </Button>
                        </Center>
                      ) : this.state.des === "" ||
                        this.state.yesno === "no" ||
                        this.state.qte === "" ||
                        this.state.stkfin <= 0 ||
                        parseInt(this.state.qte) <= 0 ? (
                        <Center>
                          <Button
                            disabled
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {translate("Ajouter")}
                          </Button>
                        </Center>
                      ) : (
                        <Center>
                          <Button
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      )}
                    </form>
                  </Card.Body>
                </Card>

                <Card style={{ marginTop: "10px" }}>
                  <Card.Body style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}>
                    <div className="tabbl2">
                      <table 
                    
                      style={{
                          marginTop: "10px",
                          backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        }}>
                        <thead
                          style={{ textAlign: "center", fontSize: "12px" }}
                        >
                          <tr>
                            <th>Code</th>
                            <th style={{ width: "37%" }}>
                              {" "}
                              {translate("Désignation")}{" "}
                            </th>
                            <th> {translate("Quantité")} </th>
                            <th> {translate("PUHT")} </th>
                            <th> {translate("Remise")} </th>
                            <th> {translate("TVA")} </th>
                            <th> {translate("TotalHT")} </th>
                            <th> {translate("PUNet")} </th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody
                          style={{
                            overflowY: "auto",
                            display: "block",
                            maxHeight: "10em",
                          }}
                        >
                          {this.state.tab.map((t, i) => (
                            <tr key={i}>
                              <td  style={{ color: `${darck ? "" : "#fff"}`,
                                }}>
                                <span>{t.codart}</span>
                              </td>
                              <td  style={{
                                  fontSize: "12px",
                                  width: "37%",
                                  color: `${darck ? "" : "#fff"}`,
                                }}>
                                {t.desart}
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{t.quantite}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.priuni).toFixed(3)}</span>
                              </td>

                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.remise).toFixed(2)}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.tautva).toFixed(2)}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.montht).toFixed(3)}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <span>{Number(t.PUTTCNET).toFixed(3)}</span>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <Tooltip title="Modifier cet article">
                                  <Fab size="small">
                                    <EditIcon
                                      onClick={() => {
                                        fetch(
                                          `https://www.24-crm.tn/webservice/api/ARTICLEs?codartt=${t.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                        )
                                          .then((response) => response.json())
                                          .then((data) =>
                                            this.setState({
                                              stkfin: data.map((t) => t.stkfin),
                                            })
                                          );

                                        this.setState({
                                          codearticle: t.codart,
                                          des: t.desart,
                                          unite: t.unite,
                                          puht: t.priuni,
                                          remisea: t.remise,
                                          tva: t.tautva,
                                          faudec: t.typfodec,
                                          qte: t.quantite,
                                          totalht: t.montht,
                                          puttcnet: t.PUTTCNET,
                                          pmp: t.pmp,
                                          changeButton: true,
                                        });
                                        this.deleteRowMod(i);
                                      }}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                              <td style={{ color: `${darck ? "" : "#fff"}` }}>
                                <Tooltip
                                  title={translate("Supprimer cet article")}
                                >
                                  <Fab size="small">
                                    <DeleteIcon
                                      style={{}}
                                      onClick={() => this.deleteRow(i)}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <EditRowModal
              show={this.state.openEditModal}
              onHide={EditModalClose}
              quantt={quantt}
              remiss={remiss}
              artid={artid}
              totalhtt={totalhtt}
              puttcnett={puttcnett}
              numfacccc={numfacccc}
              numligg={numligg}
              prixunix={prixunix}
              tab={this.state.tab}
            />
          </Modal.Body>
          <Modal.Footer style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  border: `${darck ? "" : "2px solid #fff"}`,
                }}>
            {!this.state.tab ? (
              <Button disabled variant="contained" style={{ width: "30%" }}>
                {translate("Enregistrer")}
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(0, 8, 126)",
                  color: "white",
                  width: "30%",
                }}
                onClick={() => {
                  // this.props.submitHandler(this.state.tab,
                  //     this.state.totalqte,
                  //     this.state.sumremisearticle,
                  //     this.state.totalhtbrut,
                  //     this.state.totaltva,
                  //     this.state.totalhtnet,
                  //     this.state.remiseglobal,
                  //     this.state.netapayer,
                  //     this.state.btnEnabled,
                  // )
                  this.enregistrer();
                  this.props.onHide();
                  this.props.onHide01();
                  window.alert("Modification enregistrés");

                  this.props.SelectBLBR(
                    this.props.ConfigDossier.coddos,
                    this.props.ConfigDossier.serveur,
                    this.props.ConfigDossier.utilisateur,
                    this.props.ConfigDossier.pwd_serveur,
                    this.props.ConfigDossier.database
                  );
                }}
              >
                {translate("Enregistrer")}
              </Button>
            )}
         </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    SelectBLBR: (a, b, c, d, e) => dispatch(SelectBLBR(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => dispatch(SelectClient(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    articles: state.articles,
    clients: state.clients,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModifierBLBRModal);
