const initialState = {
  ReleverClient: [],
};
export default function (state = initialState, action) {
  switch (action.type) {
    case "RELEVERCLIENT_GET":
      return {
        ...state,
        ReleverClient: action.payload,
      };
    default:
      return state;
  }
}
