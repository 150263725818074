import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import SideBarReducer from "./reducers/SideBarReducer";
import thunkMiddlewar from "redux-thunk";
import { DataTablesReducer } from "./reducers/DataTablesReducer";
import loaderReducer from "./reducers/LoaderReducer";
import SearchingReducer from "./reducers/SearchingReducer";
import DevisClientReducer from "./reducers/DevisClient";
import { reducer as reduxFormReducer } from "redux-form";
import GetClientsReducer from "./reducers/GetClients";
import GetArticlesReducer from "./reducers/GetArticles";

import GetNumFacDevisReducer from "./reducers/GetNumfacDevis";
import GetNumFacAvoirReducer from "./reducers/GetNumfacAvoir";

import GetDevisLigReducer from "./reducers/GetDevisLig";
import GetTestReducer from "./reducers/TestReducer";
import GetBCReducer from "./reducers/GetBC";
import GetBCLigModal from "./reducers/GetBCLig";
import GetBCFrs from "./reducers/GetBCFrs";
import GetBLReducer from "./reducers/GetBL";
import GetBLLigReducer from "./reducers/GetBLLig";
import GetFactureReducer from "./reducers/GetFacture";
import GetFactureLigReducer from "./reducers/GetFactureLig";
import SetAffectationRegCliReducer from "./reducers/setAffectationRegCli";
import ReleverClient from "./reducers/ReducerGetReleverClient";
import AllReleverClient from "./reducers/GetAllreleverCli.js";
import AllReleverFRS from "./reducers/GetAllreleverFRS.js";
import ReleverFrc from "./reducers/ReducerGetReleverFrc";

// import GetFichesReducer from "./reducers/GetficheSTK";
// import GetFichesGMMReducer from "./reducers/GetficheSTKGMM";
// import AffStock from "./reducers/ReducerGetAffStock";
// import fichesstock from "./reducers/GetficheSTK"
// import GetNumFacDevisReducer from "./reducers/GetNumfacDevis";
// import GetDevisLigReducer from "./reducers/GetDevisLig";
import GetNomeReducer from "./reducers/GetNome";
import GetSousFamilleReducer from "./reducers/GetSousFamille";
import GetCodartReducer from "./reducers/GetCodart";
import GetCodCliReducer from "./reducers/GetCodcli";
import GetFournisseurReducer from "./reducers/GetFournisseur";

import GetCodFrsReducer from "./reducers/GetCodFrs";
import GetSumQLigDVReducer from "./reducers/GetSumQLigDV";
import GetDBReducer from "./reducers/GetDBFolders";
import GetCodBlReducer from "./reducers/GetBLcod.";
import GetNomenclatureReducer from "./reducers/GetNomenclature";
import GetCodBCReducer from "./reducers/Getcodbc";
import GetCodBCFRsReducer from "./reducers/Getcodbcfrs";
import GettopclientReducer from "./reducers/Top5";
import GetTopFrsReducer from "./reducers/Top5Frs";
import GetSumChargeReducer from "./reducers/SumCharge";
import GetBEReducer from "./reducers/GetBE";
import GetBECodReducer from "./reducers/GetBECod";
import GetFacFrsCodReducer from "./reducers/GetFacFrsCod";
import GetRECodReducer from "./reducers/GetRECod";
import GetRegReducer from "./reducers/GetReg";
import GetNomeBQReducer from "./reducers/GetNomeBQ";
import GetNomeAGReducer from "./reducers/GetNomeAG";
import GetNomeCSReducer from "./reducers/GetNomeCS";
import GetNomeSCReducer from "./reducers/GetNomeSituation";
import GetNomeCCBReducer from "./reducers/GetNomeCCB";
import GetAllNomeReducer from "./reducers/GetAllNome";
import GetCatNomeReducer from "./reducers/GetCatNome";
import GetCodFacReducer from "./reducers/GetCodFac";
import GetVendeurReducer from "./reducers/GetVendeur";
import getCodVDReducer from "./reducers/GetCodVD";
import getValTimbreReducer from "./reducers/GetValTimbre";
import getBSReducer from "./reducers/GetBS";
import getBSCReducer from "./reducers/GetBSC";
import getBSCodReducer from "./reducers/GetBSCod";
import getBSCCodReducers from "./reducers/GetBSCCod";
import getBLBReducer from "./reducers/GetBLBR";
import getBLBCodReducer from "./reducers/GetBLBRcod";
import GetBLBRLigReducer from "./reducers/GetBLBRLig";
import GetREReducer from "./reducers/GetRE";
import GetAvoirClient from "./reducers/GetAvoirClient";
import GetAvoirClientCod from "./reducers/GetAvoirCLientCod";
import GetAvoirClientLig from "./reducers/GetAvoirClientLig";
import GetRECode from "./reducers/GetRECode";
import GetBordReducer from "./reducers/GetBord";
import GetReglementFournisseur from "./reducers/GetReglementFournisseur";
import GetReglementFournisseurCod from "./reducers/GetReglementFournisseurCod";
import GetReglementFournisseurLig from "./reducers/GetReglementFournisseurLig";
import AuthReducer from "./reducers/AuthReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import SelectAVCod from "./reducers/GetCodAv";
import GetFacFrsReducer from "./reducers/GetFacFrs";
import SetAffectationRegFrsReducer from "./reducers/SetAffectationRegFrsReducer";
import GetBLFrsReducer from "./reducers/GetBLFrsReducer";
import GetBFCODreducer from "./reducers/GetBFCODreducer";
import SetAffectationMntRetenuFRSReducer from "./reducers/SetAffectationMntRetenuFRS";
// import { setAffectationMntRetenuALaSourceCli } from "./actions/SetAffectationMntRetenuALaSourceCli";
import SetAffectationMntRetenucliReducer from "./reducers/SetAffectationRetenuALaSourceCli";
import SetDossierReducer from "./reducers/SetDossierReducer";
import InfoEntrepriseReducer from "./reducers/InfoEntrepriseReducer";
import GetBCFrsLig from "./reducers/GetBCFrsLig";
import GetDossier from "./reducers/GetDossier";
import darck from "./reducers/darckModeReucer";

import GetMemosReducer from "./reducers/GetMemo";
import GetNomcategorieReducer from "./reducers/GetNomCategorie.js";

import GetCaisse from"./reducers/GetCaisse.js";
import GetBordsReducer from "./reducers/GetBordereau";
import Getdepense from "./reducers/Getdepense.js";
import GetEmpl from "./reducers/GetEmpl.js";
import GetCodCais from "./reducers/GetCodCais.js";
import GetVends from "./reducers/GetVend.js"


const persistConfig = {
  key: "root",
  storage,
};
const persistConfig2 = {
  key: "Config",
  storage,
};
const persistConfig3 = {
  key: "Info",
  storage,
};

// const persistedReducer = persistReducer(
//   persistConfig,

// );
const middleware = [thunkMiddlewar];
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));
const myStore = createStore(
  combineReducers({
    darck: darck,
    ReleverClient: ReleverClient,
    AllReleverClient: AllReleverClient,
    AllReleverFRS: AllReleverFRS,
    ReleverFrc: ReleverFrc,
    //AffStock:AffStock,
    // fichesstock:fichesstock,
    SideBarTitles: SideBarReducer,
    DataTablesReducer,
    loaderReducer,
    SearchingReducer,
    devis: DevisClientReducer,
    form: reduxFormReducer,
    clients: GetClientsReducer,
    
    bordereaux: GetBordsReducer,

    articles: GetArticlesReducer,

    numfac: GetNumFacDevisReducer,
  

    ligs: GetDevisLigReducer,
    tests: GetTestReducer,
    bcs: GetBCReducer,
    bcligs: GetBCLigModal,
    bcfligs: GetBCFrsLig,
    bcf: GetBCFrs,
    bls: GetBLReducer,
    blbrs: getBLBReducer,
    brs: GetREReducer,
    codbrs: GetRECode,
    codblbr: getBLBCodReducer,
    blligs: GetBLLigReducer,
    blbrligs: GetBLBRLigReducer,

    rf: GetReglementFournisseur,
    codrf: GetReglementFournisseurCod,
    rfligs: GetReglementFournisseurLig,
    nomCategories:GetNomcategorieReducer,
    avoirs: GetAvoirClient,
    codavoirs: GetAvoirClientCod,
    avoirsligs: GetAvoirClientLig,

    factures: GetFactureReducer,
    fligs: GetFactureLigReducer,
    nomes: GetNomeReducer,
    sousfamilles: GetSousFamilleReducer,
    codarts: GetCodartReducer,
    codclis: GetCodCliReducer,
    fournisseurs: GetFournisseurReducer,

    Caisses:GetCaisse,
    // fiches: GetFichesReducer,
    // fichesGMM: GetFichesReducer,
    memos: GetMemosReducer,
    codCais:GetCodCais,
    codfrss: GetCodFrsReducer,
    sums: GetSumQLigDVReducer,
    dbs: GetDBReducer,
    codbls: GetCodBlReducer,
    depenses:Getdepense,
    Emplss:GetEmpl,
    nomenclatures: GetNomenclatureReducer,
    numfacbcs: GetCodBCReducer,
    numfacbcfrs: GetCodBCFRsReducer,
    tops: GettopclientReducer,
    topfrss: GetTopFrsReducer,
    charges: GetSumChargeReducer,
    bes: GetBEReducer,
    codbes: GetBECodReducer,
    codfacfrss: GetFacFrsCodReducer,
    codres: GetRECodReducer,
    regs: GetRegReducer,
    bqs: GetNomeBQReducer,
    ags: GetNomeAGReducer,
    css: GetNomeCSReducer,
    scs: GetNomeSCReducer,
    ccbs: GetNomeCCBReducer,
    allnomes: GetAllNomeReducer,
    catnomes: GetCatNomeReducer,
    codBords:GetBordReducer,
    codfacs: GetCodFacReducer,
    codavs: GetNumFacAvoirReducer,
    vends:GetVends,
    vendeurs: GetVendeurReducer,
    codvds: getCodVDReducer,
    codav: SelectAVCod,
    codbf: GetBFCODreducer,
    valtimbres: getValTimbreReducer,
    bsss: getBSReducer,
    bssc: getBSCReducer,
    bscods: getBSCodReducer,
    bsccods: getBSCCodReducers,
    facfrs: GetFacFrsReducer,
    blfrs: GetBLFrsReducer,
    detailTabRegCli: SetAffectationRegCliReducer,
    detailTabRegFrs: SetAffectationRegFrsReducer,
    Auth: persistReducer(persistConfig, AuthReducer),
    mntretenufrs: SetAffectationMntRetenuFRSReducer,
    mntretenucli: SetAffectationMntRetenucliReducer,
    ConfigDossier: persistReducer(persistConfig2, SetDossierReducer),
    InfoDossier: persistReducer(persistConfig3, InfoEntrepriseReducer),
    dossier: GetDossier,
  }),
  enhancer
);

let persistor = persistStore(myStore);

export { myStore, persistor };
