import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {
  TextField,
  Checkbox,
  Grid,
  Snackbar,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { GetCodcli } from "../../../redux/actions/GetCodcli";
import { SelectClient } from "../../../redux/actions/GetClients";
import { withStyles } from "@material-ui/styles";
import { parse } from "@fortawesome/fontawesome-svg-core";
import { parseTwoDigitYear } from "moment/moment";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});
var curr = new Date();
curr.setDate(curr.getDate());

var date = curr.toISOString().substr(0, 10);
class AddCModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passager: 0,
      timbre: 0,
      soustraitant: 0,
      snackbaropen: false,
      snackbarmsg: ",",
      defaultdate: date,
      tablecat: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
      ],

      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],
      numnum: 0,
      categoriefiscale: "",
      attestation: "",
      dateetabli: "",
      datexp: "",
      statutsCodeClient: false,
   
    };
  }

  componentDidMount() {
    this.props.GetCodcli(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  

  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

  submitHandler = (event) => {
    event.preventDefault();

    let res = this.props.allCodeClient.every(
      (el) => el.codcli !== event.target.codcli.value
    );

    if (res) {
      if(this.state.categoriefiscale=="0"||this.state.categoriefiscale=="6"){
        fetch(
          `https://www.24-crm.tn/webservice/api/CLIENTs?codcli=${event.target.codcli.value}&raisoc=${event.target.raisoc.value}&tel1=${event.target.tel1.value}&tel2=${event.target.tel2.value}&passager=${event.target.passager.value}&email=${event.target.emailcli.value}&adr=${event.target.adressecli.value}&sitweb=${event.target.sitewebcli.value}&cin=${event.target.cincli.value}&ville=${event.target.villecli.value}&cp=${event.target.cp.value}&codtva=${event.target.identcli.value}&compte=${event.target.fodeccli.value}&timbre=${event.target.timbre.value}&NAR=&RC=${event.target.contactcli.value}&CodDep=${event.target.impotcli.value}&catfisc=${this.state.categoriefiscale}&RIB2=${event.target.comptable.value}&numexon=${this.state.attestation}&dfexon=${this.state.defaultdate}&dvexon=${this.state.defaultdate}&regimecli=${event.target.regforf.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
         
          {
            method: "POST",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              this.props.onHide();
              this.props.SelectClient(
                this.props.ConfigDossier.coddos,
                this.props.ConfigDossier.serveur,
                this.props.ConfigDossier.utilisateur,
                this.props.ConfigDossier.pwd_serveur,
                this.props.ConfigDossier.database
              );
  
              this.setState({ snackbaropen: true, snackbarmsg: result });
              this.props.GetCodcli(
                this.props.ConfigDossier.coddos,
                this.props.ConfigDossier.serveur,
                this.props.ConfigDossier.utilisateur,
                this.props.ConfigDossier.pwd_serveur,
                this.props.ConfigDossier.database
              );
  
  
          //  window.location.reload();
            },
            (error) => {
              this.setState({ snackbaropen: true, snackbarmsg: "failed" });
            }
          ) 
         
          ;
      }else{
      fetch(
        `https://www.24-crm.tn/webservice/api/CLIENTs?codcli=${event.target.codcli.value}&raisoc=${event.target.raisoc.value}&tel1=${event.target.tel1.value}&tel2=${event.target.tel2.value}&passager=${event.target.passager.value}&email=${event.target.emailcli.value}&adr=${event.target.adressecli.value}&sitweb=${event.target.sitewebcli.value}&cin=${event.target.cincli.value}&ville=${event.target.villecli.value}&cp=${event.target.cp.value}&codtva=${event.target.identcli.value}&compte=${event.target.fodeccli.value}&timbre=${event.target.timbre.value}&NAR=&RC=${event.target.contactcli.value}&CodDep=${event.target.impotcli.value}&catfisc=${this.state.categoriefiscale}&RIB2=${event.target.comptable.value}&numexon=${this.state.attestation}&dfexon=${this.state.datexp}&dvexon=${this.state.dateetabli}&regimecli=${event.target.regforf.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        
        {
          method: "POST",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.props.onHide();
            this.props.SelectClient(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );

            this.setState({ snackbaropen: true, snackbarmsg: result });
            this.props.GetCodcli(
              this.props.ConfigDossier.coddos,
              this.props.ConfigDossier.serveur,
              this.props.ConfigDossier.utilisateur,
              this.props.ConfigDossier.pwd_serveur,
              this.props.ConfigDossier.database
            );


      //    window.location.reload();
          },
          (error) => {
            this.setState({ snackbaropen: true, snackbarmsg: "failed" });
          }
        ) 
        //POUR INCREMANTATION CODE CLIENT
        // .then((res) => {
        //   //////////// switch update ////////////////
  
        //   fetch(
        //     `https://www.24-crm.tn/webservice/api/Switch?code=CLI&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        //     {
        //       method: "PUT",
        //       header: {
        //         Accept: "application/json",
        //         "Content-Type": "application/json",
        //       },
        //     }
        //   )
        //     .then((res) => res.json())
        //     .then(
        //       (result) => {
        //         console.log(result);
             
        //       },
        //       (error) => {
        //         this.setState({ snackbaropen: true,  snackbarmsg: "Enregistrétest sous le N° " + this.state.numnum, });
        //       }
        //     );
        //   // .then((res) => {
        //   //   window.location.reload();
        //   // });
        // })
        ;}
    } else {
      this.setState({ statutsCodeClient: true });
    }
  };

  render() {
    const { darck, classes, allCodeClient } = this.props;
    const { statutsCodeClient } = this.state;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
             
            >
              x
             
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ padding: "0px" }}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Client</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.submitHandler}
              className={`${darck ? "" : classes.root}`}
            >
              <Row form>
                <Col sm={6}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      <Row form>
                        {/* <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            {/* {this.props.codclis.codclis.map((cod) => ( 
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Code Client"
                              margin="dense"
                              variant="outlined"
                              // value={parseInt(
                              //   this.props.codclis.codclis[0] &&
                              //     this.props.codclis.codclis[0].valeur
                              // )}
                              // value={
                              //   this.props.codclis.codclis[0] &&
                              //     this.props.codclis.codclis[0].valeur
                              // }
                              fullWidth
                              name="codcli"
                              required
                              // onChange={(event) =>
                              //   this.setState({
                              //     CodeClient: event.target.value,
                              //   })
                              // }
                              error={statutsCodeClient}
                              helperText={
                                statutsCodeClient
                                  ? "Code Client existe déjà"
                                  : ""
                              }
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                            {/* ))} 
                          </FormGroup>
                        </Col> */}
                           <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            {this.props.codclis.codclis.map((cod) => ( 
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Code Client"
                              margin="dense"
                              variant="outlined"
                             
                              fullWidth
                              name="codcli"
                              required
                              value={(parseInt(cod.Column1) + 1).toString().padStart(4,'0')}
                             
                              error={statutsCodeClient}
                              helperText={
                                statutsCodeClient
                                  ? "Code Client existe déjà"
                                  : ""
                              }
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                           ))} 
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <Grid
                              item
                              style={{
                                marginTop: "10px",
                                color: `${darck ? "" : "#fff"}`,
                              }}
                            >
                           
                              
                              <Checkbox
                                name="passager"
                                color="primary"
                                checked={this.state.passager}
                                onChange={this.handleChange("passager")}
                                value={this.state.passager}
                                style={{ color: `${darck ? "" : "#fff"}` }}
                              />
                              Passager
                            </Grid>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* /////////////////////////Raison Sociale///////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Raison Sociale"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="raisoc"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* /////////////////////////Email///////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Email"
                              margin="dense"
                              variant="outlined"
                              type="email"
                              fullWidth
                              name="emailcli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* /////////////////////////Adresse///////////////////////// */}
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Adresse"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="adressecli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ///////////////////////Ville/////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Ville"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="villecli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="number"
                              //   type="number"
                              //   InputLabelProps={{
                              //     shrink: true,
                              //   }}
                              label="Code Postal"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="cp"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ///////////////////tel/////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="tel"
                              //   InputLabelProps={{
                              //     shrink: true,
                              //   }}
                              label="Téléphone 1"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="tel1"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Téléphone 2"
                              type="tel"
                              //   InputLabelProps={{
                              //     shrink: true,
                              //   }}
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="tel2"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                       {/* ///////////////////Site Web/////////////////////////////// */}
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Site Web"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="sitewebcli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={6}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                       {this.state.passager==0?(
                      <Row form>
                        <Col style={{ margin: "0" }}>
                           {/* ///////////////////////Identifiant unique/////////////////////////// */}
                    
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Identifiant unique"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="identcli"
                             required
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        
                      </Row>
                      ):(
                      <Row form>
                        <Col style={{ margin: "0" }}>
                          {/* ///////////////////////Identifiant unique/////////////////////////// */}

                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Identifiant unique"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="identcli"
                            // required
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        
                      </Row>)}
                   {/* //////////////////////Autre Impots//////////////////////////// */}

                      <Row form>
                        <Col style={{ margin: "0" }}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Autre Impots"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="impotcli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* ///////////////////////Taux Fodec/////////////////////////// */}

                      <Row form>
                        <Col style={{ margin: "0" }}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Taux Fodec"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="fodeccli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
          {/* ///////////////////////Comptable/////////////////////////// */}
                        <Col style={{ margin: "0" }}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Comptable"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="comptable"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////catfisc////////////////////////////// */}

                      <Row form>
                        <Col style={{ margin: "0" }} lg={6}>
                          <FormGroup
                            style={{ overflow: "hidden" }}
                            controlId="catfisc"
                          >
                            <Autocomplete
                              id="outlined-select-currency"
                              // className="card add-input"
                              // options={this.props.clients.clients}
                              // options={this.state.rechs}
                              options={this.state.cat}
                              getOptionLabel={(option) => option.label}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel.label == "Assujetti"
                                  ? this.setState({ categoriefiscale: "0" })
                                  : getOptionLabel.label == "Non Assujetti"
                                  ? this.setState({ categoriefiscale: "1" })
                                  : getOptionLabel.label == "Exonéré TVA/FODEC"
                                  ? this.setState({ categoriefiscale: "2" })
                                  : getOptionLabel.label == "Exonéré TVA"
                                  ? this.setState({ categoriefiscale: "3" })
                                  : getOptionLabel.label == "Suspenssion"
                                  ? this.setState({ categoriefiscale: "4" })
                                  : getOptionLabel.label == "Cession à Quai"
                                  ? this.setState({ categoriefiscale: "5" })
                                  : this.setState({ categoriefiscale: "6" });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Categorie Fisc"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  name="catfisc"
                                  required
                                  // InputProps={{
                                  //   className: !darck
                                  //     ? this.props.classes.input
                                  //     : null,
                                  // }}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        {
                        // this.state.categoriefiscale == "0" ? (
                        //   <Col style={{ margin: "0" }} lg={6}>
                        //     <FormGroup style={{ overflow: "hidden" }}>
                        //       <TextField
                        //         // className="card add-input"
                        //         id="standard-basic-att"
                        //         label="N°Attestation"
                        //         margin="dense"
                        //         variant="outlined"
                        //         fullWidth
                        //         disabled
                        //        // required
                        //         name="attestation"
                        //         onChange={(e) =>
                        //           this.setState({
                        //             attestation: e.target.value,
                        //           })
                        //         }
                        //         InputProps={{
                        //           className: !darck
                        //             ? this.props.classes.input
                        //             : null,
                        //         }}
                        //         InputLabelProps={{
                        //           style: {
                        //             color: !darck ? "#fff" : null,
                        //           },
                        //         }}
                        //       />
                        //     </FormGroup>
                        //   </Col>
                        // ) :
                        // this.state.categoriefiscale == "1" ? (
                        //   <Col style={{ margin: "0" }} lg={6}>
                        //     <FormGroup style={{ overflow: "hidden" }}>
                        //       <TextField
                        //         // className="card add-input"
                        //         id="standard-basic-att"
                        //         label="N°Attestation"
                        //         margin="dense"
                        //         variant="outlined"
                        //         fullWidth
                        //         //disabled
                        //         required
                        //         name="attestation"
                        //         onChange={(e) =>
                        //           this.setState({
                        //             attestation: e.target.value,
                        //           })
                        //         }
                        //         InputProps={{
                        //           className: !darck
                        //             ? this.props.classes.input
                        //             : null,
                        //         }}
                        //         InputLabelProps={{
                        //           style: {
                        //             color: !darck ? "#fff" : null,
                        //           },
                        //         }}
                        //       />
                        //     </FormGroup>
                        //   </Col>
                        // ) :
                        this.state.categoriefiscale == "2" ? (
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                onChange={(e) =>
                                  this.setState({
                                    attestation: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : this.state.categoriefiscale == "3" ? (
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                onChange={(e) =>
                                  this.setState({
                                    attestation: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : this.state.categoriefiscale == "4" ? (
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                onChange={(e) =>
                                  this.setState({
                                    attestation: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        ) : this.state.categoriefiscale == "5" ? (
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-att"
                                label="N°Attestation"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="attestation"
                                onChange={(e) =>
                                  this.setState({
                                    attestation: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            </FormGroup>
                          </Col>
                        // ) :this.state.categoriefiscale == "6" ? (
                        //   <Col style={{ margin: "0" }} lg={6}>
                        //     <FormGroup style={{ overflow: "hidden" }}>
                        //       <TextField
                        //         // className="card add-input"
                        //         id="standard-basic-att"
                        //         label="N°Attestation"
                        //         margin="dense"
                        //         variant="outlined"
                        //         fullWidth
                        //         disabled
                        //        // required
                        //         name="attestation"
                        //         onChange={(e) =>
                        //           this.setState({
                        //             attestation: e.target.value,
                        //           })
                        //         }
                        //         InputProps={{
                        //           className: !darck
                        //             ? this.props.classes.input
                        //             : null,
                        //         }}
                        //         InputLabelProps={{
                        //           style: {
                        //             color: !darck ? "#fff" : null,
                        //           },
                        //         }}
                        //       />
                        //     </FormGroup>
                        //   </Col>
                        ) :  null}
                      </Row>
                      {this.state.categoriefiscale == "0" ? (
                        <Row form>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                // required
                                name="dateetabli"
                                disabled
                                // onChange={(e) =>
                                //   this.setState({
                                //     dateetabli: e.target.value,
                                //   })
                                // }
                                defaultValue={this.state.defaultdate}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                disabled
                                // required
                                // onChange={(e) =>
                                //   this.setState({ datexp: e.target.value })
                                // }
                                defaultValue={this.state.defaultdate}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ):this.state.categoriefiscale == "1" ? (
                        <Row form>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                disabled
                                 //required
                                name="dateetabli"
                                // onChange={(e) =>
                                //   this.setState({
                                //     dateetabli: e.target.value,
                                //   })
                                // }
                                defaultValue={this.state.defaultdate}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                // required
                                // onChange={(e) =>
                                //   this.setState({ datexp: e.target.value })
                                // }
                                defaultValue={this.state.defaultdate}
                                disabled
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ):this.state.categoriefiscale == "2" ? (
                        <Row form>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({
                                    dateetabli: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ):this.state.categoriefiscale == "3" ? (
                        <Row form>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({
                                    dateetabli: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) :this.state.categoriefiscale == "4" ? (
                        <Row form>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({
                                    dateetabli: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) :this.state.categoriefiscale == "5" ? (
                        <Row form>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                required
                                name="dateetabli"
                                onChange={(e) =>
                                  this.setState({
                                    dateetabli: e.target.value,
                                  })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                required
                                onChange={(e) =>
                                  this.setState({ datexp: e.target.value })
                                }
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ) :this.state.categoriefiscale == "6" ? (
                        <Row form>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                // required
                                name="dateetabli"
                                disabled
                                // onChange={(e) =>
                                //   this.setState({
                                //     dateetabli: e.target.value,
                                //   })
                                // }
                                defaultValue={this.state.defaultdate}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Etablie le :</FormHelperText>
                            </FormGroup>
                          </Col>
                          <Col style={{ margin: "0" }} lg={6}>
                            <FormGroup style={{ overflow: "hidden" }}>
                              <TextField
                                // className="card add-input"
                                id="standard-basic-exp"
                                type="date"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                name="datexp"
                                disabled
                                // required
                                // onChange={(e) =>
                                //   this.setState({ datexp: e.target.value })
                                // }
                                defaultValue={this.state.defaultdate}
                                InputProps={{
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                              <FormHelperText>Expire le :</FormHelperText>
                            </FormGroup>
                          </Col>
                        </Row>
                      ): null}
                      
                      <Row form>
                        <Col style={{ margin: "0" }} lg={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="A Contacter"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="contactcli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col style={{ margin: "0" }} lg={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              type="tel"
                              label="CIN"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="cincli"
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <Grid
                              item
                              style={{
                                marginTop: "12px",
                                color: `${darck ? "" : "#fff"}`,
                              }}
                            >
                              <Checkbox
                                name="timbre"
                                color="primary"
                                checked={this.state.timbre}
                                onChange={this.handleChange("timbre")}
                                value={this.state.timbre}
                                style={{ color: `${darck ? "" : "#fff"}` }}
                              />
                              Timbre Fiscale
                            </Grid>
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            {/* {this.props.codclis.codclis.map((cod) => ( */}
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Regime Forfaitaire"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="regforf"
                              inputProps={{
                                type: "number",
                                max: 9,
                                min: 0,
                              }}
                              defaultValue="0"
                              onKeyDown={(event) => {
                                event.preventDefault();
                              }}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                            {/* {this.state.regimecli.map((option) => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.code}
                              </MenuItem>
                            ))} */}
                            {/* ))} */}
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* <Col>
                          <FormGroup> 
                            <Grid
                              item
                              style={{ marginTop: "12px", textAlign: "center" }}
                            >
                              <Switch
                                name="soustraitant"
                                color="primary"
                                checked={this.state.soustraitant}
                                onChange={this.handleChange("soustraitant")}
                                value={this.state.soustraitant}
                              />
                              Sous Traitant
                            </Grid>
                          </FormGroup>
                        </Col> */}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Button color="primary" style={{ width: "100%" }}>
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    GetCodcli: (a, b, c, d, e) => dispatch(GetCodcli(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => {
      dispatch(SelectClient(a, b, c, d, e));
    },
  };
}


function mapStateToProps(state) {
  return {
    codclis: state.codclis,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddCModal));
