import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import { Row, Col, FormGroup } from "reactstrap";
import { connect } from "react-redux";
// import { SelectFournisseur } from "../../redux/actions/GetFournisseurs";
import {
  TextField,
  Button,
  Snackbar,
  IconButton,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
// import { GetRECod } from "../../redux/actions/GetRECod";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SelectNomenclature } from "../../redux/actions/GetNomenclature";
import { SelectNomenclatureBQ } from "../../redux/actions/GetNomeBQ";
import { SelectNomenclatureAG } from "../../redux/actions/GetNomeAG";
import { SelectNomenclatureCS } from "../../redux/actions/GetNomeCS";
import { SelectNomenclatureSC } from "../../redux/actions/GetNomeSituation";
import { SelectNomenclatureCCB } from "../../redux/actions/GetNomeCCB";
import { SelectReglement } from "../../redux/actions/GetReg";
import LinkIcon from "@material-ui/icons/Link";
import ModifierAffectationFrs from "./ModifierAffectationFrs";
import ModifierAffectationRetenuAlaSourceFRS from "./ModifierAffectationRetenuAlaSourceFRS";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});


const styles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: 300,
    margin: 100,
  },
  //style for font size
  resize: {
    fontSize: 10,
  },
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

const DATE_OPTIONSS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

class ModifierReglement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: true,
      defaultdate: date,
      raisonsocial: "",

      btnEnable: false,
      soldfacbl: 0,
      codmodreg: "",
      libmodreg: "",
      bqfournisseur: "",
      montant: 0,
      codbqfournisseur: "",
      libbqfournisseur: "",
      codagence: "",
      libagence: "",
      codcaisse: "",
      libcaisse: "",
      codsituation: "",
      libsituation: "",
      codbqvers: "",
      libbqvers: "",
      codccb: "",
      libccb: "",
      chdec: "",
      snackbaropen: false,
      snackbarmsg: ",",
      numchq: "",
      titulaire: "",
      note: "",
      listeFacture: [],
      listeAV: [],
      listebl: [],
    };
  }

  componentDidMount() {
    // this.props.SelectFournisseur();
    this.props.SelectNomenclature(
      "MR",
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureBQ(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureAG(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureCS(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureSC(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureCCB(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectReglement(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.setState({
      codmodreg: this.props.codmodreg,
      codbqfournisseur: this.props.codbqfournisseur,
      codagence: this.props.codagence,
      codcaisse: this.props.codcaisse,
      codsituation: this.props.codsituation,
      codbqvers: this.props.codbqvers,
      codccb: this.props.codccb,
      montant: this.props.montant,
      numchq: this.props.numchq,
      titulaire: this.props.titulaire,
      note: this.props.note,
    });
  }

  montantHandler = (event) => {
    this.setState({ montant: event.target.value });
  };

  numchqHandler = (event) => {
    this.setState({ numchq: event.target.value });
  };

  titulaireHandler = (event) => {
    this.setState({ titulaire: event.target.value });
  };

  noteHandler = (event) => {
    this.setState({ note: event.target.value });
  };

  enregistrer = (event) => {
    event.preventDefault();
    this.setState({
      numreg: event.target.codre.value,
      codefournisseur: this.props.codefournisseur,
    });

    fetch(
      `https://www.24-crm.tn/webservice/api/REGFRs?numreg=${
        event.target.codre.value
      }&datreg=${event.target.datreg.value}&codfrs=${
        this.props.codefournisseur
      }&raisoc=${this.props.raisonsocial}&modreg=${
        this.state.codmodreg
      }&numcais=${this.state.codcaisse}&matban=${
        this.state.codbqfournisseur
      }&monreg=${event.target.montant.value}&VENDEUR=${
        this.props.Auth.username
      }&numchq=${event.target.numpiece.value}&titulaire=${
        event.target.titulaire.value
      }&datech=${event.target.echeance.value}&mntreg=${
        event.target.montant.value
      }&sens=d&impaye=0&verser=${this.state.codsituation}&lib_reg=${
        event.target.note.value
      }&ListPiece=${
        this.props.detailTabRegFrs
          ? this.props.detailTabRegFrs.map((el) => el.numfac)
          : ""
      }&BqEscompte=${this.state.codbqvers}&codccb=${this.state.codccb}&coddos=${
        this.props.ConfigDossier.coddos
      }&serveur=${this.props.ConfigDossier.serveur}&user=${
        this.props.ConfigDossier.utilisateur
      }&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${
        this.props.ConfigDossier.database
      }`,
      {
        method: "PUT",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (
          this.props.detailTabRegFrs &&
          this.props.detailAffectation != this.props.detailTabRegFrs
        ) {
          fetch(
            `https://www.24-crm.tn/webservice/api/LigREGFRS/${this.state.numreg}?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "DELETE",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          ).then((result) => {
            this.props.detailTabRegFrs.map((k, index) =>
              fetch(
                `https://www.24-crm.tn/webservice/api/LigREGFRS?numreg=${this.state.numreg}&numfac=${k.numfac}&typfac=${k.typfac}&montant=${k.monreg}&numfbl=0&typfbl=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                {
                  method: "POST",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              ).then((res) => {
                res.json();
              })
            );
          });
        }
        this.setState({ snackbaropen: true, snackbarmsg: result });
      })
      .then(
        (res) => {
          this.props.onHide();

          this.props.SelectReglement(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          fetch(
            `https://www.24-crm.tn/webservice/api/FOURNISSEURs?Codfrssss=${this.props.codefournisseur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          ).then((res) => window.location.reload());
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
  };

  fournisseurHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/CLIENTs?codclii=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data }));
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  handleShow = () => {
    fetch(
      `https://www.24-crm.tn/webservice/api/NOME?coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())

      .then((data) => {
        this.setState({
          chdec: data.filter(
            (el) => el.code.toUpperCase() == this.props.codmodreg.toUpperCase()
          )[0].chdec,
        });
      });

    fetch(
      `https://www.24-crm.tn/webservice/api/FacFrs?codfrsss=${this.props.codefournisseur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())

      .then((data) => {
        this.setState({
          listeFacture: data,
          showModal: true,
        });
      })
      .then((res) =>
        fetch(
          `https://www.24-crm.tn/webservice/api/FACCLIs?codcliiis=${this.props.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "GET",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())

          .then((res) => {
            this.setState({
              listeAV: res,
            });
          })
      );
    fetch(
      `https://www.24-crm.tn/webservice/api/BLBRs?codclii=${this.props.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())

      .then((data) => {
        this.setState({
          listebl: data,
          showModal: true,
        });
      });
  };
  render() {

    const { darck, classes } = this.props;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Modifier Règlement fournisseur</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                                style={{
                                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                  borderTop: `none`,
                                  borderRight: `${darck ? "" : "2px solid #fff"}`,
                                  borderLeft: `${darck ? "" : "2px solid #fff"}`,
                                  borderBottom: `${darck ? "" : "2px solid #fff"}`,
                                }}
          >
            <form autocomplete="off" 
                  onSubmit={this.enregistrer}
                  className={`${darck ? "" : classes.root}`}>
              <Card>
                <Card.Body
                                                  style={{
                                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                                    border: `${darck ? "" : "2px solid #fff"}`,
                                                  }}
                >
                  <Row>
                    <Col sm={4}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="№ Règlement"
                          // margin="normal"
                          value={this.props.regid}
                          fullWidth
                          name="codre"
                          variant="outlined"
                          margin="dense"
                          disabled

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        // margin="normal"
                        type="date"
                        variant="outlined"
                        margin="dense"
                        fullWidth

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}

                        defaultValue={new Date(this.props.datreg)
                          .toLocaleDateString()
                          .substring(0, 10)
                          .split("/")
                          .reverse()
                          .join("-")}
                      />
                    </Col>

                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.nomenclatures.nomenclatures}
                          defaultValue={{ code: this.props.codmodreg }}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codmodreg: getOptionLabel.code,
                                  libmodreg: getOptionLabel.lib,
                                  chdec: getOptionLabel.chdec,
                                })
                              : this.setState({
                                  codmodreg: "",
                                  libmodreg: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Mode Règ"
                              margin="dense"
                              fullWidth
                              name="modreg"
                              variant="outlined"
                              // className="card add-input"
                              required

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}

                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Code Fournisseur"
                          // margin="normal"
                          fullWidth
                          name="codcli"
                          margin="dense"
                          variant="outlined"
                          value={this.props.codefournisseur}
                          disabled

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={6}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Raison Social"
                          // margin="normal"
                          fullWidth
                          name="raisonsocial"
                          variant="outlined"
                          margin="dense"
                          value={this.props.raisonsocial}
                          disabled

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row style={{ marginBottom: "-30px", marginTop: "-20px" }}>
                    <Col sm={4}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="№ Règlement"
                          margin="normal"
                          value={this.props.regid}
                          fullWidth
                          name="codre"
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={4}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        margin="normal"
                        type="date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        defaultValue={new Date(this.props.datreg)
                          .toISOString()
                          .substr(0, 10)}
                      />
                    </Col>

                    <Col sm={4}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="normal"
                          defaultValue={Number(this.state.montant).toFixed(3)}
                          fullWidth
                          name="montant"
                          onChange={this.montantHandler}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "-30px" }}>
                    <Col sm={6}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Code fournisseur"
                          margin="normal"
                          fullWidth
                          name="codcli"
                          value={this.props.codefournisseur}
                          disabled
                        />
                      </FormGroup>
                    </Col>

                    <Col sm={6}>
                      <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Raison Social"
                          margin="normal"
                          fullWidth
                          name="raisonsocial"
                          value={this.props.raisonsocial}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                </Card.Body>
              </Card>

              <Card style={{ marginTop: "2px" }}>
                <Card.Body
                                                  style={{
                                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                                    border: `${darck ? "" : "2px solid #fff"}`,
                                                  }}
                >
                  <Row>
                    <Col sm={11}>
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "2px",
                          color: "#007bff",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {this.state.libmodreg}{" "}
                      </p>
                    </Col>
                    <Col sm={1}>
                      <Tooltip title="Affectation" aria-label="Affectation">
                        <LinkIcon
                          onClick={
                            this.state.libmodreg
                              .toUpperCase()
                              .includes("RETENUE A LA SOURCE")
                              ? this.state.codefournisseur
                                ? this.handleShow
                                : () =>
                                    alert(
                                      "il faut préciser le code fournisseur  "
                                    )
                              : this.state.montant
                              ? this.handleShow
                              : () =>
                                  alert(
                                    "il faut préciser le code fournisseur et le montant "
                                  )
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      {this.state.codmodreg.toUpperCase().includes("RS") ? (
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          name="montant"
                          value={
                            this.props.mntretenufrs
                              ? parseFloat(this.props.mntretenufrs).toFixed(3)
                              : this.props.montant
                          }
                          // disabled

                          required
                          // className="card add-input"
                          onChange={(e) => {
                            this.setState({
                              montant: e.target.value,
                            });
                          }}

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      ) : (
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          name="montant"
                          // disabled
                          defaultValue={this.props.montant}
                          required
                          // className="card add-input"
                          onChange={(e) => {
                            this.setState({
                              montant: e.target.value,
                            });
                          }}

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      )}
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.css.css}
                          defaultValue={{ code: this.props.codcaisse }}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codcaisse: getOptionLabel.code,
                                  libcaisse: getOptionLabel.lib,
                                })
                              : this.setState({
                                  codcaisse: "",
                                  libcaisse: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Caisse"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              // className="card add-input"
                              required

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libcaisse}</FormHelperText>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <Row style={{ marginBottom: "-25px" }}> */}
                  <Row>
                    {" "}
                    <Col
                      sm={12}
                      style={{
                        display:
                          !this.state.codmodreg ||
                          this.state.codmodreg.toUpperCase().includes("ESP") ||
                          this.state.codmodreg.toUpperCase().includes("RS")
                            ? "none"
                            : "block",
                      }}
                    >
                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="№ Pièce"
                              margin="dense"
                              fullWidth
                              defaultValue={this.props.numchq}
                              name="numpiece"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              type="date"
                              label="Echéance"
                              margin="dense"
                              fullWidth
                              variant="outlined"
                              //defaultValue={this.state.defaultdate}
                              name="echeance"
                              // className="card add-input"

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Titulaire"
                              margin="dense"
                              fullWidth
                              defaultValue={this.props.titulaire}
                              variant="outlined"
                              name="titulaire"
                              // className="card add-input"

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.bqs.bqs}
                              defaultValue={{
                                code: this.props.codbqfournisseur,
                              }}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqclient: getOptionLabel.code,
                                      libbqclient: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqclient: "",
                                      libbqclient: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Bq client"
                                  margin="dense"
                                  fullWidth
                                  name="bqclient"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqclient}
                            </FormHelperText>
                          </FormGroup>
                        </Col>

                        {/* <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.ags.ags}
                              defaultValue={{ code: this.props.codagence }}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codagence: getOptionLabel.code,
                                      libagence: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codagence: "",
                                      libagence: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Agence"
                                  margin="dense"
                                  fullWidth
                                  name="agence"
                                  variant="outlined"
                                  className="card add-input"
                                />
                              )}
                            />
                            <FormHelperText>
                              {this.state.libagence}
                            </FormHelperText>
                          </FormGroup>
                        </Col> */}
                      </Row>

                      {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libagence}{" "}
                          </p>
                        </Col> */}

                      {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libcaisse}{" "}
                          </p>
                        </Col> */}

                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.scs.scs}
                              defaultValue={{
                                code: this.props.codsituation,
                              }}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codsituation: getOptionLabel.code,
                                      libsituation: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codsituation: "",
                                      libsituation: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Situation"
                                  margin="dense"
                                  fullWidth
                                  name="situation"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libsituation}
                            </FormHelperText>
                          </FormGroup>
                        </Col>

                        {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libsituation}{" "}
                          </p>
                        </Col> */}
                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.bqs.bqs}
                              defaultValue={{ code: this.props.codbqvers }}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqvers: getOptionLabel.code,
                                      libbqvers: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqvers: "",
                                      libbqvers: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Bq vers"
                                  margin="dense"
                                  fullWidth
                                  name="bqvers"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqvers}
                            </FormHelperText>
                          </FormGroup>
                        </Col>

                        {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libbqvers}{" "}
                          </p>
                        </Col> */}

                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.ccbs.ccbs}
                              defaultValue={{ code: this.props.codccb }}
                              getOptionLabel={(option) =>
                                option.chdec === this.state.codbqvers
                                  ? option.code
                                  : null
                              }
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codccb: getOptionLabel.code,
                                      libccb: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codccb: "",
                                      libccb: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="CCB"
                                  margin="dense"
                                  fullWidth
                                  name="ccb"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>{this.state.libccb}</FormHelperText>
                          </FormGroup>
                        </Col>

                        {/* <Col sm={4}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.libccb}{" "}
                          </p>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <FormGroup>
                        <TextField
                          id="outlined-multiline-static"
                          label="Note"
                          fullWidth
                          defaultValue={this.props.note}
                          variant="outlined"
                          name="note"
                          rows={2}
                          multiline
                          // className="card add-input"

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/* <Row
                        style={{ marginBottom: "-15px", marginTop: "-30px" }}
                      >
                        <Col sm={6}>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-fournisseur-input"
                            options={this.props.nomenclatures.nomenclatures}
                            getOptionLabel={(option) => option.code}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    codmodreg: getOptionLabel.code,
                                    libmodreg: getOptionLabel.lib,
                                  })
                                : this.setState({
                                    codmodreg: "",
                                    libmodreg: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Mode Règ"
                                margin="normal"
                                fullWidth
                                name="modreg"
                              />
                            )}
                          />
                        </Col>

                        <Col sm={6}>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.codmodreg}{" "}
                          </p>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-15px" }}>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="№ Pièce"
                              margin="normal"
                              fullWidth
                              name="numchq"
                              defaultValue={this.state.numchq}
                              onChange={this.numchHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-25px" }}>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              type="date"
                              label="Echéance"
                              margin="normal"
                              fullWidth
                              name="echeance"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              defaultValue={new Date(this.props.datech)
                                .toISOString()
                                .substr(0, 10)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "5px" }}>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Titulaire"
                              margin="normal"
                              fullWidth
                              name="titulaire"
                              defaultValue={this.state.titulaire}
                              onChange={this.titulaireHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-10px" }}>
                        <Col sm={12}>
                          <FormGroup>
                            <TextField
                              id="outlined-multiline-static"
                              label="Note"
                              variant="outlined"
                              fullWidth
                              name="note"
                              rows={2}
                              multiline
                              defaultValue={this.state.note}
                              onChange={this.noteHandler}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>

                <Col sm={6}>
                  <Card>
                    <Card.Body>
                      <Row
                        style={{ marginBottom: "-35px", marginTop: "-30px" }}
                      >
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-fournisseur-input"
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqfournisseur: getOptionLabel.code,
                                      libbqfournisseur: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqfournisseur: "",
                                      libbqfournisseur: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Bq fournisseur"
                                  margin="normal"
                                  fullWidth
                                  name="bqfournisseur"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={6}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.codbqfournisseur}{" "}
                          </p>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-25px" }}>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-fournisseur-input"
                              options={this.props.ags.ags}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codagence: getOptionLabel.code,
                                      libagence: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codagence: "",
                                      libagence: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Agence"
                                  margin="normal"
                                  fullWidth
                                  name="agence"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={6}>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.codagence}{" "}
                          </p>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-25px" }}>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-fournisseur-input"
                              options={this.props.css.css}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codcaisse: getOptionLabel.code,
                                      libcaisse: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codcaisse: "",
                                      libcaisse: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Caisse"
                                  margin="normal"
                                  fullWidth
                                  name="caisse"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={6}>
                          <p
                            style={{
                              fontSize: "13px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.codcaisse}{" "}
                          </p>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-25px" }}>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-fournisseur-input"
                              options={this.props.scs.scs}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codsituation: getOptionLabel.code,
                                      libsituation: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codsituation: "",
                                      libsituation: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Situation"
                                  margin="normal"
                                  fullWidth
                                  name="situation"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={6}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.codsituation}{" "}
                          </p>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-25px" }}>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-fournisseur-input"
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.code}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqvers: getOptionLabel.code,
                                      libbqvers: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqvers: "",
                                      libbqvers: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Bq vers"
                                  margin="normal"
                                  fullWidth
                                  name="bqvers"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={6}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.codbqvers}{" "}
                          </p>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: "-25px" }}>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-fournisseur-input"
                              options={this.props.ccbs.ccbs}
                              getOptionLabel={(option) =>
                                option.chdec === this.state.codbqvers
                                  ? option.code
                                  : null
                              }
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codccb: getOptionLabel.code,
                                      libccb: getOptionLabel.lib,
                                      chdec: getOptionLabel.chdec,
                                    })
                                  : this.setState({
                                      codccb: "",
                                      libccb: "",
                                      chdec: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="CCB"
                                  margin="normal"
                                  fullWidth
                                  name="ccb"
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={6}>
                          <p
                            style={{
                              fontSize: "12px",
                              marginTop: "40px",
                              color: "#007bff",
                            }}
                          >
                            {" "}
                            {this.state.codccb}{" "}
                          </p>
                        </Col>
                      </Row> */}
                </Card.Body>
              </Card>

              <br />
              <Row>
                <Col sm={6}>
                  {/* <h3 style={{ color: "rgb(23, 162, 184)" }}>
                    &nbsp; Solde:{"  "}
                    <span style={{ color: "black" }}>
                      {Number(this.state.soldfacbl).toFixed(3)}
                    </span>
                  </h3> */}
                </Col>
                <Col sm={2}></Col>
                <Col sm={4}>
                  <Button
                    variant="contained"
                    // color="secondary"
                    type="submit"
                    style={{
                      //marginTop: "20px",
                      width: "100%",
                      color: "white",
                      backgroundColor: "#020F64",
                    }}
                  >
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
        {this.state.codmodreg.toUpperCase().includes("RS") ? (
          <ModifierAffectationRetenuAlaSourceFRS
            chdec={this.state.chdec}
            numreg={this.state.numreg}
            montReg={this.state.montant}
            raisonsocial={this.state.raisonsocial}
            codefournisseur={this.props.codefournisseur}
            listeFacture={this.state.listeFacture}
            listebl={this.state.listebl}
            listeAV={this.state.listeAV}
            soldfacbl={this.state.soldfacbl}
            show={this.state.showModal}
            detailAffectation={this.props.detailAffectation}
            handleClose={() =>
              this.setState({ showModal: !this.state.showModal })
            }
          />
        ) : (
          <ModifierAffectationFrs
            numreg={this.state.numreg}
            montReg={this.state.montant}
            raisonsocial={this.state.raisonsocial}
            codefournisseur={this.props.codefournisseur}
            listeFacture={this.state.listeFacture}
            listebl={this.state.listebl}
            listeAV={this.state.listeAV}
            soldfacbl={this.state.soldfacbl}
            show={this.state.showModal}
            detailAffectation={this.props.detailAffectation}
            handleClose={() =>
              this.setState({ showModal: !this.state.showModal })
            }
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    // SelectFournisseur: () => dispatch(SelectFournisseur()),
    SelectNomenclature: (cat, a, b, c, d, e) =>
      dispatch(SelectNomenclature("MR", a, b, c, d, e)),
    SelectNomenclatureBQ: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
    SelectNomenclatureAG: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureAG(a, b, c, d, e)),
    SelectNomenclatureCS: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureCS(a, b, c, d, e)),
    SelectNomenclatureSC: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureSC(a, b, c, d, e)),
    SelectNomenclatureCCB: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureCCB(a, b, c, d, e)),
    SelectReglement: (a, b, c, d, e) =>
      dispatch(SelectReglement(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    fournisseurs: state.fournisseurs,
    nomenclatures: state.nomenclatures,
    bqs: state.bqs,
    ags: state.ags,
    css: state.css,
    scs: state.scs,
    ccbs: state.ccbs,
    Auth: state.Auth,
    detailTabRegFrs: state.detailTabRegFrs,
    mntretenufrs: state.mntretenufrs,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(ModifierReglement));
