import React, { Component } from "react";
import { Modal, Card, Row, FormGroup, Col, Accordion } from "react-bootstrap";
import "../styling/Styles.css";
import "./ss.scss";

import { TextField, Button, Snackbar, IconButton } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
// import { GetNumFacDevis } from "../../redux/actions/GetNumfacDevis";
import { SelectArticle } from "../../redux/actions/GetArticles";

import Tooltip from "@material-ui/core/Tooltip";

import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";

import { Divider, Chip } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import LigBCArticle from "./LigBCArticle";
import { GetNumFacBCFrs } from "../../redux/actions/Getcodbcfrs";
import { SelectBCFrs } from "../../redux/actions/GetBCFrs";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";
import "./Responsivebcfrs.css";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

// const roundTo = require("round-to");

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddBCFrsModal extends Component {
  constructor(props) {
    super(props);
    const username = localStorage.getItem("username");

    this.state = {
      gilad: true,
      passager: false,
      defaultdate: date,
      showTimbre: false,
      showForfitaire: 0,
      showButtonModalPassager: false,
      addModalShow1: false,
      ligModalShow: false,
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      TOTTVA: 0,
      remiseg: 0,
      representant: "",
      raisonsocial: "",
      codeclient: "",
      categoriefiscale: "",
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      NETAPAYER: 0,
      netnetapayer: 0,
      NETNETAPAYER: 0,
      btnEnable: false,
      btnEnabled: false,
      cemail: "",
      openActionModal: false,

      snackbaropen: false,
      snackbarmsg: ",",

      rechs: [],
      username: username,

      libellecatfisc: "",

      typfac: "",
      numfac: "",
      typfacTab: [],
      numdevTab: [],
      facOrBl: "",
      numnum: "",
      annuler: 0,

      //valtimbre: 0,
      clicked: false,
    };
  }

  componentDidMount() {
    this.props.SelectBCFrs(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    // this.props.GetNumFacDevis(
    //   this.props.ConfigDossier.coddos,
    //   this.props.ConfigDossier.serveur,
    //   this.props.ConfigDossier.utilisateur,
    //   this.props.ConfigDossier.pwd_serveur,
    //   this.props.ConfigDossier.database
    // );
    this.props.GetNumFacBCFrs(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.setState({ tab: [] });
  }
 

  submitHandler = (
    tab,
    totalqte,
    sumremisearticle,
    totalhtbrut,
    totaltva,
    totalhtnet,
    remiseglobal,
    netapayer,
    btnEnabled,
    netnetapayer
  ) => {
    this.setState({
      tab: tab,
      totalqte: totalqte,
      sumremisearticle: sumremisearticle,
      totalhtbrut: totalhtbrut,
      totaltva: totaltva,
      totalhtnet: totalhtnet,
      remiseglobal: remiseglobal,
      netapayer: netapayer,
      btnEnabled: btnEnabled,
      netnetapayer: netnetapayer,
    });
  };

  clientHandlerChange = (event) => {
    this.setState({ codcli: event.target.value });
    fetch(
      `https://www.24-crm.tn/webservice/api/FOURNISSEURs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ openActionModal: true });
  };

  remiseChange = (event) => {
    this.setState({
      remiseg: event.target.value,
      remiseglobal:
        ((this.state.totalhtbrut - this.state.sumremisearticle) *
          event.target.value) /
        100,
      totalhtnet:
        (this.state.totalhtbrut - this.state.sumremisearticle) *
        ((100 - event.target.value) / 100),
      TOTTVA: this.state.totaltva * ((100 - event.target.value) / 100),
      NETAPAYER: this.state.netapayer * ((100 - event.target.value) / 100),
      NETNETAPAYER:
        ((100 - event.target.value) / 100) * this.state.netapayer +
        Number(this.state.valtimbre),
    });
  };
  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };


  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  remiseglobalChange = (event) => {
    this.setState({ remiseg: event.target.value });
  };

  enregistrer = (event) => {
    event.preventDefault();

    //////////// post BCDV /////////////////////////////
    fetch(
      `https://www.24-crm.tn/webservice/api/BCDVFrs?numfac&typfac=CF&datfac=${this.state.defaultdate}&codfrs=${event.target.codcli.value}&raisoc=${event.target.raissoc.value}&taurem=${event.target.remise.value}&timbre=${this.state.showTimbre}&vendeur=${this.props.Auth.username}&valtimbre=${this.state.valtimbre}&codrep=${this.props.Auth.code}&pj=${this.state.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      /////////////// post ligarticle ////////////////////////////////
      .then((res) => {
        this.setState({ numnum: res });
        this.state.tab.map((k, index) => {
          for (var i = 0; i < this.state.tab.length; i++) {
            fetch(
              `https://www.24-crm.tn/webservice/api/LIGBCDVFrs/{ID}?numfac=${res}&typfac=CF&numlig=${index}&codart=${k.codearticle}&desart=${k.des}&quantite=${k.qte}&priuni=${k.puht}&remise=${k.remisea}&codtva=${k.codtvaa}&tautva=${k.tva}&fodart=0&datfac=${this.state.defaultdate}&unite=${k.unite}&qtecom=${k.qte}&CB=${k.codbar}&montht=${k.totalht}&pmp=${k.pmp}&ttclig=${k.ttclig}&annuler=0&livre=0&ID=-1&formqt=0&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            )
              .then((res) => res.json())
              .then(
                (result) => {
                  console.log("ressss", result);
                },
                (error) => {
                  this.setState({
                    snackbaropen: true,
                    snackbarmsg: "Erreur Ajout Ligne",
                  });
                }
              );
          }
        });
      })

      .then(
        (result) => {
          this.props.onHide();
          this.props.SelectBCFrs(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          //Exécuter la procédure SommeTTCBCDV
          fetch(
            `https://www.24-crm.tn/webservice/api/LIGBCDVFrs?FAC=${this.state.numnum}&typfacc=CF&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );

          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.GetNumFacBCFrs(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
        },
        (error) => {
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Erreur Calcul Somme",
          });
        }
      )

      .then((res) => {
        //////////// switch update ////////////////

        fetch(
          `https://www.24-crm.tn/webservice/api/Switch?code=CF2&valeur&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "PUT",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then(
            (result) => {
              console.log(result);
            },
            (error) => {
              this.setState({
                snackbaropen: true,
                snackbarmsg: "Erreur Switch",
              });
            }
          )
          .then(() => window.location.reload());
      });
  };
  render() {
    const { dvnumfac, dvraisoc, rem, clientmail } = this.state;

    const { darck, classes} = this.props;

    let addModalClose1 = () => this.setState({ addModalShow1: false });
    let ligModalClose = () => this.setState({ ligModalShow: false });

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter un Bon de commande Fournisseur</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `none`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                      }}
          >
            <form autocomplete="off" 
                  onSubmit={this.enregistrer}
                  className={`${darck ? "" : classes.root}`}>
              <Card>
                <Card.Body
                  style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                >
                  <Row form>
                    <Col sm={4}>
                      <FormGroup>
                        <TextField
                          // className="card add-input"
                          id="standard-basic"
                          label="№ BC"
                          // className="card add-input"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          name="codbc"
                         // value={
                         //   this.props.numfacbcfrs.numfacbcfrs[0] &&
                         //   this.props.numfacbcfrs.numfacbcfrs[0].valeur
                         // }
                          defaultValue={
                            this.props.numfacbcfrs.numfacbcfrs[0] &&
                            this.props.numfacbcfrs.numfacbcfrs[0].valeur
                          }
                         // disabled
                          InputProps={{
                            className: !darck
                              ? this.props.classes.input
                              : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Date"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        type="date"
                        fullWidth
                        name="datfac"
                        defaultValue={this.state.defaultdate}
                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                  </Row>

                  <Row form>
                    <Col sm={4} id="switch">
                      <FormGroup style={{ marginTop: "25px" }}>
                        <Typography component="div">
                          <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item style={{ color: `${darck ? "grey" : "#fff"}` }}>
                              Raison sociale
                            </Grid>
                            <Grid item>
                            <ThemeProvider theme={theme}>
                              <Switch
                                checked={this.state.gilad}
                                onChange={this.handleChange("gilad")}
                                value="gilad"
                                color="primary"
                              />
                              </ThemeProvider>
                            </Grid>
                            <Grid item style={{ color: `${darck ? "#3f51b5" : "#fff"}` }}>
                              Code
                            </Grid>
                          </Grid>
                        </Typography>
                      </FormGroup>
                    </Col>
                    {this.state.gilad ? (
                      <Col sm={3}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.fournisseurs.fournisseurs
                            }
                            getOptionLabel={(option) => option.codfrs}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    codeclient: getOptionLabel.codfrs,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,

                                    cemail: getOptionLabel.email,
                                    codetva: getOptionLabel.CodeTVA,
                                    valtimbre:
                                      // getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    showForfitaire: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Code Fournisseur"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="codcli"

                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={5}>
                        <FormGroup>
                          <Autocomplete
                            id="include-input-in-list"
                            includeInputInList
                            className="ajouter-client-input"
                            // options={this.props.clients.clients}
                            // options={this.state.rechs}
                            options={
                              this.state.clicked
                                ? this.state.rechs
                                : this.props.fournisseurs.fournisseurs
                            }
                            getOptionLabel={(option) => option.raisoc}
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    raisonsocial: getOptionLabel.raisoc,
                                    codeclient: getOptionLabel.codfrs,
                                    btnEnable: true,
                                    showTimbre: getOptionLabel.timbre,

                                    cemail: getOptionLabel.email,
                                    codetva: getOptionLabel.CodeTVA,
                                    valtimbre:
                                      //getOptionLabel.timbre
                                      //   ? this.props.valtimbre
                                      0,
                                  })
                                : this.setState({
                                    raisonsocial: "",
                                    remiseg: 0,
                                    codeclient: "",
                                    categoriefiscale: "",
                                    btnEnable: false,
                                    showTimbre: false,
                                    showForfitaire: 0,
                                    showButtonModalPassager: false,
                                    valtimbre: 0,
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                // {...params}
                                label="Raison sociale"
                                // className="card add-input"
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                onChange={this.clientHandlerChange}
                                name="raissoc"

                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {this.state.gilad ? (
                      <Col sm={5}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Raison sociale"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.raisonsocial}
                            fullWidth
                            name="raissoc"
                            disabled={
                              this.state.codeclient === "100" ? false : true
                            }
                            onChange={this.clientHandlerChange}

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col sm={3}>
                        <FormGroup>
                          <TextField
                            id="standard-basic"
                            label="Code fournisseur"
                            // className="card add-input"
                            margin="dense"
                            variant="outlined"
                            value={this.state.codeclient}
                            fullWidth
                            name="codcli"
                            disabled

                            InputProps={{
                              className: !darck
                                ? this.props.classes.input
                                : null,
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                            InputLabelProps={{
                              style: {
                                color: !darck ? "#fff" : null,
                              },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>

                  <Row>
                    {/* <Col sm={2}>
                      <TextField
                        id="standard-basic"
                        label="Cat Fis"
                        margin="normal"
                        //variant="outlined"
                        fullWidth
                        name="categoriefiscale"
                        value={this.state.categoriefiscale}
                        disabled
                        //  onChange={this.catHandler}
                      />
                    </Col> */}

                    {/* <Col sm={2}>
                      {this.state.categoriefiscale === "0" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Assujetti{" "}
                        </p>
                      ) : this.state.categoriefiscale === "1" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Non Assujetti{" "}
                        </p>
                      ) : this.state.categoriefiscale === "2" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Exonéré TVA/FODEC{" "}
                        </p>
                      ) : this.state.categoriefiscale === "3" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Exonéré TVA{" "}
                        </p>
                      ) : this.state.categoriefiscale === "4" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Suspenssion{" "}
                        </p>
                      ) : this.state.categoriefiscale === "5" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Cession à Quai{" "}
                        </p>
                      ) : this.state.categoriefiscale === "6" ? (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Réduction TVA{" "}
                        </p>
                      ) : (
                        <p
                          style={{
                            fontSize: "13px",
                            marginTop: "40px",
                            color: "#007bff",
                          }}
                        >
                          Cat Fiscale{" "}
                        </p>
                      )}
                    </Col> */}

                    <Col sm={3}>
                      <TextField
                        id="standard-basic"
                        label="Remise Globale %"
                        // className="card add-input"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        name="remise"
                        onChange={this.remiseChange}

                        InputProps={{
                          className: !darck
                            ? this.props.classes.input
                            : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    {/* {this.state.codeclient ? (
                      <Col sm={4}>
                        {console.log(this.state.numdevTab)}

                        <FormGroup>
                          <Autocomplete
                            id="list-fac-bl"
                            includeInputInList
                            className="ajouter-client-input"
                            options={this.state.numdevTab}
                            getOptionLabel={(option) =>
                              `${option.typfac} : ${option.numfac}`
                            }
                            onChange={(event, getOptionLabel) => {
                              getOptionLabel
                                ? this.setState({
                                    facOrBl: getOptionLabel,
                                    numfac: getOptionLabel.numfac,
                                    typfac: getOptionLabel.typfac,
                                  })
                                : this.setState({
                                    numfac: "",
                                    typfac: "",
                                    facOrBl: "",
                                  });
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // label={translate("N° facture")}
                                label="N° Devis"
                                margin="normal"
                                onFocus={() => {
                                  //liste  devis par code client
                                  fetch(
                                    `http://www.24-crm.com/webservice/Api/BCDVCLIs?typfac=DV&vendeur=${this.props.Auth.username}&codcliss=${this.state.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
                                    {
                                      method: "GET",
                                      header: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  )
                                    .then((res) => res.json())
                                    .then((result) => {
                                      console.log(result);
                                      this.setState({
                                        numdevTab: result,
                                        typfacTab: result.map(
                                          (el) => el.typfac
                                        ),
                                      });
                                    });
                                }}
                                //variant="outlined"
                                fullWidth
                                name="devis"
                                // value={this.state.facOrBl}
                                // onChange={(event) =>
                                //   this.setState({ facOrBl: event.target.value })
                                // }
                              />
                            )}
                          />
                        </FormGroup>
                      </Col>
                    ) : null} */}
                  </Row>
                </Card.Body>
              </Card>

              {/* ///////////////////////////////////////////////////////// Accordiation //////////// */}

              <Accordion style={{ marginTop: "10px" }}>
                <Card bg="light">
                  <Card.Header style={{ 
                                  height: "60px" ,
                                  backgroundColor: `${darck ? "" : "#828282"}` 
                                  }}>
                    <Row>
                      <Col sm={12}>
                        <Row style={{ dispaly: "flex", flexWrap: "nowrap" }}>
                          <Col sm={11}>
                            <Accordion.Toggle
                              as={Button}
                              eventKey="0"
                              style={{ marginTop: "-5px" }}
                            >
                              <Chip
                                style={{ 
                                  fontSize: "16px" ,
                                  color: `${darck ? "" : "#fff"}`,
                                  border: `1px solid ${darck ? "" : "#fff"}`,
                                }}
                                icon={<VisibilityIcon />}
                                color="primary"
                                label="Liste des Articles"
                                variant="outlined"
                              />
                            </Accordion.Toggle>
                          </Col>
                          <Col sm={1}>
                            {this.state.btnEnable ? (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      style={{
                                        backgroundColor: "#3f51b5",
                                        color: "white",
                                        width: "40px",
                                        height: "40px",
                                      }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            ) : (
                              <label>
                                <h5>
                                  <Tooltip title="Ajouter article">
                                    <Fab
                                      disabled
                                      style={{ width: "40px", height: "40px" }}
                                      aria-label="add"
                                      onClick={() =>
                                        this.setState({
                                          ligModalShow: true,
                                          rem: this.state.remiseg,
                                        })
                                      }
                                    >
                                      <AddIcon />
                                    </Fab>
                                  </Tooltip>
                                </h5>
                              </label>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card.Header>

                  <Accordion.Collapse eventKey="0">
                    <Card.Body 
                                          style={{
                                            backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                            border: `${darck ? "" : "2px solid #fff"}`,
                                          }}
                    >
                      <div className="tabd28">
                        <table style={{ marginTop: "10px" }}>
                          <thead style={{ fontSize: "12px" }}>
                            <tr>
                              <th>Code</th>
                              <th style={{ width: "37%" }}>Désignation</th>
                              <th>Quantité</th>
                              <th>PU HT</th>
                              <th>Remise</th>
                              <th>TVA</th>
                              <th>PUTTCNet</th>
                              <th>TotalHT</th>
                            </tr>
                          </thead>
                          <tbody                             style={{
                              textAlign: "center",
                              color: `${darck ? "" : "#fff"}`,
                            }}>
                            {this.state.tab.map((t, i) => (
                              <tr key={i}>
                                <td>
                                  <span>{t.codearticle}</span>
                                </td>
                                <td style={{ fontSize: "12px", width: "37%" }}>
                                  <span> {t.des} </span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {t.qte}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puht).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.remisea).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.tva).toFixed(2)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.puttcnet).toFixed(3)}</span>
                                </td>
                                <td>
                                  {" "}
                                  <span> {Number(t.totalht).toFixed(2)}</span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              {/* //////////////// Footer //////////////////// */}
              <Card style={{ marginTop: "10px" }} id="bcfr1">
                <Card.Body
                                  style={{
                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                    border: `${darck ? "" : "2px solid #fff"}`,
                                  }}
                >
                  <Row>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HT Brut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: "-25px" }}>
                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Valeur timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>0.000</p>
                    </Col>

                    <Col
                      sm={3}
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` , fontWeight: "bold" }}>
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` , fontWeight: "bold" }}>
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* ////////Responsive////////// */}

              <Card style={{ marginTop: "10px" }} id="bcfr2">
                <Card.Body   style={{
                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                    border: `${darck ? "" : "2px solid #fff"}`,
                                  }}
                                  >
                  <Row className="rowbcf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HTBrut
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtbrut).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Remise Article
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.sumremisearticle).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="bcf">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbcf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TVA
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.totaltva).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.TOTTVA).toFixed(3)}
                        </p>
                      )}
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Remise Globale
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.remiseglobal).toFixed(3)}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col id="bcf">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbcf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total HT Net
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                        {Number(this.state.totalhtnet).toFixed(3)}
                      </p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Total TTC
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.netapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}` }}>
                          {Number(this.state.NETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col id="bcf">
                      <Divider
                        style={{ marginTop: "-8px", backgroundColor: "grey" }}
                      ></Divider>
                    </Col>
                  </Row>

                  <Row className="rowbcf">
                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{ color: `${darck ? "darkslateblue" : "#fff"}`, marginBottom: "-5px" }}
                      >
                        Valeur Timbre
                      </p>
                      <p style={{ color: `${darck ? "black" : "#fff"}` }}>0.000</p>
                    </Col>

                    <Col
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      <p
                        style={{
                          color: "rgb(220, 0, 78)",
                          fontWeight: "bold",
                          marginBottom: "-5px",
                        }}
                      >
                        Net à Payer
                      </p>
                      {this.state.remiseg === 0 ? (
                        <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                          {Number(this.state.netnetapayer).toFixed(3)}
                        </p>
                      ) : (
                        <p style={{ color: `${darck ? "black" : "#fff"}`, fontWeight: "bold" }}>
                          {Number(this.state.NETNETAPAYER).toFixed(3)}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  {this.state.totalqte === 0 ? (
                    <Button
                      variant="contained"
                      disabled
                      style={{ marginTop: "20px", width: "100%" }}
                    >
                      Enregistrer tous
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{
                        marginTop: "20px",
                        width: "100%",
                        color: "white",
                        backgroundColor: "#020F64",
                      }}
                      type="submit"
                    >
                      Enregistrer tous
                    </Button>
                  )}
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>

        <LigBCArticle
          submitHandler={this.submitHandler}
          show={this.state.ligModalShow}
          onHide={ligModalClose}
          tabArticle={this.state.tab}
          rem={rem}
          // numfaccc={this.props.numfacbcfrs.numfacbcfrs.map((nu) =>
          //   parseInt(nu.valeur)
          // )}
          numfaccc={0}
          totalqte={this.state.totalqte}
          sumremisearticle={this.state.sumremisearticle}
          totalhtbrut={this.state.totalhtbrut}
          totaltva={this.state.totaltva}
          totalhtnet={this.state.totalhtnet}
          remiseglobal={this.state.remiseglobal}
          netapayer={this.state.netapayer}
          btnEnabled={this.state.btnEnabled}
          netnetapayer={this.state.netnetapayer}
          datfac={this.state.defaultdate}
          valtimbre={this.state.valtimbre}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectFournisseur: (a, b, c, d, e) =>
      dispatch(SelectFournisseur(a, b, c, d, e)),
    //GetNumFacDevis: (a, b, c, d, e) => dispatch(GetNumFacDevis(a, b, c, d, e)),
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
    GetNumFacBCFrs: (a, b, c, d, e) => dispatch(GetNumFacBCFrs(a, b, c, d, e)),
    SelectBCFrs: (a, b, c, d, e) => dispatch(SelectBCFrs(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    fournisseurs: state.fournisseurs,
    numfacbcfrs: state.numfacbcfrs,
    articles: state.articles,
    Auth: state.Auth,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddBCFrsModal));
