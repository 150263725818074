import React, { Component } from "react";
import "./HomePage.scss";
import { connect } from "react-redux";
import { SelectTopclient } from "../../redux/actions/Top5";
import { SelectTopFrs } from "../../redux/actions/Top5Frs";
import { FormGroup, Row, Col } from "reactstrap";
import { TextField } from "@material-ui/core";
import Axios from "axios";
import { Bar} from "react-chartjs-2";
import translate from "../../i18n/translate";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class FullWidthTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      change: true,
      data: {},
      annee: "2024",
    };
  }

  componentDidMount() {
    Axios.get(
      `https://www.24-crm.tn/webservice/api/CHIFFREs?annee=2024&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(record.Mois);

        runscore.push(record.Ventes);
      });

      this.setState({
        Data: {
          labels: playername,

          datasets: [
            {
              label: "chiffre d'année par mois",

              data: runscore,

              backgroundColor:
                "rgb(220, 0, 78)",

              //   [ "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",

              //   "rgb(220, 0, 78)",
              // ],
              // borderColor: "rgba(0,0,0,1)",
              // borderWidth: 2,
            },
          ],
        },
      });
    });
  }

  anneeHandler = (event) => {
    Axios.get(
      `https://www.24-crm.tn/webservice/api/CHIFFREs?annee=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    ).then((res) => {

      const ipl = res.data;

      let playername = [];

      let runscore = [];

      ipl.forEach((record) => {
        playername.push(record.Mois);

        runscore.push(record.Ventes);
      });

      this.setState({
        Data: {
          labels: playername,

          datasets: [
            {
              label: "chiffre d'année par mois",

              data: runscore,

              backgroundColor: [
                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",
                "rgb(220, 0, 78)",

                "rgb(220, 0, 78)",
              ],
              // borderColor: "rgba(0,0,0,1)",
              // borderWidth: 2,
            },
          ],
        },
      });
    });
  };

  render() {
    const { darck , classes } = this.props;
    return (
      <div>
        <p className={darck ? "p1" : "p1Darck"} style={{ margin: "10px" }}>
          {translate("Chiffre d'affaire Global")}
        </p>
        <form>
          <Row>
            <Col>
              <FormGroup className={`${darck ? "" : classes.root}`}>
                <TextField
                  id="standard-basic"
                  label={translate("Année")}
                  defaultValue={this.state.annee}
                  fullWidth
                  name="annee"
                  type="number"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  // margin="dense"
                  variant="outlined"
                  onChange={this.anneeHandler}
                  InputProps={{
                    className: !darck ? this.props.classes.input : null,
                  }}
                  InputLabelProps={{
                    style: {
                      color: !darck ? "#fff" : null,
                    },
                  }}
                />

                <div>
                  <Bar
                    data={this.state.Data}
                    options={{ maintainAspectRatio: false }}
                    width={"300px"}
                    height={"200px"}
                  />
                </div>
              </FormGroup>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectTopclient: (a, b, c, d, e) =>
      dispatch(SelectTopclient(a, b, c, d, e)),
    SelectTopFrs: (a, b, c, d, e) => dispatch(SelectTopFrs(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    tops: state.tops,
    topfrss: state.topfrss,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(stylesFn)(FullWidthTabs));
