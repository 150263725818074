import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { SelectBL } from "../../redux/actions/GetBL";
import { connect } from "react-redux";
import "./bl.scss";
import AddBLModal from "./AddBLModal";
import GetBLByIdModal from "./GetBLByIdModal";
import { Redirect } from "react-router-dom";
import translate from "../../i18n/translate";

import {
  Button,
  Breadcrumbs,
  InputBase,
  FormLabel,
  Paper,
  TableSortLabel,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { SelectValTimbre } from "../../redux/actions/GetValTimbre";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AddIcon from "@material-ui/icons/Add";
import ListAltIcon from "@material-ui/icons/ListAlt";
import HomeIcon from "@material-ui/icons/Home";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { Link } from "react-router-dom";

import "./Responsivebl.css";
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined';

import { Divider, Fab, IconButton, Snackbar } from "@material-ui/core";



import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import moment from 'moment';
const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  // { icon: <EditIcon />, name: "Modifier" },
  // { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  // { icon: <ListAltIcon />, name: "Relevé" },
];



const DATE_OPTIONS = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
};

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class BonDeLivraison extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      addModalShow: false,
      getBLByIdModalShow: false,
      loggedIn,
      rechs: [],
      icon: false,
      rechercheclicked: false,
      tabtab: [],

      defaultdate: date,
      firstdate: date,
      seconddate: date,
      rechdats: [],
      showrechbydate: false,

      gilad: true,
      isSearchDate: false,
      order: "asc",
      orderBy: "",
      rechvalue: "",
    };
  }

  componentDidMount() {
    this.props.SelectBL(
      this.props.Auth.username,
      this.props.Auth.fct,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectValTimbre(
      this.props.ConfigDossier.database,
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur
    );

    document.title = "Bon De Livraison";
  }


  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });

    this.state.gilad
      ? this.setState({ showrechbydate: false })
      : this.setState({ rechercheclicked: false });
  };

  toggle = () => this.setState({ modal: !this.state.modal });

  rechercheHandler = (event) => {
    this.setState({rechs: []});
    this.setState({ rechvalue: event.target.value });
    //recherche par codcli ou par raison social

    fetch(
      `https://www.24-crm.tn/webservice/api/BLBRs/${event.target.value}?type=bl&VENDEUR=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechs: data,
          rechercheclicked: true,
        })
      );
  };


  firstrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ firstdate: event.target.value });
    //recherche par date début
    fetch(
      `https://www.24-crm.tn/webservice/api/BLBRs?datt=${event.target.value}&dattt=${this.state.seconddate}&typpe=BL&VENDEUR=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };

  secondrechdatHandler = (event) => {
    this.setState({rechdats: []});
    this.setState({ seconddate: event.target.value });
    //recherche par date fin
    fetch(
      `https://www.24-crm.tn/webservice/api/BLBRs?datt=${this.state.firstdate}&dattt=${event.target.value}&typpe=BL&VENDEUR=${this.props.Auth.username}&grp=${this.props.Auth.fct}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) =>
        this.setState({
          rechdats: data,
          showrechbydate: true,
          rechercheclicked: false,
        })
      );
  };
  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };
  // getTotalmontant = () => {
  //   let totalmontant = 0;
  //   this.props.bls.bls.forEach((article) => {
  //     totalmontant += parseFloat(Number(article.mntbon));
  //   });
  //   return totalmontant.toFixed(3); // arrondi à 4 chiffres après la virgule
  // };
  getTotalmontant = () => {
    let totalmontant = 0;
    if(this.state.rechercheclicked){
      this.state.rechs.forEach((article) => {
       
          totalmontant += parseFloat(Number(article.mntbon));
    })
  }else if (this.state.showrechbydate){
    this.state.rechdats.forEach((article)=>{
      totalmontant+=parseFloat(Number(article.mntbon));
    })
  }
  else{
    this.props.bls.bls.forEach((article) => {
      totalmontant += parseFloat(Number(article.mntbon));
    })
  };
    return totalmontant.toFixed(3); // arrondi à 4 chiffres après la virgule
  };





  render() {
    const classes = {
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
    };

    let addModalClose1 = () => this.setState({ addModalShow: false });
    let getModalClose = () => this.setState({ getBLByIdModalShow: false });
    // let exit;
    const {
      blid,
      datebl,
      client,
      raisonsociale,
      totalqteee,
      totalhtbrut,
      remiselignes,
      remiseglobale,
      totalhtnet,
      totaldc,
      totalcos,
      totalttc,
      totaltva,
      droitdetimbre,
      avanceimpot,
      rem,
      annuler,
      sumqt,
      pj,
      facture,
      catfisc,
      SumHtBrut,
      taurem,
      reste,
      vendeur,
      regimecli,
      avance,
      valtimbree,
      MG,
    } = this.state;

    if (this.state.loggedIn === false) {
      return <Redirect to="/" />;
    }
    const { darck } = this.props;

    return (
      <div>
        <Breadcrumbs aria-label="breadcrumb" fontSize="small">
          <Link to={"/"}>
            <StyledBreadcrumb
              component="a"
              label="Vente"
              icon={
                <HomeIcon
                  style={{ color: `${darck ? "" : "#fff"}` }}
                  fontSize="small"
                />
              }
              style={{
                color: `${darck ? "" : "#fff"}`,
                backgroundColor: `${darck ? "" : "#172431"}`,
                border: "2px solid #fff",
              }}
            />
          </Link>
          {/* <div>/</div> */}
          <StyledBreadcrumb
            component="a"
            color="Primary"
            style={{
              color: `${darck ? "rgb(63, 81, 181)" : "#fff"}`,
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // href="/fiche-client"
            label="Bon de livraison"
            icon={<ListAltIcon fontSize="small" />}
          />
           
        </Breadcrumbs>

        <div>
          <br />
          <Paper
            style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}
            // elevation={0.5}
            elevation={2}
          >
            <Row style={{display : "flex" , flexWrap: "nowrap",marginTop:"5px"}}>
              <Col sm={9}>
                <SearchIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "inherit" : "primary"}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                  onClick={() => this.setState({ isSearchDate: false })}
                />
                &nbsp;&nbsp;
                <DateRangeIcon
                  className="search-icon"
                  color={this.state.isSearchDate ? "primary" : "inherit"}
                  onClick={() => this.setState({ isSearchDate: true })}
                  style={{ color: `${darck ? "" : "#fff"}` }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.isSearchDate ? (
                  <>
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date début
                    </FormLabel>
                    &nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      onClick={this.toggle}
                      onChange={this.firstrechdatHandler}
                      value={this.state.firstdate}
                      defaultValue={this.state.defaultdate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />{" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FormLabel style={{ color: `${darck ? "" : "#fff"}` }}>
                      Date Fin
                    </FormLabel>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBase
                      type="date"
                      // fullWidth={true}
                      placeholder="date"
                      defaultValue={this.state.defaultdate}
                      onChange={this.secondrechdatHandler}
                      value={this.state.seconddate}
                      style={{ color: `${darck ? "" : "#fff"}` }}
                    />
                  </>
                ) : (
                  <InputBase
                    margin="dense"
                    // fullWidth={true}
                    placeholder="Rechercher"
                    onChange={this.rechercheHandler}
                    onClick={this.toggle}
                    style={{
                      color: `${darck ? "" : "#fff"}`,
                      backgroundColor: `${darck ? "" : "#172431"}`,
                    }}
                  />
                )}
              </Col>

              <Col sm={3} style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  color="primary"
                  onClick={() => {
                    this.setState({
                      addModalShow: true,
                    });
                  }}
                  style={{ color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}` }}
                >
                  <AddIcon style={{
                  color: `${darck ? "rgb(63, 81, 181)" : "#4CAF50"}`,
                }}/>
                  {/* {translate("Bon de Livraison")} */}
                 Bon de Livraison
                </Button>
              </Col>
            </Row>
          </Paper>
        </div>

        <br />

        {this.state.rechercheclicked && this.state.rechvalue !== "" ? (
          <div className="tabbl">
            <table>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />

              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechs,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{ cursor: "pointer", paddingRight: "17px" }}
                    key={test.numfac}
                    onClick={() => {
                      fetch(
                        `https://www.24-crm.tn/webservice/Api/LigBLBRs?type=BL&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                            SumHtBrut:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite) * v.priuni,
                                0
                              ),
                          })
                        );
                      this.setState({
                        getBLByIdModalShow: true,
                        blid: test.numfac,
                        datebl: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.ForfaitCli,
                        annuler: test.annuler,
                        reste: test.reste,
                        facture: test.facture,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.VENDEUR,
                        pj: test.numcom,
                        regimecli: test.tauxNASS,
                        avance: test.smNASS,
                        MG: test.CodDep,
                      });
                      test.timbre
                        ? this.setState({
                            valtimbree: this.props.valtimbres.valtimbres.map(
                              (t) => parseFloat(t.valtimbre)
                            ),
                          })
                        : this.setState({
                            valtimbree: Number(0).toFixed(3),
                          });
                    }}
                  >
                    <td>
                      <span
                        id="num"
                        style={{ color: "#8f2d56", paddingRight: "23px" }}
                      >
                        {test.numfac}
                      </span>
                    </td>

                    <td>
                      <span
                        id="dat"
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingRight: "10px",
                        }}
                      >
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codcli}</span>
                    </td>

                    <td style={{ color: `${darck ? "#182532" : "#fff"}` }}>
                      <span>{test.raisoc}</span>
                    </td>
                    <td>
                      {test.annuler != "0" ? (
                      <span
                      id="annuler"
                      style={{
                        color: 'red',        // Changer la couleur en rouge
                        fontWeight: 'bold',   // Mettre le texte en gras
                        paddingLeft: '14px',
                      }}
                    >
                      Annulé
                    </span>
                      ) : test.facture != "0" ? (
                        <span
                          id="annuler"
                          style={{ paddingLeft: "16px", color: "#4FA2B8" }}
                        >
                          FT: N°{test.facture}
                        </span>
                      ) : (
                        <span id="annuler"   style={{
                        color: 'red',        // Changer la couleur en rouge
                        fontWeight: 'bold',   // Mettre le texte en gras
                        paddingLeft: '14px',
                      }}>
                          {translate("Non Facturé")}
                        </span>
                      )}
                    </td>
                    <td>
                      {" "}
                      <span
                        id="pj"
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingLeft: "25px",
                        }}
                      >
                        {test.numcom ? test.numcom : "--"}
                      </span>{" "}
                    </td>
                    <td>
                      <span
                        id="mont"
                        style={{ color: "green", paddingLeft: "20px" }}
                      >
                        {Number(test.mntbon).toFixed(3)}
                      </span>
                    </td>
                  
                    <td>
                      {" "}
                      <span
                        id="vend"
                        style={{
                          color: `${darck ? "#182532" : "#fff"}`,
                          paddingLeft: "33px",
                        }}
                      >
                        {test.VENDEUR}
                      </span>{" "}
                    </td>
                 
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : this.state.showrechbydate ? (
          <div className="tabbl">
            <table>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />

              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.state.rechdats,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                   // style={{ cursor: "pointer", paddingRight: "17px" }}
                    key={test.numfac}
                    onClick={() => {
                      //liste des lignes d'article d'un BL
                      fetch(
                        `https://www.24-crm.tn/webservice/Api/LigBLBRs?type=BL&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                            SumHtBrut:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite) * v.priuni,
                                0
                              ),
                          })
                        );
                      this.setState({
                        getBLByIdModalShow: true,
                        blid: test.numfac,
                        datebl: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.ForfaitCli,
                        annuler: test.annuler,
                        reste: test.reste,
                        facture: test.facture,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.VENDEUR,
                        pj: test.numcom,
                        regimecli: test.tauxNASS,
                        avance: test.smNASS,
                        MG: test.CodDep,
                      });
                      test.timbre
                        ? this.setState({
                            valtimbree: this.props.valtimbres.valtimbres.map(
                              (t) => parseFloat(t.valtimbre)
                            ),
                          })
                        : this.setState({
                            valtimbree: Number(0).toFixed(3),
                          });
                    }}
                  >
                    <td>
                      <span
                        id="num"
                        style={{ color: "#8f2d56", paddingRight: "23px" }}
                      >
                        {test.numfac}
                      </span>
                    </td>

                    <td>
                      <span id="dat" style={{ paddingRight: "10px" }}>
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codcli}</span>
                    </td>

                    <td>
                      <span>{test.raisoc}</span>
                    </td>
                    <td>
                      {test.annuler != "0" ? (
                        <span
                          id="annuler"
                          style={{ paddingLeft: "16px", color: "#DF5F10" }}
                        >
                          {translate("Annulé")}
                        </span>
                      ) : test.facture != "0" ? (
                        <span
                          id="annuler"
                          style={{ paddingLeft: "16px", color: "#4FA2B8" }}
                        >
                          FT: N°{test.facture}
                        </span>
                      ) : (
                        <span id="annuler" style={{ color: "#007FFF" }}>
                          {translate("Non Facturé")}
                        </span>
                      )}
                    </td>
                    <td>
                      {" "}
                      <span id="pj" style={{ paddingLeft: "25px" }}>
                        {test.numcom ? test.numcom : "--"}
                      </span>{" "}
                    </td>
                    <td>
                      <span
                        id="mont"
                        style={{ color: "green", paddingLeft: "20px" }}
                      >
                        {Number(test.mntbon).toFixed(3)}
                      </span>
                    </td>
                  
                    <td>
                      {" "}
                      <span id="vend" style={{ paddingLeft: "33px" }}>
                        {test.VENDEUR}
                      </span>{" "}
                    </td>
                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="tabbl">
            <table>
              <EnhancedTableHead
                classes={classes}
                order={this.state.order}
                orderBy={this.state.orderBy}
                onRequestSort={this.handleRequestSort}
                darck={darck}
              />
              {/* <thead>
                <tr>
                  <th>№ BL</th>
                  <th>Date</th>
                  <th>Code client</th>
                  <th >Raison Sociale</th>
                  <th>Facturé</th>

                  <th>Montant</th>
                </tr>
              </thead> */}
              <tbody style={{ maxHeight: "60vh" }}>
                {stableSort(
                  this.props.bls.bls,
                  getComparator(this.state.order, this.state.orderBy)
                ).map((test) => (
                  <tr
                    style={{
                      cursor: "pointer",
                      paddingRight: "17px",
                      // backgroundColor: test.annuler != "0" ? "#ffd5d5" : null,
                    }}
                    key={test.id}
                    onClick={() => {
                      //liste des lignes d'article d'un BL
                      fetch(
                        `https://www.24-crm.tn/webservice/Api/LigBLBRs?type=BL&numfac=${test.numfac}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                      )
                        .then((response) => response.json())
                        .then((data) =>
                          this.setState({
                            tabtab: data,
                            sumqt:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite),
                                0
                              ),
                            SumHtBrut:
                              data &&
                              data.reduce(
                                (a, v) => a + parseInt(v.quantite) * v.priuni,
                                0
                              ),
                          })
                        );
                      this.setState({
                        getBLByIdModalShow: true,
                        blid: test.numfac,
                        datebl: test.datfac,
                        client: test.codcli,
                        raisonsociale: test.raisoc,
                        totalhtbrut: test.smhtb,
                        remiselignes: test.smremart,
                        remiseglobale: test.smremglo,
                        totalhtnet: test.smhtn,
                        totaldc: test.smDC,
                        totalcos: test.smCOS,
                        totalttc: test.mntbon,
                        totaltva: test.smtva,
                        droitdetimbre: test.timbre,
                        avanceimpot: test.ForfaitCli,
                        annuler: test.annuler,
                        reste: test.reste,
                        facture: test.facture,
                        catfisc: test.catfisc,
                        taurem: test.taurem,
                        vendeur: test.VENDEUR,
                        pj: test.numcom,
                        regimecli: test.tauxNASS,
                        avance: test.smNASS,
                        MG: test.CodDep,
                      });
                      test.timbre
                        ? this.setState({
                            valtimbree: this.props.valtimbres.valtimbres.reduce(
                              (c, t) => c + parseFloat(t.valtimbre),
                              0
                            ),
                          })
                        : this.setState({
                            valtimbree: Number(0).toFixed(3),
                          });
                    }}
                  >
                    <td>
                      <span
                        id="num"
                        style={{ color: "#8f2d56", paddingRight: "23px" }}
                      >
                        {test.numfac}
                      </span>
                    </td>

                    <td>
                      <span
                        id="dat"
                        style={{
                          paddingRight: "10px",
                          color: `${darck ? "" : "#fff"}`,
                        }}
                      >
                        {new Date(test.datfac).toLocaleDateString(
                          "fr",
                          DATE_OPTIONS
                        )}
                      </span>
                    </td>

                    <td>
                      <span style={{ color: "#DF5F10" }}>{test.codcli}</span>
                    </td>

                    <td>
                      <span style={{ color: `${darck ? "" : "#fff"}` }}>
                        {test.raisoc}
                      </span>
                    </td>
                    <td>
                      {test.annuler != "0" ? (
                        <span
                          id="annuler"
                          style={{ color: "#DF5F10", paddingLeft: "16px" }}
                        >
                          {translate("Annulé")}
                        </span>
                      ) : test.facture != "0" ? (
                        <span
                          id="annuler"
                          style={{ paddingLeft: "16px", color: "#4FA2B8" }}
                        >
                          FT: N°{test.facture}
                        </span>
                      ) : (
                        <span
                          id="annuler"
                          style={{
                            paddingLeft: "16px",
                            color: `${darck ? "" : "#fff"}`,
                          }}
                        >
                          {translate("Non Facturé")}
                        </span>
                      )}
                    </td>
                    <td>
                      {" "}
                      <span
                        id="pj"
                        style={{
                          paddingLeft: "25px",
                          color: `${darck ? "" : "#fff"}`,
                        }}
                      >
                        {test.numcom ? test.numcom : "--"}
                      </span>{" "}
                    </td>
                    <td>
                      <span
                        id="mont"
                        style={{ color: "green", paddingLeft: "20px" }}
                      >
                        {Number(test.mntbon).toFixed(3)}
                      </span>
                    </td>
                  
                    <td>
                      {" "}
                      <span
                        id="vend"
                        style={{
                          paddingLeft: "33px",
                          color: `${darck ? "" : "#fff"}`,
                        }}
                      >
                        {test.VENDEUR}
                      </span>{" "}
                    </td>
                 
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
  <br />
   
   <Paper
     style={{
       backgroundColor: `${darck ? "" : "#172431"}`,
       border: `${darck ? "" : "2px solid #fff"}`,
     }}
     // elevation={0.5}
     elevation={3}
   >
     <Row style={{ flexWrap: "nowrap" }}>
       {/* <InputGroupText>
       <SearchIcon className="search-icon" color="primary" />
     </InputGroupText> */}

       <Col sm={9}>
         <div style={{ display: "flex", flexWrap: "nowrap" ,marginTop:"5px"}}>
          

           {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
           <label
            style={{ color: `${darck ? "#0D47A1" : "#fff"}`
            , fontSize: "12px" }}

           >T O T A U X : </label>
         </div>
       </Col>
       <Col sm={1} style={{ display: "flex", justifyContent: "end" }}>
       <label>

    <span style={{ color: "green" }}>
    {this.getTotalmontant()}
</span>
</label>   

         {/* <InputGroupAddon addonType="append">
       
     </InputGroupAddon> */}
       </Col>
     </Row>
   </Paper>
{/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
           <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<PrintIcon openIcon={<PrintIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                      
                      }}
                    />
                  ))}
                    {!this.state.open ? (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            display: "none",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  ) : (
                    <ReactToPrint
                      trigger={() => (
                        <Fab
                          size="small"
                          style={{
                            backgroundColor: "white",
                            marginLeft: "7px",
                            marginTop: "7px",
                            color: "grey",
                          }}
                          aria-label="add"
                        >
                          <PrintIcon />
                        </Fab>
                      )}
                      content={() => this.componentRef}
                    />
                  )}
                </SpeedDial>
              
                   </Col>
            </Row>  
            <div style={{ display: "none" }}>
            <div
              ref={(el) => (this.componentRef = el)}
             style={{ margin: "40px  10px 20px 10px", height: "94%" }}
            >
  
  <img
    src={`data:image/png;base64,${this.props.InfoEntreprise[0].Photo} `}
   alt="Pas-Photo"
    style={{width: "250px",height: "auto",position: "absolute",top: "15px",right: "15px",}} 
   /> 
  
  
              <h1 style={{ fontSize: "50px", fontWeight: "bolder" ,marginLeft: "25px"}}>
                Société {this.props.InfoEntreprise[0].coddos}
              </h1>
              <p style={{ fontSize: "20px", marginTop: "-10px" ,marginLeft: "25px",}}>
                {this.props.InfoEntreprise[0].spe}
                <br />
                Adresse : {this.props.InfoEntreprise[0].adr} <br />
                Tél:{this.props.InfoEntreprise[0].tel1} / Gsm:{" "}
                {this.props.InfoEntreprise[0].tel2} / Email:{" "}
                {this.props.InfoEntreprise[0].email} <br />
                Maricule Fiscale   :  {this.props.InfoEntreprise[0].codtva} <br />
                RIB: {this.props.InfoEntreprise[0].rib}{" "}
                {this.props.InfoEntreprise[0].matban}
                {this.props.InfoEntreprise[0].agence}{" "}
                {this.props.InfoEntreprise[0].ville}
                <br />
                {translate("Etabli par")}: {this.props.Auth.username}
              </p>
              <h5
                style={{
                  marginLeft: "450px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
               
                  <>Bon de livraison client</>
            
              </h5>
              <br/>
              <h5
                style={{
                  marginLeft: "390px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
             
             {this.state.rechercheclicked ? (<></>

                ) : this.state.showrechbydate ? (
                <> de &nbsp;{ moment(this.state.firstdate).format("DD/MM/YYYY")}&nbsp; Au &nbsp;
                            { moment(this.state.seconddate).format("DD/MM/YYYY")} </>
                ) : (
                <></>
                )}
                  
            
  
              
              </h5>

      <Col> <h6 style={{ marginLeft: "35px", marginTop: "40px" ,      marginBottom: "20px", }}> Edité le :{" "}  {new Date(this.state.defaultdate).toLocaleDateString(DATE_OPTIONS)} </h6>
      <h6
                style={{
                  marginLeft: "35px",
                  flexDirection: "column",
                  marginTop: "25px",
                  marginBottom: "-35px",
                  fontWeight: "bolder",
                }}
              >
                {/* Article  : {this.props.codearticle}  */}
              </h6>
                </Col>
            
              <div
                style={{
                  marginLeft: "5px",
                  marginTop: "10px",
                  width: "99%",
                  minHeight: "540px",
                  padding: "20px 25px",
                }}
              >      
            <table
                  style={{
                    textAlign: "center",
                    borderStyle: "10px",
                    width: "100%",
                    marginTop: "25px",
                
                  }}
                >
                  <thead
                    style={{
                      textAlign: "center",
                  
                      paddingTop: "10px",
                      paddingBottom: "30px",
                        border: "1px solid gray"
                      }} >

  <tr style={{ borderBottom: "1px solid grey" ,border: "1px solid grey",   height: "50px", }}>
                <th style={{ width: "0%" ,   border: "1px solid gray"}}>{translate("№ BL")}</th>
                <th style={{ width: "10%" ,   border: "1px solid gray"}}>{translate(" Date")}</th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Code")} </th>
                <th style={{ width: "15%" ,   border: "1px solid gray" }}>{translate("Raison sociale")}</th>  
                 <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Etat")}</th>
                 <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Bon de commande")}</th>
             
                <th style={{ width: "15%"  ,   border: "1px solid gray"}}>{translate("Montant")}</th>
              
               
                </tr> 
             
                </thead>
                {this.state.rechercheclicked ? (
 <tbody>
          
 {this.state.rechs.map((t, i) => (


  <tr
    key={i}
    style={{
      //textAlign: "center",
      height: "30px",
      border: "1px solid gray",
   
    }}>
{/* ///////////////////////////////////////// n.dv ///////////////////////*/}
<td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
 
 {t.numfac}
  
  </td>
{/* ///////////////////////////////////////////// date ///////////////////*/}
<td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
 
{ moment(t.datfac).format("DD/MM/YYYY")} 
  
  </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

          <span>{t.codcli}</span>
     
</td>

{/* //////////////////////////////////////////////// raison ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

          <span >{t.raisoc}</span>
     
</td>
{/* /////////// color: `${darck ? "darkslateblue" : "#fff"}` ///////////////////////////////////// etat ///////////*/}
<td style={{width: "3%" ,   border: "1px solid gray"}}> 

{t.annuler != "0" ? (
          <span
            id="annuler"
            style={{ color: "#DF5F10", paddingLeft: "16px" }}
          >
            {translate("Annulé")}
          </span>
        ) : t.facture != "0" ? (
          <span
            id="annuler"
            style={{ paddingLeft: "16px", color: "#4FA2B8" }}
          >
            FT: N°{t.facture}
          </span>
        ) : (
          <span
            id="annuler"
            style={{
              paddingLeft: "16px",
              color: `${darck ? "" : "#fff"}`,
            }}
          >
            {translate("Non Facturé")}
          </span>
        )}

</td>
{/* //////////////////////////////////////////////// Bon de commande ///////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

<span>{t.numcom? t.numcom:'--'}</span>

</td>

{/* //////////////////////////////////////////////// montant ///////////*/}
<td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

<span>{Number(t.mntbon).toFixed(3)}</span>

</td>
    
    
      
   
     </tr>  
    
    ))} 
   </tbody>
) : this.state.showrechbydate ? (
  <tbody>
          
  {this.state.rechdats.map((t, i) => (


   <tr
     key={i}
     style={{
       //textAlign: "center",
       height: "30px",
       border: "1px solid gray",
    
     }}>
{/* ///////////////////////////////////////// n.dv ///////////////////////*/}
<td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
  
  {t.numfac}
   
   </td>
{/* ///////////////////////////////////////////// date ///////////////////*/}
<td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
  
{ moment(t.datfac).format("DD/MM/YYYY")} 
   
   </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

           <span>{t.codcli}</span>
      
</td>

{/* //////////////////////////////////////////////// raison ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

           <span >{t.raisoc}</span>
      
</td>
{/* /////////// color: `${darck ? "darkslateblue" : "#fff"}` ///////////////////////////////////// etat ///////////*/}
<td style={{width: "3%" ,   border: "1px solid gray"}}> 

{t.annuler != "0" ? (
           <span
             id="annuler"
             style={{ color: "#DF5F10", paddingLeft: "16px" }}
           >
             {translate("Annulé")}
           </span>
         ) : t.facture != "0" ? (
           <span
             id="annuler"
             style={{ paddingLeft: "16px", color: "#4FA2B8" }}
           >
             FT: N°{t.facture}
           </span>
         ) : (
           <span
             id="annuler"
             style={{
               paddingLeft: "16px",
               color: `${darck ? "" : "#fff"}`,
             }}
           >
             {translate("Non Facturé")}
           </span>
         )}

</td>
{/* //////////////////////////////////////////////// Bon de commande ///////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

<span>{t.numcom? t.numcom:'--'}</span>

</td>

{/* //////////////////////////////////////////////// montant ///////////*/}
<td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

<span>{Number(t.mntbon).toFixed(3)}</span>

</td>
     
     
       
    
      </tr>  
     
     ))} 
    </tbody>
) : (
  <tbody>
          
  {this.props.bls.bls.map((t, i) => (


   <tr
     key={i}
     style={{
       //textAlign: "center",
       height: "30px",
       border: "1px solid gray",
    
     }}>
{/* ///////////////////////////////////////// n.dv ///////////////////////*/}
<td  style={{ color: `${darck ? "#8f2d56" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
  
  {t.numfac}
   
   </td>
{/* ///////////////////////////////////////////// date ///////////////////*/}
<td  style={{ color: `${darck ? "black" : "#fff"}` ,width: "10%" ,   border: "1px solid gray"}} > 
  
{ moment(t.datfac).format("DD/MM/YYYY")} 
   
   </td>
{/* /////////////////////////////////////#DF5F10//////////// code ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

           <span>{t.codcli}</span>
      
</td>

{/* //////////////////////////////////////////////// raison ///////////*/}
<td style={{ color: `${darck ? "black" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

           <span >{t.raisoc}</span>
      
</td>
{/* /////////// color: `${darck ? "darkslateblue" : "#fff"}` ///////////////////////////////////// etat ///////////*/}
<td style={{width: "3%" ,   border: "1px solid gray"}}> 

{t.annuler != "0" ? (
           <span
             id="annuler"
             style={{ color: "#DF5F10", paddingLeft: "16px" }}
           >
             {translate("Annulé")}
           </span>
         ) : t.facture != "0" ? (
           <span
             id="annuler"
             style={{ paddingLeft: "16px", color: "#4FA2B8" }}
           >
             FT: N°{t.facture}
           </span>
         ) : (
           <span
             id="annuler"
             style={{
               paddingLeft: "16px",
               color: `${darck ? "" : "#fff"}`,
             }}
           >
             {translate("Non Facturé")}
           </span>
         )}

</td>
{/* //////////////////////////////////////////////// Bon de commande ///////////*/}
<td style={{ color: `${darck ? "darkslateblue" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

<span>{t.numcom? t.numcom:'--'}</span>

</td>

{/* //////////////////////////////////////////////// montant ///////////*/}
<td style={{ color: `${darck ? "green" : "#fff"}` ,width: "3%" ,   border: "1px solid gray"}}> 

<span>{Number(t.mntbon).toFixed(3)}</span>

</td>
     
     
       
    
      </tr>  
     
     ))} 
    </tbody>
)}
                 
                 <thead
                    style={{
                      textAlign: "center",
                      fontSize: "20px",
                      fontWeight: "bold",
                      paddingTop: "-100px",
                      paddingBottom: "10px", }} >

               </thead>
          
                </table>
     
                <Col>
                <div
                  style={{ width: "100%", display: "flex",alignItems: "center",flexDirection: "row", textAlign: "center", justifyContent: "space-between", }}  >
                </div>
                <div
                  style={{
                    marginTop: "10px",
                     border: "1px solid gray",
                    // borderStyle: "solid",
                    height: "30px",
                    width: "100%",
                    //borderRadius: "5px",
                    marginBottom: "15px",
                  }}
                >
                   <h6
              style={{
                marginLeft: "20px",
                flexDirection: "column",
                // marginTop: "25px",
                // marginBottom: "-35px",
                fontWeight: "bolder",
              }}
            >  
            
                <label
          style={{ color: `${darck ? "#0D47A1" : "#fff"}`
          , fontSize: "14px" }}

         >TOTAUX : </label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
         
         <span style={{ color: "blac",paddingLeft: "650px" }}>
          
          
      
           {this.getTotalmontant()}
         </span>
            </h6>
            <br/>
              
             
                 <div
                    style={{
                      width: "10%",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      textAlign: "center",
                      marginTop: "20px",
                    }}
                  >
                  </div>
                </div>
              </Col> 

       
      
              
               
           
           
               
              </div>
            
          
            
            </div>
          </div>
 {/* /////////////////////////////////////////////////////////// debut print etat ////////////////////////////////////////////////////////////// */}
    
     
        <AddBLModal
          show={this.state.addModalShow}
          onHide={addModalClose1}
          valtimbre={this.props.valtimbres.valtimbres.map((t) =>
            parseFloat(t.valtimbre)
          )}
        />
        <GetBLByIdModal
          show={this.state.getBLByIdModalShow}
          onHide={getModalClose}
          blid={blid}
          datebl={datebl}
          client={client}
          raisonsociale={raisonsociale}
          totalhtbrut={totalhtbrut}
          remiselignes={remiselignes}
          remiseglobale={remiseglobale}
          totalhtnet={totalhtnet}
          totaldc={totaldc}
          totalcos={totalcos}
          totalttc={totalttc}
          totaltva={totaltva}
          droitdetimbre={droitdetimbre}
          avanceimpot={avanceimpot}
          rem={rem}
          pj={pj}
          regimecli={regimecli}
          annuler={annuler}
          reste={reste}
          vendeur={vendeur}
          tabtab={this.state.tabtab}
          sumqt={sumqt}
          facture={facture}
          catfisc={catfisc}
          SumHtBrut={SumHtBrut}
          taurem={taurem}
          avance={avance}
          locale={this.props.locale}
          valtimbree={valtimbree}
          MG={MG}
          valtimbre={this.props.valtimbres.valtimbres.reduce(
            (c, t) => c + parseFloat(t.valtimbre),
            0
          )}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectBL: (f, g, a, b, c, d, e) => dispatch(SelectBL(f, g, a, b, c, d, e)),
    SelectValTimbre: (a, b, c, d, e) =>
      dispatch(SelectValTimbre(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    bls: state.bls,
    SearchingResult: state.SearchingReducer,
    valtimbres: state.valtimbres,
    ConfigDossier: state.ConfigDossier,
    Auth: state.Auth,
    darck: state.darck.darck,
    InfoEntreprise: state.InfoDossier,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BonDeLivraison);
// les fonctions utilisées pour le trie des colonnes

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
const headCells = [
  {
    id: "numfac",
    numeric: false,
    disablePadding: false,
    label: translate("№ BL"),
  },

  {
    id: "datfac",
    numeric: true,
    disablePadding: false,
    label: "Date",
  },

  {
    id: "codcli",
    numeric: false,
    disablePadding: false,
    label: translate("Code client"),
  },

  {
    id: "raisoc",
    numeric: true,
    disablePadding: false,
    label: translate("Raison Social"),
  },
  {
    id: "facture",
    numeric: true,
    disablePadding: false,
    label: translate("Etat"),
  },
   {
    id: "pj",
    numeric: true,
    disablePadding: false,
    label: "Bon de Commande",
  },
  {
    id: "mntbon",
    numeric: true,
    disablePadding: false,
    label: translate("Montant"),
  },
 
  {
    id: "VENDEUR",
    numeric: true,
    disablePadding: false,
    label: translate("Utilisateur"),
  },
 
];
function EnhancedTableHead(props) {
  const {
    classes,

    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "" : ""}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);
