import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import NavBar from "./Navbar";
import { Switch, Route } from "react-router-dom";
import HomePage from "../home-page/HomePage";
import Contact from "../contact-page/Contact";
import BonDeCommande from "../Commande-client-devis/BonDeCommande";
import BonDeCommandeFrs from "../bon-de-commande-Frs/BonDeCommandeFrs";
import DevisClient from "../Commande-client-devis/DevisClients";
import ConnectedFicheFournisseur from "../clients-fournisseurs/FicheFournisseur";
import FicheClient from "../clients-fournisseurs/FicheClient";
import FicheArticle from "../gestion-des-articles/GestionDesArticles";
import BonDeLivraison from "../bon-de-livraison/BonDeLivraison";
import Facture from "../facture/Facture";
import Nomenclature from "../Nomenclatures/Nomenclature";

import BonEntree from "../bon-entree/BonEntree";
import Reglement from "../reglement/Reglement";
import ClickToCall from "../clicktocall/ClickToCall";
import Personnel from "../personnels/Personnel";
import BonDeSortie from "../bon-de-sortie/BonDeSortie";

import BonDeSortieCam from "../Bon-de-sortie-camion/BonDeSortieCam";
import BonDeRetourLivraison from "../bon-de-retour-livraison/BonDeRetourLivraison";
import AvoirClient from "../avoir-client/AvoirClient";
import ReglementFournisseur from "../reglement-fournisseur/ReglementFournisseur";
import PrivateRouteDash from "./PrivateRouteDash";
import DashboardCommerciale from "../dashbordCommerciale/DashboardCommerciale";
import FactureFRS from "../facture-fournisseur/FactureFRS";
import BoneRetourFourisseur from "../Bon-de retour-fournisseur/Bon-de-Retour-Frs";
import BonDeLivraisonFrs from "../Bon-de-livraison-fournisseur/BonDeLivraisonFrs";
import FicheDossier from "../Fiche-Dossier/FicheDossier";
import Bordereaux from "../BordereauxBancaire/Bordereaux";

import Releveclient from "../ReleveClient/relevecli";
import ReleveFournisseur from "../ReleveFRS/releveFRS";
import MvtCais from "../caisse/MvtCais";
import RapportCais from "../caisse/RappCais";
import RegCais from "../caisse/RegBLS";
import Journal from "../caisse/Journaldepense";
import JournalCaiss from "../caisse/JournaldeCaiss"
// import Logout from "./Logout";
export default (props) => (
  <Container
    fluid
    className={classNames("content", { "is-open": props.isOpen })}
    style={{ overflow: "hidden" }}
  >
    <NavBar toggle={props.toggle} />
    <Switch>
      {/* représentant authorized */}
      <Route exact path="/faq" component={() => "FAQ"} />
      <Route exact path="/contact" component={Contact} />
      <Route
        exact
        path="/fiche-article"
        render={() => <FicheArticle locale={props.locale} />}
      />
      <Route
        exact
        path="/devis"
        render={() => <DevisClient locale={props.locale} />}
      />
      <Route
        exact
        path="/bon-de-commande"
        render={() => <BonDeCommande locale={props.locale} />}
      />
      <Route
        exact
        path="/bon-de-livraison"
        render={() => <BonDeLivraison locale={props.locale} />}
      />
      <Route exact path="/dashboard" component={DashboardCommerciale} />

      {/* admin authorized */}
      <Route
        exact
        path="/fiche-client"
        component={FicheClient}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/fiche-fournisseur"
        component={ConnectedFicheFournisseur}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/bon-d'entrée"
        component={BonEntree}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/facture-fournisseur"
        component={FactureFRS}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/bl-fournisseur"
        component={BonDeLivraisonFrs}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/facture"
        component={Facture}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/bon-de-commande-frs"
        component={BonDeCommandeFrs}
        locale={props.locale}
      />

      <PrivateRouteDash exact path="/homepage" component={HomePage} />
      <PrivateRouteDash exact path="/nomenclature" component={Nomenclature} />
   
      <PrivateRouteDash
        exact
        path="/reglement-client"
        component={Reglement}
        locale={props.locale}
      />
      <PrivateRouteDash exact path="/clicktocall" component={ClickToCall} />

      
      <PrivateRouteDash
        exact
        path="/ajout-d'un-utilisateur"
        component={Personnel}
      />
      
      <PrivateRouteDash exact path="/fiche-dossier" component={FicheDossier} />
      
      <PrivateRouteDash
        exact
        path="/bon-de-sortie"
        component={BonDeSortie}
        locale={props.locale}
      />
    
      <PrivateRouteDash
        exact
        path="/bon-de-sortie-camion"
        component={BonDeSortieCam}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/bon-de-retour"
        component={BonDeRetourLivraison}
        locale={props.locale}
      />

      <PrivateRouteDash
        exact
        path="/bon-de-retour-fournisseur"
        component={BoneRetourFourisseur}
        locale={props.locale}
      />
      {/* <PrivateRouteDash
        exact
        path="/fiche-de-stock"
        component={Etatmvt}
        locale={props.locale}
      /> */}
      <PrivateRouteDash exact path="/avoir-client" component={AvoirClient} />

      <PrivateRouteDash
        exact
        path="/reglement-fournisseur"
        component={ReglementFournisseur}
        locale={props.locale}
      />

      <PrivateRouteDash 
        exact path="/suivi-bordereau" 
        component={Bordereaux}
        locale={props.locale} />  
        <PrivateRouteDash
        exact
        path="/relevé-Clients"
        component={Releveclient}
        locale={props.locale}
      />
       <PrivateRouteDash
        exact
        path="/relevé-Fournisseurs"
        component={ReleveFournisseur}
        locale={props.locale}
      />
        <PrivateRouteDash
        exact
        path="/mouvements-de-caisse"
        component={MvtCais}
        locale={props.locale}
      />
       <PrivateRouteDash
        exact
        path="/rapport-de-caisse"
        component={RapportCais}
        locale={props.locale}
      />

      <PrivateRouteDash
        exact
        path="/réglements-sur-bls"
        component={RegCais}
        locale={props.locale}
      />
      <PrivateRouteDash
        exact
        path="/journal-des-dépenses"
        component={Journal}
        locale={props.locale}
      />
       <PrivateRouteDash
        exact
        path="/jounal-de-caisse-recette-dépense"
        component={JournalCaiss}
        locale={props.locale}
      />
    </Switch>
  </Container>
);








// import React from "react";
// import classNames from "classnames";
// import { Container } from "reactstrap";
// import NavBar from "./Navbar";
// import { Switch, Route } from "react-router-dom";
// import HomePage from "../home-page/HomePage";
// import Contact from "../contact-page/Contact";
// import BonDeCommande from "../Commande-client-devis/BonDeCommande";
// import BonDeCommandeFrs from "../bon-de-commande-Frs/BonDeCommandeFrs";
// import DevisClient from "../Commande-client-devis/DevisClients";
// import ConnectedFicheFournisseur from "../clients-fournisseurs/FicheFournisseur";
// import FicheClient from "../clients-fournisseurs/FicheClient";
// import FicheArticle from "../gestion-des-articles/GestionDesArticles";
// import BonDeLivraison from "../bon-de-livraison/BonDeLivraison";
// import Facture from "../facture/Facture";
// import Nomenclature from "../Nomenclatures/Nomenclature";
// import BonEntree from "../bon-entree/BonEntree";
// import Reglement from "../reglement/Reglement";
// import ClickToCall from "../clicktocall/ClickToCall";
// import Personnel from "../personnels/Personnel";
// import BonDeSortie from "../bon-de-sortie/BonDeSortie";
// import BonDeSortieCam from "../Bon-de-sortie-camion/BonDeSortieCam";
// import BonDeRetourLivraison from "../bon-de-retour-livraison/BonDeRetourLivraison";
// import AvoirClient from "../avoir-client/AvoirClient";
// import ReglementFournisseur from "../reglement-fournisseur/ReglementFournisseur";
// import PrivateRouteDash from "./PrivateRouteDash";
// import DashboardCommerciale from "../dashbordCommerciale/DashboardCommerciale";
// import FactureFRS from "../facture-fournisseur/FactureFRS";
// import BoneRetourFourisseur from "../Bon-de retour-fournisseur/Bon-de-Retour-Frs";
// import BonDeLivraisonFrs from "../Bon-de-livraison-fournisseur/BonDeLivraisonFrs";
// import FicheDossier from "../Fiche-Dossier/FicheDossier";
// import Bordereaux from "../BordereauxBancaire/Bordereaux";
// import Releveclient from "../ReleveClient/relevecli";
// import ReleveFournisseur from "../ReleveFRS/releveFRS";
// // import Logout from "./Logout";
// export default (props) => (
//   <Container
//     fluid
//     className={classNames("content", { "is-open": props.isOpen })}
//     style={{ overflow: "hidden" }}
//   >
//     <NavBar toggle={props.toggle} />
//     <Switch>
//       {/* représentant authorized */}
//       <Route exact path="/faq" component={() => "FAQ"} />
//       <Route exact path="/contact" component={Contact} />
//       <Route
//         exact
//         path="/fiche-article"
//         render={() => <FicheArticle locale={props.locale} />}
//       />
//       <Route
//         exact
//         path="/devis"
//         render={() => <DevisClient locale={props.locale} />}
//       />
//       <Route
//         exact
//         path="/bon-de-commande"
//         render={() => <BonDeCommande locale={props.locale} />}
//       />
//       <Route
//         exact
//         path="/bon-de-livraison"
//         render={() => <BonDeLivraison locale={props.locale} />}
//       />
//       <Route exact path="/dashboard" component={DashboardCommerciale} />

//       {/* admin authorized */}
//       <Route
//         exact
//         path="/fiche-client"
//         component={FicheClient}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/fiche-fournisseur"
//         component={ConnectedFicheFournisseur}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/bon-d'entrée"
//         component={BonEntree}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/facture-fournisseur"
//         component={FactureFRS}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/bl-fournisseur"
//         component={BonDeLivraisonFrs}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/facture"
//         component={Facture}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/bon-de-commande-frs"
//         component={BonDeCommandeFrs}
//         locale={props.locale}
//       />

//       <PrivateRouteDash exact path="/homepage" component={HomePage} />
//       <PrivateRouteDash exact path="/nomenclature" component={Nomenclature} />
//       <PrivateRouteDash
//         exact
//         path="/reglement-client"
//         component={Reglement}
//         locale={props.locale}
//       />
//       <PrivateRouteDash exact path="/clicktocall" component={ClickToCall} />

      
//       <PrivateRouteDash
//         exact
//         path="/ajout-d'un-utilisateur"
//         component={Personnel}
//       />
      
//       <PrivateRouteDash exact path="/fiche-dossier" component={FicheDossier} />
      
//       <PrivateRouteDash
//         exact
//         path="/bon-de-sortie"
//         component={BonDeSortie}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/bon-de-sortie-camion"
//         component={BonDeSortieCam}
//         locale={props.locale}
//       />
//       <PrivateRouteDash
//         exact
//         path="/bon-de-retour"
//         component={BonDeRetourLivraison}
//         locale={props.locale}
//       />

//       <PrivateRouteDash
//         exact
//         path="/bon-de-retour-fournisseur"
//         component={BoneRetourFourisseur}
//         locale={props.locale}
//       />
//       <PrivateRouteDash exact path="/avoir-client" component={AvoirClient} />

//       <PrivateRouteDash
//         exact
//         path="/reglement-fournisseur"
//         component={ReglementFournisseur}
//         locale={props.locale}
//       />
     
     
//      <PrivateRouteDash  
//         exact path="/suivi-bordereau" 
//         component={Bordereaux}
//         locale={props.locale}
//          /> 
//      <PrivateRouteDash 
//         exact path="/suivi-bordereau" 
//         component={Bordereaux}
//         locale={props.locale} />  
//         <PrivateRouteDash
//         exact
//         path="/relevé-Clients"
//         component={Releveclient}
//         locale={props.locale}
//       />
//        <PrivateRouteDash
//         exact
//         path="/relevé-Fournisseurs"
//         component={ReleveFournisseur}
//         locale={props.locale}
//       />
//     </Switch>
//   </Container>
// );
