import React, { Component } from "react";
import { Modal, Card, Form } from "react-bootstrap";
import "../styling/Styles.css";
import { Row, Col, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { SelectFournisseur } from "../../redux/actions/GetFournisseur";
import TextField from "@material-ui/core/TextField";
import {
  Button,
  Snackbar,
  IconButton,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { SelectNomenclature } from "../../redux/actions/GetNomenclature";
import { SelectNomenclatureBQ } from "../../redux/actions/GetNomeBQ";
import { SelectNomenclatureAG } from "../../redux/actions/GetNomeAG";
import { SelectNomenclatureCS } from "../../redux/actions/GetNomeCS";
import { SelectNomenclatureSC } from "../../redux/actions/GetNomeSituation";
import { SelectNomenclatureCCB } from "../../redux/actions/GetNomeCCB";
import { SelectReglement } from "../../redux/actions/GetReg";
import { GetRegFrsCod } from "../../redux/actions/GetRegFrsCod";
import LinkIcon from "@material-ui/icons/Link";
import AffectationFrsModal from "./AffectationFrsModal";
import AffectationRetenuALaSourceFrs from "./AffectationRetenuALaSourceFrs";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});


// const styles = {
//   container: {
//     display: "flex",
//     flexWrap: "wrap",
//   },
//   textField: {
//     width: 300,
//     margin: 100,
//   },
//   //style for font size
//   resize: {
//     fontSize: 10,
//   },
// };

var curr = new Date();
curr.setDate(curr.getDate());
var date = curr.toISOString().substr(0, 10);

class AddReglementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: true,
      defaultdate: date,
      raisonsocial: "",
      codefournisseur: "",
      btnEnable: false,
      soldfacbl: 0,
      codmodreg: "",
      libmodreg: "",
      bqfournisseur: "",
      montant: 0,
      codbqfournisseur: "",
      libbqfournisseur: "",
      codagence: "",
      libagence: "",
      codcaisse: "",
      libcaisse: "",
      codsituation: "",
      libsituation: "",
      codbqvers: "",
      libbqvers: "",
      codccb: "",
      libccb: "",
      chdec: "",
      snackbaropen: false,
      snackbarmsg: ",",
      rechs: [],
      rechercheValue: "",
      showModal: false,
      listeFacture: [],
      numreg: "",
      listebl: [],
      listeAV: [],
      numnum: "",
      CodeCCb: "",
      CodeCCb:[],
    };
  }

  componentDidMount() {
    this.setState({
      rechercheValue: "",
      codefournisseur: "",
      raisonsocial: "",
    });
    fetch(
      `https://www.24-crm.tn/webservice/api/FOURNISSEURs?codfrss&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          rechs: data,
          rechercheclicked: true,
        });
      });

    this.props.SelectFournisseur(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.GetRECod(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclature(
      "MR",
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureBQ(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureAG(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureCS(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureSC(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectNomenclatureCCB(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
    this.props.SelectReglement(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  enregistrer = (event) => {
    event.preventDefault();
    this.setState({ numreg: event.target.codre.value });


    fetch(
      `https://www.24-crm.tn/webservice/api/REGFRs?numreg&datreg=${
        this.state.defaultdate
      }&codfrs=${event.target.codfrs.value}&raisoc=${
        event.target.raisonsocial.value
      }&modreg=${this.state.codmodreg}&numcais=${this.state.codcaisse}&matban=${
        this.state.codbqfournisseur
      }&monreg=${event.target.montant.value}&VENDEUR=${
        this.props.Auth.username
      }&RetenueALaSource=${
        this.state.codmodreg.toUpperCase().includes("RS") ? true : false
      }&sens=d&numchq=${event.target.numpiece.value}&titulaire=${
        event.target.titulaire.value
      }&datech=${this.state.defaultdate}&mntreg=${
        event.target.montant.value
      }&verser=${this.state.codsituation}&lib_reg=${
        event.target.note.value
      }&ListPiece=&BqEscompte=${this.state.codbqvers}&codccb=${
        this.state.codccb
      }&heur&coddos=${this.props.ConfigDossier.coddos}&serveur=${
        this.props.ConfigDossier.serveur
      }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
        this.props.ConfigDossier.pwd_serveur
      }&database=${this.props.ConfigDossier.database}`,
      {
        method: "POST",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log(
          "detailTabRegFrs",
          this.props.detailTabRegFrs && this.props.detailTabRegFrs
        );
        this.setState({ numnum: result });
        this.props.detailTabRegFrs &&
          this.props.detailTabRegFrs.map((k, index) => {
            fetch(
              `https://www.24-crm.tn/webservice/api/LigREGFRS?numreg=${result}&numfac=${k.numfac}&typfac=${k.typfac}&montant=${k.monreg}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
              {
                method: "POST",
                header: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }
            ).then((res) => {
              res.json();
            });
          });
        //this.setState({ snackbaropen: true, snackbarmsg: result });
      })

      .then(
        (res) => {
          this.props.onHide();

          this.props.SelectReglement(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );

          //recalculer solde fournisseur après reglement
          fetch(
            `https://www.24-crm.tn/webservice/api/FOURNISSEURs?Codfrssss=${this.state.codefournisseur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
            {
              method: "POST",
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          )
            .then((res) => res.json())
            // this.setState({
            //   raisonsocial: "",
            // });
            //window.location.reload();

            //////////// switch update ////////////////
            .then((res) => {
              fetch(
                `https://www.24-crm.tn/webservice/api/Switch?code=REGF&valeur=${
                  parseInt(this.state.numreg) + 1
                }&coddos=${this.props.ConfigDossier.coddos}&serveur=${
                  this.props.ConfigDossier.serveur
                }&user=${this.props.ConfigDossier.utilisateur}&pwd=${
                  this.props.ConfigDossier.pwd_serveur
                }&database=${this.props.ConfigDossier.database}`,
                {
                  method: "PUT",
                  header: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                  },
                }
              )
                .then((res) => res.json())
                .then(
                  (result) => {
                    window.location.reload();
                  }
                  //   (error) => {
                  //     this.setState({
                  //       snackbaropen: true,
                  //       snackbarmsg: "failed",
                  //     });
                  //   }
                );
            });
          this.setState({
            snackbaropen: true,
            snackbarmsg: "Enregistré sous le N° " + this.state.numnum,
          });
          this.props.GetRECod(
            this.props.ConfigDossier.coddos,
            this.props.ConfigDossier.serveur,
            this.props.ConfigDossier.utilisateur,
            this.props.ConfigDossier.pwd_serveur,
            this.props.ConfigDossier.database
          );
          this.setState({
            soldfacbl: "0",
          });
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
  };

  clientHandlerChange = (event) => {
    fetch(
      `https://www.24-crm.tn/webservice/api/fournisseurs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      //.then((data) => this.setState({ rechs: data, clicked: true }));
      .then((data) =>
        this.setState({
          codeclient: data[0].codcli,
          soldfacbl: data[0].soldfacbl,
        })
      );
  };

  fournisseurHandlerChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    //   fetch(
    //     `http://www.24-crm.com/webservice/api/fournisseurs?codfrss=${this.state.codefournisseur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    //     // `/api/CLIENTs?codclii=${this.state.codeclient}`
    //   )
    //     .then((response) => response.json())
    //     .then((data) => this.setState({ rechs: data }));
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };
  rechercheHandler = (event) => {
    this.setState({
      rechercheValue: event.target.value,
      codefournisseur: "",
      raisonsocial: "",
    });
    fetch(
      `https://www.24-crm.tn/webservice/api/FOURNISSEURs?codfrss=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length === 1) {
          this.setState({
            codefournisseur: data[0].codfrs,
            raisonsocial: data[0].raisoc,
            soldfacbl: data[0].soldfacbl,
          });
        }
        this.setState({
          rechs: data,
          rechercheclicked: true,
        });
      });
  };
  handleClose = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  handleShow = () => {
    fetch(
      `https://www.24-crm.tn/webservice/api/FacFrs?codfrsss=${this.state.codefournisseur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())

      .then((data) => {
        this.setState({
          listeFacture: data,
          showModal: true,
        });
      })
      .then((res) =>
        fetch(
          `https://www.24-crm.tn/webservice/api/FacFrs?codcliiis=${this.state.codefournisseur}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
          {
            method: "GET",
            header: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
          .then((response) => response.json())

          .then((data) => {
            this.setState({
              listebl: data,
              showModal: true,
            });
          })
      );
    fetch(
      `https://www.24-crm.tn/webservice/api/BLBRs?codclii=${this.props.codeclient}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "GET",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())

      .then((data) => {
        this.setState({});
      });
  };
  render() {

    const { darck, classes } = this.props;

    const theme = createMuiTheme({
      overrides: {
        MuiSwitch: {
          switchBase: {
            color: "#BEBEBE",
            "&$checked": {
              color: "#BEBEBE",
            },
          },
          track: {
            opacity: 0.2,
            backgroundColor: "#BEBEBE",
            "$checked$checked + &": {
              opacity: 0.7,
              backgroundColor: "#BEBEBE",
            },
          },
        },
      },
    });

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Ajouter Règlement fournisseur</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `none`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                      }}
          >
            <form autocomplete="off" 
                  onSubmit={this.enregistrer}
                  className={`${darck ? "" : classes.root}`}>
              <Card>
                <Card.Body
                                  style={{
                                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                                    border: `${darck ? "" : "2px solid #fff"}`,
                                  }}
                >
                  <Row>
                    <Col sm={4}>
                      <Form.Group>
                        {/* {this.props.codres.codres.map((t) => ( */}
                        <TextField
                          // className="card add-input"
                          id="standard-basic"
                          label="№ Règlement"
                          margin="dense"
                         defaultValue={ 
                            this.props.codres.codres[0] &&
                              this.props.codres.codres[0].valeur
                          }
                          //value={parseInt(
                           // this.props.codres.codres[0] &&
                             // this.props.codres.codres[0].valeur
                          //)}
                          fullWidth
                          name="codre"
                          variant="outlined"
                         // disabled

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                        {/* ))} */}
                      </Form.Group>
                    </Col>

                    <Col sm={4}>
                      <TextField
                        name="datreg"
                        id="standard-basic"
                        label="Date"
                        margin="dense"
                        type="date"
                        fullWidth
                        variant="outlined"
                        defaultValue={this.state.defaultdate}
                        // className="card add-input"

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>

                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.nomenclatures.nomenclatures}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codmodreg: getOptionLabel.code,
                                  libmodreg: getOptionLabel.lib,
                                  chdec: getOptionLabel.chdec,
                                })
                              : this.setState({
                                  codmodreg: "",
                                  libmodreg: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Mode Règ"
                              margin="dense"
                              fullWidth
                              name="modreg"
                              variant="outlined"
                              // className="card add-input"
                              required

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                      {/* <FormGroup>
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="normal"
                          defaultValue={Number(this.state.montant).toFixed(3)}
                          fullWidth
                          name="montant"
                          required
                        />
                      </FormGroup> */}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <TextField
                        id="filled-full-width"
                        label="Recherche"
                        placeholder="Recherche Fournisseur"
                        fullWidth
                        // className="card"
                        margin="dense"
                        onChange={this.rechercheHandler}
                        variant="outlined"

                        InputProps={{
                          className: !darck ? this.props.classes.input : null,
                        }}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            color: !darck ? "#fff" : null,
                          },
                        }}
                      />
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-frs-input"
                          options={this.state.rechs.filter((el) =>
                            el.codfrs
                              .toUpperCase()
                              .includes(this.state.rechercheValue.toUpperCase())
                          )}
                          getOptionLabel={(option) => option.codfrs}
                          inputValue={this.state.codefournisseur}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ codefournisseur: newInputValue });
                          }}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  codefournisseur: getOptionLabel.codfrs,
                                  btnEnable: true,
                                  showTimbre: getOptionLabel.timbre,
                                  soldfacbl: getOptionLabel.soldfacbl,

                                  cemail: getOptionLabel.email,
                                  codetva: getOptionLabel.CodeTVA,
                                  valtimbre: getOptionLabel.timbre
                                    ? this.props.valtimbre
                                    : 0,
                                })
                              : this.setState({
                                  raisonsocial: "",
                                  codefournisseur: "",
                                  btnEnable: false,
                                  showTimbre: false,
                                  showButtonModalPassager: false,
                                  codetva: "",
                                  valtimbre: 0,
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Code Fournisseur"
                              margin="dense"
                              fullWidth
                              // value={this.state.codefournisseur}
                              //onChange={this.clientCodehandlerChange}
                              name="codfrs"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col sm={4}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.state.rechs.filter((el) =>
                            el.raisoc
                              .toUpperCase()
                              .includes(this.state.rechercheValue.toUpperCase())
                          )}
                          getOptionLabel={(option) => option.raisoc}
                          inputValue={this.state.raisonsocial}
                          onInputChange={(event, newInputValue) => {
                            this.setState({ raisonsocial: newInputValue });
                          }}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  raisonsocial: getOptionLabel.raisoc,
                                  codefournisseur: getOptionLabel.codfrs,
                                  soldfacbl: getOptionLabel.soldfacbl,
                                  //btnEnable: true,
                                })
                              : this.setState({
                                  raisonsocial: "",
                                  codefournisseur: "",
                                  //btnEnable: false,
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Raison Sociale"
                              margin="dense"
                              fullWidth
                              value={this.state.raisonsocial}
                              onChange={this.clientHandlerChange}
                              name="raisonsocial"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

             
              <Card style={{ marginTop: "2px" }}>
                <Card.Body                   style={{
                    backgroundColor: `${darck ? "#fff" : "#172431"}`,
                    border: `${darck ? "" : "2px solid #fff"}`,
                  }}
                  >
                  <Row>
                    <Col sm={11}>
                      <p
                        style={{
                          fontSize: "13px",
                          marginTop: "2px",
                          color: "#007bff",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        {this.state.libmodreg}{" "}
                      </p>
                    </Col>
                    <Col sm={1}>
                      <Tooltip title="Affectation" aria-label="Affectation">
                        <LinkIcon
                          onClick={
                            this.state.codmodreg.toUpperCase().includes("RS")
                              ? this.state.codefournisseur
                                ? this.handleShow
                                : () =>
                                    alert(
                                      "il faut préciser le code fournisseur "
                                    )
                              : this.state.codefournisseur && this.state.montant
                              ? this.handleShow
                              : () =>
                                  alert(
                                    "il faut préciser le code fournisseur et le montant "
                                  )
                          }
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      {this.state.codmodreg.toUpperCase().includes("RS") ? (
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          name="montant"
                          required
                          value={parseFloat(this.props.mntretenufrs).toFixed(3)}
                          // className="card add-input"
                          onChange={(e) => {
                            this.setState({
                              montant: e.target.value,
                            });
                          }}

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      ) : (
                        <TextField
                          id="standard-basic"
                          label="Montant"
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          name="montant"
                          required
                          //defaultValue={parseFloat(0).toFixed(3)}
                          // className="card add-input"
                          onChange={(e) => {
                            this.setState({
                              montant: e.target.value,
                            });
                          }}

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      )}
                    </Col>
                    <Col sm={6}>
                      <FormGroup>
                        <Autocomplete
                          id="include-input-in-list"
                          includeInputInList
                          className="ajouter-client-input"
                          options={this.props.css.css}
                          getOptionLabel={(option) => option.code}
                          onChange={(event, getOptionLabel) => {
                            getOptionLabel
                              ? this.setState({
                                  codcaisse: getOptionLabel.code,
                                  libcaisse: getOptionLabel.lib,
                                })
                              : this.setState({
                                  codcaisse: "",
                                  libcaisse: "",
                                });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // {...params}
                              label="Caisse"
                              margin="dense"
                              fullWidth
                              name="caisse"
                              variant="outlined"
                              // className="card add-input"
                              // required={
                              //   this.state.codmodreg
                              //     .toUpperCase()
                              //     .includes("RS")
                              //     ? false
                              //     : true
                              // }

                              InputProps={{
                                ...params.InputProps,
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              inputProps={params.inputProps}
                              id={params.id}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          )}
                        />
                        <FormHelperText>{this.state.libcaisse}</FormHelperText>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* <Row style={{ marginBottom: "-25px" }}> */}
                  <Row>
                    {" "}
                    <Col
                      sm={12}
                      style={{
                        display:
                          !this.state.libmodreg ||
                          this.state.libmodreg.toUpperCase().includes("ESP") ||
                          this.state.codmodreg.toUpperCase().includes("RS")
                            ? "none"
                            : "block",
                      }}
                    >
                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="№ Pièce"
                              margin="dense"
                              fullWidth
                              name="numpiece"
                              variant="outlined"
                              // className="card add-input"

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>

                      
                           <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              type="date"
                              label="Echéance"
                              margin="dense"
                              fullWidth
                              variant="outlined"
                              defaultValue={this.state.defauldate}
                              name="echeance"
                              // className="card add-input"
                              // InputLabelProps={{
                              //   shrink: true,
                              // }}

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label="Titulaire"
                              margin="dense"
                              fullWidth
                              variant="outlined"
                              name="titulaire"
                              // className="card add-input"

                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                shrink: true,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                      <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqclient: getOptionLabel.code,
                                      libbqclient: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqclient: "",
                                      libbqclient: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Bq client"
                                  margin="dense"
                                  fullWidth
                                  name="bqclient"
                                  variant="outlined"
                                  // className="card add-input"
                                 // value={this.state.libbqclient}
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqclient}
                            </FormHelperText>
                          </FormGroup>
                        </Col>
                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.ags.ags}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codagence: getOptionLabel.code,
                                      libagence: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codagence: "",
                                      libagence: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Agence"
                                  margin="dense"
                                  fullWidth
                                  name="agence"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText>
                              {this.state.libagence}
                            </FormHelperText>
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              options={this.props.scs.scs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codsituation: getOptionLabel.code,
                                      libsituation: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codsituation: "",
                                      libsituation: "",
                                    });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  // {...params}
                                  label="Situation"
                                  margin="dense"
                                  fullWidth
                                  name="situation"
                                  variant="outlined"
                                  // className="card add-input"

                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText>
                              {this.state.libsituation}
                            </FormHelperText>
                          </FormGroup>
                        </Col>

                    
<Col sm={4}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //options={this.props.bqs.bqs}
                              options={this.props.bqs.bqs}
                              getOptionLabel={(option) => option.lib}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codbqvers: getOptionLabel.code,
                                      libbqvers: getOptionLabel.lib,
                                    })
                                  : this.setState({
                                      codbqvers: "",
                                      libbqvers: "",
                                    });
                         if ( getOptionLabel !== null ) {
      fetch(
      `https://www.24-crm.tn/webservice/api/BordereauVersements?CodeBqCcb=${getOptionLabel.code}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      )
        .then((res) => res.json())
        .then((data) => this.setState({ CodeCCb: data, clicked: true ,}));} 
         else
        {this.setState({ ccb:"",});} }}
              renderInput={(params) => (
              <TextField
                                  label="Bq vers"
                                  margin="dense"
                                  fullWidth              
                                  name="bqvers"
                                  variant="outlined"
                                  InputProps={{
                                    ...params.InputProps,
                                    className: !darck
                                      ? this.props.classes.input
                                      : null,
                                  }}
                                  inputProps={params.inputProps}
                                  id={params.id}
                                  InputLabelProps={{
                                    style: {
                                      color: !darck ? "#fff" : null,
                                    },
                                  }}
                                />
                              )}
                            />
                            <FormHelperText style={{color: !darck ? "#fff" : null }}>
                              {this.state.libbqvers}
                            </FormHelperText>
                          </FormGroup>
                        </Col>
 {/* ////////////////////////////////////////////////  Declaration Code CCB       /////////////////////////////////////////////////////////////////////////// */}
                        <Col sm={4}>
                        <FormGroup>
                          <Autocomplete
                              id="outlined-select-currency"
                              includeInputInList
                              options={this.state.CodeCCb}
                              getOptionLabel={(option) => `${option.CodeCCb}`}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                    ccb: getOptionLabel.CodeCCb,
                                    })
                                  : this.setState({
                                    ccb: "",
                                    });
                              }}
                              renderInput={(params) => (
                                  <TextField
                                label="CCB"
                                value={this.state.ccb}
                                name="codeccb"
                                margin="dense"
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  className: !darck
                                    ? this.props.classes.input
                                    : null,
                                }}
                                inputProps={params.inputProps}
                                id={params.id}
                                InputLabelProps={{
                                  style: {
                                    color: !darck ? "#fff" : null,
                                  },
                                }}
                              />
                            )}
                          />
                      </FormGroup>
                      </Col>     

                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <FormGroup>
                        <TextField
                          id="outlined-multiline-static"
                          label="Note"
                          variant="outlined"
                          fullWidth
                          name="note"
                          rows={2}
                          multiline
                          // className="card add-input"

                          InputProps={{
                            className: !darck ? this.props.classes.input : null,
                          }}
                          InputLabelProps={{
                            style: {
                              color: !darck ? "#fff" : null,
                            },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              <br />
              <Row>
                <Col sm={6}>
                  <h3 style={{ color: "rgb(23, 162, 184)" }}>
                    &nbsp; Solde:{"  "}
                    <span style={{ color: !darck ? "#fff" : "black" }}>
                      {Number(this.state.soldfacbl).toFixed(3)}
                    </span>
                  </h3>
                </Col>
                <Col sm={2}></Col>
                <Col sm={4}>
                  <Button
                    variant="contained"
                    // color="secondary"
                    type="submit"
                    style={{
                      //marginTop: "20px",
                      width: "100%",
                      color: "white",
                      backgroundColor: "#020F64",
                    }}
                  >
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>
        {this.state.codmodreg.toUpperCase().includes("RS") ? (
          <AffectationRetenuALaSourceFrs
            chdec={this.state.chdec}
            numreg={this.state.numreg}
            montReg={this.state.montant}
            raisonsocial={this.state.raisonsocial}
            codefrs={this.state.codefournisseur}
            listeFacture={this.state.listeFacture}
            listebl={this.state.listebl}
            listeAV={this.state.listeAV}
            soldfacbl={this.state.soldfacbl}
            show={this.state.showModal}
            handleClose={() =>
              this.setState({ showModal: !this.state.showModal })
            }
          />
        ) : (
          <AffectationFrsModal
            numreg={this.state.numreg}
            montReg={this.state.montant}
            raisonsocial={this.state.raisonsocial}
            codefrs={this.state.codefournisseur}
            listeFacture={this.state.listeFacture}
            listebl={this.state.listebl}
            listeAV={this.state.listeAV}
            soldfacbl={this.state.soldfacbl}
            show={this.state.showModal}
            handleClose={() =>
              this.setState({ showModal: !this.state.showModal })
            }
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    SelectFournisseur: (a, b, c, d, e) =>
      dispatch(SelectFournisseur(a, b, c, d, e)),
    GetRECod: (a, b, c, d, e) => dispatch(GetRegFrsCod(a, b, c, d, e)),
    SelectNomenclature: (cat, a, b, c, d, e) =>
      dispatch(SelectNomenclature("MR", a, b, c, d, e)),
    SelectNomenclatureBQ: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureBQ(a, b, c, d, e)),
    SelectNomenclatureAG: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureAG(a, b, c, d, e)),
    SelectNomenclatureCS: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureCS(a, b, c, d, e)),
    SelectNomenclatureSC: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureSC(a, b, c, d, e)),
    SelectNomenclatureCCB: (a, b, c, d, e) =>
      dispatch(SelectNomenclatureCCB(a, b, c, d, e)),
    SelectReglement: (a, b, c, d, e) =>
      dispatch(SelectReglement(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
    fournisseurs: state.fournisseurs,
    codres: state.codres,
    nomenclatures: state.nomenclatures,
    bqs: state.bqs,
    ags: state.ags,
    css: state.css,
    scs: state.scs,
    ccbs: state.ccbs,
    Auth: state.Auth,
    detailTabRegFrs: state.detailTabRegFrs,
    mntretenufrs: state.mntretenufrs,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(AddReglementModal));

