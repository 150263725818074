import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import { Row, Col, FormGroup, Button } from "reactstrap";
import {
  TextField,
  Checkbox,
  Grid,
  Snackbar,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { GetCodcli } from "../../redux/actions/GetCodcli";
import { SelectClient } from "../../redux/actions/GetClients";
import { withStyles } from "@material-ui/styles";

const stylesFn = () => ({
  input: {
    color: "#fff",
  },
  root: {
    "& label.Mui-focused": {
      color: "#fff",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#fff",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#fff",
      },
      "&:hover fieldset": {
        borderColor: "#fff",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fff",
      },
    },
  },
});

class EditElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gilad: 0,
      timbre: 0,
      soustraitant: 0,
      snackbaropen: false,
      snackbarmsg: ",",

      tablecat: [
        { code: "0" },
        { code: "1" },
        { code: "2" },
        { code: "3" },
        { code: "4" },
        { code: "5" },
        { code: "6" },
      ],

      cat: [
        { code: "0", label: "Assujetti" },
        { code: "1", label: "Non Assujetti" },
        { code: "2", label: "Exonéré TVA/FODEC" },
        { code: "3", label: "Exonéré TVA" },
        { code: "4", label: "Suspenssion" },
        { code: "5", label: "Cession à Quai" },
        { code: "6", label: "Réduction TVA" },
      ],

      categoriefiscale: "",
      attestation: "",
      dateetabli: "",
      datexp: "",
      statutsCodeClient: false,
      code: null,
      lib: null,
      chdec: null
    };
  }

  componentDidMount() {
    this.props.GetCodcli(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database
    );
  }

  snackbarClose = () => {
    this.setState({ snackbaropen: false });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  catHandler = (event) => {
    this.setState({ categoriefiscale: event.target.value });
  };

  codeHandler = (event) => {
    this.setState({ code: event.target.value });
  };

  libHandler = (event) => {
    this.setState({ lib: event.target.value });
  };

  chdecHandler = (event) => {
    this.setState({ chdec: event.target.value });
  };


  

  modifierHandler = (event) => {
    event.preventDefault();
    if ( (this.state.lib !== null && this.state.lib !== "") && (this.state.chdec !== null && this.state.chdec !== "")) {
    fetch(
      `https://www.24-crm.tn/webservice/api/Nome?cat=${this.props.catP}&code=${this.props.codeP}&lib=${this.state.lib}&chdec=${this.state.chdec}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
      {
        method: "PUT",
        header: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({ snackbaropen: true, snackbarmsg: result });
          window.location.reload();
        },
        (error) => {
          this.setState({ snackbaropen: true, snackbarmsg: "failed" });
        }
      );
    } else {
      alert("il faut donner libellé et valeur");
    }

    
  };

  render() {
    const { darck, classes, allCodeClient  } = this.props;
    const { statutsCodeClient } = this.state;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          onClose={this.snackbarClose}
          message={<span id="message-id"> {this.state.snackbarmsg} </span>}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClose}
            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Modal
          onHide={this.props.onHide}
          show={this.props.show}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ padding: "0px" }}
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Edit Nomenclature</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            <form
              autocomplete="off"
              onSubmit={this.modifierHandler}
              className={`${darck ? "" : classes.root}`}
            >
              <Row form>
                <Col sm={12}>
                  <Card className="card0" style={{ height: "100%" }}>
                    <Card.Body
                      style={{
                        backgroundColor: `${darck ? "#fff" : "#172431"}`,
                        borderTop: `${darck ? "" : "2px solid #fff"}`,
                        borderRight: `${darck ? "" : "2px solid #fff"}`,
                        borderLeft: `${darck ? "" : "2px solid #fff"}`,
                        borderBottom: `${darck ? "" : "2px solid #fff"}`,
                        borderRadius: "5px",
                      }}
                    >
                      <Row form>
                        <Col sm={6}>
                          <FormGroup style={{ overflow: "hidden" }}>
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Code"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="code"
                              disabled
                              value={this.state.code === null ? this.props.codeP : this.state.code}
                              onChange={this.codeHandler}
                              InputProps={{
                                className: !darck ? this.props.classes.input : null,
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* ////////////////////////////////////////////////// */}

                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Libélle"
                              margin="dense"
                              variant="outlined"
                            //   type="email"
                              fullWidth
                              name="lib"
                              value={this.state.lib === null ? this.props.libP : this.state.lib}
                              onChange={this.libHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                   
  {/*  //////////////////////////// Modification pour nomenclature de Nombre des décimales //////////////////////////*/}          
  { this.props.catP=== "UM" ? (
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Nombre des Décimales"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>  
    

    ):(
                      <Row form>
                        <Col sm={12}>
                          <FormGroup style={{ overflow: "hidden" }}>
                            <TextField
                              // className="card add-input"
                              id="standard-basic"
                              label="Valeur"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              name="chdec"
                              value={this.state.chdec === null ? this.props.chdecP : this.state.chdec}
                              onChange={this.chdecHandler}
                              InputProps={{
                                className: !darck
                                  ? this.props.classes.input
                                  : null,
                              }}
                              InputLabelProps={{
                                style: {
                                  color: !darck ? "#fff" : null,
                                },
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>   )}







                      {/* ////////////////////////////////////////////////// */}

                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <br />
              <Row>
                <Col sm={8}></Col>
                <Col sm={4}>
                  <Button
                          type="submit"
                          color="primary"
                          style={{ width: "100%" }}
                          >
                    Enregistrer
                  </Button>
                </Col>
              </Row>
            </form>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    GetCodcli: (a, b, c, d, e) => dispatch(GetCodcli(a, b, c, d, e)),
    SelectClient: (a, b, c, d, e) => {
      dispatch(SelectClient(a, b, c, d, e));
    },
  };
}

function mapStateToProps(state) {
  return {
    codclis: state.codclis,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(stylesFn)(EditElement));
