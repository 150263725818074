import { DARCK_MODE } from "../../constants/actionTypes";

export const DarckModeAction = (a) => {
  return (dispatch) => {
    dispatch({
      type: DARCK_MODE,
      payload: a,
    });
  };
};
