import React, { Component } from "react";
import { Modal, Card } from "react-bootstrap";
import "../styling/Styles.css";
import "./bl.scss";
import { FormGroup, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { SelectArticle } from "../../redux/actions/GetArticles";
import { TextField, Fab, FormHelperText } from "@material-ui/core";
import "../styling/Styling.scss";
import Center from "react-center";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
// import EditArticleModal from "./EditArticleModal";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import Autocomplete from "@material-ui/lab/Autocomplete";
import translate from "../../i18n/translate";



// const roundTo = require("round-to");

class LigBLArticle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codearticle: "",
      qte: "",
      totalht: 0,
      des: "",
      unitee: "",
      puht: "",
      remisea: 0,
      codtvaa: 0,
      tva: 0,
      puttcnet: 0,
      faudec: "",
      tab: [],
      totalqte: 0,
      sumremisearticle: 0,
      totalhtbrut: 0,
      totaltva: 0,
      totalhtnet: 0,
      remiseglobal: 0,
      netapayer: 0,
      ttclig: 0,
      changeButton: false,
      pmp: "",
      codbar: "",
      snackbaropen: false,
      snackbarfail: false,
      editModalShow: false,
      qtte: 0,
      btnEnabled: false,
      gilad: true,
      rechs: [],
      stkfin: 0,
      stkmg: 0,
      snackbarmsg: ",",
      yesno: "",
      netnetapayer: 0,
      avance: 0,
      clicked: false,




      codeclient:"",
     
    

    };
  }
 



  
   componentDidMount() {
    this.props.SelectArticle(
      this.props.ConfigDossier.coddos,
      this.props.ConfigDossier.serveur,
      this.props.ConfigDossier.utilisateur,
      this.props.ConfigDossier.pwd_serveur,
      this.props.ConfigDossier.database,
  
    );
    this.setState({
      totalqte: this.props.totalqte,
      sumremisearticle: this.props.sumremisearticle,
      totalhtbrut: this.props.totalhtbrut,
      totaltva: this.props.totaltva,
      totalhtnet: this.props.totalhtnet,
      remiseglobal: this.props.remiseglobal,
      netapayer: this.props.netapayer,
      avance: this.props.avance,
      netnetapayer: this.props.netnetapayer,
    });
  }

  componentDidUpdate(prevprops, prevstate) {
    if (prevprops.tabArticle != this.props.tabArticle) {
      this.setState({
        tab: this.props.tabArticle,
      });
    }
  }

  articleHandlerChange = (event) => {
    //get article tel que codart='codartt'
    fetch(
      `https://www.24-crm.tn/webservice/api/ARTICLEs?codartt=${event.target.value}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
    )
      .then((response) => response.json())
      .then((data) => this.setState({ rechs: data, clicked: true }));
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarFailClose = (event) => {
    this.setState({ snackbarfail: false });
  };

  qteHandler = (event) => {
    this.setState({
      qte: event.target.value,
      puttcnet: Number(this.state.puht * (1 + this.state.tva / 100)).toFixed(3),
      totalht: Number(
        event.target.value *
          this.state.puht *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
    });

    this.state.stkfin >= parseInt(event.target.value)
      ? this.setState({ yesno: "yes" })
      : this.setState({ yesno: "no" });
  };

  remiseHandler = (event) => {
    this.setState({
      remisea: event.target.value,
      totalht:
        this.state.qte * this.state.puht * ((100 - event.target.value) / 100),
      puttcnet: Number(
        this.state.puht *
          (1 + this.state.tva / 100) *
          ((100 - event.target.value) / 100)
      ).toFixed(3),
    });
  };

  puhtHandler = (event) => {
    this.setState({
      puht: event.target.value,
      totalht:
        this.state.qte *
        event.target.value *
        ((100 - this.state.remisea) / 100),
      puttcnet: Number(
        event.target.value *
          (1 + this.state.tva / 100) *
          ((100 - this.state.remisea) / 100)
      ).toFixed(3),
    });
  };

  puttcHandler = (event) => {
    this.setState({
      puttcnet: event.target.value,
      puht: (
        event.target.value /
        Number((100 - this.state.remisea) / 100) /
        (1 + this.state.tva / 100)
      ).toFixed(3),
      totalht:
        this.state.qte *
        ((event.target.value /
          ((100 - this.state.remisea) / 100) /
          (1 + this.state.tva / 100)) *
          ((100 - this.state.remisea) / 100)),
    });
  };

  onCellChange = (event) => {
    this.setState({ codearticle: event.target.value });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  deleteRow = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) *
            v.puht *
            ((100 - v.remisea) / 100) *
            ((100 - this.props.rem) / 100) *
            (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) ** ((100 - this.props.rem) / 100) *
              (v.tva / 100)),
        0
      );

    const Avance = (SumNetAPayer * this.props.regimecli) / 100;

    const SumNetNetAPayer = SumNetAPayer + Avance;

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      totalhtnet: SumHtNet,
      avance: Avance,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbarfail: true,
      netnetapayer: SumNetNetAPayer,
      
    });
  };

  submitHandlers = (event) => {
    event.preventDefault();
    if (
      this.state.tab.filter((el) => el.codearticle == this.state.codearticle)
        .length > 0
    ) {
      alert("article existant");
    } else if (
      this.props.tabArticle
        .filter((el) => el.codearticle == this.state.codearticle)
        .filter((el) => this.state.qte > el.qte).length > 0
    ) {
      alert("la quantité dépasse celle de la facture ");
    } else {
      const newtab = this.state.tab.concat({
        codearticle: this.state.codearticle,
        des: this.state.des,
        qte: this.state.qte,
        unitee: this.state.unitee,
        puht: this.state.puht,
        faudec: this.state.faudec,
        remisea: event.target.remisea.value,
        codtvaa: this.state.codtvaa,
        codbar: this.state.codbar,
        tva: this.state.tva,
        puttcnet: this.state.puttcnet,
        totalht: this.state.totalht,
        pmp: this.state.pmp,
      });
      const SumQte = newtab && newtab.reduce((a, v) => a + parseInt(v.qte), 0);
      const SumRemiseArticle =
        newtab &&
        newtab.reduce(
          (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
          0
        );
      const SumHtBrut =
        newtab && newtab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
      const SumTva =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              ((100 - this.props.rem) / 100) *
              (v.tva / 100),
          0
        );
      const SumHtNet =
        newtab &&
        newtab.reduce(
          (a, v) => a + v.totalht * ((100 - this.props.rem) / 100),
          0
        );
      const SumRemiseGlobale =
        newtab &&
        newtab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
      const SumNetAPayer =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            (v.totalht * ((100 - this.props.rem) / 100) +
              v.qte *
                v.puht *
                ((100 - v.remisea) / 100) *
                ((100 - this.props.rem) / 100) *
                (v.tva / 100)),
          0
        );

      const Avance = (SumNetAPayer * this.props.regimecli) / 100;

      const SumNetNetAPayer = SumNetAPayer + Avance;

      this.setState({
        tab: newtab,

        totalqte: SumQte,
        sumremisearticle: SumRemiseArticle,
        totalhtbrut: SumHtBrut,
        totaltva: SumTva,
        avance: Avance,
        totalhtnet: SumHtNet,
        remiseglobal: SumRemiseGlobale,
        netapayer: SumNetAPayer,
        snackbaropen: true,
        btnEnabled: true,
        netnetapayer: SumNetNetAPayer,
      });

      this.setState({
        codearticle: "",
        qte: "",
        totalht: 0,
        des: "",
        unitee: "",
        puht: "",
        remisea: 0,
        codtvaa: 0,
        codbar: "",
        tva: 0,
        puttcnet: 0,
        faudec: "",
        pmp: "",
        stkfin: "",
      });
    }
  };

  modifiermodification = (event) => {
    // event.preventDefault();
    if (
      this.state.tab.filter((el) => el.codeartricle == this.state.codearticle)
        .length > 0
    ) {
      alert("article existant");
      // const newtab = this.state.tab.map(el=>el.codart==this.state.codearticle?{...,}:el
      //  );
    } else {
      const newtab = this.state.tab.concat({
        codearticle: this.state.codearticle,
        des: this.state.des,
        qte: this.state.qte,
        unitee: this.state.unitee,
        puht: this.state.puht,
        faudec: this.state.faudec,
        remisea: this.state.remisea,
        codtvaa: this.state.codtvaa,
        codbar: this.state.codbar,
        tva: this.state.tva,
        puttcnet: this.state.puttcnet,
        totalht: this.state.totalht,
        pmp: this.state.pmp,
      });

      const SumQte = newtab && newtab.reduce((a, v) => a + parseInt(v.qte), 0);
      const SumRemiseArticle =
        newtab &&
        newtab.reduce(
          (a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100,
          0
        );
      const SumHtBrut =
        newtab && newtab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
      const SumTva =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              ((100 - this.props.rem) / 100) *
              (v.tva / 100),
          0
        );
      const SumHtNet =
        newtab &&
        newtab.reduce(
          (a, v) => a + v.totalht * ((100 - this.props.rem) / 100),
          0
        );
      const SumRemiseGlobale =
        newtab &&
        newtab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
      const SumNetAPayer =
        newtab &&
        newtab.reduce(
          (a, v) =>
            a +
            (v.totalht * ((100 - this.props.rem) / 100) +
              v.qte *
                v.puht *
                ((100 - v.remisea) / 100) *
                ((100 - this.props.rem) / 100) *
                (v.tva / 100)),
          0
        );

      const Avance = (SumNetAPayer * this.props.regimecli) / 100;
      const SumNetNetAPayer = SumNetAPayer + Avance;

      this.setState({
        tab: newtab,

        totalqte: SumQte,
        sumremisearticle: SumRemiseArticle,
        totalhtbrut: SumHtBrut,
        totaltva: SumTva,
        avance: Avance,
        totalhtnet: SumHtNet,
        remiseglobal: SumRemiseGlobale,
        netapayer: SumNetAPayer,
        snackbaropen: true,
        btnEnabled: true,
        netnetapayer: SumNetNetAPayer,
        changeButton: false,
      });

      this.setState({
        codearticle: "",
        qte: "",
        totalht: 0,
        des: "",
        unitee: "",
        puht: "",
        remisea: 0,
        codtvaa: 0,
        codbar: "",
        tva: 0,
        puttcnet: 0,
        faudec: "",
        pmp: "",
        stkfin: "",
      });
    }
  };

  deleteRowMod = (index) => {
    var tab = [...this.state.tab];
    tab.splice(index, 1);
    const SumQte = tab && tab.reduce((a, v) => a + parseInt(v.qte), 0);
    const SumRemiseArticle =
      tab &&
      tab.reduce((a, v) => a + (parseInt(v.qte) * v.puht * v.remisea) / 100, 0);
    const SumHtBrut =
      tab && tab.reduce((a, v) => a + parseInt(v.qte) * v.puht, 0);
    const SumTva =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          parseInt(v.qte) *
            v.puht *
            ((100 - v.remisea) / 100) *
            ((100 - this.props.rem) / 100) *
            (v.tva / 100),
        0
      );
    const SumHtNet =
      tab &&
      tab.reduce((a, v) => a + v.totalht * ((100 - this.props.rem) / 100), 0);
    const SumRemiseGlobale =
      tab && tab.reduce((a, v) => a + v.totalht * (this.props.rem / 100), 0);
    const SumNetAPayer =
      tab &&
      tab.reduce(
        (a, v) =>
          a +
          (v.totalht * ((100 - this.props.rem) / 100) +
            parseInt(v.qte) *
              v.puht *
              ((100 - v.remisea) / 100) *
              (v.tva / 100)),
        0
      );

    const Avance = (SumNetAPayer * this.props.regimecli) / 100;
    const SumNetNetAPayer = SumNetAPayer + Avance;

    this.setState({
      tab,
      totalqte: SumQte,
      sumremisearticle: SumRemiseArticle,
      totalhtbrut: SumHtBrut,
      totaltva: SumTva,
      avance: Avance,
      totalhtnet: SumHtNet,
      remiseglobal: SumRemiseGlobale,
      netapayer: SumNetAPayer,
      snackbaropen: true,
      btnEnabled: true,
      netnetapayer: SumNetNetAPayer,
    });
  };

  render() {

  
    let editModalClose = () => this.setState({ editModalShow: false });
 const { darck, classes } = this.props;
    const sommeTotalHT = this.state.tab.reduce((total, t) => total + Number(t.puttcnet), 0);
    const sommeQuantité = this.state.tab.reduce((total, t) => total + Number(t.qte), 0);

  
    return (
      <div className="container">
        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarClose}
            severity="success"
          >
            {translate("Article ajouté")}
          </Alert>
        </Snackbar>

        <Snackbar
          open={this.state.snackbarfail}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarFailClose}
        >
          <Alert
            style={{ height: "50px" }}
            onClose={this.snackbarFailClose}
            severity="error"
          >
            {translate("Article supprimé")}
          </Alert>
        </Snackbar>

        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#00087E" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}>
            <Modal.Title id="contained-modal-title-vcenter">
              <b> {translate("Articles")}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body  style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}>
            <Row>
              <Col style={{ margin: "0" }}>
                <Card>
                  <Card.Body  style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}>
                    <form autocomplete="off" onSubmit={this.submitHandlers}>
                      <Row>
                        <b style={{ color: `${darck ? "" : "#fff"}` }}> {translate("Chercher article")} : </b>
                        <b style={{ marginTop: "10px", marginLeft: "10px" }}>
                          {this.state.faudec === "A" ? (
                            <p style={{ color: "blue" }}>Fodec: ✔</p>
                          ) : null}
                        </b>
                      </Row>
                       <Row form>
                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unitee: getOptionLabel.unite,
                                      puht: Number(
                                        getOptionLabel.PUDHT
                                      ).toFixed(3),
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codtvaa: getOptionLabel.codtva,
                                      codbar: getOptionLabel.codbar,
                                      pmp: getOptionLabel.pmp,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unitee: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "",
                                      codtvaa: 0,
                                      codbar: "",
                                      qte: "",
                                      pmp: "",
                                    });
                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.tn/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField 
                                  {...params}
                                  label={translate("Code article")}
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.desart}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unitee: getOptionLabel.unite,
                                      puht: Number(
                                        getOptionLabel.PUDHT
                                      ).toFixed(3),
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codtvaa: getOptionLabel.codtva,
                                      codbar: getOptionLabel.codbar,
                                      pmp: getOptionLabel.pmp,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unitee: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "",
                                      qte: "",
                                      codbar: "",
                                      codtvaa: 0,
                                      puttcnet: 0,
                                      pmp: "",
                                    });
                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.tn/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={translate("Désignation article")}
                                  className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>





                        <Col sm={3}>
                          <FormGroup>
                            <Autocomplete
                              id="include-input-in-list"
                              includeInputInList
                              className="ajouter-client-input"
                              //   options={this.props.articles.articles}
                              // options={this.state.rechs}
                              options={
                                this.state.clicked
                                  ? this.state.rechs
                                  : this.props.articles.articles
                              }
                              getOptionLabel={(option) => option.codbar}
                              onChange={(event, getOptionLabel) => {
                                getOptionLabel
                                  ? this.setState({
                                      codearticle: getOptionLabel.codart,
                                      des: getOptionLabel.desart,
                                      unitee: getOptionLabel.unite,
                                      puht: Number(
                                        getOptionLabel.PUDHT
                                      ).toFixed(3),
                                      remisea: getOptionLabel.remise,
                                      tva: getOptionLabel.tautva,
                                      faudec: getOptionLabel.typfodec,
                                      codtvaa: getOptionLabel.codtva,
                                      pmp: getOptionLabel.pmp,
                                      codbar: getOptionLabel.codbar,
                                    })
                                  : this.setState({
                                      codearticle: "",
                                      totalht: 0,
                                      des: "",
                                      unitee: "",
                                      puht: "",
                                      remisea: 0,
                                      tva: 0,
                                      faudec: "",
                                      qte: "",
                                      codbar: "",
                                      codtvaa: 0,
                                      puttcnet: 0,
                                      pmp: "",
                                    });
                                getOptionLabel && this.props.GMM
                                  ? fetch(
                                      `https://www.24-crm.tn/webservice/api/MAGASIN?codep=${this.props.MG}&codart=${getOptionLabel.codart}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                    )
                                      .then((res) => res.json())
                                      .then((res) =>
                                        res.map((el) =>
                                          this.setState({ stkfin: el.StkFin })
                                        )
                                      )
                                  : getOptionLabel
                                  ? this.setState({
                                      stkfin: getOptionLabel.stkfin,
                                    })
                                  : this.setState({ stkfin: 0 });
                              }}
                              renderInput={(params) => (
                                <TextField
                                 {...params}
                                  label={translate("Code à Barre")}
                                 className="card add-input"
                                  margin="dense"
                                  variant="outlined"
                                  fullWidth
                                  onChange={this.articleHandlerChange}
                                  
                                />
                              )}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {/* {this.state.yesno} */}

                      <Row form>
                        
                        <Col sm={3}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Code article")}
                              value={this.state.codearticle}
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={6}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Désignation")}
                              value={this.state.des}
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={1}>
                          <FormGroup>
                            {/* {this.state.des === "" ? (
                              <TextField
                                id="standard-basic"
                                label={translate("Quantité")}
                                type="number"
                                disabled
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                style={{ marginTop: "16px" }}
                                margin="normal"
                                fullWidth
                                required
                              />
                            ) : ( */}
                            <TextField
                              id="standard-basic"
                              label="Qte"
                              type="number"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={this.state.qte}
                              onChange={this.qteHandler}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                              required
                            />
                            {/* )} */}
                            {/* {this.state.qte > this.state.stkfin && (
                             // <FormHelperText style={{ color: "#DC004E" }}>
                                {translate("Stock insuffisant!")}
                             // </FormHelperText>
                            )} */}
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              value={`Stock: ${this.state.stkfin}`}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row form>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Unité")}
                              value={this.state.unitee}
                              fullWidth
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("PUHT")}
                              value={this.state.puht}
                              fullWidth
                              name="puht"
                              onChange={this.puhtHandler}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("Remise %")}
                              value={this.state.remisea}
                              fullWidth
                              name="remisea"
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                              onChange={this.remiseHandler}
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("TVA")}
                              value={this.state.tva}
                              fullWidth
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>
                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("PUTTCNet")}
                              value={this.state.puttcnet}
                              fullWidth
                              onChange={this.puttcHandler}
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>

                        <Col sm={2}>
                          <FormGroup>
                            <TextField
                              id="standard-basic"
                              label={translate("TotalHT")}
                              value={Number(this.state.totalht).toFixed(3)}
                              fullWidth
                              disabled
                              className="card add-input"
                              margin="dense"
                              variant="outlined"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* 
                      {this.state.des === "" ||
                      this.state.yesno === "no" ||
                      this.state.qte === "" ||
                      this.state.stkfin <= 0 ||
                      parseInt(this.state.qte) <= 0 ? (
                        <Center>
                          <Button
                            disabled
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {translate("Ajouter")}
                          </Button>
                        </Center>
                      ) : (
                        <Center>
                          <Button
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            {translate("Ajouter")}
                          </Button>
                        </Center>
                      )} */}

{this.state.changeButton ? (
                        <Center>
                          <Button
                            style={{ width: "320px" }}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              this.modifiermodification();
                            }}
                          >
                            Enregistrer les modifications
                          </Button>
                        </Center>
                      ) : this.state.des === "" ? (
                        <Center>
                          <Button
                            disabled
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      ) : (
                        <Center>
                          <Button
                            style={{ width: "250px" }}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Ajouter
                          </Button>
                        </Center>
                      )}
                    </form>
                  </Card.Body>
                </Card>

                <Card style={{ marginTop: "10px" }}>
                  <Card.Body  style={{
                      backgroundColor: `${darck ? "#fff" : "#172431"}`,
                      border: `${darck ? "" : "2px solid #fff"}`,
                    }}>
                    <div className="tabbl2">
                      <table style={{ marginTop: "10px" }}>
                        <thead
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                            marginRight: "55px",
                          }}
                        >
                          <tr>
                            <th>Code</th>
                            <th style={{ width: "37%" }}>
                              {" "}
                              {translate("Désignation")}{" "}
                            </th>
                            <th> {translate("Quantité")} </th>
                            <th> {translate("PUHT")} </th>
                            <th> {translate("Remise")} </th>
                            <th> {translate("TVA")} </th>
                            <th> {translate("PUTTCNet")} </th>
                            <th> {translate("TotalHT")} </th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody  
                        style={{
                            overflowY: "auto",
                            display: "block",
                            maxHeight: "10em",
                            textAlign: "center",
                            color: `${darck ? "" : "#fff"}`,
                          }}>
                          {this.state.tab.map((t, i) => (
                            <tr key={i}>
                              <td>
                                <span>{t.codearticle}</span>
                              </td>
                              <td style={{ fontSize: "12px", width: "37%" }}>
                                <span> {t.des} </span>
                              </td>
                              <td>
                                {" "}
                                <span> {t.qte}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.puht).toFixed(2)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.remisea).toFixed(2)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.tva).toFixed(2)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.puttcnet).toFixed(3)}</span>
                              </td>
                              <td>
                                {" "}
                                <span> {Number(t.totalht).toFixed(2)}</span>
                              </td>
                              <td>
                                <Tooltip
                                  title={translate("Editer cet article")}
                                >
                                  <Fab size="small">
                                    <EditIcon
                                      style={{}}
                                      onClick={() => {
                                        fetch(
                                          `https://www.24-crm.tn/webservice/api/ARTICLEs?codartt=${t.codearticle}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`
                                        )
                                          .then((response) => response.json())
                                          .then((data) => {
                                            this.setState({
                                              stkfin: data.map((t) => t.stkfin),
                                            });
                                          });

                                        this.setState({
                                          codearticle: t.codearticle,
                                          des: t.des,
                                          unite: t.unite,
                                          puht: t.puht,
                                          remisea: t.remisea,
                                          tva: t.tva,
                                          faudec: t.faudec,
                                          qte: t.qte,
                                          totalht: t.totalht,
                                          puttcnet: t.puttcnet,
                                          codtvaa: t.codtvaa,
                                          pmp: t.pmp,
                                          changeButton: true,
                                        });
                                        this.deleteRowMod(i);
                                      }}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                              <td>
                                <Tooltip
                                  title={translate("Supprimer cet article")}
                                >
                                  <Fab size="small">
                                    <DeleteIcon
                                      style={{}}
                                      onClick={() => this.deleteRow(i)}
                                    />
                                  </Fab>
                                </Tooltip>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>


  {/*Pour l'affichager de la totale de quantité et somme prixTTC */}
                    <Row style={{ marginBottom: "-15px", marginTop: "10px" ,marginLeft: "10px"  }}>
  <Col style={{ marginRight: "2px", marginLeft: "450px" }}><p style={{ color: `${darck ? "#008000" : "#008000"}`, fontWeight: "bold", fontSize: "13px", margin: "0" }}>Total QTé : </p><p style={{ color: darck ? "#008000" : "#008000", fontWeight: "bold", margin: "0 -10px 0 0" }}>{sommeQuantité.toFixed(3)} </p> </Col>
  <Col style={{ marginRight: "2px", marginLeft: "-20px" }}><p style={{ color: `${darck ? "#008000" : "#008000"}`, fontWeight: "bold", fontSize: "13px", margin: "0" }}>Total prix :</p><p style={{ color: darck ? "#008000" : "#008000", fontWeight: "bold", margin: "0" }}>{sommeTotalHT.toFixed(3)}  </p></Col>
</Row> 





                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer  style={{
              backgroundColor: `${darck ? "" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              border: `${darck ? "" : "2px solid #fff"}`,
            }}>
            {/* {
              // !this.state.btnEnabled ? (
              //   <Button disabled variant="contained" style={{ width: "30%" }}>
              //     Enregistrer
              //   </Button>
              // ) : (
              this.state.tab.map((t, i) => ( */}
            {this.state.tab != this.props.tabArticle ? (
              <Button
                variant="contained"
                style={{
                  backgroundColor: "rgb(0, 8, 126)",
                  color: "white",
                  width: "30%",
                }}
                onClick={() => {
                  this.props.submitHandler(
                    this.state.tab,
                    this.state.codtvaa,
                    this.state.totalqte,
                    this.state.sumremisearticle,
                    this.state.totalhtbrut,
                    this.state.totaltva,
                    this.state.totalhtnet,
                    this.state.remiseglobal,
                    this.state.netapayer,
                    this.state.btnEnabled,
                    this.state.avance,
                    this.state.netnetapayer,
                    this.state.pmp
                  );

                  
                  // this.enregistrer();
                  this.props.onHide();
                }}
              >
                {translate("Enregistrer")}
              </Button>
            ) : (
              <Button
                variant="contained"
                style={{
                  color: "white",
                  width: "30%",
                }}
                disabled
              >
                {translate("Enregistrer")}
              </Button>
            )}
            {/* ))
              // )
            } */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  


}
}

function mapDispatchToProps(dispatch) {
  return {
    SelectArticle: (a, b, c, d, e) => dispatch(SelectArticle(a, b, c, d, e)),
  };
}

function mapStateToProps(state) {
  return {
 
    articles: state.articles,
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(LigBLArticle);
