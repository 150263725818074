import React, { Component } from "react";
import { connect } from "react-redux";
import LoaderGif from "../assests/img/loader.gif";
import "../assests/css/loader.scss";
class FullPageLoader extends Component {
  state = {};

  render() {
    const { loading } = this.props;
    if (!loading) return null;

    return (
      <div class="loader-container">
        <div className="loader">
          <img src={LoaderGif} alt="Chargement" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ loading: state.Auth.loading });

export default connect(mapStateToProps)(FullPageLoader);
