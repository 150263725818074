const initialState = {
    vends: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "Vend_SELECT":
        return {
          ...state,
          vends: action.payload,
        };
      default:
        return state;
    }
  }