import React, { Component } from "react";
import { Modal, Card, Row, Col, Alert } from "react-bootstrap";
import "../styling/Styles.css";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import LanguageIcon from "@material-ui/icons/Language";
import { Divider, Snackbar, IconButton } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ListAltIcon from '@material-ui/icons/ListAlt';
import ModifierClientModal from "./ModifierClientModal";
import ReleveClientModal from "./ReleveClientModal";
import MailModal from "./MailModal";
import { connect } from "react-redux";

const actions = [
  // { icon: <MailOutlineIcon />, name: "Mail" },
  { icon: <EditIcon />, name: "Modifier" },
  { icon: <DeleteOutlineIcon />, name: "Supprimer" },
  { icon: <ListAltIcon />, name: "Relevé" },
];

class GetClientByID extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      hidden: false,
      openModifierModal: false,
      openReleveClientModal: false,
      openMailModal: false,
      snackbaropen: false,
      snackbaropenn: false,
      snackbarmsg: "",
    };
  }

  // componentDidMount() {
  //   document.getElementById("tell").innerHTML =
  //     '<a href="tel:' + this.props.tel1 + '">' + this.props.tel1 + "</a>";
  // }
  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  openMail = () => {
    this.setState({ openMailModal: true });
  };

  openModifier = () => {
    this.setState({ openModifierModal: true });
  };

  openReleveClient = () => {
    this.setState({ openReleveClientModal: true });
  };

  nomail = () => {
    this.setState({ snackbaropen: true });
  };

  snackbarClose = (event) => {
    this.setState({ snackbaropen: false });
  };

  snackbarClosee = (event) => {
    this.setState({ snackbaropenn: false });
  };

  deleteclient(clientid) {
    if (window.confirm("êtes-vous sûr de vouloir supprimer ce client?")) {
      fetch(
        `https://www.24-crm.tn/webservice/api/CLIENTs?&id=${clientid}&coddos=${this.props.ConfigDossier.coddos}&serveur=${this.props.ConfigDossier.serveur}&user=${this.props.ConfigDossier.utilisateur}&pwd=${this.props.ConfigDossier.pwd_serveur}&database=${this.props.ConfigDossier.database}`,
        {
          method: "DELETE",
          header: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({ snackbaropenn: true, snackbarmsg: result });
            this.props.onHide();

            window.location.reload();
          },

          (error) => {
            this.setState({ snackbaropenn: true, snackbarmsg: "failed" });
          }
        );
      //  this.props.SelectArticle();
    }
  }

  call1 = () => {
    fetch(
      `https://localhost/couplagetel/calling.php?exten=${this.props.tel1}&number=${this.props.tel1}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) =>
        //console.log(data)
        window.alert(data)
      );
  };

  call2 = () => {
    fetch(
      `https://localhost/couplagetel/calling.php?exten=${this.props.tel2}&number=${this.props.tel2}`,
      {
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => response.json())
      .then((data) =>
        //console.log(data)
        window.alert(data)
      );
  };

  render() {
    let ModifierModalClose = () => this.setState({ openModifierModal: false });
    let ReleveClientModalClose = () => this.setState({ openReleveClientModal: false });
    let MailModalClose = () => this.setState({ openMailModal: false });
    const { darck, classes } = this.props;

    return (
      <div className="container">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.snackbaropenn}
          autoHideDuration={3000}
          onClose={this.snackbarClosee}
          message={this.state.snackbarmsg}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={this.snackbarClosee}
              style={{
                
                color: `${darck ? "#020F64" : "#fff"}`
              }}

            >
              x
            </IconButton>,
          ]}
        ></Snackbar>

        <Snackbar
          open={this.state.snackbaropen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={this.snackbarClose}
        >
          <Alert onClose={this.snackbarClose} variant={"danger"}>
            Email non trouvable pour ce client
          </Alert>
        </Snackbar>

        <Modal
          {...this.props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              color: `${darck ? "#020F64" : "#fff"}`,
              borderTop: `${darck ? "" : "2px solid #fff"}`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `none`,
            }}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              <b>Détails Client</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              backgroundColor: `${darck ? "#fff" : "#172431"}`,
              borderTop: `none`,
              borderRight: `${darck ? "" : "2px solid #fff"}`,
              borderLeft: `${darck ? "" : "2px solid #fff"}`,
              borderBottom: `${darck ? "" : "2px solid #fff"}`,
            }}
          >
            {this.props.passager ? (
              <Alert
                style={{ width: "100%", textAlign: "center" }}
                variant={"primary"}
              >
                Client passager
              </Alert>
            ) : null}
            <Card>
              <Card.Body
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  textAlign: "center",
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                }}
              >
                <h3 style={{ color: `${darck ? "#172431" : "#fff"}` }}>
                  {this.props.raisoc}
                </h3>

                <p
                  style={{
                    color: `${darck ? "gray" : "#fff"}`,
                    fontSize: "larger",
                  }}
                >
                  {this.props.codeclient}
                </p>
                {/* <a href="tel: 58941408">{this.props.tel1}</a> */}

                <Divider></Divider>
                {this.props.tel1 === "" && !this.props.tel2 === "" ? (
                  <p>
                    <PhoneIcon />{" "}
                    <a
                      href={`tel:${this.props.tel2}`}
                      style={{ color: `${darck ? "#172431" : "#fff"}` }}
                    >
                      {this.props.tel2}
                    </a>
                  </p>
                ) : !this.props.tel1 === "" && this.props.tel2 === "" ? (
                  <p id="tell">
                    <PhoneIcon />{" "}
                    <a
                      href={`tel:${this.props.tel1}`}
                      style={{ color: `${darck ? "#172431" : "#fff"}` }}
                    >
                      {this.props.tel1}
                    </a>
                  </p>
                ) : this.props.tel1 === "" && this.props.tel2 === "" ? (
                  <p>
                    <PhoneIcon /> --
                  </p>
                ) : (
                  <p>
                    {/* <PhoneIcon />{" "} */}
                    <a
                      href={`tel:${this.props.tel1}`}
                      style={{ color: `${darck ? "#172431" : "#fff"}` }}
                    >
                      {this.props.tel1}
                    </a>{" "}
                    /{" "}
                    <a
                      href={`tel:${this.props.tel2}`}
                      style={{ color: `${darck ? "#172431" : "#fff"}` }}
                    >
                      {this.props.tel2}
                    </a>{" "}
                  </p>
                )}
                {this.props.email === "" ? (
                  <p>
                    <EmailIcon /> --
                  </p>
                ) : (
                  <p>
                    <EmailIcon />{" "}
                    <a
                      href={"mailto:" + this.props.email}
                      style={{ color: `${darck ? "#172431" : "#fff"}` }}
                    >
                      {this.props.email}
                    </a>
                  </p>
                )}

                {this.props.adresse === "" ? (
                  <p>
                    <HomeIcon /> --
                  </p>
                ) : (
                  <p>
                    <HomeIcon />{" "}
                    <a
                      href={
                        "https://www.google.com/maps/search/" +
                        this.props.adresse
                      }
                      style={{ color: `${darck ? "#172431" : "#fff"}` }}
                      target="_blank"
                    >
                      {this.props.adresse}
                    </a>
                  </p>
                )}

                {this.props.siteweb === "" ? null : (
                  <p style={{ color: `${darck ? "#172431" : "#fff"}` }}>
                    <LanguageIcon /> {this.props.siteweb}{" "}
                  </p>
                )}
              </Card.Body>
            </Card>

            <Row style={{ marginTop: "18px" }}>
              <Col sm={6}>
                {/* {this.props.timbre ? (
                  <Alert style={{ width: "100%" }} variant={"success"}>
                    Avec Timbre
                  </Alert>
                ) : (
                  <Alert style={{ width: "100%" }} variant={"danger"}>
                    Sans Timbre
                  </Alert>
                )} */}
                <Alert style={{ width: "100%" }} variant={"success"}>
                  Solde Factures: {Number(this.props.soldfac).toFixed(3)}
                </Alert>
              </Col>

              <Col sm={6}>
                {/* {this.props.soustraitant ? (
                  <Alert style={{ width: "100%" }} variant={"success"}>
                    Avec soustraitant
                  </Alert>
                ) : (
                  <Alert style={{ width: "100%" }} variant={"danger"}>
                    Sans soustraitant
                  </Alert>
                )} */}
                <Alert style={{ width: "100%" }} variant={"danger"}>
                  Solde Global: {Number(this.props.soldfacbl).toFixed(3)}
                </Alert>
              </Col>
            </Row>

            <Card>
              <Card.Body
                style={{
                  backgroundColor: `${darck ? "#fff" : "#172431"}`,
                  borderTop: `${darck ? "" : "1px solid #fff"}`,
                  borderRight: `${darck ? "" : "1px solid #fff"}`,
                  borderLeft: `${darck ? "" : "1px solid #fff"}`,
                  borderBottom: `${darck ? "" : "1px solid #fff"}`,
                }}
              >
                <Row style={{ marginLeft: "10px" }}>
                  <Col sm={2}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: `${darck ? "#172431" : "#fff"}`,
                      }}
                    >
                      CIN
                    </p>
                  </Col>
                  <Col sm={3}>
                    {this.props.cin === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.cin}
                      </p>
                    )}
                  </Col>
                  <Col sm={3}>
                    <p
                      style={{
                        color: `${darck ? "#172431" : "#fff"}`,
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Identifiant
                    </p>
                  </Col>
                  <Col sm={4}>
                    {this.props.identifiant === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.identifiant}
                      </p>
                    )}
                  </Col>
                </Row>

                <Row style={{ marginLeft: "10px" }}>
                  <Col sm={2}>
                    <p
                      style={{
                        color: `${darck ? "gray" : "#fff"}`,
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Ville
                    </p>
                  </Col>
                  <Col sm={3}>
                    {this.props.ville === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.ville}
                      </p>
                    )}
                  </Col>
                  <Col sm={4}>
                    <p
                      style={{
                        color: `${darck ? "#172431" : "#fff"}`,
                        fontSize: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      Code Postal
                    </p>
                  </Col>
                  <Col sm={3}>
                    {this.props.codepostal === "" ? (
                      <p style={{ color: `${darck ? "gray" : "#fff"}` }}>--</p>
                    ) : (
                      <p
                        style={{
                          color: `${darck ? "gray" : "#fff"}`,
                          fontSize: "12px",
                        }}
                      >
                        {this.props.codepostal}
                      </p>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Row>
              <Col sm={10}></Col>
              <Col sm={2}>
                <SpeedDial
                  style={{ position: "absolute", bottom: "0px", right: "10px" }}
                  ariaLabel="SpeedDial openIcon example"
                  hidden={this.state.hidden}
                  icon={<EditIcon openIcon={<EditIcon />} />}
                  onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  FabProps={{ size: "small" }}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={() => {
                        this.handleClose();
                        action.name === "Imprimer" && this.affiche();
                        this.props.email !== "" &&
                          action.name === "Mail" &&
                          this.openMail();
                        this.props.email === "" &&
                          action.name === "Mail" &&
                          this.nomail();
                        action.name === "Modifier" && this.openModifier();
                        action.name === "Supprimer" &&
                          this.deleteclient(this.props.codeclient) &&
                          this.props.onHide();
                        action.name === "Relevé" && this.openReleveClient();
                      }}
                    />
                  ))}
                </SpeedDial>
                {/* <Button
                  // variant="primary"
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    right: "70px",
                    backgroundColor: "#4153AF",
                    border: "1px solid #4153AF",
                  }}
                  onClick={this.openReleveClient}
                >
                  Relevé
                </Button> */}
              </Col>
            </Row>

            <ModifierClientModal
              show={this.state.openModifierModal}
              onHide={ModifierModalClose}
              passager={this.props.passager}
              codeclient={this.props.codeclient}
              raisoc={this.props.raisoc}
              tel1={this.props.tel1}
              tel2={this.props.tel2}
              email={this.props.email}
              adresse={this.props.adresse}
              siteweb={this.props.siteweb}
              cin={this.props.cin}
              ville={this.props.ville}
              codepostal={this.props.codepostal}
              identifiant={this.props.identifiant}
              tauxfodec={this.props.tauxfodec}
              timbre={this.props.timbre}
              soustraitant={this.props.soustraitant}
              acontacter={this.props.acontacter}
              impot={this.props.impot}
              comptable={this.props.comptable}
              categoriefiscale={this.props.categoriefiscale}
              numexon={this.props.numexon}
              datexp={this.props.datexp}
              dateetabli={this.props.dateetabli}
            />
            <ReleveClientModal
              show={this.state.openReleveClientModal}
              onHide={ReleveClientModalClose}
              codeclient={this.props.codeclient}
              raisonsociale={this.props.raisoc}
              tel1={this.props.tel1}
             adresse= {this.props.adresse} 
            />

            <MailModal
              show={this.state.openMailModal}
              onHide={MailModalClose}
              email={this.props.email}
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ConfigDossier: state.ConfigDossier,
    darck: state.darck.darck,
  };
}
export default connect(mapStateToProps, null)(GetClientByID);
