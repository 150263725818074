const initialState = {
    AllReleverFRS: [],
  };
  export default function (state = initialState, action) {
    switch (action.type) {
      case "RELEVERFRS_GET":
        return {
          ...state,
          AllReleverFRS: action.payload,
        };
      default:
        return state;
    }
  }
  